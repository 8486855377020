import React from "react"
import { Grid, Button, TextField } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Loading from "components/Loading"
import { MessageField } from "components/MessageField"
import moment from "moment-timezone"
import "moment/locale/fr"
import { renderDate } from "utils/func"

moment.locale("fr")
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "100%",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}))
export const AbsenceRemove = ({
  globalError,
  values,
  handleSubmit,
  loading,
  timezone,
}) => {
  const theme = useTheme()
  const classes = useStyles()

  if (loading) {
    return <Loading />
  }
  
  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      {globalError && <MessageField message={globalError} severity={"error"} />}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="start"
            label="Début"
            name="start"
            fullWidth
            value={values && values.start ? renderDate(values.start, timezone) : ""}
            disabled={true}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id="end"
            label="Fin"
            name="end"
            fullWidth
            value={values && values.end ? renderDate(values.end, timezone) : ""}
            disabled={true}
          />
        </Grid>

        <Grid item >
           
        </Grid>
      </Grid>
    </form>
  )
}
