import React from 'react'
import {
  Grid,
  Button,
  TextField,
  InputLabel,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Loading from 'components/Loading'
import { MessageField } from 'components/MessageField'
import { SelectField } from 'components/SelectField'
import { SelectInput } from 'components/SelectInput'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: '100%',
    padding: 20
  },
  chip: {
    margin: theme.spacing(0.5)
  }
}))
export const ServiceEditIntern = ({
  values,
  touched,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,

  globalError,
  setFieldValue,

  categories,
  //regions,
  currency,

  canModify
}) => {
  const theme = useTheme()
  const classes = useStyles()
  if (isSubmitting) {
    return <Loading />
  }

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit}
    >
      {globalError && <MessageField message={globalError} severity={'error'} />}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <FormControlLabel
            //   disabled={!canModify}
            control={
              <Checkbox
                id='is_active'
                name='is_active'
                checked={values.is_active}
                onChange={async (_e, value) =>
                  await setFieldValue('is_active', value)
                }
              />
            }
            label={values.is_active ? 'Désactiver' : 'Activer'}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!canModify}
            value={values.name}
            id='name'
            name='name'
            label='Nom'
            onChange={handleChange}
            error={touched.name && errors.name ? true : false}
            fullWidth
            helperText={touched.name && errors.name ? errors.name : null}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SelectInput
            key={'select-blood_group-patient'}
            name='category_id'
            label={'Catégorie'}
            fullWidth={true}
            value={values.category_id}
            options={categories}
            required={false}
            helperText={
              touched.category_id && errors.category_id ? errors.category_id : null
            }
            error={touched.category_id && errors.category_id ? true : false}
            onChange={async ({ target: { value } }) => {
              try {
                await setFieldValue('category_id', value)
              } catch (error) {
                window.alert(error.message)
              }
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputLabel required>Prix en {currency}</InputLabel>

          <TextField
            fullWidth
            value={values.price}
            name='price'
            id='price'
            onChange={handleChange}
            type='number'
            //  disabled={!values.show_price}
            helperText={touched.price && errors.price ? errors.price : null}
            error={touched.price && errors.price ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            multiline
            rows={2}
            disabled={!canModify}
            id='description'
            name='description'
            label='Description'
            value={values.description}
            onChange={handleChange}
            style={{ width: '100%' }}
          />

          {touched.description && errors.description && (
            <span style={{ color: 'red' }}>{errors.description}</span>
          )}
        </Grid>

        <Grid item xs={6}>
          <div>
            <Button
              color='primary'
              disabled={!canModify}
              type='submit'
              onClick={handleSubmit}
              variant='contained'
            >
              enregistrer
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}
