import React, { useState } from "react"
import { useQuery, useMutation } from "react-apollo"
import Loading from "components/Loading"
import { Grid, Typography, Box } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import IconButton from "@material-ui/core/IconButton"
import NotificationIcon from "@material-ui/icons/NotificationImportant"
import Pagination from "@material-ui/lab/Pagination"
import { Accordion } from "components/Accordion"
import { readNumber } from "utils/readnumber"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { oneSettingAdminQuery } from "graphql/query/setting_admin"
import { renderDate } from "utils/func"
import { Fragment } from "react"
import { salerTeamUsersQuery } from "graphql/query/user"
import { TextField } from "@material-ui/core"
import moment from "moment-timezone"
import { Button } from "@material-ui/core"
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';

import { toast } from "react-toastify"
import { findCompanyHistoryQuery } from "graphql/query/comp_history"
import { COMPANY_ID_STORAGE } from "utils/static_constants"
import { removeCompHistoryMutation } from "graphql/mutation/comp_history"

const CompHistoryView = () => {
  const now = moment()
  const from = now.clone().subtract(1, "months").startOf("month")
  const to = now.clone().add(1, "months").endOf("month")
  const from_date = from.format("YYYY-MM-DD")
  const to_date = to.format("YYYY-MM-DD")
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [limit] = useState(10)
  const [author_ids, setAuthors] = useState([])
  const [startDate, setStartDate] = useState(from_date)
  const [endDate, setEndDate] = useState(to_date)

  const [from_remove, setFromRemove] = useState(null)
  const [to_remove, setToRemove] = useState(null)

  const [expanded1, setExpanded1] = useState(true)
  const [expanded2, setExpanded2] = useState(true)
  const [removeHistories] = useMutation(removeCompHistoryMutation)
  const { data: generalSetting } = useQuery(oneSettingAdminQuery)

  const {
    loading: loading1,
    data: historyData,
    fetchMore,
    refetch,
  } = useQuery(findCompanyHistoryQuery, {
    variables: {
      page,
      limit,
      start: from_date,
      end: to_date,
      company_id: localStorage.getItem(COMPANY_ID_STORAGE),
    },
  })
  const { data: senrvUsersData, loading: loading4 } = useQuery(salerTeamUsersQuery, {
    variables: {
      company_id: localStorage.getItem(COMPANY_ID_STORAGE),
    },
  })
  const histories =
    historyData &&
    historyData.findCompanyHistory &&
    historyData.findCompanyHistory.docs
      ? historyData.findCompanyHistory
      : null

  const timezone =
    generalSetting &&
    generalSetting.oneSettingAdmin &&
    generalSetting.oneSettingAdmin.time_zone
      ? generalSetting.oneSettingAdmin.time_zone
      : "Africa/Dakar"

  const optionsUsers =
    senrvUsersData && senrvUsersData.salerTeamUsers
      ? [
          ...new Set(
            senrvUsersData.salerTeamUsers.map((e) => {
              const gender = e.gender == "male" ? "Monsieur" : "Madame"
              return `${gender} ${e.name}-->${e.phone}`
            })
          ),
        ]
      : []
  const remove = async () => {
    try {
      if (!from_remove || !to_remove) {
        window.alert("Choisir les plages de suppression")
        return
      } else if (moment(from_remove).isAfter(moment(to_remove))) {
        window.alert("dates incorrectes")
        return
      } else {
        const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
        await setLoading(true)
        const res = await removeHistories({
          variables: {
            from_date: from_remove,
            to_date: to_remove,
            company_id,
          },
        })

        await setLoading(false)
        const { ok, errors } = res.data.removeCompHistory
        if (ok) {
          await toast.success("historique supprimmée avec succès")

          await setFromRemove(null)
          await setToRemove(null)

          await clearFilter()
        } else {
          window.alert(errors[0].message)
        }
      }
    } catch (error) {
      await setLoading(false)
      window.alert(error.message)
    }
  }
/*   const selectUser = async ({ target: { value } }) => {
    try {
      if (value) {
        const phone = value.split("-->")[1]
        const user =
          senrvUsersData &&
          senrvUsersData.salerTeamUsers &&
          senrvUsersData.salerTeamUsers.length > 0
            ? senrvUsersData.salerTeamUsers.find(
                (user) => user && user.phone == phone
              )
            : null
        if (user && user.id) {
          await setAuthors([user.id])
        }
      } else {
        await setAuthors([])
      }
    } catch (error) {
      window.alert(error.message)
    }
  } */

  const selectUser = async (e, value) => {
    try {
      //senrvUsersData.salerTeamUsers
      e.preventDefault()
      if (value) {
        const auth_ids = []

        value.forEach((val) => {
          const phone = val.split("-->")[1]
          const user =
            senrvUsersData &&
            senrvUsersData.salerTeamUsers &&
            senrvUsersData.salerTeamUsers.length > 0
              ? senrvUsersData.salerTeamUsers.find((user) => user && user.phone == phone)
              : null

          if (user && user.id) {
            auth_ids.push(user.id)
          }
        })

        await setAuthors(auth_ids)
      } else {
        await setAuthors([])
      }
    } catch (error) {
      window.alert(error.message)
    }
  }
  const computeVariables = async (page) => {
    await setPage(page)
    const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
    let variables = { page, limit, company_id }

    if (startDate) {
      variables.start = startDate
    }

    if (endDate) {
      variables.end = endDate
    }
    if (author_ids.length > 0) {
      variables.author_ids = author_ids
    }
    return variables
  }

  const onPageChange = async (page) => {
    try {
      let variables = await computeVariables(page)
      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { findCompanyHistory } = fetchMoreResult

          return Object.assign({}, prev, {
            findCompanyHistory,
          })
        },
      })
    } catch (error) {
      window.alert(error.message)
    }
  }

  const search = async () => {
    try {
      if (startDate && endDate) {
        if (moment(startDate).isAfter(moment(endDate))) {
          window.alert("Dates incorrectes")
          return
        }
      }
      const variables = await computeVariables(1)
      await setLoading(true)

      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { findCompanyHistory } = fetchMoreResult

          return Object.assign({}, prev, {
            findCompanyHistory,
          })
        },
      })
      await setLoading(false)
    } catch (error) {
      window.alert(error.message)
    }
  }
  const clearFilter = async () => {
    try {
      await setAuthors([])
      await setStartDate(from_date)
      await setEndDate(to_date)
      await setPage(1)
      let variables = await computeVariables(1)
      await setLoading(true)
      await refetch({ variables })
      await setLoading(false)
    } catch (error) {
      await setLoading(false)
      window.alert(error.message)
    }
  }

  return (
    <Fragment>
      <Accordion
        id={"search-client-filter"}
        title={"Suppression activités"}
        expanded={expanded2}
        setExpanded={setExpanded2}
      >
        <Grid container spacing={3} >
          <Grid item xs={12} sm={6}>
            <TextField
              id="start"
              label="Du "
              name="start"
              type="date"
              fullWidth
              value={from_remove}
              onChange={async ({ target: { value } }) => {
                await setFromRemove(value)
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="end"
              label="Au"
              name="end"
              type="date"
              value={to_remove}
              onChange={async ({ target: { value } }) => {
                await setToRemove(value)
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <div className="controle-btn">
            <Button
              color="secondary"
              startIcon={<DeleteIcon />}
              disabled={loading}
              //type="submit"
              onClick={remove}
              variant="contained"
            >
              Supprimer
            </Button>
          </div>

          {loading && <Loading />}
        </Grid>
      </Accordion>
      <Accordion
        id={"search-client-activities"}
        title={"Historiques des activités"}
        expanded={expanded1}
        setExpanded={setExpanded1}
      >
        <Grid container spacing={3} >
          <Grid item xs={12} sm={6}>
            <TextField
              id="start"
              label="Du "
              name="start"
              type="date"
              fullWidth
              value={startDate}
              onChange={async ({ target: { value } }) => {
                await setStartDate(value)
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="end"
              label="Au"
              name="end"
              type="date"
              value={endDate}
              onChange={async ({ target: { value } }) => {
                await setEndDate(value)
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              multiple={true}
              noOptionsText={"Aucun résultat"}
              id="service"
              label={"Personnel"}
              options={optionsUsers}
              onChange={selectUser}
              getOptionLabel={(option) => option}
              //   style={{ margin: 100 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Personnel entreprise"
                  // variant="outlined"
                  required
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
              <div className="content-btn-profil-footer">
                <Button
                  className="buttonLeft"
                  color="secondary"
                  disabled={loading}
                  //type="submit"
                  onClick={clearFilter}
                  variant="contained"
                >
                  Effacer les filtres
                </Button>
                <Button
                  color="primary"
                  disabled={loading}
                  //type="submit"
                  onClick={search}
                  variant="contained"
                  startIcon={<SearchIcon />}
                >
                  Chercher
                </Button>
              </div>
          </Grid>
        
          {histories && histories.totalPages && (
            <Grid item xs={12} sm={12} style={{marginTop:20}}>
              <Pagination
                disabled={loading}
                count={histories.totalPages}
                page={page}
                onChange={async (_event, value) => {
                  /*  if (value.toString() !== page.toString()) {
                  await onPageChange(value)
                } */

                  await onPageChange(value)
                }}
              />
            </Grid>
          )}
          {loading1 && <Loading />}
          <Grid item xs={12} sm={12}>
            <Box marginTop={2}>
              {!loading && histories && histories.totalDocs && (
                <Typography
                  variant="body2"
                  style={{ textAlign: "center", marginBottom: 20 }}
                >
                  {readNumber(histories.totalDocs) + " activité(s)"}
                </Typography>
              )}
              {histories &&
                histories.docs &&
                histories.docs.map((item, index) => (
                  <Alert
                    style={{ margin: 10 }}
                    className={"notif"}
                    key={index}
                    severity={"success"}
                    icon={<NotificationIcon  style={{marginTop:10}}/>}
                    action={
                      <React.Fragment>
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          // onClick={async () => {
                          //   await removeItem({ variables: { id: notif.id } })
                          // }}
                          style={{ margin: 30 }}
                        >
                          {/*  <CloseIcon fontSize="inherit" color="error" /> */}
                        </IconButton>
                        {/*   <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={async () => {
                                    await props.history.push(`${EVENT_ONE_PATH}/${notif.notif_type_id}`)
                                  }}
                                  style={{ margin: 30 }}
                                >
                                  <CalendarIcon fontSize="inherit" color="action" />
                                </IconButton> */}
                      </React.Fragment>
                    }
                  >
                    <h4>
                      {item.title + " "} {renderDate(item.createdAt, timezone)}
                    </h4>
                    <p className={"descriptionNotif"}>{item.description}</p>
                  </Alert>
                ))}
            </Box>
          </Grid>
        </Grid>
      </Accordion>
    </Fragment>
  )
}
export default CompHistoryView
