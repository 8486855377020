import React, { useState } from 'react'
import { useTheme } from '@material-ui/styles'
import { Button, Grid, TextField } from '@material-ui/core'
import { Accordion } from 'components/Accordion'
import AddIcon from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search'
import DeleteIcon from '@material-ui/icons/Delete'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { colors } from 'utils/constants'

export const ServiceToolbar = (props) => {
  const {
    openModal,
    name,
    search,
    setName,
    clearFilter,
    canModify,
    openModalIntern
  } = props

  const theme = useTheme()
  const [expanded1, setExpanded1] = useState(true)
  return (
    <Accordion
      id='params-calendar-services'
      title={'Rechercher un service'}
      expanded={expanded1}
      setExpanded={setExpanded1}
    >
      <Grid container>
        {canModify && (
          <div className='new-service-content'>
            <Button
              color='primary'
              variant='contained'
              onClick={openModal}
              startIcon={<AddIcon />}
            >
              Nouveau service
            </Button>
          </div>
        )}

        {canModify && (
          <div className='new-service-content new-service-content-two'>
            <Button
              color='primary'
              variant='contained'
              onClick={openModalIntern}
              startIcon={<LockOutlinedIcon />}
            >
              Nouveau service interne
            </Button>
          </div>
        )}
        <Grid container spacing={3} style={{ width: '100%' }}>
          <Grid item xs={12} sm={12}>
            <TextField
              id='name'
              name='name'
              label='Nom'
              InputLabelProps={{
                shrink: true
              }}
              onChange={async ({ target: { value } }) => await setName(value)}
              fullWidth
              value={name}
            />
          </Grid>

          <div className='controle-btn content-btn-profil-footer'>
            <Button
              color='secondary'
              //disabled={!formState.isValid}
              onClick={clearFilter}
              variant='contained'
              style={{ marginRight: 6 }}
              startIcon={<DeleteIcon />}
            >
              Effacer les filtres
            </Button>
            <Button
              color='primary'
              //disabled={!formState.isValid}
              onClick={search}
              variant='contained'
              startIcon={<SearchIcon />}
            >
              Recherche
            </Button>
          </div>
        </Grid>
      </Grid>
    </Accordion>
  )
}
