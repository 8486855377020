import React from "react"
import { Select, Input, InputLabel, MenuItem, Chip } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}))
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
function getStyles(name, personName, theme) {
  return {
    fontWeight:
    personName && personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}

export const SelectField = ({
  handleChange,
  name,
  label,
  value,
  dataSource,
  helperText,
  multiple,
  error,
  open,
  required,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <React.Fragment>
      <InputLabel id={name} required={required}>
        {label}
      </InputLabel>
      <Select
        error={error}
        helperText={helperText}
        fullWidth
        required
        labelId={name}
        id={name}
        name={name}
        multiple={multiple}
        value={value}
        onChange={handleChange}
        input={<Input id={name} />}
        open={open}
        renderValue={(selected) => {
          let data = []
          if (Array.isArray(selected)) {
            data = selected
          } else {
            data = [selected]
          }
          return (
            <div className={classes.chips}>
              {data.map((value) => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          )
        }}
        MenuProps={MenuProps}
      >
        {dataSource.map((name) => (
          <MenuItem key={name} value={name} style={getStyles(name, value, theme)}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </React.Fragment>
  )
}
