import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { AuthRoute } from './components/AuthRoute/AuthRoute'
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts'
import SettingView from './views/Setting/SettingView'
import SignInView from './views/SignIn/SignIn'
import {
  LOGIN_PATH,
  SETTING_PATH,
  SERVICE_PATH,
  TEAM_PATH,
  COMPANY_PATH,
  PROFILE_PATH,
  EVENT_PATH,
  NOTIF_PATH,
  NEW_EVENT_COMPANY_PATH,
  ABSENCE_PATH,
  COMPANY_CLIENT_PATH,
  CLIENT_NOTICE_PATH,
  CLIENT_SIGNAL_PATH,
  FACTURE_PATH,
  EVENT_WORKER_PATH,
  COMP_HISTORY_PATH,
  EVENT_ONE_PATH,
  ONE_COMPANY_FACTURE_PATH,
  FACTURE_LIST_PATH,
  PASSWORD_FORGOT_PATH,
  CONFIRM_PASSWORD_LOST_PATH,
  CLIENT_HISTORY_PATH,
  APP_MOBILE_PATH,
  NEW_PATIENT_CLIENT_PATH
} from 'utils/static_constants'
import UnAuthRoute from 'components/UnAuthRoute'
import ServiceView from 'views/Service/ServiceView'
import TeamView from 'views/Team/TeamView'
import CompanyView from 'views/Company/CompanyView'
import ProfileView from 'views/Profile/ProfileView'
import EventView from 'views/Event/EventView'
import EventWorkerView from './views/EventWorker/EventWorkerView'
import NotifView from 'views/Notif/NotifView'
import RdvView from 'views/Rdv/RdvView'
import NewEventView from 'views/NewEvent/NewEventView'
import AbsenceView from 'views/Absence/AbsenceView'
import CompanyClientView from './views/CompanyClient/CompanyClientView'
import AvisView from 'views/Avis/AvisView'
import ClientSignalView from 'views/ClientSignal/ClientSignalView'
import FactureView from 'views/Facture/FactureView'
import CompHistoryView from 'views/CompHistory/CompHistoryView'
import OneFactureView from 'views/OneFacture/OneFactureView'
import BillHistoryView from 'views/BillHistory/BillHistoryView'
import PasswordForgotView from 'views/PasswordForgot/PasswordForgotView'
import ConfirmPasswordLostView from 'views/ConfirmPasswordLost/ConfirmPasswordLostView'
import ClientHistoryView from 'views/ClientHistory/ClientHistoryView'
import MobileView from 'views/MobileApp/MobileView'
import NewPatientView from 'views/NewPatient/NewPatientView'

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from='/' to={COMPANY_PATH} />
      <AuthRoute
        component={CompanyView}
        exact
        layout={MainLayout}
        path={COMPANY_PATH}
      />

      <AuthRoute
        component={SettingView}
        exact
        layout={MainLayout}
        path={SETTING_PATH}
      />

      <AuthRoute
        component={MobileView}
        exact
        layout={MainLayout}
        path={APP_MOBILE_PATH}
      />

      <AuthRoute
        component={ClientSignalView}
        exact
        layout={MainLayout}
        path={CLIENT_SIGNAL_PATH}
      />
      <AuthRoute component={EventView} exact layout={MainLayout} path={EVENT_PATH} />
      <AuthRoute
        component={EventWorkerView}
        exact
        layout={MainLayout}
        path={EVENT_WORKER_PATH}
      />
      <AuthRoute
        component={RdvView}
        exact
        layout={MainLayout}
        path={`${EVENT_ONE_PATH}/:id`}
      />

      <AuthRoute component={TeamView} exact layout={MainLayout} path={TEAM_PATH} />
      <AuthRoute
        component={ProfileView}
        exact
        layout={MainLayout}
        path={PROFILE_PATH}
      />

      <AuthRoute
        component={AbsenceView}
        exact
        layout={MainLayout}
        path={ABSENCE_PATH}
      />

      <AuthRoute
        component={CompHistoryView}
        exact
        layout={MainLayout}
        path={COMP_HISTORY_PATH}
      />

      <AuthRoute
        component={NewPatientView}
        exact
        layout={MainLayout}
        path={NEW_PATIENT_CLIENT_PATH}
      />

      <AuthRoute
        component={FactureView}
        exact
        layout={MainLayout}
        path={FACTURE_PATH}
      />

      <AuthRoute
        component={OneFactureView}
        exact
        layout={MainLayout}
        path={`${ONE_COMPANY_FACTURE_PATH}/:bill_id`}
      />

      <AuthRoute
        component={ClientHistoryView}
        exact
        layout={MainLayout}
        path={`${CLIENT_HISTORY_PATH}/:company_id/:patient_id`}
      />

      <AuthRoute
        component={ServiceView}
        exact
        layout={MainLayout}
        path={SERVICE_PATH}
      />

      <AuthRoute
        component={NewEventView}
        exact
        layout={MainLayout}
        path={NEW_EVENT_COMPANY_PATH}
      />

      <AuthRoute
        component={AvisView}
        exact
        layout={MainLayout}
        path={CLIENT_NOTICE_PATH}
      />

      <AuthRoute component={NotifView} exact layout={MainLayout} path={NOTIF_PATH} />

      <AuthRoute
        component={CompanyClientView}
        exact
        layout={MainLayout}
        path={COMPANY_CLIENT_PATH}
      />
      <AuthRoute
        component={BillHistoryView}
        exact
        layout={MainLayout}
        path={FACTURE_LIST_PATH}
      />
      <UnAuthRoute
        component={SignInView}
        exact
        layout={MinimalLayout}
        path={LOGIN_PATH}
      />

      <UnAuthRoute
        component={PasswordForgotView}
        exact
        layout={MinimalLayout}
        path={PASSWORD_FORGOT_PATH}
      />

      <UnAuthRoute
        component={ConfirmPasswordLostView}
        exact
        layout={MinimalLayout}
        path={CONFIRM_PASSWORD_LOST_PATH}
      />
      <Redirect to='/not-found' />
    </Switch>
  )
}

export default Routes
