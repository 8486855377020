import gql from 'graphql-tag'

export const createPatientMutation = gql`
  mutation createPatient($company_id: String!, $patient: PatientInput) {
    createPatient(company_id: $company_id, patient: $patient) {
      ok

      errors {
        path
        message
      }
    }
  }
`

export const deletePatientMutation = gql`
  mutation deletePatient($company_id: String!, $id: String!) {
    deletePatient(company_id: $company_id, id: $id) {
      ok
      id
      errors {
        path
        message
      }
    }
  }
`
//  updatePatient(company_id:String!,patient:PatientUpdateInput,id:String!):UpdatePatientResponse
export const updatePatientMutation = gql`
  mutation updatePatient(
    $company_id: String!
    $patient: PatientUpdateInput
    $id: String!
  ) {
    updatePatient(company_id: $company_id, patient: $patient, id: $id) {
      ok

      errors {
        path
        message
      }

      patient {
        id
        company_id
        client_id
        region
        address
        birth_year
        height
        weight
        register_number
        blood_group
        name
        lastname
        phone
        email
        gender

        is_old_smoking
        is_smoking

        has_vital_card
        vital_card_number

        has_health_insurance
        health_insurance {
          name
          validity_end
          percentage
        }

        has_current_desease
        current_deseases {
          name
          description
          year
        }

        has_old_desease
        old_deseases {
          name
          description
          year
        }

        has_allergy
        allergies {
          name
          description
          year
        }

        updatedAt
        createdAt
      }
    }
  }
`
