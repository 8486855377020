import React, { useState, Fragment } from 'react'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { toast } from 'react-toastify'
import { useQuery, useMutation } from 'react-apollo'

import Loading from 'components/Loading'
import {
  CLIENT_HISTORY_PATH,
  COMPANY_ID_STORAGE,
  NEW_PATIENT_CLIENT_PATH
} from 'utils/static_constants'
import {
  Button,
  Grid,
  makeStyles,
  TextField,
  IconButton,
  Paper
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import { Accordion } from 'components/Accordion'
import { readNumber } from 'utils/readnumber'

import { oneSettingAdminQuery } from 'graphql/query/setting_admin'
import { Link } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import SearchIcon from '@material-ui/icons/Search'
import HistoryIcon from '@material-ui/icons/History'

import { removePatientMutation } from 'graphql/mutation/patient_history'
import { compPatientsQuery } from 'graphql/query/patient'
import { deletePatientMutation } from 'graphql/mutation/patient'

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650
  },

  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1)
  }
}))
const CompanyClientView = ({ history }) => {
  const [page, setPage] = useState(1)

  const [loading, setLoading] = useState(false)

  const [expanded1, setExpanded1] = useState(true)
  const [phone, setPhone] = useState(undefined)
  const [register_number, setRegisterNumber] = useState(undefined)

  const [removeClient] = useMutation(deletePatientMutation)
  const { loading: loading3, data: settingAdminData } = useQuery(
    oneSettingAdminQuery
  )

  const { loading: loading1, data, fetchMore, refetch } = useQuery(
    compPatientsQuery,
    {
      variables: { page: 1, company_id: localStorage.getItem(COMPANY_ID_STORAGE) }
    }
  )

  const classes = useStyles()

  const phone_code =
    settingAdminData &&
    settingAdminData.oneSettingAdmin &&
    settingAdminData.oneSettingAdmin.phone_code
      ? settingAdminData.oneSettingAdmin.phone_code
      : null
  
  const clients = data && data.compPatients ? data.compPatients : null
  if (loading1 || loading || loading3) {
    return <Loading />
  }
  const onPageChange = async (page) => {
    try {
      await setLoading(true)
      await setPage(page)
      const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
      let variables = { page, company_id }

      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { compPatients } = fetchMoreResult
          return Object.assign({}, prev, {
            compPatients
          })
        }
      })

      await setLoading(false)
    } catch (error) {
      throw error
    }
  }

  const clearFilter = async () => {
    try {
      await setPage(1)
      await setPhone(undefined)
      await setRegisterNumber(undefined)

      const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
      await refetch({ variables: { page: 1, company_id } })
    } catch (error) {
      window.alert(error.message)
    }
  }

  const remove = async (id) => {
    if (window.confirm('êtes vous sûr(e) de vouloir supprimer ce client')) {
      const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
      const res = await removeClient({
        variables: {
          company_id,
          id
        }
      })
      console.log('res', res)
      await setLoading(true)
      const { ok, errors } = res.data.deletePatient
      await setLoading(false)
      if (ok) {
        await toast.success(`Patient supprimé `)

        await clearFilter()
      } else {
        errors.forEach(async (error) => {
          window.alert(error.message)
        })
      }
    }
  }

  const computeVariables = async (page) => {
    await setPage(page)
    const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
    let variables = { page, company_id }
    try {
      if (phone) {
        variables.phone = phone
      }

      if (register_number) {
        variables.register_number = register_number
      }

      return variables
    } catch (error) {
      window.alert(error.message)
    }
  }
  const search = async () => {
    try {
      const variables = await computeVariables(1)

      await setLoading(true)

      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { compPatients } = fetchMoreResult

          return Object.assign({}, prev, {
            compPatients
          })
        }
      })
      await setLoading(false)
    } catch (error) {
      await setLoading(false)
      window.alert(error.message)
    }
  }
  return (
    <Fragment>
      <Accordion
        id={'search-client-form'}
        title={'Listes des patients'}
        expanded={expanded1}
        setExpanded={setExpanded1}
        accordionStyle={true}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className='new-service-content'>
              <Button
                color='primary'
                variant='contained'
                onClick={async () => {
                  // await setCreateModal(true)
                  await history.push(NEW_PATIENT_CLIENT_PATH)
                }}
                startIcon={<AddIcon />}
              >
                Ajouter un nouveau patient
              </Button>
            </div>
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              id='register_number'
              label={`numéro registre`}
              name='register_number'
              value={register_number}
              onChange={async ({ target: { value } }) => {
                await setRegisterNumber(value)
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id='phone'
              label={`Téléphone ${phone_code}`}
              name='phone'
              type='phone'
              value={phone}
              onChange={async ({ target: { value } }) => {
                await setPhone(value)
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

          <div className='content-btn-profil-footer controle-btn'>
            <Button
              className='buttonLeft'
              color='secondary'
              //type="submit"
              onClick={clearFilter}
              variant='contained'
              startIcon={<DeleteIcon />}
            >
              effacer la recherche
            </Button>

            <Button
              //  style={{ backgroundColor: "red", color: "white" }}
              color='primary'
              //type="submit"
              onClick={search}
              variant='contained'
              startIcon={<SearchIcon />}
            >
              chercher
            </Button>
          </div>
          {clients && (
            <Grid item lg={12} xs={12} sm={12} style={{ marginTop: 15 }}>
              <Pagination
                count={clients.totalPages}
                page={page}
                onChange={async (_event, value) => {
                  if (value.toString() !== page.toString()) {
                    await onPageChange(value)
                  }
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12}>
            <h3 style={{ textAlign: 'center' }}>
              {' '}
              {readNumber(clients.totalDocs) + ' patient(s)'}
            </h3>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Numéro</TableCell>
                    <TableCell>Patient</TableCell>
                    <TableCell>Téléphone</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clients &&
                    clients.docs.map((evt, index) => (
                      <TableRow key={index}>
                        <TableCell>{evt.register_number}</TableCell>
                        <TableCell>
                          <Link
                            to={`${CLIENT_HISTORY_PATH}/${localStorage.getItem(
                              COMPANY_ID_STORAGE
                            )}/${evt.id}`}
                          >
                            {`${evt.gender == 'male' ? 'Monsieur' : 'Madame'} ${
                              evt.lastname
                            } ${evt.name}`}
                          </Link>
                        </TableCell>

                        <TableCell>{evt.phone}</TableCell>
                        <TableCell>
                          <IconButton component='span'onClick={async (e) => {
                                try {
                                  e.preventDefault()
                                  await remove(evt.id)
                                } catch (error) {
                                  throw error
                                }
                              }}>
                            <DeleteIcon
                              className='buttonLeft'
                              //type="submit"
                             
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Accordion>
    </Fragment>
  )
}
export default CompanyClientView
