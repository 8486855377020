import React from 'react'
import { MenuItem, TextField } from '@material-ui/core'

export const SelectInput = ({
  label,
  name,
  value,
  onChange,
  key,
  fullWidth,
  required,
  helperText,
  options,
  error
}) => {
  return (
    <TextField
      id={key}
      required={required}
      fullWidth={fullWidth}
      name={name}
      select
      label={label}
      value={value}
      onChange={onChange}
      helperText={helperText}
      error={error ? error : ''}
    >
      {options.map((option, index) => (
        <MenuItem key={index} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  )
}
