import React, { useState } from "react"
import { useQuery, useMutation } from "react-apollo"
import moment from "moment-timezone"
import { findEventsWorkerQuery } from "../../graphql/query/event"
import { toast } from "react-toastify"
import Loading from "../../components/Loading"
import { COMPANY_ID_STORAGE } from "../../utils/static_constants"
import { EventCalendar } from "./components/EventCalendar"
import { settingQuery } from "../../graphql/query/setting"
import { oneSettingAdminQuery } from "graphql/query/setting_admin"
import { EventList } from "./components/EventList"
import { ModalDialog } from "components/ModalDialog"
import { EventEdit } from "./components/EventEdit"
import { salerTeamUsersQuery } from "graphql/query/user"
import {
  cancelEventCompMutation,
  signalEventMutation,
  updateEventWorkersMutation,
} from "graphql/mutation/event"
import { EventSearch } from "./components/EventSearch"
import { oneCompanyQuery } from "graphql/query/company"
import { allServicesQuery } from "graphql/query/service"
import { colors } from "utils/constants"

export default function EventViewList() {
  const [eventVisible, setEventVisible] = useState(false)
  const [editedEvent, setEditedEvent] = useState(null)
  const [updateWorkersLoading, setUpdateWorkersLoading] = useState(false)

  //setGlobalSignalError
  const [globalSignalError, setGlobalSignalError] = useState("")
  const [selectedWorkers, setSelectedWorkers] = useState([])
  const [updateLoading, setUpdateLoading] = useState(false)
  const [changedWorkers, setChangedWorkers] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [eventNumber, setEventNumber] = useState("")
  const [services, setServices] = useState([])
  const [categories, setCategories] = useState([])
  const [start, setStart] = useState(moment(new Date()).format("MM-DD-YYYY"))
  const [selectedWorkersSearch, setSelectedWorkersSearch] = useState([])
  const [globalError, setGlobalError] = useState("")
  const { loading: loading1, data: generalSetting } = useQuery(oneSettingAdminQuery)

  const { data: settingData, loading: loading2 } = useQuery(settingQuery, {
    variables: {
      company_id: localStorage.getItem(COMPANY_ID_STORAGE),
    },
  })

  const {
    loading: loading3,
    data,
    fetchMore,
    refetch: refetchEvents,
  } = useQuery(findEventsWorkerQuery, {
    variables: {
      page: currentPage,
      company_id: localStorage.getItem(COMPANY_ID_STORAGE),
      start: moment(new Date()).format("MM-DD-YYYY"),
    },
    fetchPolicy: "cache-and-network",
  })

  const { data: salerTeamUsersData, loading: loading4 } = useQuery(
    salerTeamUsersQuery,
    {
      variables: {
        company_id: localStorage.getItem(COMPANY_ID_STORAGE),
      },
    }
  )
  const { loading: loading5, data: compData } = useQuery(oneCompanyQuery, {
    variables: { company_id: localStorage.getItem(COMPANY_ID_STORAGE) },
  })
  const { loading: loading6, data: serviceData } = useQuery(allServicesQuery, {
    variables: { company_id: localStorage.getItem(COMPANY_ID_STORAGE) },
  })

  const [signalEvent] = useMutation(signalEventMutation, {
    async update(
      cache,
      {
        data: {
          signalEvent: { ok, id, finished, author_presence, event_signaled },
        },
      }
    ) {
      if (ok) {
        let variables = await computeVariables(currentPage)
        const { findEventsWorker } = await cache.readQuery({
          query: findEventsWorkerQuery,
          variables,
        })

        let docs = findEventsWorker.docs

        const eventIndex = findEventsWorker.docs.findIndex(
          (d) => d.id.toString() === id.toString()
        )

        if (eventIndex > -1) {
          docs[eventIndex] = {
            ...docs[eventIndex],
            finished,
            author_presence,
            event_signaled,
          }

          await cache.writeQuery({
            query: findEventsWorkerQuery,
            data: {
              findEventsWorker: {
                ...findEventsWorker,
                docs,
              },
            },
          })
        }
      }
    },
  })
  const [updateWorkers] = useMutation(updateEventWorkersMutation, {
    async update(
      cache,
      {
        data: {
          updateEventWorkers: { ok, workers },
        },
      }
    ) {
      if (ok) {
        let variables = await computeVariables(currentPage)
        const { findEventsWorker } = await cache.readQuery({
          query: findEventsWorkerQuery,
          variables,
        })

        let docs = findEventsWorker.docs

        const eventIndex = findEventsWorker.docs.findIndex(
          (d) => d.id.toString() === editedEvent.id.toString()
        )

        if (eventIndex >= 0) {
          let event = docs[eventIndex]

          event.workers = workers

          docs[eventIndex] = event

          await cache.writeQuery({
            query: findEventsWorkerQuery,
            data: {
              findEventsWorker: {
                ...findEventsWorker,
                docs,
              },
            },
          })
        }
      }
    },
  })

  const [removeItem] = useMutation(cancelEventCompMutation, {
    async update(
      cache,
      {
        data: {
          cancelEventComp: { ok, id },
        },
      }
    ) {
      if (ok) {
        let variables = await computeVariables(currentPage)
        const { findEventsWorker } = await cache.readQuery({
          query: findEventsWorkerQuery,
          variables,
        })

        let docs = findEventsWorker.docs

        const eventIndex = findEventsWorker.docs.findIndex(
          (d) => d.id.toString() === id.toString()
        )

        if (eventIndex > -1) {
          docs.splice(eventIndex, 1)
        }

        await cache.writeQuery({
          query: findEventsWorkerQuery,
          data: {
            findEventsWorker: {
              ...findEventsWorker,
              totalDocs: findEventsWorker.totalDocs - 1,
              docs,
            },
          },
        })
      }
    },
  })
  if (loading1 || loading2 || loading3 || loading4 || loading5 || loading6) {
    return <Loading />
  }

  if (!data || !data.findEventsWorker) {
    return null
  }
  const selectEvent = async (id) => {
    try {
      const event = docs.find((e) => e.id.toString() === id.toString())
      if (event) {
        await setEditedEvent(event)
        await setEventVisible(true)
      }
    } catch (error) {
      window.alert("Erreur", error.message)
    }
  }

  const signal = async (author_presence, id) => {
    try {
      if (window.confirm("êtes vous sûr(e) de vouloir dsignaler cette action ?")) {
        const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
        const res = await signalEvent({
          variables: { id, company_id, author_presence },
        })

        const { ok, errors } = res.data.signalEvent

        if (ok) {
          await setChangedWorkers(false)
          await setEventVisible(false)
          await setEditedEvent(null)
          await setSelectedWorkers([])
          await setGlobalSignalError("")

          toast.success("Rendez-vous signalé avec succès")
        } else {
          errors.forEach(async (error) => {
            if (error.path && error.path.toString() === "global") {
              await setGlobalSignalError(error.message)
            }
          })
        }
      }
    } catch (error) {
      window.alert("Erreur", error.message)
    }
  }
  const selectWorkers = async ({ target: { value } }) => {
    try {
      await setChangedWorkers(true)
      await setSelectedWorkers(value)
    } catch (error) {
      window.alert("Erreur", error.message)
    }
  }
  const selectSearchUsers = async ({ target: { value } }) => {
    try {
      await setSelectedWorkersSearch(value)
    } catch (error) {
      window.alert("Erreur", error.message)
    }
  }
  const selectSearchServices = async ({ target: { value } }) => {
    try {
      await setServices(value)
    } catch (error) {
      window.alert("Erreur", error.message)
    }
  }
  const selectSearchCategories = async ({ target: { value } }) => {
    try {
      await setCategories(value)
    } catch (error) {
      window.alert("Erreur", error.message)
    }
  }

  const removeEvent = async (id) => {
    try {
      if (window.confirm("êtes vous sûr(e) de vouloir annuler ce rendez-vous ?")) {
        const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
        const res = await removeItem({
          variables: { id, company_id },
        })

        const { ok, errors } = res.data.cancelEventComp

        if (ok) {
          await setChangedWorkers(false)
          await setEventVisible(false)
          await setEditedEvent(null)
          await setSelectedWorkers([])

          toast.success("Rendez-vous annulé avec succès")
        } else {
          errors.forEach(async (error) => {
            if (error.path && error.path.toString() === "global") {
              await setGlobalError(error.message)
            }
          })
        }
      }
    } catch (error) {
      window.alert("Erreur", error.message)
    }
  }

  const updateWorkersFunc = async () => {
    try {
      await setUpdateWorkersLoading(true)
      const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
      const worker_ids = []

      salerTeamUsersData.salerTeamUsers.forEach((u) => {
        selectedWorkers.forEach((w) => {
          const phone = w.split("-->")[1]

          if (phone == u.phone) {
            worker_ids.push(u.id)
          }
        })
      })
      console.log("worker_ids", worker_ids)
      const res = await updateWorkers({
        variables: {
          company_id,
          worker_ids,
          event_id: editedEvent.id,
        },
      })
      const { ok, errors } = res.data.updateEventWorkers
      await setUpdateWorkersLoading(false)
      if (ok) {
        await setChangedWorkers(false)
        await setEventVisible(false)
        await setEditedEvent(null)
        toast.success("Affectation mis-à-jour avec succès")
      } else {
        window.alert(errors[0].message)
      }
    } catch (error) {
      await setUpdateWorkersLoading(false)
      window.alert("Erreur", error.message)
    }
  }
  const computeVariables = async (page) => {
    const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
    let variables = { page, company_id }

    await setCurrentPage(page)

    const category_ids = []
    const service_ids = []
    const worker_ids = []

    if (eventNumber) {
      variables.event_number = eventNumber
    }
    if (start) {
      variables.start = start
    } else {
      variables.start = moment(start).format("MM-DD-YYYY")
    }

    if (
      categories.length &&
      compData.oneCompany &&
      compData.oneCompany.categories &&
      compData.oneCompany.categories.length
    ) {
      categories.forEach((cat) => {
        compData.oneCompany.categories.forEach((el) => {
          if (el.name.toString() === cat.toString()) {
            category_ids.push(el.id)
          }
        })
      })
    }

    if (category_ids.length) {
      variables.category_ids = category_ids
    }

    if (services.length && serviceData && serviceData.allServices.length) {
      services.forEach((serv) => {
        serviceData.allServices.forEach((el) => {
          if (el.name.toString() === serv.toString()) {
            service_ids.push(el.id)
          }
        })
      })
    }
    if (service_ids.length) {
      variables.service_ids = service_ids
    }

    if (
      selectedWorkersSearch.length &&
      salerTeamUsersData &&
      salerTeamUsersData.salerTeamUsers &&
      salerTeamUsersData.salerTeamUsers.length
    ) {
      salerTeamUsersData.salerTeamUsers.forEach((u) => {
        selectedWorkersSearch.forEach((w) => {
          if (w.split("-->")[1].toString() === u.phone.toString()) {
            worker_ids.push(u.id)
          }
        })
      })
    }

    if (worker_ids.length) {
      variables.worker_ids = worker_ids
    }

    return variables
  }
  const onPageChange = async (page) => {
    try {
      let variables = computeVariables(page)

      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { findEventsWorker } = fetchMoreResult
          return Object.assign({}, prev, {
            findEventsWorker,
          })
        },
      })
    } catch (error) {
      throw error
    }
  }
  const search = async () => {
    try {
      let variables = await computeVariables(1)

      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { findEventsWorker } = fetchMoreResult
          return Object.assign({}, prev, {
            findEventsWorker,
          })
        },
      })
    } catch (error) {
      throw error
    }
  }

  const { docs, ...rest } = data.findEventsWorker
  const evs = docs.map((ev) => ({
    id: ev.id,
    title: `${ev.service.name} ${ev.author.name} ${ev.author.lastname}`,
    allDay: false,
    color: colors.VIOLET,
    start: moment(new Date(parseInt(ev.start))).format(),
    end: moment(new Date(parseInt(ev.end))).format(),
  }))

  const { page, totalPages } = rest
  const timezone =
    generalSetting &&
    generalSetting.oneSettingAdmin &&
    generalSetting.oneSettingAdmin.time_zone
      ? generalSetting.oneSettingAdmin.time_zone
      : "Africa/Dakar"
  return (
    <div>
      <EventSearch
        start={start}
        setStart={setStart}
        eventNumber={eventNumber}
        categories={categories}
        categoriesDatasource={
          compData && compData.oneCompany && compData.oneCompany.categories
            ? compData.oneCompany.categories.map((e) => e.name)
            : []
        }
        services={services}
        servicesDatasource={
          serviceData && serviceData.allServices
            ? serviceData.allServices.map((e) => `${e.name}`)
            : []
        }
        users={selectedWorkersSearch}
        usersDatasource={
          salerTeamUsersData && salerTeamUsersData.salerTeamUsers
            ? salerTeamUsersData.salerTeamUsers.map(
                (e) => `${e.fullname}-->${e.phone}`
              )
            : []
        }
        selectWorkers={selectSearchUsers}
        selectServices={selectSearchServices}
        selectCategories={selectSearchCategories}
        eventNumber={eventNumber}
        setEventNumber={setEventNumber}
        submit={search}
        refetch={async () => {
          await setEventNumber("")
          await setServices([])
          await setCategories([])
          await setSelectedWorkersSearch([])
          await setCurrentPage(1)
          await setStart(moment(new Date()).format("MM-DD-YYYY"))
          await refetchEvents()
        }}
      />

      <EventList
        data={data && data.findEventsWorker ? data.findEventsWorker : null}
        onPageChange={onPageChange}
        selectEvent={selectEvent}
        timezone={timezone}
      />

      <EventCalendar
        events={evs}
        selectEvent={selectEvent}
        {...settingData.setting.setting}
        timezone={timezone}
        visibleRange={{
          start:
            data &&
            data.findEventsWorker &&
            data.findEventsWorker.docs &&
            data.findEventsWorker.docs[0] &&
            data.findEventsWorker.docs[0].start
              ? moment(new Date(parseInt(docs[0].start))).format()
              : new Date(),
          end:
            data &&
            data.findEventsWorker &&
            data.findEventsWorker.docs &&
            docs[docs.length - 1] &&
            docs[docs.length - 1].end
              ? moment(new Date(parseInt(docs[docs.length - 1].end))).format()
              : new Date(),
        }}
      />

      <ModalDialog
        open={eventVisible}
        cancel={async () => {
          await setEventVisible(false)
          await setEditedEvent(null)
        }}
        title={`Rendez-vous`}
      >
        <EventEdit
          event={editedEvent}
          timezone={timezone}
          globalError={globalError}
          remove={removeEvent}
          signal={signal}
          globalSignalError={globalSignalError}
          selectWorkers={selectWorkers}
          users={
            salerTeamUsersData && salerTeamUsersData.salerTeamUsers
              ? salerTeamUsersData.salerTeamUsers.map(
                  (e) => `${e.fullname}-->${e.phone}`
                )
              : []
          }
          workers={
            editedEvent && editedEvent.workers.length
              ? editedEvent.workers.map((e) => {
                  const gender = e.gender == "male" ? "Monsieur" : "Madame"

                  return `${gender} ${e.fullname}-->${e.phone}`
                })
              : []
          }
          valueWorkers={
            !changedWorkers &&
            selectedWorkers &&
            selectedWorkers.length === 0 &&
            editedEvent &&
            editedEvent.workers.length
              ? editedEvent.workers.map((e) => `${e.fullname} --> ${e.phone}`)
              : // .concat(selectedWorkers)
                selectedWorkers
          }
          updateWorkers={updateWorkersFunc}
          loading={updateLoading || updateWorkersLoading}
        />
      </ModalDialog>
      {/* <ToastContainer
        autoClose={false}
        style={{
          marginTop: 70,
          padding: 30,
          fontSize: 12,
          fontStyle: "italic",
          width: "30%",
        }}
      /> */}
    </div>
  )
}
