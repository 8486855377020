import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"
import { TableCell, TableRow, Avatar,IconButton } from "@material-ui/core"
import RemoveIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import inconnu from "../../../assets/inconnu.jpg"
import { COMPANY_ID_STORAGE } from "utils/static_constants"

const useStyles = makeStyles((theme) => ({
  statsIcon: {
    cursor:'pointer',
    // color: theme.palette.icon,
    marginRight: theme.spacing(1),
  },
}))

export const Team = (props) => {
  const { item, selectForUpdate, selectForRemove, company_id } = props
  const classes = useStyles();
  const gender = item.gender=='male'?'Monsieur':'Madame'
  return (
    <TableRow key={item.id}>
      {item.picture && (
        <TableCell>
          <Avatar size="tiny" src={item.picture} alt={item.fullname} />
        </TableCell>
      )}

      {!item.picture && (
        <TableCell>
          <Avatar size="tiny" src={inconnu} alt={item.fullname} />
        </TableCell>
      )}
      <TableCell>{gender+' '}{item.fullname}</TableCell>
      <TableCell>{item.phone}</TableCell>

      <TableCell>
        {item.roles
          .filter((r) => r.company_id && r.company_id == company_id)
          .map((r) => (
            <span key={r.id}>{r.name + " "}</span>
          ))}
      </TableCell>

      <TableCell>{item.email}</TableCell>

    {/*   <TableCell>
        <RemoveIcon
          className={classes.statsIcon}
          onClick={async (e) => {
            e.preventDefault()
            await selectForRemove(item)
          }}
        />
      </TableCell> */}

      <TableCell>
        <IconButton component="span">
          <EditIcon
          
            onClick={async (e) => {
              e.preventDefault()
              await selectForUpdate(item)
            }}
          />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

Team.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
}
