import React, { Fragment } from 'react'
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Paper,
  TableBody,
  IconButton
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: '100%',
    padding: '0px 15px'
  },
  chip: {
    margin: theme.spacing(0.5)
  }
}))

export const BillItems = (props) => {
  
  const {
    items,
    removeItem,
    covered,
    label_insurance,

    currency
  } = props
  const classes = useStyles()
  const amounts = items.map(a=>a.total)
  const covs=items.map(a=>a.total_covered)
 
  let total_amount =
  amounts.reduce(
        (a, b) => (parseFloat(a) || 0) + (parseFloat(b) || 0),0
      )
     
  let covered_amount =
  covs.reduce(
          (a, b) =>
            (parseFloat(a) || 0) + (parseFloat(b) || 0),0
        )
      

 

 
  return (
    <>
      <Grid item lg={12} md={12} sm={12} xs={12} key={items.lenth}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>Prestation</TableCell>
                <TableCell>Quantité</TableCell>
                <TableCell>Prix</TableCell>
                <TableCell>Total</TableCell>
                {covered && <TableCell>{label_insurance}</TableCell>}
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, i) => (
                <TableRow key={i}>
                  <TableCell>{item.name}</TableCell>

                  <TableCell>{item.quantity}</TableCell>

                  <TableCell>{item.price}</TableCell>

                  <TableCell>{item.total}</TableCell>
                  {covered && <TableCell>{item.total_covered}</TableCell>}

                  <TableCell>
                    <IconButton color='default' component='span'>
                      <DeleteIcon
                        className='buttonLeft'
                        //type="submit"
                        onClick={async (e) => {
                          await e.preventDefault()

                          await removeItem(i)
                        }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        {covered && (
          <div className='result-facture'>
            <div className='result-facture-item'>
              <p>
                {'Total'}:{' '}
                <span>
                  {' '}
                  {total_amount} {currency}
                </span>
              </p>
            </div>
            <div className='result-facture-item'>
              <p>
                {label_insurance}:{' '}
                <span>
                  {' '}
                  {covered_amount} {currency}
                </span>
              </p>
            </div>

            <div className='result-facture-item'>
              <p>
                {'Montant dû: '}{' '}
                <span>
                  {' '}
                  {total_amount - covered_amount} {currency}
                </span>
              </p>
              <h3></h3>
            </div>
          </div>
        )}

        {!covered && (
          <div>
            <p>
              {'Total dû'}:{' '}
              <span>
                {' '}
                {total_amount} {currency}
              </span>
            </p>
          </div>
        )}
      </Grid>
    </>
  )
}
