/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from "react"
import { NavLink as RouterLink } from "react-router-dom"
/* import NotificationIcon from '@material-ui/icons/NotificationsNoneOutlined' */

import clsx from "clsx"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"
import { ToastContainer } from "react-toastify"
import { List, ListItem, Button, colors } from "@material-ui/core"
import { useQuery } from "react-apollo"
/* import { userCompNofifsQuery } from 'graphql/query/notif' */
import { currentUserQuery } from "graphql/query/user"
import { colors as colorsicon } from "../../../../../../utils/constants"

import Loading from "components/Loading"

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: "400",
    fontFamily: `'Open Sans', sans-serif`,
  },
  icon: {
    color: colorsicon.PRIMARY,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.iconActive,
    fontWeight: "600!important",
    "& $icon": {
      color: theme.palette.iconActive,
    },
  },
}))

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
))

const SidebarNav = (props) => {
  const { pages, className, ...rest } = props

  /*   const { loading: loading1, data: notifData, ...rest2 } = useQuery(
    userCompNofifsQuery,
    {
      variables: {
        company_id: localStorage.getItem(COMPANY_ID_STORAGE)
      }
    }
  ) */

  const { data: currentUserData, loading: loading2 } = useQuery(currentUserQuery)

  const classes = useStyles()

  /*   const notifNumber =
    notifData &&
    notifData.userCompNofifs &&
    notifData.userCompNofifs.length &&
    notifData.userCompNofifs.filter((n) => !n.seen).length > 0
      ? notifData.userCompNofifs.filter((n) => !n.seen).length
      : 0 */

  if (loading2) {
    return <Loading />
  }

  return (
    <List {...rest} className={clsx(classes.root, className)} key={pages}>
      {/* <ListItem className={classes.item} disableGutters key={'Notification'}>
        <Button
          activeClassName={classes.active}
          className={classes.button}
          component={CustomRouterLink}
          to={NOTIF_PATH}
        >
          <Badge
            badgeContent={notifNumber}
            color='error'
            style={{ padding: 5 }}
            invisible={notifNumber == 0}
          >
            <div className={classes.icon}>
              <NotificationIcon />
            </div>

            {'Notification'}
          </Badge>
        </Button>
      </ListItem> */}

      {pages.map((page, i) => (
        <ListItem className={classes.item} disableGutters key={i}>
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            to={page.href}
          >
            <div className={classes.icon}>{page.icon}</div>
            {page.title}
          </Button>
        </ListItem>
      ))}

      <ToastContainer
        autoClose={false}
        style={{
          marginTop: 70,
          padding: 30,
          fontSize: 12,
          fontStyle: "italic",
          width: "30%",
        }}
      />
    </List>
  )
}

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
}

export default SidebarNav
