import React from "react"
import { makeStyles } from "@material-ui/styles"
import {  Button, TextField, Link } from "@material-ui/core"
 
import { MessageField } from "components/MessageField"
import Loading from "components/Loading"
import {
  LOGIN_PATH
} from "utils/static_constants"

 import { Link as componentLink} from "react-router-dom/cjs/react-router-dom.min"

const useStyles = makeStyles((theme) => ({

  signInButton:{
   /*  maxWidth:'175px', */
    marginTop:18
  }
 
}))

const PasswordForgot = ({
  touched,
  errors,
  
  handleSubmit,
  isSubmitting,
  handleChange,
  globalError,
  values,
}) => {
  const classes = useStyles()

  if (isSubmitting) {
    return <Loading />
  }
  return (
 
        <form
                className="formcontent"
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
              >
                {globalError && (
                  <MessageField message={globalError} severity={"error"} />
                )}

                <br />

                <h2 className="titlelogin"> Mot de passe oublié</h2>

                <TextField
                  required
                  autoComplete='off'
                  error={touched.email && errors.email ? true : false}
                  fullWidth
                  helperText={touched.email && errors.email ? errors.email : null}
                  label="Votre adresse email"
                  name="email"
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="filled"
                />

                <div className="footer-login">
                  <Button
                    className={classes.signInButton}
                    size="large"
                    color="primary"
                    //disabled={!formState.isValid}
                    type="submit"
                    variant="contained"
                  >
                    Envoyer
                  </Button>

                  <Link to={'/'} component={componentLink} className="linkreturnhomepage">
                    <span>Se connecter</span>
                  </Link>
                </div>
     </form>
         
  )
}

export default PasswordForgot
