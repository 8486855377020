import gql from 'graphql-tag';

export const oneSettingAdminQuery = gql`
  query oneSettingAdmin {
    oneSettingAdmin {
      id
      country
      regions
      sms_price
      currency
      phone_code
      phone_min_size
      phone_max_size
      time_zone
      createdAt
      updatedAt
    }
  }
`;
