import React from 'react'
import {
  Grid,
  Button,
  TextField,
  Input,
  InputLabel,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import moment from 'moment-timezone'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import Loading from 'components/Loading'
import { MessageField } from 'components/MessageField'
import { SelectField } from 'components/SelectField'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: '100%',
    padding: '0px 15px'
  },
  chip: {
    margin: theme.spacing(0.5)
  }
}))
export const ServiceCreate = ({
  values,
  touched,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,

  globalError,
  setFieldValue,
  optionDays,
  categories,
  //regions,
  currency,
  sms_price,
  canModify
}) => {
  const theme = useTheme()
  const classes = useStyles()

  if (isSubmitting) {
    return <Loading />
  }
  const dataSourceDays = optionDays.map((d) => d.name)

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit}
    >
      {globalError && <MessageField message={globalError} severity={'error'} />}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!canModify}
            required
            id='name'
            name='name'
            label='Nom'
            onChange={handleChange}
            error={touched.name && errors.name ? true : false}
            fullWidth
            helperText={touched.name && errors.name ? errors.name : null}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SelectField
            required={true}
            multiple={false}
            disabled={!canModify}
            label={'Catégorie'}
            value={values.category_id}
            name='category_id'
            id='category_id'
            theme={theme}
            dataSource={categories}
            disabled={false}
            helperText={
              touched.category_id && errors.category_id ? errors.category_id : null
            }
            error={touched.category_id && errors.category_id ? true : false}
            handleChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            disabled={!canModify}
            multiline
            required={false}
            id='description'
            name='description'
            label='Description'
            value={values.description}
            onChange={handleChange}
            style={{ width: '100%' }}
          />
          {touched.description && errors.description && (
            <span style={{ color: 'red' }}>{errors.description}</span>
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!canModify}
                id='show_price'
                name='show_price'
                checked={values.show_price}
                onChange={async (_e, value) => {
                  await setFieldValue('show_price', value)
                 // await setFieldValue('price', '')
                }}
              />
            }
            style={{ color: values.show_price ? 'green' : 'red' }}
            label={'prix visible au public'}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CurrencyTextField
            as={TextField}
            fullWidth
            value={values.price}
            name='price'
            id='price'
            variant='standard'
         //   disabled={!values.show_price && !canModify}
            label={`Prix en ${currency}`}
            onChange={async (_event, value) => await setFieldValue('price', value)}
            helperText={touched.price && errors.price ? errors.price : null}
            error={touched.price && errors.price ? true : false}
            currencySymbol={currency}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                id='sms_reminder'
                name='sms_reminder'
                checked={values.sms_reminder}
                onChange={async (_e, value) => {
                  await setFieldValue('sms_reminder', value)
                }}
              />
            }
            style={{ color: values.sms_reminder ? 'green' : 'red' }}
            label={'sms de rappel rv'}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label={"Prix d'un sms"}
            value={sms_price + ' ' + currency}
            readOnly={true}
            fullWidth
            disabled={!values.sms_reminder}
          />
        </Grid>

        {/*     <Grid item xs={12} sm={12}>
          <InputLabel required>Photo</InputLabel>
          <br />
          <Input
            disabled={!canModify}
            fullWidth
            type="file"
            name="file"
            id="file"
            onChange={async ({
              target: {
                files: [file],
              },
            }) => {
              await setFieldValue("file", file)
            }}
            style={{ display: "none" }}
          />
          <label htmlFor="file">
            <Button
              style={{ borderRadius: 100 }}
              variant="contained"
              component="span"
              color="secondary"
            >
              Ajouter une photo
            </Button>
          </label>
          <br />
          <br />
          {values.file && (
            <div>
              <img
                src={URL.createObjectURL(values.file)}
                alt="service"
                width="100"
                height="100"
              />
            </div>
          )}
          <br />
          {touched.file && errors.file && (
            <span style={{ color: "red" }}>{errors.file}</span>
          )}
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <InputLabel required>Date d'ouverture disponibilté calendrier</InputLabel>
          <TextField
            disabled={!canModify}
            onChange={handleChange}
            value={moment(new Date(values.validRangeStart)).format('YYYY-MM-DD')}
            name='validRangeStart'
            id='validRangeStart'
            type='date'
            fullWidth
            helperText={
              touched.validRangeStart && errors.validRangeStart
                ? errors.validRangeStart
                : null
            }
            error={touched.validRangeStart && errors.validRangeStart ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel required>Date fermeture disponibilté calendrier</InputLabel>
          <TextField
            onChange={handleChange}
            name='validRangeEnd'
            id='validRangeEnd'
            value={moment(new Date(values.validRangeEnd)).format('YYYY-MM-DD')}
            type='date'
            fullWidth
            helperText={
              touched.validRangeEnd && errors.validRangeEnd
                ? errors.validRangeEnd
                : null
            }
            error={touched.validRangeEnd && errors.validRangeEnd ? true : false}
          />
        </Grid>
        {/*  <Grid item xs={12} sm={6}>
          <TimeField
            required
            value={values.slotDuration}
            id="slotDuration"
            name="slotDuration"
            label="Durée Rendez-vous heures:minutes (hh:mm)"
            onChange={async (_event, value) => {
              await setFieldValue("slotDuration", value)
            }}
            input={<TextField />}
            colon=":"
            fullWidth
            helperText={
              touched.slotDuration && errors.slotDuration
                ? errors.slotDuration
                : null
            }
            error={touched.slotDuration && errors.slotDuration ? true : false}
          />
        </Grid> */}

        {/*  <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            label="Capacité Rv"
            value={values.slotEventCount}
            name="slotEventCount"
            id="slotEventCount"
            onChange={handleChange}
            type="number"
            helperText={
              touched.slotEventCount && errors.slotEventCount
                ? errors.slotEventCount
                : null
            }
            error={touched.slotEventCount && errors.slotEventCount ? true : false}
          />
        </Grid> */}

        {/*  <Grid item xs={12} sm={6}>
          <TextField
            required
            name="startTime"
            id="startTime"
            label="Heure début d'activité"
            value={values.startTime}
            onChange={handleChange}
            type="time"
            fullWidth
            helperText={
              touched.startTime && errors.startTime ? errors.startTime : null
            }
            error={touched.startTime && errors.startTime ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            name="endTime"
            id="endTime"
            label="Heure fin d'activité"
            value={values.endTime}
            onChange={handleChange}
            type="time"
            fullWidth
            helperText={touched.endTime && errors.endTime ? errors.endTime : null}
            error={touched.endTime && errors.endTime ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            name="startTimeRest"
            id="startTimeRest"
            label="Heure début pause"
            value={values.startTimeRest}
            onChange={handleChange}
            type="time"
            fullWidth
            helperText={
              touched.startTimeRest && errors.startTimeRest
                ? errors.startTimeRest
                : null
            }
            error={touched.startTimeRest && errors.startTimeRest ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            name="endTimeRest"
            id="endTimeRest"
            label="Heure fin pause"
            value={values.endTimeRest}
            onChange={handleChange}
            type="time"
            fullWidth
            helperText={
              touched.endTimeRest && errors.endTimeRest ? errors.endTimeRest : null
            }
            error={touched.endTimeRest && errors.endTimeRest ? true : false}
          />
        </Grid> */}

        <Grid item xs={12} sm={6}>
          <SelectField
            required={true}
            multiple={true}
            disabled={!canModify}
            label={'jours  activités'}
            value={values.daysOfWeek}
            name='daysOfWeek'
            id='daysOfWeek'
            theme={theme}
            dataSource={dataSourceDays}
            disabled={false}
            helperText={
              touched.daysOfWeek && errors.daysOfWeek ? errors.daysOfWeek : null
            }
            error={touched.daysOfWeek && errors.daysOfWeek ? true : false}
            handleChange={async ({ target: { value } }) => {
              await setFieldValue('daysOfWeek', value)

              const newHiddenDays = []
              optionDays.forEach((el) => {
                if (value.indexOf(el.name) < 0) {
                  newHiddenDays.push(el.name)
                }
              })

              await setFieldValue('hiddenDays', newHiddenDays)
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SelectField
            required={true}
            name='hiddenDays'
            id='hiddenDays'
            helperText={
              touched.hiddenDays && errors.hiddenDays ? errors.hiddenDays : null
            }
            error={touched.hiddenDays && errors.hiddenDays ? true : false}
            handleChange={async ({ target: { value } }) => {
              await setFieldValue('hiddenDays', value)
              const newDays = []
              optionDays.forEach((el) => {
                if (value.indexOf(el.name) < 0) {
                  newDays.push(el.name)
                }
              })

              await setFieldValue('daysOfWeek', newDays)
            }}
            multiple={true}
            label={'jours hors activités'}
            value={values.hiddenDays}
            theme={theme}
            dataSource={dataSourceDays}
            disabled={false}
          />
        </Grid>

        {/*    <Grid item xs={12} sm={12}>
          <Calendar
             businessHours={
              setting && setting.businessHours && setting.businessHours.daysOfWeek
                ? setting.businessHours.daysOfWeek.map(dayNumberFunc)
                : []
            }
            hiddenDays={
              setting && setting.hiddenDays
                ? setting.hiddenDays.map(dayNumberFunc)
                : []
            }
            slotDuration={
              setting && setting.slotDuration ? setting.slotDuration : null
            }
            rest={{
              daysOfWeek:
                setting && setting.rest && setting.rest.daysOfWeek
                  ? setting.rest.daysOfWeek
                  : [],
              endTime:
                setting && setting.rest && setting.rest.endTime
                  ? setting.rest.endTime
                  : null,
              startTime:
                setting && setting.rest && setting.rest.startTime
                  ? setting.rest.startTime
                  : null,
              title: "Pause",
            }}  
             validRange={{
              start: values.validRangeStart ? values.validRangeStart : new Date(),
              end: values.validRangeEnd ? values.validRangeEnd : new Date(),
            }}
            timezone={timezone}
          {...calendarProps} 
          />
        </Grid> */}
        <div style={{ paddingLeft: 10 }}>
          <Button
            color='primary'
            disabled={!canModify}
            fullWidth
            size='large'
            type='submit'
            onClick={handleSubmit}
            variant='contained'
          >
            enregistrer
          </Button>
        </div>
      </Grid>
    </form>
  )
}
