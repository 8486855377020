import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import { useMutation } from "react-apollo"
import { passwordLostMutation } from "../../graphql/mutation/user"
import PasswordForgot from "./components/PasswordForgot"
import { CONFIRM_PASSWORD_LOST_PATH } from "../../utils/static_constants"
import { toast } from "react-toastify"

const loginSchema = Yup.object().shape({
  email: Yup.string().email("email invalide").required("champ requis"),
})

const PasswordForgotView = ({ history }) => {
  const [passwordLost] = useMutation(passwordLostMutation)

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={loginSchema}
      onSubmit={async (
        { email },

        { setSubmitting, setFieldError }
      ) => {
        try {
          const res = await passwordLost({
            variables: {
              email: email.trim(),
            },
          })

          const { ok, errors } = res.data.passwordLost

          if (ok) {
            // await localStorage.setItem(TOKEN_NAME, "authenticated");
            // await login({ token });
            await history.push(CONFIRM_PASSWORD_LOST_PATH);
            await toast.success('Un code de confirmation a été envoyé sur votre adresse email (vérifier les spams)')
          } else {
            errors.forEach(async (error) => {
              await setFieldError(error.path, error.message)
              await setSubmitting(false)
              window.alert(error.message)
            })
          }
        } catch (error) {
          window.alert(error.message)
        }
      }}
    >
      {(props) => <PasswordForgot {...props} />}
    </Formik>
  )
}

export default PasswordForgotView
