import React from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"
import { Divider, Drawer } from "@material-ui/core"

import { Profile, SidebarNav } from "./components"

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 260,
    top: "64px",
    [theme.breakpoints.up("lg")]: {
      /* marginTop: 64, */
      height: "calc(100% - 64px)",
    },
    /*    top: 'auto' */
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(1),
  },
  divider: {
    marginTop: 16,
    marginBottom: 5,
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}))

const Sidebar = (props) => {
  const { open, variant, onClose, className, computedRole, pages, ...rest } = props

  const classes = useStyles()
  if (!computedRole) {
    return null
  }
  return (
    <Drawer
      key={pages.length}
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        {/*  <Profile />
        <Divider className={classes.divider} /> */}
        <SidebarNav className={classes.nav} pages={pages} key={pages.length} />
        {/* <UpgradePlan /> */}
      </div>
    </Drawer>
  )
}

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
}

export default Sidebar
