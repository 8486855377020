import gql from "graphql-tag";

export const settingQuery = gql`
  query setting($company_id: String) {
    setting(company_id: $company_id) {
      ok
      errors {
        path
        message
      }
      setting {
        id
        company_id
        no_rest
        slotEventCount
        hiddenDays
        slotDuration
        createdAt
        updatedAt
        businessHours {
          daysOfWeek
          startTime
          endTime
        }
        rest {
          title
          daysOfWeek
          startTime
          endTime
        }
      }
    }
  }
`;
