import { ApolloClient } from "apollo-client"
import { InMemoryCache } from "apollo-cache-inmemory"
import { setContext } from "apollo-link-context"
import { split, ApolloLink } from "apollo-link"
import { createUploadLink } from "apollo-upload-client"
//import { WebSocketLink } from "apollo-link-ws"
import { onError } from "apollo-link-error"
import { getMainDefinition } from "apollo-utilities"
import { TOKEN_NAME } from "../utils/static_constants"
import { typeDefs, resolvers } from "./store/resolvers"
/*
const graphqlUrl = `http://localhost:3001/graphql`
const wsUrl = "ws://localhost:3001/graphql"



*/

//const graphqlUrl = `http://localhost:3001/graphql`
const graphqlUrl = `https://server.doctosen.com/graphql`
const cache = new InMemoryCache()
/* const wsUrl = "wss://server.senrv.biz/graphql"    



export const wsLink = new WebSocketLink({
  uri: wsUrl,

  options: {
    reconnect: true,
    /*  connectionParams: async () => ({
      token: await localStorage.getItem(TOKEN_NAME),
    }), 
  },
}) */

const httpLink = createUploadLink({
  uri: graphqlUrl,
  credentials: "include",
})
const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors)
    graphQLErrors.map(async (error) => {
      console.log("error graphql", error)
      /*  await localStorage.removeItem(TOKEN_NAME)
      useHistory().push(LOGIN_PATH) */
      // useHistory().push(LOGIN_PATH)
    })
})

const authLink = setContext(async (_, { headers }) => {
  const token = await localStorage.getItem(TOKEN_NAME)

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }
})

const finaleLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === "OperationDefinition" && operation === "subscription"
  },
  //wsLink,
  //authLink.concat(httpLink)
  httpLink
)
const client = new ApolloClient({
  connectToDevTools: true,

  link: ApolloLink.from([errorLink, httpLink]),

  cache,
  typeDefs,
  resolvers,
})

cache.writeData({
  data: {
    currentUser: {
      authenticated: false,
      token: null,

      __typename: "currentUser",
    },
    smallMenu: {
      __typename: "smallMenu",
      smallMenu: false,
    },
  },
})
export default client
