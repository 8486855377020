import React from "react"
import { Grid } from "@material-ui/core"
import Loading from "components/Loading"
import { MessageField } from "components/MessageField"

export const TeamDelete = ({
  isSubmitting,

  item,
  globalError,
}) => {
  if (!item) {
    return null
  }

  if (isSubmitting) {
    return <Loading />
  }

  return (
    <Grid item xs={12} sm={6}>
      {globalError && <MessageField message={globalError} severity={"error"} />}
      <p>Voulez vous supprimer l'utilisateur {item.fullname} de votre personnel ?</p>
    </Grid>
  )
}
