import React from "react"
import { Grid, Button, TextField } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"

import Loading from "components/Loading"
import { MessageField } from "components/MessageField"
import { SelectField } from "components/SelectField"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "100%",
    padding: "0px 15px",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}))
export const HistoryCreate = ({
  values,
  touched,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,

  globalError,

  categories,
}) => {
  const theme = useTheme()
  const classes = useStyles()

  if (isSubmitting) {
    return <Loading />
  }

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      {globalError && <MessageField message={globalError} severity={"error"} />}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <SelectField
            required={true}
            multiple={false}
            label={"Catégorie"}
            value={values.category_id}
            name="category_id"
            id="category_id"
            theme={theme}
            dataSource={categories}
            disabled={false}
            helperText={
              touched.category_id && errors.category_id ? errors.category_id : null
            }
            error={touched.category_id && errors.category_id ? true : false}
            handleChange={handleChange}
          />
          {touched.category_id && errors.category_id && (
            <span style={{ color: "red", textAlign: "center" }}>
              {errors.category_id}
            </span>
          )}
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            multiline
            rows={2}
            id="description"
            name="description"
            label="Description"
            value={values.description}
            onChange={handleChange}
            style={{ width: "100%" }}
          />
          {touched.description && errors.description && (
            <span style={{ color: "red" }}>{errors.description}</span>
          )}
        </Grid>

        <div style={{ paddingLeft: 10 }}>
          <Button
            color="primary"
            fullWidth
            size="large"
            type="submit"
            onClick={handleSubmit}
            variant="contained"
          >
            Ajouter
          </Button>
        </div>
      </Grid>
    </form>
  )
}
