import React, { useState, forwardRef } from "react"
import { NavLink as RouterLink } from "react-router-dom"
import client from "graphql/apollo"
import clsx from "clsx"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"
import { AppBar, Toolbar, IconButton, Badge } from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import CloseIcon from "@material-ui/icons/Close"
import InputIcon from "@material-ui/icons/Input"
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone"
import PersonOutlineIcon from "@material-ui/icons/PersonOutline"
import { useMutation, useQuery } from "react-apollo"
import { logoutSMutation, LOGOUT_USER_MUTATION } from "graphql/mutation/user"
import { ConfirmDialog } from "components/ConfirmDialog"
import {
  NOTIF_PATH,
  COMPANY_ID_STORAGE,
  PROFILE_PATH,
  FACTURE_LIST_PATH,
} from "../../../../utils/static_constants"
import { oneCompanyQuery } from "graphql/query/company"
import { userCompNofifsQuery } from "graphql/query/notif"
import MonetizationOnIcon from "@material-ui/icons/MonetizationOnOutlined"

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    /*  flexGrow: 1, */
    display: "flex",
    justifyContent: "center",
    flex: 2,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  centerheader: {
    flex: 1,
    display: "flex",
  },
  headeright: {
    justifyContent: "flex-end",
  },
}))

const CustomRouterLink = forwardRef((props, ref) => <RouterLink {...props} />)

const Topbar = (props) => {
  const { className, onSidebarOpen, openSidebar, ...rest } = props

  const {
    loading: loading1,
    data: notifData,
    ...rest2
  } = useQuery(userCompNofifsQuery, {
    variables: {
      company_id: localStorage.getItem(COMPANY_ID_STORAGE),
    },
    pollInterval: 1000 * 5,
  })

  const { loading, data: compData } = useQuery(oneCompanyQuery, {
    variables: { company_id: localStorage.getItem(COMPANY_ID_STORAGE) },
  })

  const notifNumber =
    notifData &&
    notifData.userCompNofifs &&
    notifData.userCompNofifs.length &&
    notifData.userCompNofifs.filter((n) => !n.seen).length > 0
      ? notifData.userCompNofifs.filter((n) => !n.seen).length
      : 0

  const classes = useStyles()

  const [logoutModal, setLogoutModal] = useState(false)
  const [logout] = useMutation(LOGOUT_USER_MUTATION)
  const [logoutS] = useMutation(logoutSMutation)

  return (
    <AppBar {...rest} className={clsx(classes.root)}>
      <Toolbar>
        <div className={classes.centerheader}>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            {openSidebar ? <CloseIcon /> : <MenuIcon />}
            {/* <CloseIcon /> */}
          </IconButton>
        </div>
        {/* <RouterLink to="/">
          <img alt="Logo" src="https://www.chatelaillonplage.fr/var/storage/images/site-de-la-ville-v2/demarches/rdv-en-ligne-carte-d-identite-et-passeport/721110-1-fre-FR/RDV-EN-LIGNE-carte-d-identite-et-passeport_image_600x285.jpg" />
        </RouterLink> */}

        <div className={`${classes.flexGrow}`}>
          {compData && compData.oneCompany && (
            <div>
              <span>{compData.oneCompany.name}</span>
            </div>
          )}
        </div>

        <div className={`${classes.centerheader} ${classes.headeright}`}>
          <IconButton
            color="inherit"
            to={FACTURE_LIST_PATH}
            component={CustomRouterLink}
          >
            <MonetizationOnIcon />
          </IconButton>
          <IconButton color="inherit" to={PROFILE_PATH} component={CustomRouterLink}>
            <PersonOutlineIcon />
          </IconButton>
          <IconButton color="inherit" to={NOTIF_PATH} component={CustomRouterLink}>
            <Badge
              badgeContent={notifNumber}
              color="error"
              invisible={notifNumber == 0}
            >
              <NotificationsNoneIcon />
            </Badge>
          </IconButton>
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={async (e) => {
              e.preventDefault()
              await setLogoutModal(true)
            }}
          >
            <InputIcon />
          </IconButton>
        </div>
        {/* <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden> */}
      </Toolbar>

      <ConfirmDialog
        open={logoutModal}
        cancel={async () => {
          await setLogoutModal(false)
        }}
        confirmAction={async () => {
          try {
            // await client.clearStore()
            await localStorage.clear()
            await logoutS()

            await logout()
            await setLogoutModal(false)
          } catch (error) {
            console.log("error logging out", error)
          }
        }}
        title={"Déconnexion"}
      >
        <p>Voulez vous vous déconnectez ?</p>
      </ConfirmDialog>
    </AppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
}

export default Topbar
