import React from "react"
import { Route, Redirect } from "react-router-dom"
import { useQuery } from "react-apollo"
import { GET_CURRENT_USER_QUERY } from "../../graphql/query/user"
import { LOGIN_PATH } from "utils/static_constants"

export const AuthRoute = (props) => {
  const response = useQuery(GET_CURRENT_USER_QUERY)
  const { loading, data } = response
  const { layout: Layout, component: Component, ...rest } = props

  return (
    <Route
      {...rest}
      render={(props) =>
        !loading && data && data.currentUser && data.currentUser.authenticated ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to={LOGIN_PATH} />
        )
      }
    />
  )
}
