import React from 'react'
import {
  Grid,
  Button,
  TextField,
  Input,
  InputLabel,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import moment from 'moment-timezone'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Loading from 'components/Loading'
import { MessageField } from 'components/MessageField'
import { SelectField } from 'components/SelectField'
import { SelectInput } from 'components/SelectInput'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: '100%',
    padding: 20
  },
  chip: {
    margin: theme.spacing(0.5)
  }
}))
export const ServiceEdit = ({
  values,
  touched,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,

  globalError,
  setFieldValue,
  optionDays,
  categories,
  //regions,
  currency,

  item,
  canModify,
  sms_price
}) => {
   
  const theme = useTheme()
  const classes = useStyles()
  if (isSubmitting) {
    return <Loading />
  }
  const dataSourceDays = optionDays.map((d) => d.name)

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit}
    >
      {globalError && <MessageField message={globalError} severity={'error'} />}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <FormControlLabel
            disabled={!canModify}
            control={
              <Checkbox
                color='primary'
                id='is_active'
                name='is_active'
                checked={values.is_active}
                onChange={async (_e, value) =>
                  await setFieldValue('is_active', value)
                }
              />
            }
            label={values.is_active ? 'Désactiver' : 'Activer'}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!canModify}
            value={values.name}
            id='name'
            name='name'
            label='Nom'
            onChange={handleChange}
            error={touched.name && errors.name ? true : false}
            fullWidth
            helperText={touched.name && errors.name ? errors.name : null}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          {/*  <SelectField
            required={true}
            multiple={false}
            disabled={!canModify}
            label={"Catégorie"}
            value={values.category_id}
            name="category_id"
            id="category_id"
            theme={theme}
            dataSource={categories}
            disabled={false}
            helperText={
              touched.category_id && errors.category_id ? errors.category_id : null
            }
            error={touched.category_id && errors.category_id ? true : false}
            handleChange={handleChange}
          /> */}

          <SelectInput
            key={'select-blood_group-patient'}
            name='category_id'
            label={'Catégorie'}
            fullWidth={true}
            value={values.category_id}
            options={categories}
            required={false}
            helperText={
              touched.category_id && errors.category_id ? errors.category_id : null
            }
            error={touched.category_id && errors.category_id ? true : false}
            onChange={async ({ target: { value } }) => {
              try {
                await setFieldValue('category_id', value)
              } catch (error) {
                window.alert(error.message)
              }
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            multiline
            disabled={!canModify}
            id='description'
            name='description'
            label='Description'
            value={values.description}
            onChange={handleChange}
            style={{ width: '100%' }}
          />

          {touched.description && errors.description && (
            <span style={{ color: 'red' }}>{errors.description}</span>
          )}
        </Grid>
        {/*    <Grid item xs={12} sm={6}>
          <label style={{ paddingRight: 20, fontFamily: "Roboto" }}>Photo</label>
          <br />
          <br />
          <Input
          disabled={!canModify}
            fullWidth
            type="file"
            name="file"
            id="file"
            onChange={async ({
              target: {
                files: [file],
              },
            }) => {
              await setFieldValue("file", file)
            }}
            style={{ display: "none" }}
          />

          {values.file && (
            <img
              src={URL.createObjectURL(values.file)}
              alt="service-edit"
              style={{ width: "100%", height: 200 }}
            />
          )}
          <br />
          {touched.file && errors.file && (
            <span style={{ color: "red" }}>{errors.file}</span>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <label htmlFor="file">
            <Button
              style={{ borderRadius: "100px" }}
              variant="contained"
              component="span"
              color="secondary"
            >
              Changer de photo
            </Button>
          </label>

          <img
            src={item.picture}
            alt="service-edit"
            style={{ width: "100%", height: 200, marginTop: 8 }}
          />
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <InputLabel required>Date ouverture calendrier</InputLabel>
          <TextField
            key='validRangeStart'
            onChange={handleChange}
            value={moment(new Date(values.validRangeStart)).format('YYYY-MM-DD')}
            name='validRangeStart'
            id='validRangeStart'
            type='date'
            fullWidth
            helperText={
              touched.validRangeStart && errors.validRangeStart
                ? errors.validRangeStart
                : null
            }
            error={touched.validRangeStart && errors.validRangeStart ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputLabel required>Date fermeture calendrier</InputLabel>
          <TextField
            key='validRangeEnd'
            onChange={handleChange}
            name='validRangeEnd'
            id='validRangeEnd'
            value={moment(new Date(values.validRangeEnd)).format('YYYY-MM-DD')}
            type='date'
            fullWidth
            helperText={
              touched.validRangeEnd && errors.validRangeEnd
                ? errors.validRangeEnd
                : null
            }
            error={touched.validRangeEnd && errors.validRangeEnd ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControlLabel
            as={TextField}
            control={
              <Checkbox
                color='primary'
                id='show_price'
                name='show_price'
                checked={values.show_price}
                onChange={async (_e, value) =>
                  await setFieldValue('show_price', value)
                }
              />
            }
            label={'prix visible au public'}
            error={touched.show_price && errors.show_price ? true : false}
            helperText={
              touched.show_price && errors.show_price ? errors.show_price : null
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputLabel required>Prix en {currency}</InputLabel>

          <TextField
            fullWidth
            value={values.price}
            name='price'
            id='price'
            onChange={handleChange}
            type='number'
         //   disabled={!values.show_price}
            helperText={touched.price && errors.price ? errors.price : null}
            error={touched.price && errors.price ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                id='sms_reminder'
                name='sms_reminder'
                checked={values.sms_reminder}
                onChange={async (_e, value) => {
                  await setFieldValue('sms_reminder', value)
                }}
              />
            }
            label={'sms de rappel rv'}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label={"Prix d'un sms"}
            value={sms_price + ' ' + currency}
            readOnly={true}
            fullWidth
            disabled={!values.sms_reminder}
          />
        </Grid>

        {/*         <Grid item xs={12} sm={6}>
          <TimeField
            required
            value={values.slotDuration}
            id="slotDuration"
            name="slotDuration"
            label="Durée Rendez-vous heures:minutes (hh:mm)"
            onChange={async (_event, value) => {
              await setFieldValue("slotDuration", value)
            }}
            input={<TextField />}
            colon=":"
            fullWidth
            helperText={
              touched.slotDuration && errors.slotDuration
                ? errors.slotDuration
                : null
            }
            error={touched.slotDuration && errors.slotDuration ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            label="Capacité Rv"
            value={values.slotEventCount}
            name="slotEventCount"
            id="slotEventCount"
            onChange={handleChange}
            type="number"
            helperText={
              touched.slotEventCount && errors.slotEventCount
                ? errors.slotEventCount
                : null
            }
            error={touched.slotEventCount && errors.slotEventCount ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            name="startTime"
            id="startTime"
            label="Heure début d'activité"
            value={values.startTime}
            onChange={handleChange}
            type="time"
            fullWidth
            helperText={
              touched.startTime && errors.startTime ? errors.startTime : null
            }
            error={touched.startTime && errors.startTime ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            name="endTime"
            id="endTime"
            label="Heure fin d'activité"
            value={values.endTime}
            onChange={handleChange}
            type="time"
            fullWidth
            helperText={touched.endTime && errors.endTime ? errors.endTime : null}
            error={touched.endTime && errors.endTime ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            name="startTimeRest"
            id="startTimeRest"
            label="Heure début pause"
            value={values.startTimeRest}
            onChange={handleChange}
            type="time"
            fullWidth
            helperText={
              touched.startTimeRest && errors.startTimeRest
                ? errors.startTimeRest
                : null
            }
            error={touched.startTimeRest && errors.startTimeRest ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            name="endTimeRest"
            id="endTimeRest"
            label="Heure fin pause"
            value={values.endTimeRest}
            onChange={handleChange}
            type="time"
            fullWidth
            helperText={
              touched.endTimeRest && errors.endTimeRest ? errors.endTimeRest : null
            }
            error={touched.endTimeRest && errors.endTimeRest ? true : false}
          />
        </Grid> */}

        <Grid item xs={12} sm={6}>
          <SelectField
            required={true}
            multiple={true}
            label={'jours  activités'}
            value={values.daysOfWeek}
            name='daysOfWeek'
            id='daysOfWeek'
            theme={theme}
            dataSource={dataSourceDays}
            disabled={false}
            helperText={
              touched.daysOfWeek && errors.daysOfWeek ? errors.daysOfWeek : null
            }
            error={touched.daysOfWeek && errors.daysOfWeek ? true : false}
            handleChange={async ({ target: { value } }) => {
              await setFieldValue('daysOfWeek', value)

              const newHiddenDays = []
              optionDays.forEach((el) => {
                if (value.indexOf(el.name) < 0) {
                  newHiddenDays.push(el.name)
                }
              })

              await setFieldValue('hiddenDays', newHiddenDays)
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SelectField
            required={true}
            name='hiddenDays'
            id='hiddenDays'
            helperText={
              touched.hiddenDays && errors.hiddenDays ? errors.hiddenDays : null
            }
            error={touched.hiddenDays && errors.hiddenDays ? true : false}
            handleChange={async ({ target: { value } }) => {
              await setFieldValue('hiddenDays', value)
              const newDays = []
              optionDays.forEach((el) => {
                if (value.indexOf(el.name) < 0) {
                  newDays.push(el.name)
                }
              })

              await setFieldValue('daysOfWeek', newDays)
            }}
            multiple={true}
            label={'jours hors activités'}
            value={values.hiddenDays}
            theme={theme}
            dataSource={dataSourceDays}
            disabled={false}
          />
        </Grid>
        {/*   <Grid item xs={12} sm={12}>
          <Calendar
            {...calendarProps}
            timezone={timezone}
            validRange={{
              start:
                values && values.validRangeStart
                  ? values.validRangeStart
                  : new Date(),
              end:
                values && values.validRangeEnd ? values.validRangeEnd : new Date(),
            }}
          />
        </Grid> */}
        <div style={{ paddingLeft: 10 }}>
          <Button
            color='primary'
            disabled={!canModify}
            fullWidth
            size='large'
            type='submit'
            onClick={handleSubmit}
            variant='contained'
          >
            enregistrer
          </Button>
        </div>
      </Grid>
    </form>
  )
}
