import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { makeStyles } from "@material-ui/styles"
import { Paper, Grid, Button, TextField, Link, Typography } from "@material-ui/core"
import MuiPhoneInput from "material-ui-phone-number"
import { MessageField } from "components/MessageField"
import Loading from "components/Loading"

const Boxlogin = ({
  touched,
  errors,
  setFieldValue,
  handleSubmit,
  isSubmitting,
  handleChange,
  globalError,
  values,
  classes,
  setshowbox,
}) => {
  const handleChangeBox = (stringbox) => {
    setshowbox(stringbox)
  }

  return (
    <form
      className="formcontent"
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
    >
      {globalError && <MessageField message={globalError} severity={"error"} />}
      <br />
      <h2 className="titlelogin">Se connecter</h2>

      <MuiPhoneInput
        required
        defaultCountry="sn"
        onlyCountries={["sn"]}
        region={"africa"}
        as={TextField}
        className={classes.textField}
        error={touched.phone && errors.phone ? true : false}
        fullWidth
        helperText={touched.phone && errors.phone ? errors.phone : null}
        label="Téléphone"
        name="phone"
        onChange={async (phone) => setFieldValue("phone", phone)}
        type="text"
        value={values.phone}
        variant="filled"
      />
      <TextField
        required
        className={classes.textField}
        error={touched.reference && errors.reference ? true : false}
        fullWidth
        helperText={touched.reference && errors.reference ? errors.reference : null}
        label="Référence"
        name="reference"
        onChange={handleChange}
        type="password"
        value={values.reference}
        variant="filled"
      />
      <TextField
        required
        className={classes.textField}
        error={touched.password && errors.password ? true : false}
        fullWidth
        helperText={touched.password && errors.password ? errors.password : null}
        label="Password"
        name="password"
        onChange={handleChange}
        type="password"
        value={values.password}
        variant="filled"
      />

      <Button
        className={classes.signInButton}
        color="primary"
        //disabled={!formState.isValid}

        size="large"
        type="submit"
        variant="contained"
      >
        Se connecter
      </Button>
      {isSubmitting && <Loading />}
      {/* <div className="footerLogin">
      <span className="link" onClick={() => handleChangeBox('boxpassword')}>
        Mot de passe oublié ?
      </span>

      <span className="link" onClick={() => handleChangeBox('boxcode')}>
        Récupérer mon password suite à un code reçu par email
      </span>
    </div> */}
    </form>
  )
}

export default Boxlogin
