import React,{Fragment} from "react"
import PropTypes from "prop-types"
import { Typography, Grid, Box, Button } from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import "../../../assets/scss/index.scss"

export const Setting = (props) => {
  const { item, selectForUpdate, optionDays, hiddenDays, daysOfWeek } = props


  // let hiddenDaysFinal = hiddenDays.map(el => {
  //   if(el === week[week.length -1]){
  //     let item = el
  //     splitItem = item.split('-')
  //     splitItem = splitItem[0]
  //     el = splitItem
  //   }
  
  //   return el  
  // })

  if (!item || !item.id) {
    return null
  }
  return (
    <Box style={{ marginBottom: 30 }}>
      <Grid container spacing={3}>
        <Grid className={"contentBtn"} item xs={12} sm={12}>
          <Button
            onClick={async (e) => {
              e.preventDefault()
              await selectForUpdate(item)
            }}
            size=""
            variant="contained"
            color="primary"
            className={"buttonUpdate"}
            startIcon={<EditIcon color="#fff" className={"statsIcon"} />}
          >
            Modifier
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box className={"miniContent miniContentSetting"}>
            <Box className={"miniContentMain miniContentMainSetting"}>
              <Typography style={{ flex: 2 }} variant="body2">
                Durée Rendez-vous
              </Typography>
              <p className={"infoText"}>{item.slotDuration}</p>
            </Box>
          </Box>
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box className={"miniContent miniContentSetting"}>
            <Box className={"miniContentMain miniContentMainSetting"}>
              <Typography style={{ flex: 2 }} variant="body2">
                Capacité Rv
              </Typography>
              <p className={"infoText"}>{item.slotEventCount}</p>
            </Box>
          </Box>
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box className={"miniContent miniContentSetting"}>
            <Box className={"miniContentMain miniContentMainSetting"}>
              <Typography style={{ flex: 2 }} variant="body2">
                Heure début d'activité
              </Typography>
              <p className={"infoText"}>{item.businessHours.startTime}</p>
            </Box>
          </Box>
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box className={"miniContent miniContentSetting"}>
            <Box className={"miniContentMain miniContentMainSetting"}>
              <Typography style={{ flex: 2 }} variant="body2">
                Heure fin d'activité
              </Typography>
              <p className={"infoText"}>{item.businessHours.endTime}</p>
            </Box>
          </Box>
        </Grid>

        {!item.no_rest && (
          <Fragment>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box className={"miniContent miniContentSetting"}>
                <Box className={"miniContentMain miniContentMainSetting"}>
                  <Typography style={{ flex: 2 }} variant="body2">
                    Heure début pause
                  </Typography>
                  <p className={"infoText"}>{item.rest.startTime}</p>
                </Box>
              </Box>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box className={"miniContent miniContentSetting"}>
                <Box className={"miniContentMain miniContentMainSetting"}>
                  <Typography style={{ flex: 2 }} variant="body2">
                    Heure fin pause
                  </Typography>
                  <p className={"infoText"}>{item.rest.endTime}</p>
                </Box>
              </Box>
            </Grid>
          </Fragment>
        )}

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box className={"miniContent miniContentSetting"}>
            <Box className={"miniContentMain miniContentMainSetting"}>
              <Typography style={{ flex: 2 }} variant="body2">
                jours d'activités
              </Typography>
              <p className={"infoText"}>{daysOfWeek.map((d) => {
                if(d === daysOfWeek[daysOfWeek.length -1]){
                  return d
                }else{
                  return `${d},`
                }
              })}</p>
            </Box>
          </Box>
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box className={"miniContent miniContentSetting"}>
            <Box className={"miniContentMain miniContentMainSetting"}>
              <Typography style={{ flex: 2 }} variant="body2">
                jours hors activités
              </Typography>
              <p className={"infoText"}>{hiddenDays.map((d) => {
                if(d === hiddenDays[hiddenDays.length -1]){
                  return d
                }else{
                  return `${d},`
                }
              })}</p>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

Setting.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
}
