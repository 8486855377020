import gql from "graphql-tag"

export const removeCompHistoryMutation = gql`
  mutation removeCompHistory(
    $from_date: String!
    $to_date: String!
    $company_id: String!
  ) {
    removeCompHistory(
      from_date: $from_date
      to_date: $to_date
      company_id: $company_id
    ) {
      ok

      errors {
        path
        message
      }
    }
  }
`
