import React, { useState } from "react"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import momentTimezonePlugin from "@fullcalendar/moment-timezone"
import timeGridPlugin from "@fullcalendar/timegrid"
import frLocale from "@fullcalendar/core/locales/fr"
import moment from "moment-timezone"
import "moment/locale/fr"
import "@fullcalendar/core/main.css"
import "@fullcalendar/daygrid/main.css"
import "@fullcalendar/timegrid/main.css"
import { Accordion } from "components/Accordion"

export const EventCalendar = ({ events, timezone, selectEvent, visibleRange }) => {
  const [expanded, setExpanded] = useState(false)

  if (!events || events.length == 0) {
    return null
  }
  return (
    <Accordion
      id={"calendar-display-events"}
      title={"Calendrier des Rendez-vous"}
      expanded={expanded}
      setExpanded={setExpanded}
    >
      <FullCalendar
        firstDay={false}
        editable={false}
        defaultDate={visibleRange.start}
        select={async ({ startStr }) => {
          if (moment().diff(startStr) <= 0) {
            return
          }
        }}
        events={events}
        eventClick={async ({ event: { id } }) => {
          try {
            await selectEvent(id)
          } catch (error) {
            throw error
          }
        }}
        timeZone={timezone}
        locale={frLocale}
        nowIndicator={true}
        eventBackgroundColor={"gray"}
        defaultView="timeGridWeek"
        plugins={[
          dayGridPlugin,
          interactionPlugin,
          momentTimezonePlugin,
          timeGridPlugin,
        ]}
        selectAllow={async (selectInfo) => {
          return moment().diff(selectInfo.start) <= 0
        }}
      />
    </Accordion>
  )
}
