import gql from "graphql-tag"

export const compSignalQuery = gql`
  query compSignal($page: Int!, $limit: Int, $company_id: String!) {
    compSignal(page: $page, limit: $limit, company_id: $company_id) {
      totalDocs
      hasPrevPage
      hasNextPage
      totalPages
      limit
      offset
      page
      prevPage
      nextPage
      docs {
        id
        message
        service {
          id
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`
