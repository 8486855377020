import React from "react"

import { makeStyles } from "@material-ui/styles"
import { TableCell, TableRow, Avatar } from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import WorkerIcon from "@material-ui/icons/Accessibility"
import inconnu from "../../../assets/inconnu.jpg"
import { DisplayDate } from "components/DisplayDate"
import { colors } from "utils/constants"
import { ONE_COMPANY_PATH } from "utils/static_constants"
import { Link } from "react-router-dom"
import { renderDate } from "utils/func"

const useStyles = makeStyles((theme) => ({
  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1),
  },
}))

export const Event = (props) => {
  const { item, selectEvent, timezone } = props

  const classes = useStyles()
  const cellStyle = {  }
  const gender =
    item && item.author && item.author.gender == "male" ? "Monsieur" : "Madame"
  const computeStyle = () => {
    if (item) {
      if (item.canceled || (item.finished && !item.author_presence)) {
        return { margin: 10, backgroundColor: "#ddd"}
      }

      if (item.author_presence) {
        return { margin: 10, backgroundColor: "#ADFF2F" }
      }
    }

    return { margin: 10 }
  }
  return (
    <TableRow key={item.id} style={computeStyle()}>
     {/*  <TableCell>
        <Link to={`${ONE_COMPANY_PATH}/${item.company.id}`}>
          {item.company.name}
        </Link>
      </TableCell>
      <TableCell style={cellStyle}>{item.service.name}</TableCell>
      <TableCell style={cellStyle}>{item.service.category.name}</TableCell>

      <TableCell>{item.company.region}</TableCell> */}
      <TableCell
        style={cellStyle}
      >{`${gender} ${item.author.name} ${item.author.lastname} `}</TableCell>
      <TableCell>{item.event_number}</TableCell>
      <TableCell >
        {renderDate(item.start, timezone)}
      </TableCell>

      <TableCell >
        {item.service.name}
      </TableCell>

     
      {/*
      <TableCell>
        <WorkerIcon className={classes.statsIcon} />
        {item.workers ? item.workers.length : 0}
      </TableCell>

       <TableCell>
        <EditIcon
          disable={item && item.canceled}
          className={classes.statsIcon}
          onClick={async (e) => {
            try {
              e.preventDefault()
              await selectEvent(item.id)
            } catch (error) {
              throw error
            }
          }}
        />
      </TableCell> */}
    </TableRow>
  )
}
