import React, { useState } from "react"
import { useQuery } from "react-apollo"
import Loading from "components/Loading"
import { COMPANY_ID_STORAGE } from "utils/static_constants"
import { Divider, Grid } from "@material-ui/core"
import Pagination from "@material-ui/lab/Pagination"
import { Accordion } from "components/Accordion"
import { readNumber } from "utils/readnumber"
import { oneCompanyQuery } from "graphql/query/company"
import { companyRatingsQuery } from "graphql/query/comp_ratings"
import { Rating } from "@material-ui/lab"

const AvisView = () => {
  const [page, setPage] = useState(1)
  const [limit] = useState(10)
  const [loading] = useState(false)
  const [expanded1, setExpanded1] = useState(true)
  const { loading: loading1, data: compData } = useQuery(oneCompanyQuery, {
    variables: { company_id: localStorage.getItem(COMPANY_ID_STORAGE) },
  })

  const rating =
    compData && compData.oneCompany && compData.oneCompany.rating
      ? compData.oneCompany.rating
      : null

  const { loading: loading2, data: commentData, fetchMore } = useQuery(
    companyRatingsQuery,
    {
      variables: {
        company_id: localStorage.getItem(COMPANY_ID_STORAGE),
        page,
        limit,
      },
    }
  )
  const clients =
    commentData && commentData.companyRatings && commentData.companyRatings.docs
      ? commentData.companyRatings
      : null

  const computeVariables = async (page) => {
    let variables = { page, limit }

    await setPage(page)

    return variables
  }

  const onPageChange = async (page) => {
    try {
      if (
        commentData &&
        commentData.companyRatings &&
        commentData.companyRatings.hasNextPage
      ) {
        let variables = await computeVariables(page)

        const oldData =
          commentData &&
          commentData.companyRatings &&
          commentData.companyRatings.docs
            ? commentData.companyRatings.docs
            : []
        fetchMore({
          variables,
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev

            const { companyRatings } = fetchMoreResult
            const docs = [...oldData, ...companyRatings.docs]

            return Object.assign({}, prev, {
              companyRatings: {
                ...companyRatings,
                docs,
              },
            })
          },
        })
      }
      return
    } catch (error) {
      window.alert(error.message)
    }
  }

  if (loading1 || loading2 || loading) {
    return <Loading />
  }

  return (
    <Accordion
      id={"search-client-notices"}
      title={"Avis des clients"}
      expanded={expanded1}
      setExpanded={setExpanded1}
    >
      <Grid container spacing={3}>
        {clients && (
          <Grid item xs={12} sm={12}>
            <Pagination
              count={clients.totalPages}
              page={page}
              onChange={async (_event, value) => {
                if (value.toString() !== page.toString()) {
                  await onPageChange(value)
                }
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12} style={{ textAlign: "center",marginBottom:19 }}>
          <p>{readNumber(clients.totalDocs) + " avis"}</p>
          <Rating
            value={rating && rating.average ? parseFloat(rating.average) : 0}
            readOnly={true}
            max={5}
          />
        </Grid>
        <Grid container item xs={12}>
          {clients &&  clients.docs && 
            clients.docs.map((item, index) => (
              <Grid item lg={12} md={12} sm={6} xs={12} key={index} style={{margin:10}}>
                  <div >
                    <h5>{item.author_name}</h5>
                    <p> {item.description}</p>
                    <Rating max={5} value={item.rate} readOnly={true}/>
                  </div>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Accordion>
  )
}
export default AvisView
