import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"
import { Grid, TextField,Typography } from "@material-ui/core"

import { MessageField } from "components/MessageField"
import { Button } from "@material-ui/core"
import Loading from "components/Loading"
import InfoIcon from "@material-ui/icons/Info"
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  root: {},
  imageContainer: {
    height: 64,
    width: 64,
    margin: "0 auto",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "5px",
    overflow: "hidden",
    display: "flex",
  },
  image: {
    width: "100%",
  },
  statsItem: {
    display: "flex",
  },
  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1),
  },
}))

export const Profile = (props) => {
  const {
    item,

    setUpdateAccountModal,

    setUpdatePasswordModal,
    isSubmitting,
  } = props
  const classes = useStyles()
 
  if (!item || !item.id) {
    return null
  }
  if (isSubmitting) {
    return <Loading />
  }
  return (
    <Grid container spacing={3}>
     
      <Grid item xs={12}>
           <div className={"miniContent"}>
              <div className={"miniContentHeader"}>
                <InfoIcon className={"iconminiContentHeader"} />
                <Typography variant="h3">Profil</Typography>
              </div>
              <div className={"miniContentMain"}>
                <Typography variant="body2" className="label-profil">
                    Civilité:
                </Typography>
                <p className={"infoText"}>{item.gender ? item.gender : ""}</p>
              </div>
              <div className={"miniContentMain"}>
                <Typography variant="body2" className="label-profil">
                    Nom:
                </Typography>
                <p className={"infoText"}>{item.name ? item.name : ""}</p>
              </div>
              <div className={"miniContentMain"}>
                <Typography variant="body2" className="label-profil">
                    Prénom:
                </Typography>
                <p className={"infoText"}>{item.lastname ? item.lastname : ""}</p>
              </div>
              <div className={"miniContentMain miniContentMain-last"}>
                <Typography variant="body2" className="label-profil">
                    Email:
                </Typography>
                <p className={"infoText"}>{item.email ? item.email : ""}</p>
              </div>

              <div className="content-btn-profil-footer">
                <Button
                    color="primary"
                    startIcon={<EditIcon />}
                    onClick={async () => await setUpdateAccountModal(true)}
                    variant="contained"
                  >
                    Modifier mes infos
                  </Button>

                  <Button
                      color="success"
                      onClick={async () => await setUpdatePasswordModal(true)}
                      variant="contained"
                    >
                      Changer de mot-de-passe
                    </Button>
                </div>
          </div>

         
      </Grid>

      {/*  <Grid item xs={12} sm={6}>
        <TextField
          label={"Téléphone"}
          readOnly={true}
          value={item.phone}
          fullWidth
        />
      </Grid> */}

    </Grid>
  )
}
