import gql from "graphql-tag"

export const findCompanyHistoryQuery = gql`
  query findCompanyHistory(
    $page: Int!
    $model_name: String
    $company_id: String!
    $start: String
    $end: String
    $author_ids: [String]
  ) {
    findCompanyHistory(
      page: $page
      company_id: $company_id
      start: $start
      end: $end
      model_name: $model_name
      author_ids: $author_ids
    ) {
      totalDocs
      hasPrevPage
      totalPages
      hasNextPage
      limit
      offset
      page
      prevPage
      nextPage
      docs {
        id
        author_id
        model_name
        title
        description
        company_id
        createdAt
      }
    }
  }
`
