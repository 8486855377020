import React, { useState } from "react"
import { useQuery, useMutation } from "react-apollo"
import moment from "moment-timezone"
import * as Yup from "yup"
import { Formik } from "formik"
import { makeStyles } from "@material-ui/styles"
import Loading from "components/Loading"
import { ModalDialog } from "components/ModalDialog"
import { Setting } from "./components/Setting"
import { SettingEdit } from "./components/SettingEdit"
import { updateSettingMutation } from "graphql/mutation/setting"
import { settingQuery } from "graphql/query/setting"
import { COMPANY_ID_STORAGE } from "utils/static_constants"
import { dayOfWeekFunc, dayNumberFunc, optionDays } from "utils/func"
import { oneSettingAdminQuery } from "graphql/query/setting_admin"
import { Calendar } from "../../components/Calendar"
import { Accordion } from "components/Accordion"
import { toast } from "react-toastify"

const useStyles = makeStyles((theme) => ({
  root: {
   
  },
  content: {
   
  },
}))

const updateSchema = Yup.object().shape({
  daysOfWeek: Yup.array().of(Yup.string()).nullable(),
  startTime: Yup.string().nullable(),
  endTime: Yup.string().nullable(),
  no_rest: Yup.boolean().default(false).typeError("champ requis"),
  startTimeRest: Yup.string().nullable(),
  endTimeRest: Yup.string().nullable(),
  slotDuration: Yup.string().nullable(),
  slotEventCount: Yup.number().nullable(),
  hiddenDays: Yup.array().of(Yup.string()).nullable(),
})

const SettingView = () => {
  const classes = useStyles()
  const [globalError, setGlobalError] = useState("")
  const [editModal, setEditModal] = useState(false)
  const [expanded1, setExpanded1] = useState(true)
  const [expanded2, setExpanded2] = useState(false)
  const [editedItem, setEditedItem] = useState(null)
  const { data, loading1, refetch } = useQuery(settingQuery, {
    variables: {
      company_id: localStorage.getItem(COMPANY_ID_STORAGE),
    },
  })

  const { loading: loading2, data: generalSetting } = useQuery(oneSettingAdminQuery)

  const [updateItem] = useMutation(updateSettingMutation)

  if (loading1 || loading2) {
    return <Loading />
  }

  const update = async (
    values,

    { setSubmitting, setFieldError, handleReset }
  ) => {
    try {
      const {
        startTime,
        endTime,
        startTimeRest,
        endTimeRest,
        daysOfWeek,
        slotDuration,
        slotEventCount,
        hiddenDays,
        no_rest,
      } = values

      const strTime = moment(startTime, "HH:mm")
      const ndTime = moment(endTime, "HH:mm")

      if (!no_rest) {
        const strTimeRest = moment(startTimeRest, "HH:mm")
        const ndTimeRest = moment(endTimeRest, "HH:mm")
        if (strTimeRest.isBefore(strTime)) {
          await setFieldError(
            "startTimeRest",
            "heures début activité et pause incohérentes"
          )
          await setFieldError(
            "startTime",
            "heures début activité et pause incohérentes"
          )
          return
        }

        if (ndTimeRest.isAfter(ndTime)) {
          await setFieldError(
            "endTimeRest",
            "heures fin activité et pause incohérentes"
          )
          await setFieldError("endTime", "heures fin activité et pause incohérentes")
          return
        }
        if (strTimeRest.isAfter(ndTimeRest)) {
          await setFieldError(
            "startTimeRest",
            "heures début et fin pause incohérentes"
          )
          await setFieldError(
            "endTimeRest",
            "heures début et fin pause incohérentes"
          )
          return
        }
      }

      if (strTime.isAfter(ndTime)) {
        await setFieldError(
          "startTime",
          "heures début et fin d'activités incohérentes"
        )
        await setFieldError(
          "endTime",
          "heures début et fin d'activités incohérentes"
        )
        return
      }

      let setting = { no_rest }

      if (startTime) {
        setting.startTime = startTime
      }
      if (endTime) {
        setting.endTime = endTime
      }
      if (startTimeRest) {
        setting.startTimeRest = startTimeRest
      }
      if (endTimeRest) {
        setting.endTimeRest = endTimeRest
      }

      if (slotDuration) {
        setting.slotDuration = slotDuration
      }
      if (slotEventCount) {
        setting.slotEventCount = slotEventCount
      }
      if (hiddenDays) {
        setting.hiddenDays = hiddenDays.map(dayNumberFunc)
      }
      if (daysOfWeek) {
        setting.daysOfWeek = daysOfWeek.map(dayNumberFunc)
      }

      const id = data.setting.setting.id
      const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
      const res = await updateItem({
        variables: {
          setting,
          company_id,
          id,
        },
      })

      const { ok, errors } = res.data.updateSetting
      await setSubmitting(false)
      if (ok) {
        await setEditModal(false)
        await refetch({ variables: { company_id } })
        await toast.success("Paramètre modifié avec succès")
      } else {
        errors.forEach(async (error) => {
          if (error.path && error.path.toString() === "global") {
            window.alert(error.message)
            await setGlobalError(error.message)
          } else {
            window.alert(error.message)
            await setFieldError(error.path, error.message)
          }
        })
      }
    } catch (error) {
      window.alert(error.message)
    }
  }

  const selectForUpdate = async (item) => {
    try {
      await setEditedItem(item)
      await setEditModal(true)
    } catch (error) {
      window.alert(error.message)
    }
  }

  const createInitialValues = {
    hiddenDays:
      editedItem && editedItem.hiddenDays
        ? editedItem.hiddenDays.map(dayOfWeekFunc)
        : [],
    daysOfWeek:
      editedItem && editedItem.businessHours && editedItem.businessHours.daysOfWeek
        ? editedItem.businessHours.daysOfWeek.map(dayOfWeekFunc)
        : [],
    startTime:
      editedItem && editedItem.businessHours && editedItem.businessHours.startTime
        ? editedItem.businessHours.startTime
        : "",
    endTime:
      editedItem && editedItem.businessHours && editedItem.businessHours.endTime
        ? editedItem.businessHours.endTime
        : "",
    no_rest: editedItem && editedItem.no_rest ? true : false,
    startTimeRest:
      editedItem &&
      editedItem.rest &&
      editedItem.rest.startTime &&
      editedItem.rest.startTime
        ? editedItem.rest.startTime
        : "",
    endTimeRest:
      editedItem &&
      editedItem.rest &&
      editedItem.rest.endTime &&
      editedItem.rest.endTime
        ? editedItem.rest.endTime
        : "",
    slotDuration:
      editedItem && editedItem.slotDuration ? editedItem.slotDuration : null,
    slotEventCount:
      editedItem && editedItem.slotEventCount ? editedItem.slotEventCount : null,
  }
  return (
    <div className={classes.root}>
      {editModal && (
        <Formik
          initialValues={createInitialValues}
          validationSchema={updateSchema}
          onSubmit={update}
        >
          {(props) => (
            <ModalDialog
              open={editModal}
              cancel={async () => {
                await setEditModal(false)

                await props.handleReset()
              }}
              title={"Edition paramétrage général calendrier"}
            >
              <SettingEdit
                {...props}
                item={editedItem ? editedItem : null}
                globalError={globalError}
                optionDays={optionDays}
                hiddenDays={
                  data &&
                  data.setting &&
                  data.setting.setting &&
                  data.setting.setting.hiddenDays.map(dayOfWeekFunc)
                }
                daysOfWeek={
                  data &&
                  data.setting &&
                  data.setting.setting &&
                  data.setting.setting.businessHours.daysOfWeek.map(dayOfWeekFunc)
                }
              />
            </ModalDialog>
          )}
        </Formik>
      )}

      <div className={classes.content}>
        <Accordion
          id="params-calendar"
          title={"Paramètres Calendrier"}
          expanded={expanded1}
          setExpanded={setExpanded1}
        >
          <Setting
            selectForUpdate={selectForUpdate}
            optionDays={optionDays}
            hiddenDays={
              data &&
              data.setting &&
              data.setting.setting &&
              data.setting.setting.hiddenDays.map(dayOfWeekFunc)
            }
            daysOfWeek={
              data &&
              data.setting &&
              data.setting.setting &&
              data.setting.setting.businessHours.daysOfWeek.map(dayOfWeekFunc)
            }
            item={
              data && data.setting && data.setting.setting
                ? data.setting.setting
                : null
            }
          />
        </Accordion>
      </div>
      <Accordion
        id="calendar"
        title={"Calendrier"}
        expanded={expanded2}
        setExpanded={setExpanded2}
      >
        <Calendar
          businessHours={
            data &&
            data.setting &&
            data.setting.setting &&
            data.setting.setting.businessHours &&
            data.setting.setting.businessHours.daysOfWeek
              ? data.setting.setting.businessHours.daysOfWeek
              : []
          }
          hiddenDays={
            data &&
            data.setting &&
            data.setting.setting &&
            data.setting.setting.hiddenDays
              ? data.setting.setting.hiddenDays
              : []
          }
          slotDuration={
            data &&
            data.setting &&
            data.setting.setting &&
            data.setting.setting.slotDuration
              ? data.setting.setting.slotDuration
              : "00:30"
          }
          no_rest={
            data &&
            data.setting &&
            data.setting.setting &&
            data.setting.setting.no_rest
              ? true
              : false
          }
          rest={
            data && data.setting && data.setting.setting && data.setting.setting.rest
              ? data.setting.setting.rest
              : {}
          }
          timezone={
            generalSetting &&
            generalSetting.oneSettingAdmin &&
            generalSetting.oneSettingAdmin.time_zone
              ? generalSetting.oneSettingAdmin.time_zone
              : null
          }
        />
      </Accordion>
    </div>
  )
}

export default SettingView
