import gql from 'graphql-tag'

export const createServiceInternalMutation = gql`
  mutation createServiceInternal(
    $name: String!
    $description: String!
    $file: Upload
    $category_id: String
    $price: Float
    $company_id: String!
  ) {
    createServiceInternal(
      name: $name
      description: $description
      file: $file
      category_id: $category_id
      price: $price
      company_id: $company_id
    ) {
      ok
      service {
        id
        internal
        name
        picture
        description

        is_active

        price

        createdAt
        updatedAt

        category {
          id
          name
        }
        author {
          id
          name
          lastname
        }
      }
      errors {
        path
        message
      }
    }
  }
`

export const updateServiceInternalMutation = gql`
  mutation updateServiceInternal(
    $name: String
    $description: String
    $file: Upload
    $category_id: String
    $price: Float
    $company_id: String!
    $id: String!
    $is_active: Boolean
  ) {
    updateServiceInternal(
      name: $name
      description: $description
      file: $file
      category_id: $category_id
      price: $price
      company_id: $company_id
      id: $id
      is_active: $is_active
    ) {
      ok
      service {
        id
        internal
        name
        picture
        description

        is_active

        price

        createdAt
        updatedAt

        category {
          id
          name
        }
        author {
          id
          name
          lastname
        }
      }
      errors {
        path
        message
      }
    }
  }
`
export const createServiceMutation = gql`
  mutation createService($service: ServiceInput, $company_id: String!) {
    createService(service: $service, company_id: $company_id) {
      ok
      service {
        id
        internal
        name
        picture
        description
        region
        is_active
        show_price
        sms_reminder
        price

        createdAt
        updatedAt
        slotEventCount
        hiddenDays
        slotDuration

        validRange {
          start
          end
        }
        businessHours {
          daysOfWeek
          startTime
          endTime
        }
        rest {
          title
          daysOfWeek
          startTime
          endTime
        }
        category {
          id
          name
        }
        author {
          id
          name
          lastname
        }
      }
      errors {
        path
        message
      }
    }
  }
`

export const updateServiceMutation = gql`
  mutation updateService($service: ServiceUpdateInput, $company_id: String!) {
    updateService(service: $service, company_id: $company_id) {
      ok
      service {
        id
        internal
        name
        picture
        description
        region
        show_price
        is_active
        sms_reminder
        price

        createdAt
        updatedAt
        slotEventCount
        hiddenDays
        slotDuration
        createdAt
        updatedAt
        validRange {
          start
          end
        }
        businessHours {
          daysOfWeek
          startTime
          endTime
        }
        rest {
          title
          daysOfWeek
          startTime
          endTime
        }
        category {
          id
          name
        }
        author {
          id
          name
          lastname
        }
      }
      errors {
        path
        message
      }
    }
  }
`

export const deleteServiceMutation = gql`
  mutation deleteService($service_id: String!, $company_id: String) {
    deleteService(service_id: $service_id, company_id: $company_id) {
      ok
      service_id
      errors {
        path
        message
      }
    }
  }
`
