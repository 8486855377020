import React, { useState } from "react"
import { Grid, Button, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Pagination from "@material-ui/lab/Pagination"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { Event } from "./Event"
import { Accordion } from "components/Accordion"
import { readNumber } from "utils/readnumber"

const useStyles = makeStyles({
  table: {
    width: "100%",
  },
})

export const EventList = ({
  data,
  timezone,
  selectForUpdate,
  selectForRemove,
  onPageChange,
  selectEvent,
}) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(true)

  if (!data) {
    return null
  }

  const { docs, page, totalPages, totalDocs } = data
  return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={async (_event, value) => {
              if (value.toString() !== page.toString()) {
                await onPageChange(value)
              }
            }}
          />
        </Grid>
        <Typography style={{ textAlign: "center",width:'100%',fontWeight:'700' }}>{readNumber(totalDocs)} rendez-vous</Typography>
        <Grid item xs={12} sm={12}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Entreprise</TableCell>
                  <TableCell>Numéro </TableCell>
                  <TableCell>Date rv</TableCell>
                  <TableCell>Prestation</TableCell>
                  
                  {/*  <TableCell>Date Rv</TableCell>
                  <TableCell>Affectation</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {docs.map((row) => (
                  <Event
                    key={row.id}
                    item={row}
                    timezone={timezone}
                    selectForRemove={selectForRemove}
                    selectForUpdate={selectForUpdate}
                    selectEvent={selectEvent}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
  )
}
