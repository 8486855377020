import React from 'react'
import { Grid, Button, TextField } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import Loading from 'components/Loading'
import { MessageField } from 'components/MessageField'
import { SelectField } from 'components/SelectField'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: '100%',
    padding: '0px 15px'
  },
  chip: {
    margin: theme.spacing(0.5)
  }
}))
export const ServiceCreateIntern = ({
  values,
  touched,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,

  globalError,
  setFieldValue,
  categories,
  //regions,
  currency,

  canModify
}) => {
  const theme = useTheme()
  const classes = useStyles()

  if (isSubmitting) {
    return <Loading />
  }

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit}
    >
      {globalError && <MessageField message={globalError} severity={'error'} />}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!canModify}
            required
            id='name'
            name='name'
            label='Nom'
            onChange={handleChange}
            error={touched.name && errors.name ? true : false}
            fullWidth
            helperText={touched.name && errors.name ? errors.name : null}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SelectField
            required={true}
            multiple={false}
            disabled={!canModify}
            label={'Catégorie'}
            value={values.category_id}
            name='category_id'
            id='category_id'
            theme={theme}
            dataSource={categories}
            disabled={false}
            helperText={
              touched.category_id && errors.category_id ? errors.category_id : null
            }
            error={touched.category_id && errors.category_id ? true : false}
            handleChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CurrencyTextField
            as={TextField}
            fullWidth
            required={false}
            //  disabled={!canModify}
            value={values.price}
            name='price'
            id='price'
            variant='standard'
            
            label={`Prix en ${currency} optionnel`}
            onChange={async (_event, value) => await setFieldValue('price', value)}
            helperText={touched.price && errors.price ? errors.price : null}
            error={touched.price && errors.price ? true : false}
            currencySymbol={currency}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            disabled={!canModify}
            rows={2}
            multiline
            id='description'
            name='description'
            label='Description'
            value={values.description}
            onChange={handleChange}
            style={{ width: '100%' }}
          />
          {touched.description && errors.description && (
            <span style={{ color: 'red' }}>{errors.description}</span>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
        <div>
          <Button
            color='primary'
            disabled={!canModify}
            type='submit'
            onClick={handleSubmit}
            variant='contained'
          >
            enregistrer
          </Button>
        </div>
        </Grid>
      </Grid>
    </form>
  )
}
