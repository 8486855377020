import gql from "graphql-tag"

export const addPatientHistoryMutation = gql`
  mutation addPatientHistory(
    $company_id: String!
    $patient_id: String!
    $description: String!
    $category_id: String
  ) {
    addPatientHistory(
      company_id: $company_id
      patient_id: $patient_id
      description: $description
      category_id: $category_id
    ) {
      ok

      errors {
        path
        message
      }
    }
  }
`

export const addPatientMutation = gql`
  mutation addPatient(
    $company_id: String!
    $name: String!
    $lastname: String!
    $phone: String!
    $email: String
    $gender: String!
    $sms_notify: Boolean
  ) {
    addPatient(
      company_id: $company_id
      name: $name
      lastname: $lastname
      phone: $phone
      email: $email
      gender: $gender
      sms_notify: $sms_notify
    ) {
      ok

      errors {
        path
        message
      }
    }
  }
`

export const removePatientMutation = gql`
  mutation removePatient($company_id: String!, $client_id: String!) {
    removePatient(company_id: $company_id, client_id: $client_id) {
      ok

      errors {
        path
        message
      }
    }
  }
`
