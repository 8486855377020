import React, { useState } from "react"
import {Paper,Chip} from '@material-ui/core'
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { useQuery } from "react-apollo"
import Loading from "components/Loading"
import { COMPANY_ID_STORAGE } from "utils/static_constants"
import { Grid, makeStyles } from "@material-ui/core"
import Pagination from "@material-ui/lab/Pagination"
import { Accordion } from "components/Accordion"
import { readNumber } from "utils/readnumber"
import { compSignalQuery } from "graphql/query/client_signal"
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },

  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1),
  },
}))
const ClientSignalView = () => {
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [expanded1, setExpanded1] = useState(true)
  const { loading: loading1, data, fetchMore } = useQuery(compSignalQuery, {
    variables: { page: 1, company_id: localStorage.getItem(COMPANY_ID_STORAGE) },
  })

  const classes = useStyles()
  const clients = data && data.compSignal ? data.compSignal : null
  if (loading1 || loading) {
    return <Loading />
  }
  const onPageChange = async (page) => {
    try {
      await setLoading(true)
      await setPage(page)
      const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
      let variables = { page, company_id }

      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { compSignal } = fetchMoreResult
          return Object.assign({}, prev, {
            compSignal,
          })
        },
      })

      await setLoading(false)
    } catch (error) {
      throw error
    }
  }
  return (
    <Accordion
      id={"search-client-form"}
      title={"Listes des clients"}
      expanded={expanded1}
      setExpanded={setExpanded1}
    >
      <Grid container spacing={3}>
        {clients && (
          <Grid item xs={12} sm={12}>
            <Pagination
              count={clients.totalPages}
              page={page}
              onChange={async (_event, value) => {
                if (value.toString() !== page.toString()) {
                  await onPageChange(value)
                }
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <Chip label={`Nombre d'alerte ${readNumber(clients.totalDocs)}`} color="primary" />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {/* <TableCell>n°</TableCell> */}
                  <TableCell>Prestation</TableCell>
                  <TableCell>Objet</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clients &&
                  clients.docs.map((evt, index) => (
                    <TableRow component={Paper} key={index}>
                     {/*  <TableCell>{index + 1}</TableCell> */}
                      {evt.service && evt.service.name && (
                        <TableCell>{evt.service.name}</TableCell>
                      )}
                      <TableCell>{evt.message}</TableCell>
                      
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Accordion>
  )
}
export default ClientSignalView
