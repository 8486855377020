import React, { Fragment, useCallback, useState } from 'react'
import { Grid, Button, InputLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Loading from 'components/Loading'

import axios from 'axios'

import { ModalDialog } from 'components/ModalDialog'

//import { PDFReader } from 'reactjs-pdf-reader'
import moment from 'moment-timezone'
import 'moment/locale/fr'
import { OrdonnanceInput } from './OrdonnanceInput'
import { OrdonnanceItems } from './OrdonnanceItems'

moment.locale('fr')
const HOST_FILE = 'https://pdf.doctosen.com/api/medicament-client'
const HOST_FILE_IMG = 'https://pdf.doctosen.com/images'

/* const HOST_FILE = 'http://localhost:5000/api/client-bill'
const HOST_FILE_IMG = 'http://localhost:5000/images'  */

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: '100%',
    padding: '0px 15px'
  },
  chip: {
    margin: theme.spacing(0.5)
  }
}))

export const OrdonnanceCreate = ({
  logo,
  logo_id,
  stamp,
  stamp_id,
  currency,
  time_zone,
  client,

  company,

  insurance_name,
  invoice_number,
  covered,
  percentage_insurance
}) => {
  const classes = useStyles()
  const [loadingInvoice, setLoadingInvoice] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [, updateState] = useState()
  const forceUpdate = useCallback(() => updateState({}), [])
  const [createModal, setCreateModal] = useState(false)

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [quantity, setQuantity] = useState(1)

  const [items, setItems] = useState([])

  const clearForm = async () => {
    try {
      await setName('')
      await setQuantity(1)
      await setDescription('')

      await setCreateModal(false)
    } catch (error) {
      window.alert(error.message)
    }
  }

  const addItem = async () => {
    try {
      let articles = items

      if (!name || !name.trim().length) {
        window.alert('Le médicament est obligatoire')
        return
      }

      if (!description || !description.trim().length) {
        window.alert(' la prescription est obligatoire')
        return
      }

      if (!quantity) {
        window.alert('La quantité est obligatoire')
        return
      }

      articles.push({ name, quantity, description })

      await setItems(articles)

      await clearForm()
    } catch (error) {
      window.alert(error.message)
    }
  }
  const removeItem = async (i) => {
    try {
      if (window.confirm('êtes vous sûr(e) de vouloir supprimer ?')) {
        let newItems = items

        await newItems.splice(i, 1)

        await setItems(newItems)

        await forceUpdate()
      }
    } catch (error) {
      throw error
    }
  }
  const generatePdf = async (e) => {
    e.preventDefault()

    //

    try {
      const date_time = Date.now()
      const filename = `ordonnance-${company.id}-${invoice_number}-${date_time}.pdf`
      const bill_date = moment.tz(new Date(), time_zone).format('LLLL')

      await setShowModal(true)
      await setLoadingInvoice(true)

      let company_address = ''

      if (company.description_address) {
        company_address += ` ${company.description_address},`
      }

      if (company.city) {
        company_address += ` ${company.city},`
      }

      if (company.region) {
        company_address += ` ${company.region},`
      }
      const gender = client.gender == 'male' ? 'Monsieur' : 'Madame'
      let data = {
        filename,
        company_address,
        bill_date,
        covered,
        invoice_number: date_time,
        client: `${gender} ${client.lastname} ${client.name}`,
        items,

        currency,
        company_name: company.name,
        //   insurance_name: `${percentage_insurance}% assurance  ${insurance_name}`,
        company_phone: company.phone ? company.phone : '',
        company_email: company.email ? company.email : '',
        company_signature: company.signature ? company.signature : ''
      }
      if (stamp) {
        data.stamp = stamp
        data.stamp_id = stamp_id
      }

      if (logo) {
        data.logo = logo
        data.logo_id = logo_id
      }

      const response = await axios.post(HOST_FILE, data)
      await setLoadingInvoice(false)
      if (response.status == 200) {
        await window.open(`${HOST_FILE_IMG}/${filename}`)
      }
      console.log('response', response)
      await setLoadingInvoice(false)
    } catch (error) {
      await setLoadingInvoice(false)
      window.alert(error.message)
    }
  }

  return (
    <Fragment>
      <ModalDialog
        key='current-desease'
        open={createModal}
        cancel={async () => {
          // await props.handleReset()
          await setCreateModal(false)
        }}
        title={'Ajout médicament'}
      >
        <OrdonnanceInput
          key={'current'}
          name={name}
          setName={setName}
          quantity={quantity}
          setQuantity={setQuantity}
          description={description}
          setDescription={setDescription}
          addItem={addItem}
        />
      </ModalDialog>
      <ModalDialog
        maxWidth={'md'}
        open={loadingInvoice}
        title={'PDF Médicament'}
        cancel={async () => {
          // await props.handleReset()
          await setLoadingInvoice(false)
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            {loadingInvoice && (
              <InputLabel required style={{ textAlign: 'center' }}>
                Ordonnance pdf en cours de génération
              </InputLabel>
            )}
            <br />
            <br />

            <Loading />
          </Grid>
        </Grid>
      </ModalDialog>
      <form className={classes.root} noValidate autoComplete='off'>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <InputLabel required>Logo Médicament</InputLabel>
            <br />
            {logo && (
              <div>
                <img src={logo} alt='logo' width='100' height='100' />
              </div>
            )}
          </Grid>
          <Grid item xs={6}>
            <div className='btn-content-generate'>
              <Button
                color='primary'
                // disabled={items.length === 0}
                type='button'
                onClick={async () => {
                  await setCreateModal(true)
                }}
                variant='contained'
              >
                Ajouter un médicament
              </Button>
            </div>
          </Grid>

          {items.length && (
            <OrdonnanceItems
              items={items}
              removeItem={removeItem}
              key={items.length}
            />
          )}

          <Grid item xs={12}>
            <div id='pdf'></div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ marginTop: 18 }}>
              <Button
                color='primary'
                disabled={items.length === 0}
                type='button'
                onClick={generatePdf}
                variant='contained'
              >
                Générer ordonnance en pdf
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  )
}
