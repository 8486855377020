import gql from "graphql-tag"

export const userCompNofifsQuery = gql`
  query userCompNofifs($company_id: String!) {
    userCompNofifs(company_id: $company_id) {
      id
      title
      description
      seen
      author_id
      notif_type
      notif_type_id
      createdAt
      updatedAt
    }
  }
`
