import React, { useState, Fragment } from "react"
import { useQuery } from "react-apollo"
import Loading from "components/Loading"
import { COMPANY_ID_STORAGE } from "utils/static_constants"
import {
  Grid,
  makeStyles,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  Box,
  Chip,
} from "@material-ui/core"
import Pagination from "@material-ui/lab/Pagination"
import { Accordion } from "components/Accordion"
import { readNumber } from "utils/readnumber"
import { findSmsCompQuery } from "graphql/query/facture"
import { oneSettingAdminQuery } from "graphql/query/setting_admin"
import { renderDate } from "utils/func"
import { oneCompanyQuery } from "graphql/query/company"
import moment from "moment-timezone"
import { compBillQuery } from "graphql/query/comp_bill"
import { colors } from "utils/constants"
import Paper from "@material-ui/core/Paper"

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },

  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1),
  },
}))
const FactureView = () => {
  
  const from = moment().clone().startOf("month")
  const to = moment().clone().endOf("month")
  const from_date = from.format("YYYY-MM-DD")
  const to_date = to.format("YYYY-MM-DD")
  const classes = useStyles()
  const [page, setPage] = useState(1)
  const [limit] = useState(10)

  const [expanded1, setExpanded1] = useState(true)
  const [expanded2, setExpanded2] = useState(true)
  const [expanded3, setExpanded3] = useState(false)
  const { loading: loading1, data: generalSetting } = useQuery(oneSettingAdminQuery)
  const { loading: loading3, data: compData } = useQuery(oneCompanyQuery, {
    variables: { company_id: localStorage.getItem(COMPANY_ID_STORAGE) },
  })
  const {
    loading: loading2,
    data: commentData,
    fetchMore,
  } = useQuery(findSmsCompQuery, {
    variables: {
      company_id: localStorage.getItem(COMPANY_ID_STORAGE),
      page,
      limit,
      from_date,
      to_date,
    },
  })

  const { loading: loading4, data: billData } = useQuery(compBillQuery, {
    variables: {
      company_id: localStorage.getItem(COMPANY_ID_STORAGE),
      from_date,
      to_date,
    },
  })

  const bill =
    billData && billData.compBill && billData.compBill.ok && billData.compBill.bill
      ? billData.compBill.bill
      : null
  const clients =
    commentData && commentData.findSmsComp && commentData.findSmsComp.docs
      ? commentData.findSmsComp
      : null

  const computeVariables = async (page) => {
    let variables = { page, limit }

    await setPage(page)

    return variables
  }

  const onPageChange = async (page) => {
    try {
      let variables = await computeVariables(page)

      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { findSmsComp } = fetchMoreResult

          return Object.assign({}, prev, {
            findSmsComp,
          })
        },
      })
    } catch (error) {
      window.alert(error.message)
    }
  }

  const sms_price =
    generalSetting &&
    generalSetting.oneSettingAdmin &&
    generalSetting.oneSettingAdmin.sms_price
      ? generalSetting.oneSettingAdmin.sms_price
      : 0
  const subscription =
    compData && compData.oneCompany && compData.oneCompany.subscription
      ? compData.oneCompany.subscription
      : null

  const currency =
    generalSetting &&
    generalSetting.oneSettingAdmin &&
    generalSetting.oneSettingAdmin.currency
      ? generalSetting.oneSettingAdmin.currency
      : ""
  const timezone =
    generalSetting &&
    generalSetting.oneSettingAdmin &&
    generalSetting.oneSettingAdmin.timezone
      ? generalSetting.oneSettingAdmin.timezone
      : "Africa/dakar"

  if (loading1 || loading2 || loading3) {
    return <Loading />
  }

  return (
    <Fragment>
      <h3
        style={{ textAlign: "center", color: colors.VIOLET,paddingTop:25,paddingBottom:10 }}
      >{`Facture en cours  du   ${from.format("ll")}  au   ${to.format("ll")}`}</h3>

      {bill && (
        <Accordion
          id={"search-clientbill"}
          title={"Facture totale"}
          expanded={expanded1}
          setExpanded={setExpanded1}
        >
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box className={"miniContent miniContentSetting"}>
                <Box className={"miniContentMain miniContentMainSetting"}>
                  <Typography style={{ flex: 2 }} variant="body2">
                    Facture totale en cours
                  </Typography>
                  <p className={"infoText"}>{bill.final_bill + " " + currency}</p>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box className={"miniContent miniContentSetting"}>
                <Box className={"miniContentMain miniContentMainSetting"}>
                  <Typography style={{ flex: 2 }} variant="body2">
                  Facture Hors Taxe
                  </Typography>
                  <p className={"infoText"}>{bill.final_bill_ht + " " + currency}</p>
                </Box>
              </Box>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box className={"miniContent miniContentSetting"}>
                <Box className={"miniContentMain miniContentMainSetting"}>
                  <Typography style={{ flex: 2 }} variant="body2">
                    Tva ({bill.vat} %)
                  </Typography>
                  <p className={"infoText"}>{bill.total_vat + " " + currency}</p>
                </Box>
              </Box>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box className={"miniContent miniContentSetting"}>
                <Box className={"miniContentMain miniContentMainSetting"}>
                  <Typography style={{ flex: 2 }} variant="body2">
                    Abonnement
                  </Typography>
                  <p className={"infoText"}>
                    {bill.subscription_price + " " + currency}
                  </p>
                </Box>
              </Box>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box className={"miniContent miniContentSetting"}>
                <Box className={"miniContentMain miniContentMainSetting"}>
                  <Typography style={{ flex: 2 }} variant="body2">
                    Sms consommés ( {readNumber(bill.total_sms)})
                  </Typography>
                  <p className={"infoText"}>
                    {bill.total_sms_price + " " + currency}
                  </p>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Accordion>
      )}

      <Accordion
        id={"search-client-sms"}
        title={"Total sms consommés"}
        expanded={expanded2}
        setExpanded={setExpanded2}
      >
        <Grid container spacing={3}>
          {clients && (
            <Grid item xs={12} sm={12}>
              <Pagination
                count={clients.totalPages}
                page={page}
                onChange={async (_event, value) => {
                  /*   if (value.toString() !== page.toString()) {
                    await onPageChange(value)
                  } */
                  await onPageChange(value)
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} style={{marginLeft:15}}>
            <p>
                <span className="label-badge">Nombre de sms</span>
                <Chip label={`${readNumber(clients.totalDocs)}`} color="warning" size="small" />
              </p>
          </Grid>
          {/*  <Grid item xs={12} sm={12}>
          {clients &&
            clients.docs.map((item, index) => (
              <div key={index}>
                <h5>
                  {item.author.gender == "male" ? "Monsieur" : "Madame"}{" "}
                  {item.author.lastname + " " + item.author.name}
                </h5>

                <p> {item.title}</p>
                <Divider
                  variant="middle"
                  primary
                  style={{ marginTop: 10, marginBottom: 10 }}
                />
              </div>
            ))}
        </Grid> */}

          <Grid item xs={12} sm={12}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Envoyé le</TableCell>
                    <TableCell>Téléphone</TableCell>
                    <TableCell>Envoyé à</TableCell>
                    <TableCell>Objet</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clients &&
                    clients.docs.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{renderDate(item.createdAt, timezone)}</TableCell>
                        <TableCell>{item.phone}</TableCell>

                        <TableCell>
                          {item.author.gender == "male" ? "Monsieur" : "Madame"}{" "}
                          {item.author.lastname + " " + item.author.name}
                        </TableCell>
                        <TableCell>{item.title}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Accordion>
    </Fragment>
  )
}
export default FactureView
