import gql from "graphql-tag";

export const GET_CURRENT_MENU_QUERY = gql`
  query getMenu {
    smallMenu @client {
      smallMenu
    }
  }
`;

 
