import React, { useState, useEffect } from "react"
import { useQuery, useMutation } from "react-apollo"
import * as Yup from "yup"
import { Formik } from "formik"
import { makeStyles } from "@material-ui/styles"
import Loading from "components/Loading"
import { ModalDialog } from "components/ModalDialog"
import { Company } from "./components/Company"
import { CompanyEdit } from "./components/CompanyEdit"
import { COMPANY_ID_STORAGE } from "utils/static_constants"
import { oneCompanyQuery } from "graphql/query/company"
import {
  addPictureMutation,
  removePictureMutation,
  updateCompanyMutation,
} from "graphql/mutation/company"
import { currentUserQuery } from "graphql/query/user"
import { geocodeByPlaceId, getLatLng } from "react-google-places-autocomplete"
import { toast } from "react-toastify"

const FILE_SIZE = 1600 * 1024
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"]
const updateSchema = Yup.object().shape({
  company_id: Yup.string().trim().nullable().required("champ requis"),

  name: Yup.string()
    .trim()
    .nullable()

    .min(2, "minimum 2 caractères")
    .max(100, "maximum 100 caractères"),

  phone: Yup.string()
    .trim()
    .nullable()

    .min(2, "minimum 2 caractères")
    .max(100, "maximum 100 caractères"),

  facebook: Yup.string()
    .trim()
    .nullable()

    .min(2, "minimum 2 caractères")
    .max(500, "maximum 100 caractères"),

  instagram: Yup.string()
    .trim()
    .nullable()

    .min(2, "minimum 2 caractères")
    .max(500, "maximum 100 caractères"),

  twitter: Yup.string()
    .trim()
    .nullable()

    .min(2, "minimum 2 caractères")
    .max(500, "maximum 100 caractères"),

  website: Yup.string()
    .trim()
    .nullable()

    .min(2, "minimum 2 caractères")
    .max(500, "maximum 100 caractères"),

  linkedin: Yup.string()
    .trim()
    .nullable()

    .min(2, "minimum 2 caractères")
    .max(500, "maximum 100 caractères"),

  /**
     * 
     *  facebook
        twitter
        instagram
        linkedin
        website
     */
  city: Yup.string()
    .trim()
    .nullable()

    .min(2, "minimum 2 caractères")
    .max(100, "maximum 100 caractères"),

  reference: Yup.string()
    .trim()
    .min(6, "minimun 6 caractères")
    .max(10, "maximum 10 caractères")
    .nullable(),
  description: Yup.string()
    .trim()
    .min(10, "minimum 10 caractères")
    .nullable()
    .max(500, "maximum 500 caractères"),

  description_address: Yup.string()
    .trim()
    .min(10, "minimum 10 caractères")
    .nullable()
    .max(500, "maximum 500 caractères"),

  lat: Yup.string()
    .trim()

    .nullable(),
  lng: Yup.string()
    .trim()

    .nullable(),
  file: Yup.mixed()
    .nullable()
    .test("fileFormat", "extension non acceptée", (value) => {
      if (value) {
        return value && SUPPORTED_FORMATS.includes(value.type)
      }
      return true
    }),
  /* .test("fileSize", "image trop large", (value) => {
      if (value) {
        return value && value.size <= FILE_SIZE
      }
      return true
    }), */
})
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px 12px",
  },
  content: {},
}))

const CompanyView = () => {
  const classes = useStyles()
  const [globalEditError, setGlobalEditError] = useState("")
  const [placeId, setPlaceId] = useState(null)
  const [editModal, setEditModal] = useState(false)
  const [canModify, setCanModify] = useState(false)

  const { data: userData } = useQuery(currentUserQuery, {
    fetchPolicy: "network-only",
  })
  const roles =
    userData && userData.onlineUser && userData.onlineUser.roles
      ? userData.onlineUser.roles
      : []
  const {
    loading,
    data: compData,
    refetch: refetchCompany,
  } = useQuery(oneCompanyQuery, {
    fetchPolicy: "network-only",
    variables: { company_id: localStorage.getItem(COMPANY_ID_STORAGE) },
  })
  const companyInformation =
    compData && compData.oneCompany && compData.oneCompany.id
      ? compData.oneCompany
      : null

  const [addPicture] = useMutation(addPictureMutation)
  const [removePicture] = useMutation(removePictureMutation)
  const [updateItem] = useMutation(updateCompanyMutation, {
    async update(
      cache,
      {
        data: {
          updateCompany: { ok, company },
        },
      }
    ) {
      if (ok) {
        await cache.writeQuery({
          query: oneCompanyQuery,
          data: {
            oneCompany: {
              ...company,
            },
          },
        })
      }
    },
  })

  const update = async (
    values,

    { setSubmitting, setFieldError }
  ) => {
    try {
      if (companyInformation) {
        const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
        const {
          name,
          description,
          file,
          reference,
          description_address,
          lat,
          lng,
          city,
          phone,
          facebook,
          twitter,
          instagram,
          linkedin,
          website,
        } = values
        let variables = { company_id, id: companyInformation.id }

        if (name && name.trim().length > 0 && companyInformation.name != name) {
          variables.name = name
        }

        if (phone && phone.trim().length > 0 && companyInformation.phone != phone) {
          variables.phone = phone
        }

        if (
          facebook &&
          facebook.trim().length > 0 &&
          companyInformation.facebook != facebook
        ) {
          variables.facebook = facebook
        }

        if (
          twitter &&
          twitter.trim().length > 0 &&
          companyInformation.twitter != twitter
        ) {
          variables.twitter = twitter
        }

        if (
          instagram &&
          instagram.trim().length > 0 &&
          companyInformation.instagram != instagram
        ) {
          variables.instagram = instagram
        }

        if (
          linkedin &&
          linkedin.trim().length > 0 &&
          companyInformation.linkedin != linkedin
        ) {
          variables.linkedin = linkedin
        }

        if (
          website &&
          website.trim().length > 0 &&
          companyInformation.website != website
        ) {
          variables.website = website
        }

        if (
          reference &&
          reference.trim().length > 0 &&
          companyInformation.reference != reference
        ) {
          variables.reference = reference
        }
        if (city && city.trim().length > 0 && companyInformation.city != city) {
          variables.city = city
        }

        if (lat && companyInformation.lat != lat) {
          variables.lat = lat
        }

        if (lng && companyInformation.lng != lng) {
          variables.lng = lng
        }

        if (
          description &&
          description.trim().length > 0 &&
          companyInformation.description != description
        ) {
          variables.description = description
        }
        if (
          description_address &&
          description_address.trim().length > 0 &&
          companyInformation.description_address != description_address
        ) {
          variables.description_address = description_address

          const geocode = await geocodeByPlaceId(placeId)
          const { lat, lng } = await getLatLng(geocode[0])

          variables.lng = lng.toString()
          variables.lat = lat.toString()
        }
        if (file) {
          variables.file = file
        }

        if (Object.keys(variables).length == 2) {
          await setSubmitting(false)
          await setEditModal(false)
          return
        }

        const res = await updateItem({
          variables,
        })

        const { ok, errors } = res.data.updateCompany
        if (ok) {
          await setSubmitting(false)
          await setEditModal(false)
        } else {
          errors.forEach(async (error) => {
            if (error.path && error.path.toString() === "global") {
              await setGlobalEditError(error.message)
            } else {
              await setFieldError(error.path, error.message)
              await setSubmitting(false)
            }
          })
        }
      }
    } catch (error) {
      throw error
    }
  }

  const setUpRoles = async (roles) => {
    try {
      const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)

      const modify = roles
        .filter((r) => r.company_id && r.company_id == company_id)
        .some((r) => r.is_comp_super_admin || r.is_comp_admin)

      await setCanModify(modify)

      return true
    } catch (error) {
      throw error
    }
  }

  const saveFile = async (obj) => {
    try {
      const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
      let variables = { company_id }

      if (obj.logo) {
        variables.logo = obj.logo
      }

      if (obj.logo_id) {
        variables.logo_id = obj.logo_id
      }

      if (obj.picture) {
        variables.picture = obj.picture
      }

      if (obj.picture_id) {
        variables.picture_id = obj.picture_id
      }

      if (obj.stamp) {
        variables.stamp = obj.stamp
      }

      if (obj.stamp_id) {
        variables.stamp_id = obj.stamp_id
      }
      const res = await addPicture({ variables })

      const { ok, errors } = res.data.addPicture
      if (ok) {
        await toast.success("enregistré")
        return true
      } else {
        errors.forEach(async (error) => {
          window.alert(error.message)
        })
      }
    } catch (error) {
      window.alert(error.message)
    }
  }

  const removeFile = async (obj) => {
    try {
      const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
      let variables = { company_id }

      if (obj.logo) {
        variables.logo = obj.logo
      }

      if (obj.picture) {
        variables.picture = obj.picture
      }

      if (obj.stamp) {
        variables.stamp = obj.stamp
      }

      const res = await removePicture({ variables })
      console.log("res", res)

      const { ok, errors } = res.data.removePicture
      if (ok) {
        await refetchCompany({ variables: { company_id } })

        await toast.success("Image supprimée")
        return true
      } else {
        errors.forEach(async (error) => {
          window.alert(error.message)
        })
      }
    } catch (error) {
      window.alert(error.message)
    }
  }
  useEffect(() => {
    if (roles) {
      setUpRoles(roles)
        .then(() => {})
        .catch((err) => console.log("error", err))
    }
  }, [roles])
  if (loading) {
    return <Loading />
  }

  const editInitialValues = {
    company_id:
      companyInformation && companyInformation.id ? companyInformation.id : "",
    name: companyInformation ? companyInformation.name : "",
    description: companyInformation ? companyInformation.description : "",
    reference: companyInformation ? companyInformation.reference : "",
    description_address: companyInformation
      ? companyInformation.description_address
      : "",
    region: companyInformation ? companyInformation.region : "",
    city: companyInformation ? companyInformation.city : "",
    phone: companyInformation ? companyInformation.phone : "",
    file: null,

    facebook: companyInformation ? companyInformation.facebook : "",
    lat: companyInformation ? companyInformation.lat : "",
    lng: companyInformation ? companyInformation.lng : "",
    twitter: companyInformation ? companyInformation.twitter : "",
    instagram: companyInformation ? companyInformation.instagram : "",
    linkedin: companyInformation ? companyInformation.linkedin : "",
    website: companyInformation ? companyInformation.website : "",
  }
  return (
    <div className={classes.root}>
      {canModify && (
        <Formik
          initialValues={editInitialValues}
          validationSchema={updateSchema}
          onSubmit={update}
        >
          {(props) => (
            <ModalDialog
              open={editModal}
              cancel={async () => {
                await setEditModal(false)

                await props.handleReset()
              }}
              title={"Edition paramétrage général entreprise"}
            >
              <CompanyEdit
                setPlaceId={setPlaceId}
                {...props}
                item={companyInformation}
                globalError={globalEditError}
              />
            </ModalDialog>
          )}
        </Formik>
      )}

      <div className={classes.content}>
        <Company
          saveFile={saveFile}
          removeFile={removeFile}
          selectForUpdate={async () => await setEditModal(true)}
          item={companyInformation}
          canModify={canModify}
          logo={
            companyInformation && companyInformation.logo
              ? companyInformation.logo
              : ""
          }
          stamp={
            companyInformation && companyInformation.stamp
              ? companyInformation.stamp
              : ""
          }
          stamp_id={
            companyInformation && companyInformation.stamp_id
              ? companyInformation.stamp_id
              : ""
          }
          company_id={
            companyInformation && companyInformation.id
              ? companyInformation.id
              : undefined
          }
          logo_id={
            companyInformation && companyInformation.logo_id
              ? companyInformation.logo_id
              : ""
          }
          picture={
            companyInformation && companyInformation.picture
              ? companyInformation.picture
              : ""
          }
          picture_id={
            companyInformation && companyInformation.picture_id
              ? companyInformation.picture_id
              : ""
          }
        />
      </div>

      {/*  <div className={classes.content}>
        <GooglePlacesAutocomplete
          selectProps={{
            value,
            onChange: setValue
          }}
          apiKey={GOOGLE_API_KEY}
          autocompletionRequest={{ componentRestrictions: { country: 'sn' } }}
        />
      </div> */}
    </div>
  )
}

export default CompanyView
