import React, { Fragment, useState } from 'react'
import {
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Loading from 'components/Loading'
import { MessageField } from 'components/MessageField'
import { SelectField } from 'components/SelectField'
import { PhoneField } from 'components/PhoneField'
import { Accordion } from 'components/Accordion'
import CustomTable from './CustomTable'
import { SelectInput } from 'components/SelectInput'
import { generateYearsBetween } from 'utils/func'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: '100%'
  },
  chip: {
    margin: theme.spacing(0.5)
  }
}))
export const PatientCreate = ({
  values,
  touched,
  errors,
  handleChange,
  setCurrentDeseaseModal,
  current_deseases,
  regions,
  handleSubmit,
  isSubmitting,
  globalError,
  setFieldValue,
  removeCurrentDesease,

  removeOldDesease,
  old_deseases,

  setOldDeseaseModal,

  removeAllergy,
  allergies,

  setAllergyModal
}) => {
console.log('allergies',allergies)
  const theme = useTheme()
  const classes = useStyles()
  const [expanded1, setExpanded1] = useState(true)

  const blood_groups = ['A+', 'A-', 'B-', 'O+', 'O-', 'AB+', 'AB-', 'B+']
  const insurances = ['IPM', 'SENECARE', 'Allianz']

  if (isSubmitting) {
    return <Loading />
  }

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit}
    >
      {globalError && <MessageField message={globalError} severity={'error'} />}

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          {/* <SelectField
              required={true}
              multiple={false}
              handleChange={async ({ target: { value } }) => {
                try {
                  await setFieldValue('gender', value)
                } catch (error) {}
              }}
              name='gender'
              label={'civilité'}
              value={values.gender}
              theme={theme}
              dataSource={['Monsieur', 'Madame']}
              helperText={touched.gender && errors.gender ? errors.gender : null}
              error={touched.gender && errors.gender ? true : false}
            /> */}

          <SelectInput
            key={'select-gender-patient'}
            name='gender'
            label={'Civilité'}
            fullWidth={true}
            value={values.gender}
            options={['Monsieur', 'Madame']}
            required={true}
            helperText={touched.gender && errors.gender ? errors.gender : null}
            error={touched.gender && errors.gender ? true : false}
            onChange={async ({ target: { value } }) => {
              try {
                await setFieldValue('gender', value)
              } catch (error) {
                window.alert(error.message)
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id='name'
            name='name'
            label='Nom'
            value={values.name}
            onChange={handleChange}
            error={touched.name && errors.name ? true : false}
            fullWidth
            helperText={touched.name && errors.name ? errors.name : null}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id='lastname'
            name='lastname'
            label='Prénom'
            value={values.lastname}
            onChange={handleChange}
            error={touched.lastname && errors.lastname ? true : false}
            fullWidth
            helperText={touched.lastname && errors.lastname ? errors.lastname : null}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          {/*   <TextField
              type='year'
              id='birth_year'
              name='birth_year'
              label='Année de naissance'
              onChange={handleChange}
              error={touched.birth_year && errors.birth_year ? true : false}
              fullWidth
              helperText={
                touched.birth_year && errors.birth_year ? errors.birth_year : null
              }
            /> */}

          <SelectInput
            key={'select-gender-patient'}
            name='birth_year'
            label={'Année de naissance'}
            fullWidth={true}
            value={values.birth_year}
            options={generateYearsBetween(1900, 2100)}
            required={false}
            helperText={
              touched.birth_year && errors.birth_year ? errors.birth_year : null
            }
            error={touched.birth_year && errors.birth_year ? true : false}
            onChange={async ({ target: { value } }) => {
              try {
                await setFieldValue('birth_year', value)
              } catch (error) {
                window.alert(error.message)
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required={false}
            id='email'
            name='email'
            label='Email'
            value={values.email}
            onChange={handleChange}
            error={touched.email && errors.email ? true : false}
            fullWidth
            helperText={touched.email && errors.email ? errors.email : null}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          {/*   <SelectField
              required={false}
              multiple={false}
              handleChange={async ({ target: { value } }) => {
                try {
                  await setFieldValue('region', value)
                } catch (error) {}
              }}
              name='gender'
              label={'Région'}
              value={values.region}
              theme={theme}
              dataSource={regions}
              helperText={touched.region && errors.region ? errors.region : null}
              error={touched.region && errors.region ? true : false}
            /> */}

          <SelectInput
            key={'select-region-patient'}
            name='region'
            label={'Région'}
            fullWidth={true}
            helperText={'Sélectionner une région'}
            value={values.region}
            options={regions}
            required={false}
            onChange={async ({ target: { value } }) => {
              try {
                await setFieldValue('region', value)
              } catch (error) {
                window.alert(error.message)
              }
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required={false}
            id='address'
            name='address'
            label='Adresse'
            value={values.address}
            onChange={handleChange}
            error={touched.address && errors.address ? true : false}
            fullWidth
            helperText={touched.address && errors.address ? errors.address : null}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <PhoneField
            value={values.phone}
            setFieldValue={setFieldValue}
            label='Téléphone'
            name='phone'
            helperText={touched.phone && errors.phone ? errors.phone : null}
            error={touched.phone && errors.phone ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id='register_number'
            name='register_number'
            label='Identifiant unique patient'
            onChange={handleChange}
            value={values.register_number}
            error={touched.register_number && errors.register_number ? true : false}
            fullWidth
            helperText={
              touched.register_number && errors.register_number
                ? errors.register_number
                : null
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          {/*    <TextField
              id='blood_group'
              name='blood_group'
              label='Groupe sanguin'
              onChange={handleChange}
              error={touched.blood_group && errors.blood_group ? true : false}
              fullWidth
              helperText={
                touched.blood_group && errors.blood_group ? errors.blood_group : null
              }
            /> */}

          <SelectInput
            key={'select-blood_group-patient'}
            name='blood_group'
            label={'Groupe sanguin'}
            fullWidth={true}
            value={values.blood_group}
            options={blood_groups}
            required={false}
            helperText={
              touched.blood_group && errors.blood_group ? errors.blood_group : null
            }
            error={touched.blood_group && errors.blood_group ? true : false}
            onChange={async ({ target: { value } }) => {
              try {
                await setFieldValue('blood_group', value)
              } catch (error) {
                window.alert(error.message)
              }
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id='height'
            name='height'
            label='Taille'
            onChange={handleChange}
            value={values.height}
            error={touched.height && errors.height ? true : false}
            fullWidth
            helperText={touched.height && errors.height ? errors.height : null}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id='weight'
            name='weight'
            label='Poids'
            value={values.weight}
            onChange={handleChange}
            error={touched.weight && errors.weight ? true : false}
            fullWidth
            helperText={touched.weight && errors.weight ? errors.weight : null}
          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={0}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                id='is_old_smoking'
                name='is_old_smoking'
                checked={values.is_old_smoking}
                onChange={async (_e, value) =>
                  await setFieldValue('is_old_smoking', value)
                }
              />
            }
            label={'Est-ce que le patient fumait dans le passé ? cochez si oui'}
          />
        </Grid>

      <Grid item md={12} sm={12} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                id='is_smoking'
                name='is_smoking'
                checked={values.is_smoking}
                onChange={async (_e, value) =>
                  await setFieldValue('is_smoking', value)
                }
              />
            }
            label={'Est-ce que le patient est fumeur actuellement ? cochez si oui'}
          />
        </Grid>

      <Grid item lg={7} md={7} sm={12} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                id='has_current_desease'
                name='has_current_desease'
                checked={values.has_current_desease}
                onChange={async (_e, value) => {
                  await setFieldValue('has_current_desease', value)
                  if (value) {
                    await setCurrentDeseaseModal(true)
                  }
                }}
              />
            }
            label={
              'Est-ce que le patient a des problèmes de santé actuellement ? cochez si oui'
            }
          />
        </Grid>
        
        {values.has_current_desease && (
          <Fragment>
            <Grid item lg={5} md={5} sm={12} xs={12} style={{display:'flex',flexDirection:'column'}}>
              <Button
                color='primary'
                size="small"
                //disabled={!formState.isValid}

                //  type='submit'
                onClick={async () => await setCurrentDeseaseModal(true)}
                variant='contained'
              >
                Ajouter les problèmes de santé actuels
              </Button>
             
            </Grid>

            {current_deseases.length > 0 && (
              <Grid item lg={12} md={12} xs={12} sm={12}>
                <CustomTable
                  title={'Liste des problèmes de santé actuels'}
                  key={'current_deseases'}
                  cols={['Nom de la maladie','Problème de santé', 'Année','Action']}
                  rows={current_deseases}
                  keys={['name', 'description','year']}
                  remove={removeCurrentDesease}
                />
              </Grid>
            )}
          </Fragment>
        )}
       {/*  <Grid item xs={12} sm={12}>
                <CustomTable
                  title={'Liste des problèmes de santé actuels'}
                  key={'current_deseases'}
                  cols={['Problème de santé', 'année']}
                  rows={current_deseases}
                  keys={['name', 'year']}
                  remove={removeCurrentDesease}
                />
        </Grid> */}

        <Grid item lg={7} md={7} sm={12} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                id='has_old_desease'
                name='has_old_desease'
                checked={values.has_old_desease}
                onChange={async (_e, value) => {
                  await setFieldValue('has_old_desease', value)
                  if (value) {
                    await setOldDeseaseModal(true)
                  }
                }}
              />
            }
            label={
              'Est-ce que le patient a des problèmes passés de santé  ? cochez si oui'
            }
          />
        </Grid>
        {values.has_old_desease && (
          <Fragment>
            <Grid item g={5} md={5} sm={12} xs={12} style={{display:'flex',flexDirection:'column'}}>
              <Button
                color='primary'
                size="small"
                //disabled={!formState.isValid}

                //  type='submit'
                onClick={async () => await setOldDeseaseModal(true)}
                variant='contained'
              >
                Ajouter les probèmes passés de santé
              </Button>
            </Grid>

            {old_deseases.length > 0 && (
              <Grid item lg={12} md={12} xs={12} sm={12}>
                <CustomTable
                  title={'Liste des problèmes de santé passés'}
                  key={'old_deseases'}
                  cols={['Nom de la maladie','Problème de santé', 'Année','Action']}
                  rows={old_deseases}
                  keys={['name','description','year']}
                  remove={removeOldDesease}
                />
              </Grid>
            )}
          </Fragment>
        )}

        <Grid item lg={7} md={7} sm={12} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                id='has_allergy'
                name='has_allergy'
                checked={values.has_allergy}
                onChange={async (_e, value) => {
                  await setFieldValue('has_allergy', value)
                  if (value) {
                    await setAllergyModal(true)
                  }
                }}
              />
            }
            label={'Est-ce que le patient a des allergies  ? cochez si oui'}
          />
        </Grid>
        {values.has_allergy && (
          <Fragment>
            <Grid item lg={5} md={5} sm={12} xs={12} style={{display:'flex',flexDirection:'column'}}>
              <Button
                color='primary'
                size="small"
                //disabled={!formState.isValid}

                //  type='submit'
                onClick={async () => await setOldDeseaseModal(true)}
                variant='contained'
              >
                Ajouter les allergies
              </Button>
            </Grid>

            {allergies.length > 0 && (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CustomTable
                  title={'Liste des allergies'}
                  key={'allergies'}
                  cols={['Nom allergie','Detail allergies','Année','Action']}
                  rows={allergies}
                  keys={['name','description','year']}
                  remove={removeAllergy}
                />
              </Grid>
            )}
          </Fragment>
        )}
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                id='has_vital_card'
                name='has_vital_card'
                checked={values.has_vital_card}
                onChange={async (_e, value) => {
                  await setFieldValue('has_vital_card', value)
                }}
              />
            }
            label={'Est-ce que le patient possède une carte vitale  ? cochez si oui'}
          />
        </Grid>
        {values.has_vital_card && (
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              required
              id='vital_card_number'
              name='vital_card_number'
              label='Numéro carte vitale'
              onChange={handleChange}
              value={values.vital_card_number}
              error={
                touched.vital_card_number && errors.vital_card_number ? true : false
              }
              fullWidth
              helperText={
                touched.vital_card_number && errors.vital_card_number
                  ? errors.vital_card_number
                  : null
              }
            />
          </Grid>
        )}

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                id='has_health_insurance'
                name='has_health_insurance'
                checked={values.has_health_insurance}
                onChange={async (_e, value) => {
                  await setFieldValue('has_health_insurance', value)
                }}
              />
            }
            label={'Est-ce que le patient a une assurance  ? cochez si oui'}
          />
        </Grid>

        {values.has_health_insurance && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              {/*  <TextField
                  required
                  id='name_insurance'
                  name='name_insurance'
                  label="Nom de l'organisme 'assureur"
                  onChange={handleChange}
                  error={
                    touched.name_insurance && errors.name_insurance ? true : false
                  }
                  fullWidth
                  helperText={
                    touched.name_insurance && errors.name_insurance
                      ? errors.name_insurance
                      : null
                  }
                />
              */}
              <SelectInput
                key={'select-blood_group-patient'}
                name='name_insurance'
                label={'Mutuelle'}
                fullWidth={true}
                value={values.name_insurance}
                options={insurances}
                required={false}
                helperText={
                  touched.name_insurance && errors.name_insurance
                    ? errors.name_insurance
                    : null
                }
                error={
                  touched.name_insurance && errors.name_insurance ? true : false
                }
                onChange={async ({ target: { value } }) => {
                  try {
                    await setFieldValue('name_insurance', value)
                  } catch (error) {
                    window.alert(error.message)
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                required
                fullWidth
                label='Taux de couverture (%)'
                value={values.percentage_insurance}
                inputProps={{
                  maxLength: 5,
                  step: '1',
                  min: '0'
                }}
                name='percentage_insurance'
                id='percentage_insurance'
                onChange={async ({ target: { value } }) => {
                  await setFieldValue('percentage_insurance', value)
                }}
                type='number'
                helperText={
                  touched.percentage_insurance && errors.percentage_insurance
                    ? errors.percentage_insurance
                    : null
                }
                error={
                  touched.percentage_insurance && errors.percentage_insurance
                    ? true
                    : false
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                id='validity_end_insurance'
                label='Date de fin de validité assurance'
                name='validity_end_insurance'
                type='date'
                value={values.validity_end_insurance}
                onChange={async ({ target: { value } }) => {
                  await setFieldValue('validity_end_insurance', value)
                }}
                InputLabelProps={{
                  shrink: true
                }}
                helperText={
                  touched.validity_end_insurance && errors.validity_end_insurance
                    ? errors.validity_end_insurance
                    : null
                }
                error={
                  touched.validity_end_insurance && errors.validity_end_insurance
                    ? true
                    : false
                }
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <br />
      <Grid item xs={12} sm={6}>
        <Button
          color='primary'
          //disabled={!formState.isValid}

          //type='submit'
          onClick={async (e) => {
            e.preventDefault()
            await handleSubmit()
          }}
          variant='contained'
        >
          Enregistrer les modifications
        </Button>
      </Grid>
    </form>
  )
}
