import React, { Fragment } from "react"
import { Grid, Button, TextField, Avatar } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import moment from "moment-timezone"
import Loading from "components/Loading"
import inconnu from "../../../assets/inconnu.jpg"
import { MessageField } from "components/MessageField"
import { Link } from "react-router-dom"
import { renderDate } from "utils/func"
import { colors } from "utils/constants"
import { CLIENT_HISTORY_PATH } from "utils/static_constants"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "100%",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}))
export const EventEdit = ({
  event,
  globalError,

  timezone,
  loading,

  signal,
  globalSignalError,
  workers,
}) => {
  const theme = useTheme()
  const classes = useStyles()

  if (!event) {
    return null
  }
  if (loading) {
    return <Loading />
  }
  const currentDate = moment()
  const startDate = moment(new Date(parseInt(event.start))).tz(timezone)
  const endDate = moment(new Date(parseInt(event.end))).tz(timezone)

  const shouldSignal =
    event && !event.finished && !event.canceled && currentDate.isAfter(startDate)

  const canCancel =
    event && !event.finished && !event.canceled && currentDate.isBefore(startDate)
  const id = event && event.id ? event.id : ""
  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      // onSubmit={handleSubmit}
    >
      <Link to={`${CLIENT_HISTORY_PATH}/${event.company_id}/${event.author.id}`}>
        <Button color="primary" variant="outlined">
          VOIR HISTORIQUE
        </Button>
      </Link>

      {globalSignalError && (
        <MessageField message={globalSignalError} severity={"error"} />
      )}
      {globalError && <MessageField message={globalError} severity={"error"} />}

      {event.finished && event.author_presence && (
        <MessageField
          message={`Rendez-vous accompli avec succès`}
          severity={"success"}
        />
      )}

      {event.finished && !event.author_presence && (
        <MessageField message={`Client absent`} severity={"error"} />
      )}

      {event.created_by_company && (
        <MessageField
          message={`Rendez-vous crée par ${
            event.created_by_company_author_name
          } le ${renderDate(event.createdAt)}`}
          severity={"info"}
        />
      )}

      {event.canceled_by_company && (
        <MessageField
          message={`Rendez-vous annulé par ${
            event.canceled_by_company_author_name
          } le ${renderDate(event.updatedAt)}`}
          severity={"error"}
        />
      )}

      {event.canceled_by_author && (
        <MessageField
          message={`Rendez-vous annulé par le client le ${renderDate(
            event.updatedAt
          )}`}
          severity={"error"}
        />
      )}
      <br />
      <br />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label={"Service"}
            value={event.service.name}
            readOnly={true}
            fullWidth
          />
        </Grid>

       {/*  <Grid item xs={12} sm={6}>
          {event.service && event.service.picture && (
            <Avatar src={event.service.picture} alt={event.service.name} />
          )}

          {!event.service ||
            (!event.service.picture && (
              <Avatar size="tiny" src={inconnu} alt={"iconnu"} />
            ))}
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label={"Client"}
            value={event.author.fullname}
            readOnly={true}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            label="Date rendez-vous"
            fullWidth
            readOnly={true}
            value={renderDate(event.start, timezone)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            readOnly={true}
            label="N° rdv"
            value={event.event_number}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label="Fin rendez-vous"
            fullWidth
            readOnly={true}
            value={renderDate(event.end, timezone)}
          />
        </Grid>

        {/*   {canCancel && (
          <Fragment>
            <Grid item xs={12} sm={12}>
              <SelectField
                required={false}
                multiple={true}
                handleChange={selectWorkers}
                name="workers"
                label={"Affectation(s)"}
                value={valueWorkers}
                theme={theme}
                dataSource={users}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                color="primary"
                disabled={event && event.canceled}
                onClick={async (e) => {
                  e.preventDefault()
                  await updateWorkers()
                }}
                fullWidth
                size="large"
                //    type="submit"
                // onClick={handleSubmit}
                variant="contained"
              >
                Affectez
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                style={{ backgroundColor: "red", color: "white" }}
                //disabled={!formState.isValid}
                onClick={async (e) => {
                  e.preventDefault()
                  await remove(event.id)
                }}
                disabled={event && event.canceled}
                fullWidth
                size="large"
                //    type="submit"
                // onClick={handleSubmit}
                variant="contained"
              >
                Annuler le rendez-vous
              </Button>
            </Grid>
          </Fragment>
        )} */}

        {workers.length > 0 && (
          <div className="label-regroup">
            <h4 style={{ color: colors.VIOLET, textAlign: "center" }}>
              Responsable(s)
            </h4>
            <div>
              <Grid item container xs={12}>
                {workers.map((v, i) => (
                  <Grid lg={5} md={5} sm={6} xs={12}>
                    <div className="label-responsible" key={i}>
                      {v}
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        )}
        {shouldSignal && (
          <Fragment>
            <Grid item xs={12} sm={6}>
              <Button
                style={{ backgroundColor: "red", color: "white" }}
                disabled={!shouldSignal}
                onClick={async (e) => {
                  e.preventDefault()
                  await signal(false, id)
                }}
                fullWidth
                size="large"
                //    type="submit"
                // onClick={handleSubmit}
                variant="contained"
              >
                Signaler absence client
              </Button>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                color="secondary"
                disabled={!shouldSignal}
                onClick={async (e) => {
                  e.preventDefault()
                  await signal(true, id)
                }}
                fullWidth
                size="large"
                //    type="submit"
                // onClick={handleSubmit}
                variant="contained"
              >
                Confirmer la présence du client
              </Button>
            </Grid>
          </Fragment>
        )}
      </Grid>
    </form>
  )
}
