import React, { useState } from 'react'
import { Button, Input, Box, Typography } from '@material-ui/core'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import InfoIcon from '@material-ui/icons/Info'
import AddIcon from '@material-ui/icons/Add'
import Loading from '../../../components/Loading'

const HOST_FILE = 'https://img.doctosen.com/api/file-company'
const MAIN_URL = 'https://img.doctosen.com/images'

/* const HOST_FILE = 'http://localhost:5000/api/file-company'
const MAIN_URL = 'http://localhost/images' */
export const Logo = (props) => {
  const { logo, stamp, company_id, picture, saveFile, removeFile } = props

  const [file1, setFile1] = useState(undefined)
  const [file2, setFile2] = useState(undefined)
  const [file3, setFile3] = useState(undefined)

  const [loading, setLoading] = useState(false)
  const CHANGE = 'Changer'
  const ADD = 'Ajouter'
  const saveLogo = async () => {

    console.log('logo',logo)

    try {
      if (file1) {
        await setLoading(true)
        const extension = file1.name.split('.')[1]
        const filename = `${company_id}_logo.${extension}`
        const formData = new FormData()
        formData.append('file', file1)
        formData.append('data', JSON.stringify({ extension: filename }))
        /*   const response = await axios({
          method: `post`,
          url: `${HOST_FILE}`,
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' }
        })
 */
        //if (response.status && response.status == 200) {

        if (true) {
          await saveFile({
            logo_id: filename,
            //  logo: `${MAIN_URL}/${filename}`
            logo: file1
          })
          await setLoading(false)
          await setFile1(undefined)
        }
      }
    } catch (error) {
      window.alert(error.message)
    }
  }

  const removeLogo = async () => {
    try {
      await removeFile({
        //  logo_id: filename,
        //  logo: `${MAIN_URL}/${filename}`
        logo: logo
      })
    } catch (error) {
      window.alert(error.message)
    }
  }

  const removePicture = async () => {
    try {
      await removeFile({
        //  logo_id: filename,
        //  logo: `${MAIN_URL}/${filename}`
        picture: picture
      })
    } catch (error) {
      window.alert(error.message)
    }
  }
  const removeStamp = async () => {
    try {
      await removeFile({
        //  logo_id: filename,
        //  logo: `${MAIN_URL}/${filename}`
        stamp: stamp
      })
    } catch (error) {
      window.alert(error.message)
    }
  }

  const savePicture = async () => {
    try {
      if (file2) {
        await setLoading(true)
        const extension = file2.name.split('.')[1]
        const filename = `${company_id}_main.${extension}`
        const formData = new FormData()
        formData.append('file', file2)
        formData.append('data', JSON.stringify({ extension: filename }))
        /*  const response = await axios({
          method: `post`,

          url: `${HOST_FILE}`,

          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' }
        }) */

        //   if (response.status && response.status == 200) {
        if (true) {
          await saveFile({
            picture_id: filename,
            // picture: `${MAIN_URL}/${filename}`
            picture: file2
          })

          await setLoading(false)
          await setFile2(undefined)
        }
      }
    } catch (error) {
      window.alert(error.message)
    }
  }

  const saveStamp = async () => {
    try {
      if (file3) {
        await setLoading(true)
        const extension = file3.name.split('.')[1]
        const filename = `${company_id}_stamp.${extension}`
        const formData = new FormData()
        formData.append('file', file3)
        formData.append('data', JSON.stringify({ extension: filename }))
        /*  const response = await axios({
          method: `post`,
          url: `${HOST_FILE}`,
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        console.log('response', response) */

        //   if (response.status && response.status == 200) {

        if (true) {
          await saveFile({
            stamp_id: filename,
            //  stamp: `${MAIN_URL}/${filename}`
            stamp: file3
          })

          await setLoading(false)
          await await setFile3(undefined)
        }
      }
    } catch (error) {
      window.alert(error.message)
    }
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Box className={'miniContent'}>
      <div className={'miniContentHeader'}>
        <InfoIcon className={'iconminiContentHeader'} />
        <Typography variant='h3'>Parametrage Entreprise</Typography>
      </div>

      <Box className={'miniContentMain'}>
        <Typography  style={{flex:2}} variant='body2'>
          Logo Entreprise 
        </Typography>

        <Input
          fullWidth
          type='file'
          name='file'
          id='file1'
          // inputProps={{ accept: 'image/png' }}
          onChange={async ({ target: { files } }) => {
            await setFile1(files[0])
            //   await setInvoiceData({ ...invoiceData, images: { logo: newLogo } })
          }}
          style={{ display: 'none' }}
        />


        <div className='info-img'>

        {file1 && (
          <div className='dead-time-file'>
            <img
              src={URL.createObjectURL(file1)}
              alt='logo'
              className="img-upload"
            />
          </div>
        )}

          {logo && (
            <img
              src={logo}
              alt='logo'
              className={`${file1 ?'file-hidden' : ''} img-upload`}
          />
          )}
          
          <div>
            {file1 && (
              <Button
                className='btn-register-image'
                variant='contained'
                component='span'
                color="primary"
                onClick={saveLogo}
               
              >
                Enregistrer
              </Button>
            )}

            <label htmlFor='file1'>
              <Button
                style={{ borderRadius: 100,marginRight:6 }}
                variant='contained'
                component='span'
                color='primary'
                startIcon={<AddIcon />}
              >
                {logo ? CHANGE : ADD}
              </Button>
            </label>
            {logo && (
              <Button
                onClick={removeLogo}
                style={{ borderRadius: 100, background: '#eb2632',color:'#FFF'}}
                variant='contained'
                component='span'
                color='default'
                startIcon={<DeleteOutlineIcon />}
              >
                Supprimer
              </Button>
            )}
          </div>
        </div>
      </Box>

      <Box className={'miniContentMain'}>
        <Typography style={{ flex: 2 }} variant='body2'>
          Image de Profile de votre structure*
        </Typography>
        <Input
          fullWidth
          type='file'
          name='file'
          id='file2'
          //  inputProps={{ accept: 'image/png' }}
          onChange={async ({ target: { files } }) => {
            await setFile2(files[0])
            //   await setInvoiceData({ ...invoiceData, images: { logo: newLogo } })
          }}
          style={{ display: 'none' }}
        />

        <div className='info-img'>

        {file2 && (
          <div className='dead-time-file'>
            <img
              src={URL.createObjectURL(file2)}
              alt='logo'
              className='img-upload'
            />
          </div>
        )}

          {picture ? (
            <img
              src={picture}
              alt='logo'
              className={`${file2 ?'file-hidden' : ''} img-upload`}
            />
          ) : null}
          <div>
            {file2 && (
              <Button
                className='btn-register-image'
                variant='contained'
                component='span'
                color='primary'
                onClick={savePicture}
              >
                Enregistrer
              </Button>
            )}
            <label htmlFor='file2'>
              <Button
                style={{ borderRadius: 100,marginRight:6 }}
                variant='contained'
                component='span'
                color='primary'
                startIcon={<AddIcon />}
              >
                {picture ? CHANGE : ADD}
              </Button>
            </label>

            {picture && (
              <Button
                onClick={removePicture}
                style={{ borderRadius: 100, background: '#eb2632',color:'#FFF' }}
                variant='contained'
                component='span'
                color='default'
                startIcon={<DeleteOutlineIcon />}
              >
                Supprimer
              </Button>
            )}
          </div>
        </div>
      </Box>

      <div>
        <Box className={'miniContentMain'}>
          <Typography style={{ flex: 2 }} variant='body2'>
            Signature et tampon 
          </Typography>

          <Input
            fullWidth
            type='file'
            name='file3'
            id='file3'
            // inputProps={{ accept: 'image/png' }}
            onChange={async ({ target: { files } }) => {
              await setFile3(files[0])
              //   await setInvoiceData({ ...invoiceData, images: { logo: newLogo } })
            }}
            style={{ display: 'none' }}
          />

          <div className='info-img'>
            {file3 && (
              <div className='dead-time-file'>
                <img
                  src={URL.createObjectURL(file3)}
                  alt='stamp'
                  className='img-upload'
                />
              </div>
            )}
            {stamp ? (
              <img
                src={stamp}
                alt='stamp'
                width='350'
                height='350'
                className={`${file3 ?'file-hidden' : ''} img-upload`}
              />
            ) : null}
            <div>
              {file3 && (
                <Button
                  variant='contained'
                  component='span'
                  color='primary'
                  onClick={saveStamp}
                  style={{ borderRadius: 100, marginRight:6}}
                >
                  Enregistrer
                </Button>
              )}
              <label htmlFor='file3'>
                <Button
                  style={{ borderRadius: 100,marginRight:6 }}
                  variant='contained'
                  component='span'
                  color='primary'
                  startIcon={<AddIcon />}
                >
                  {stamp ? CHANGE : ADD}
                </Button>
              </label>

              {stamp && (
                <Button
                  onClick={removeStamp}
                  style={{ borderRadius: 100,background: '#eb2632',color:'#FFF'}}
                  variant='contained'
                  component='span'
                  color='default'
                  startIcon={<DeleteOutlineIcon />}
                >
                  Supprimer
                </Button>
              )}
            </div>
          </div>
        </Box>
      </div>
    </Box>
  )
}
