import React from "react"
import { useTheme } from "@material-ui/styles"
import { Button, Grid, TextField } from "@material-ui/core"
// import { PhoneField } from "components/PhoneField"
import { SelectField } from "components/SelectField"
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const style = { padding: 20 }

export const TeamToolbar = (props) => {
  const {
    openModal,
    phone,
    name,
    email,
    setEmail,
    search,
    roles,
    setName,
    setRoles,
    selectedRoles,
    setPhone,
    clearFilter,
    phone_code,
  } = props

  const theme = useTheme()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <div >
          <Button
            color="primary"
            variant="contained"
            onClick={openModal}
            startIcon={<PersonAddIcon />}
          >
            Ajouter un personnel
          </Button>
        </div>
      </Grid>
     
     
        <Grid item lg={6} md={6} xs={12} sm={6}>
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Nom"
            onChange={async ({ target: { value } }) => await setName(value)}
            value={name}
          />
        </Grid>
        <Grid item lg={6} md={6} xs={12} sm={6}>
          <TextField
            fullWidth
            required={false}
            id="email"
            name="email"
            label="Email"
            value={email}
            onChange={async ({ target: { value } }) => await setEmail(value)}
          />
        </Grid>
        {/* <Grid item lg={6} md={6} xs={12} sm={6} style={style}>
          <SelectField
            multiple={true}
            handleChange={async ({ target: { value } }) => {
              await setRoles(value)
            }}
            name="rolename"
            label={"Role"}
            value={selectedRoles}
            theme={theme}
            dataSource={roles}
          />
        </Grid> */}
        <Grid item lg={6} md={6} xs={12} sm={6} >
        

          <TextField
            fullWidth
            required={false}
            id="phone"
            name="phone"
            label={`Téléphone ${phone_code}`}
            value={phone}
            onChange={async ({ target: { value } }) => await setPhone(value)}
          />
            {/*  <PhoneField
            fullWidth={true}
            required={false}
            value={phone}
            setFieldValue={(data)=>{
            }}
            label="Téléphone"
            name="phone"
          /> */}
        </Grid>
        <Grid item xs={12}>
            <div className="content-btn-profil-footer">
              <Button
                className="buttonLeft"
                color="secondary"
                //disabled={!formState.isValid}
                type="submit"
                onClick={clearFilter}
                variant="contained"
                startIcon={<DeleteIcon />}
              >
                Effacer les filtres
              </Button>
              <Button
                color="primary"
                //disabled={!formState.isValid}
                type="submit"
                onClick={search}
                variant="contained"
                startIcon={<SearchIcon />}
              >
                Chercher
              </Button>
            </div>
          
          </Grid>
    </Grid>
  )
}
