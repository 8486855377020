import React, { useState } from "react"
import { makeStyles } from "@material-ui/styles"
import { Accordion } from "components/Accordion"
import { useQuery } from "@apollo/react-hooks"
import { findCompBillsQuery } from "graphql/query/comp_bill"
import { Grid, Button } from "@material-ui/core"
import { oneSettingAdminQuery } from "graphql/query/setting_admin"
import { BillList } from "./components/BillList"
import { TextField } from "@material-ui/core"
import { FormControlLabel } from "@material-ui/core"
import { Checkbox } from "@material-ui/core"
import { COMPANY_ID_STORAGE } from "utils/static_constants"
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {
   
  },
 
  pagination: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}))

const BillHistoryView = () => {
  const classes = useStyles()
  const [expanded1, setExpanded1] = useState(true)
  const [expanded2, setExpanded2] = useState(true)

  const [loading, setLoading] = useState(false)

  const [bill_paid, setBillPaid] = useState(undefined)
  const [from_bill, setFromBill] = useState(undefined)
  const [to_bill, setToBill] = useState(undefined)

  const [page, setPage] = useState(1)

  const {
    loading: loading3,
    data: compBillData,
    fetchMore,
    refetch,
  } = useQuery(findCompBillsQuery, {
    // fetchPolicy: "network-only",
    variables: { page, company_id: localStorage.getItem(COMPANY_ID_STORAGE) },
  })
  
  const { loading: loading2, data: generalSetting } = useQuery(oneSettingAdminQuery)

  const timezone =
    generalSetting &&
    generalSetting.oneSettingAdmin &&
    generalSetting.oneSettingAdmin.time_zone
      ? generalSetting.oneSettingAdmin.time_zone
      : "Africa/Dakar"

  const currency =
    generalSetting &&
    generalSetting.oneSettingAdmin &&
    generalSetting.oneSettingAdmin.currency
      ? generalSetting.oneSettingAdmin.currency
      : "Africa/Dakar"
  const computeVariables = async (page) => {
    await setPage(page)
    const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
    let variables = { page, company_id }
    try {
      if (bill_paid != undefined) {
        variables.bill_paid = bill_paid
      }
      if (from_bill) {
        variables.from_date = from_bill
      }

      if (to_bill) {
        variables.to_date = to_bill
      }

      return variables
    } catch (error) {
      window.alert(error.message)
    }
  }
  const onPageChange = async (page) => {
    try {
      let variables = await computeVariables(page)
      await fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { findCompBillsComp } = fetchMoreResult
          return Object.assign({}, prev, {
            findCompBillsComp,
          })
        },
      })
    } catch (error) {
      throw error
    }
  }
  const clearFilter = async () => {
    try {
      await setBillPaid(undefined)
      await setPage(1)
      await setFromBill(undefined)
      await setToBill(undefined)
      const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
      await refetch({ variables: { page: 1, company_id } })
    } catch (error) {
      window.alert(error.message)
    }
  }

  const search = async () => {
    try {
      const variables = await computeVariables(1)

      await setLoading(true)

      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { findCompBillsComp } = fetchMoreResult

          return Object.assign({}, prev, {
            findCompBillsComp,
          })
        },
      })
    } catch (error) {
      window.alert(error.message)
    }
  }

  return (
    <div className={classes.root}>
      <Accordion
        id={"search-client-bill-total-search"}
        title={"Recherche une facture"}
        expanded={expanded2}
        setExpanded={setExpanded2}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id="bill_paid"
                  name="bill_paid"
                  checked={bill_paid}
                  onChange={async () => await setBillPaid(!bill_paid)}
                />
              }
              label={"Factures payées"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="start"
              label="Du "
              name="start"
              type="date"
              fullWidth
              value={from_bill}
              onChange={async ({ target: { value } }) => {
                await setFromBill(value)
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="end"
              label="Au"
              name="end"
              type="date"
              value={to_bill}
              onChange={async ({ target: { value } }) => {
                await setToBill(value)
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <div className="content-btn-profil-footer controle-btn">
            <Button
              className="buttonLeft"
              color="secondary"
              //type="submit"
              onClick={clearFilter}
              variant="contained"
              startIcon={<DeleteIcon />}
            >
              effacer la recherche
            </Button>

              <Button
                //  style={{ backgroundColor: "red", color: "white" }}
                color="primary"
                //type="submit"
                onClick={search}
                variant="contained"
                startIcon={<SearchIcon />}
              >
                chercher
              </Button>
          </div>

        
        </Grid>
      </Accordion>
      <Accordion
        id={"search-client-bill-total"}
        title={"Filtrer "}
        expanded={expanded1}
        setExpanded={setExpanded1}
      >
        <BillList
          time_zone={timezone}
          currency={currency}
          data={
            compBillData && compBillData.findCompBillsComp
              ? compBillData.findCompBillsComp
              : null
          }
          docs={
            compBillData &&
            compBillData.findCompBillsComp &&
            compBillData.findCompBillsComp.docs
              ? compBillData.findCompBillsComp.docs
              : null
          }
          page={page}
          totalPages={
            compBillData &&
            compBillData.findCompBillsComp &&
            compBillData.findCompBillsComp.totalPages
              ? compBillData.findCompBillsComp.totalPages
              : null
          }
          onPageChange={onPageChange}
        />
      </Accordion>
    </div>
  )
}

export default BillHistoryView
