import gql from "graphql-tag";

export const updateSettingMutation = gql`
  mutation updateSetting(
    $company_id: String
    $id: String
    $setting: SettingInput
  ) {
    updateSetting(company_id: $company_id, id: $id, setting: $setting) {
      ok
      errors {
        path
        message
      }
      setting {
        id
        company_id
        slotEventCount
        hiddenDays
        slotDuration
        createdAt
        updatedAt
        no_rest
        businessHours {
          daysOfWeek
          startTime
          endTime
        }

        rest {
          title
          daysOfWeek
          startTime
          endTime
        }
      }
    }
  }
`;
