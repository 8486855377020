import React, { useState } from "react"
import { Grid, TextField, Button } from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"
//import moment from "moment-timezone"

import { SelectField } from "components/SelectField"
import { Accordion } from "components/Accordion"
import { Autocomplete } from "@material-ui/lab"
import SearchIcon from '@material-ui/icons/Search';

/* const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "100%",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
})) */
export const EventSearch = ({
  usersDatasource,
  users,
  services,
  categoriesDatasource,
  categories,
  servicesDatasource,
  selectWorkers,
  selectServices,
  selectCategories,
  eventNumber,
  setEventNumber,
  start,
  setStart,
  submit,
  refetch,
 canModify
}) => {
  const theme = useTheme()
  const [expanded, setExpanded] = useState(false)
  //NEW_EVENT_COMPANY_PATH
  return (
    <Accordion
      id={"search-event-form"}
      title={"Filtrer les Rvs"}
      expanded={expanded}
      setExpanded={setExpanded}
    >
      <Grid container spacing={3} >
        <Grid item xs={12} sm={6}>
          <TextField
            id="start"
            name="start"
            label="A partir de"
            type="date"
            defaultValue={start}
            onChange={async (e) => {
              e.preventDefault()
              const {
                target: { value },
              } = e
              console.log("value date", value)
              await setStart(value)
            }}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={"Numéro RV"}
            type="number"
            name="eventNumber"
            value={eventNumber}
            onChange={async ({ target: { value } }) => {
              await setEventNumber(value)
            }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SelectField
            required={false}
            multiple={true}
            handleChange={selectServices}
            name="services"
            label={"Services"}
            value={services}
            theme={theme}
            dataSource={servicesDatasource}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            required={false}
            multiple={true}
            handleChange={selectCategories}
            name="categories"
            label={"Catégories"}
            value={categories}
            theme={theme}
            dataSource={categoriesDatasource}
          />
        </Grid>

        <Grid item lg={6} xs={12} md={6} sm={6}>
        {/*   <SelectField
            required={false}
            multiple={true}
            handleChange={selectWorkers}
            name="workers"
            label={"Affectation(s)"}
            value={users}
            theme={theme}
            dataSource={usersDatasource}
          /> */}

          <Autocomplete
            multiple={true}
            noOptionsText={"Aucun résultat"}
            id="workers"
            name="workers"
            label={"Personnel"}
            defaultValue={users}
            options={usersDatasource}
            onChange={async (e, newVal) => {
              e.preventDefault()

              await selectWorkers(newVal)
            }}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Personnel entreprise"
                // variant="outlined"
                required
                fullWidth
              />
            )}
          />
        </Grid>

       <Grid item xs={12}>
          <div className="controle-btn-bottom content-btn-profil-footer">
              <Button
                  color="primary"
                  //disabled={!formState.isValid}
                  onClick={async (e) => {
                    e.preventDefault()
                    await submit()
                  }}
                className="buttonLeft"
                  variant="contained"
                  startIcon={<SearchIcon />}
                >
                  Chercher
                </Button>
                <Button
                // variant="contained"
                onClick={async (e) => {
                  e.preventDefault()
                  await refetch()
                }}
              
              >
                Rafraichir
              </Button>
            </div>
       </Grid>
    
      </Grid>
    </Accordion>
  )
}
