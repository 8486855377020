import React, { useState } from "react"
import { makeStyles } from "@material-ui/styles"
import { Grid } from "@material-ui/core"

//component
import Boxlogin from "./Boxlogin"
import BoxPasswordforgot from "../../PasswordForgot/PasswordForgotView"
import BoxConfirmepassword from "../../ConfirmPasswordLost/ConfirmPasswordLostView"

const useStyles = makeStyles((theme) => ({
  parentgrid: {
    height: "100vh",
  },

  grid: {
    height: "100%",
  },

  gridcontent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },

  textField: {
    marginBottom: theme.spacing(2),
  },

  signInButton: {
    marginBottom: theme.spacing(2),
  },
}))

const Login = ({
  touched,
  errors,
  setFieldValue,
  handleSubmit,
  isSubmitting,
  handleChange,
  globalError,
  values,
}) => {
  const classes = useStyles()

  const [showbox, setshowbox] = useState("boxlogin")

  return (
    <Grid container className={classes.parentgrid}>
      <Grid item xs={6} className={classes.grid}>
        <div className={`${classes.gridcontent} gridcontentform`}>
          <header className="header-grid-content-login">
            <h1>
              Doctosen<span className="protext">pro</span>
            </h1>
            <span className="dashedtitle"></span>
          </header>

          {showbox === "boxlogin" && (
            <Boxlogin
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              globalError={globalError}
              values={values}
              classes={classes}
              setshowbox={setshowbox}
            />
          )}

          {showbox === "boxpassword" && <BoxPasswordforgot />}
          {showbox === "boxcode" && <BoxConfirmepassword />}
        </div>
      </Grid>
      <Grid item xs={6} className={classes.grid}>
        <div className={`${classes.gridcontent} gridcontentappdow`}>
          <h2 className="title-app-dow">
            Simplifiez la gestion de votre emploi du temps{" "}
            {/* et de vos rendez-vous en
            toute simplicité depuis votre téléphone mobile */}
          </h2>
          <figure>
            <img src="https://lemedecin.fr/assets/images/telecons/smartphone-mask.png" />
            <img src="https://lemedecin.fr/assets/images/telecons/doc_3.jpg" />
          </figure>
          <div className="content-dow-app">
            {/* <a href="" target="_blank" rel="noopener">
              <img src="/images/apple-btn-app.webp" alt="apple-play" />
            </a> */}
            <a
              href="https://play.google.com/store/apps/details?id=com.doctosenpro&gl=FR"
              target="_blank"
              rel="noopener"
            >
              <img src="/images/android-btn-app.webp" alt="google-play" />
            </a>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default Login
