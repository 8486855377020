import moment from 'moment-timezone'
import 'moment/locale/fr'

moment.locale('fr')

export const validatePhone = ({
  phone,
  phone_code,
  phone_min_size,
  phone_max_size
}) => {
  const phoneLength = phone.length
  if (
    !phone.startsWith(phone_code) ||
    phoneLength < phone_min_size ||
    phoneLength > phone_max_size
  ) {
    return false
  }
  return true
}
export const renderDate = (date, tmz, fmt) => {
  const timezone = tmz ? tmz : 'Africa/Dakar'
  const format = fmt ? fmt : 'LLLL'
  if (tmz) {
    let result = moment(new Date(date))
      .tz(timezone)
      .format(format)
    if (result.toString() === 'Invalid date') {
      result = moment(new Date(parseInt(date)))
        .tz(timezone)
        .format(format)
    }
    return result
  } else {
    let result = moment(new Date(date)).format(format)
    if (result.toString() === 'Invalid date') {
      result = moment(new Date(parseInt(date))).format(format)
    }
    return result
  }
}
export const dayOfWeekFunc = (day) => {
  if (day === 0) {
    return 'Dimanche'
  }
  if (day === 1) {
    return 'Lundi'
  }
  if (day === 2) {
    return 'Mardi'
  }
  if (day === 3) {
    return 'Mercredi'
  }

  if (day === 4) {
    return 'Jeudi'
  }
  if (day === 5) {
    return 'Vendredi'
  }
  if (day === 6) {
    return 'Samedi'
  }
}
export const dayNumberFunc = (day) => {
  if (day === 'Dimanche') {
    return 0
  }
  if (day === 'Lundi') {
    return 1
  }
  if (day === 'Mardi') {
    return 2
  }
  if (day === 'Mercredi') {
    return 3
  }

  if (day === 'Jeudi') {
    return 4
  }
  if (day === 'Vendredi') {
    return 5
  }
  if (day === 'Samedi') {
    return 6
  }
}
export const optionDays = [
  { id: 0, name: 'Dimanche' },
  {
    id: 1,
    name: 'Lundi'
  },
  {
    id: 2,
    name: 'Mardi'
  },
  {
    id: 3,
    name: 'Mercredi'
  },
  {
    id: 4,
    name: 'Jeudi'
  },
  {
    id: 5,
    name: 'Vendredi'
  },
  {
    id: 6,
    name: 'Samedi'
  }
]

export const hours = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '13',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24'
]

export const minutes = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '57',
  '58',
  '59'
]

export const generateYearsBetween = (
  startYear = 2000,
  endYear,
  unreverse = false
) => {
  const endDate = 2040
  let years = []
  for (var i = startYear; i <= endDate; i++) {
    years.push(startYear.toString())
    startYear++
  }
  if (unreverse) {
    return years
  }
  return years.reverse()
}
