import React from "react"
import { Grid, Button, TextField } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Loading from "components/Loading"
import { MessageField } from "components/MessageField"
import moment from "moment-timezone"
import "moment/locale/fr"

moment.locale("fr")
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "100%",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}))
export const AbsenceCreate = ({
  values,
  touched,
  errors,
  setFieldValue,
  handleSubmit,
  isSubmitting,
  globalError,
}) => {
   
  const classes = useStyles()

  if (isSubmitting) {
    return <Loading />
  }

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      {globalError && <MessageField message={globalError} severity={"error"} />}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="start"
            label="Début"
            name="start"
            type="datetime-local"
            fullWidth
            value={values.start}
            onChange={async ({ target: { value } }) => {
              await setFieldValue("start", value)
            }}
            InputLabelProps={{
              shrink: true,
            }}
            helperText={touched.start && errors.start ? errors.start : null}
            error={touched.start && errors.start ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="end"
            label="Fin"
            name="end"
            type="datetime-local"
            value={values.end}
            onChange={async ({ target: { value } }) => {
              await setFieldValue("end", value)
            }}
            InputLabelProps={{
              shrink: true,
            }}
            helperText={touched.end && errors.end ? errors.end : null}
            error={touched.end && errors.end ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Button
            color="primary"
            //disabled={!formState.isValid}
            type="submit"
            onClick={handleSubmit}
            variant="contained"
          >
            enregistrer
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
