import gql from 'graphql-tag';

export const companyAverageRatingQuery = gql`
  query companyAverageRating($company_id: String!) {
    companyAverageRating(company_id: $company_id) {
      average
      ratecount
    }
  }
`;

export const companyRatingsQuery = gql`
  query companyRatings($page: Int!, $limit: Int, $company_id: String!) {
    companyRatings(
      page: $page
      limit: $limit
      company_id: $company_id
    ) {
      totalDocs
      hasPrevPage
      hasNextPage
      totalPages
      limit
      offset
      page
      prevPage
      nextPage
      docs {
        id
        author_name
        rate
        description
        company_id
        createdAt
        updatedAt
      }
    }
  }
`;
