import gql from 'graphql-tag'

export const GET_CURRENT_USER_QUERY = gql`
  query {
    currentUser @client {
      authenticated
    }
  }
`

export const findUserByIdQuery = gql`
  query findUserById($id: String!) {
    findUserById(id: $id) {
      id
      name
      lastname
      email
      phone
      gender
    }
  }
`

//

export const findUserByPhoneQuery = gql`
  query findUserByPhone($phone: String) {
    findUserByPhone(phone: $phone) {
      ok
      user {
        id
        name
        lastname
        
        phone
        gender
      }
    }
  }
`
export const currentUserQuery = gql`
  query onlineUser {
    onlineUser {
      authenticated
      id
      name
      lastname
      email
      phone
      is_active
      picture
      phone_confirmed
      fullname
      gender
      roles {
        _id
        name

        is_comp_super_admin
        is_comp_admin
        is_comp_manager
        is_comp_worker
        company_id
      }
    }
  }
`
export const salerTeamUsersQuery = gql`
  query salerTeamUsers($company_id: String!) {
    salerTeamUsers(company_id: $company_id) {
      id
      name
      lastname
      email
      phone
      is_active
      picture
      phone_confirmed
      fullname
      roles {
        _id
        name

        is_comp_super_admin
        is_comp_admin
        is_comp_manager
        is_comp_worker
        company_id
      }
    }
  }
`
export const findUsersQuery = gql`
  query findTeamsComp(
    $page: Int!
    $name: String
    $company_id: String!
    $phone: String
    $email: String
    $rolename: [String]
  ) {
    findTeamsComp(
      page: $page
      name: $name
      company_id: $company_id

      phone: $phone
      email: $email
      rolename: $rolename
    ) {
      totalDocs
      hasPrevPage
      totalPages
      limit
      offset
      page
      prevPage
      nextPage
      docs {
        id
        name
        lastname
        fullname
        gender
        email
        picture
        phone
        is_active
        phone_confirmed
        createdAt
        roles {
          _id
          name

          is_comp_super_admin
          is_comp_admin
          is_comp_manager
          is_comp_worker
          company_id
        }
      }
    }
  }
`
