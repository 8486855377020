import React, { Fragment } from "react"

import { makeStyles } from "@material-ui/styles"

import { Topbar } from "./components"

const useStyles = makeStyles(() => ({
 
  content: {
    height: "100%",
  },
}))

const Minimal = (props) => {
  const { children } = props

  const classes = useStyles()

  return (
   
    <Fragment>
      {/* <Topbar /> */}
      <main className={classes.content}>{children}</main>
    </Fragment>
  )
}

export default Minimal
