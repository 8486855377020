import gql from "graphql-tag"

export const allServicesQuery = gql`
  query allServices($company_id: String!) {
    allServices(company_id: $company_id) {
      id
      name
      internal
      price
    }
  }
`
export const findServicesQuery = gql`
  query findServices(
    $page: Int
    $name: String
    $company_id: String!
    $category_id: String
  ) {
    findServices(
      page: $page
      name: $name
      company_id: $company_id
      category_id: $category_id
    ) {
      totalDocs
      hasPrevPage
      totalPages
      limit
      offset
      page
      prevPage
      nextPage
      docs {
        id
        internal
        name
        picture
        description
        region
        show_price
        sms_reminder
        is_active
        price
       
        createdAt
        updatedAt
        slotEventCount
        hiddenDays
        slotDuration
        createdAt
        updatedAt
        businessHours {
          daysOfWeek
          startTime
          endTime
        }
        validRange {
          start
          end
        }
        rest {
          title
          daysOfWeek
          startTime
          endTime
        }
        category {
          id
          name
        }
        author {
          id
          name
          lastname
          fullname
        }
      }
    }
  }
`
