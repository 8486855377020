import React from "react"
import { Grid } from "@material-ui/core"
import Pagination from "@material-ui/lab/Pagination"
import { Service } from "./Service"

export const ServiceList = ({
  data: { docs, page, totalPages },
  selectForUpdate,
  selectForRemove,
  onPageChange,
  canModify,
  currency
}) => {
  return (
    <Grid>
      <div style={{ marginTop:20,marginBottom: 20 }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={async (_event, value) => {
            if (value.toString() !== page.toString()) {
              await onPageChange(value)
            }
          }}
        />
      </div>
      <Grid container spacing={3}>
        {docs.map((doc) => (
          <Grid item key={doc.id} lg={4} md={4} sm={6} xs={12}>
      
            <Service
              key={doc.id}
              item={doc}
              canModify={canModify}
              selectForUpdate={selectForUpdate}
              selectForRemove={selectForRemove}
              currency={currency}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
