import React, { useState } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { makeStyles } from '@material-ui/styles'
import Loading from 'components/Loading'

import { CLIENT_HISTORY_PATH, COMPANY_ID_STORAGE } from 'utils/static_constants'

import { Accordion } from 'components/Accordion'
import { oneSettingAdminQuery } from 'graphql/query/setting_admin'
import { toast } from 'react-toastify'
import { PatientCreate } from './components/PatientCreate'
import { createPatientMutation } from 'graphql/mutation/patient'
import { ModalDialog } from 'components/ModalDialog'
import { Typography } from '@material-ui/core'
import { InputModalCustom } from './components/InputModalCustom'

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {},
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}))

const createSchema = Yup.object().shape({
  gender: Yup.string().required('champ requis'),

  name: Yup.string()
    .trim()
    .min(2, 'minimun 2 caractères')
    .max(50, 'maximum 50 caractères')
    .required('champ requis'),
  lastname: Yup.string()
    .trim()
    .min(2, 'minimun 2 caractères')
    .max(50, 'maximum 50 caractères')
    .required('champ requis'),

  email: Yup.string()
    .email('email invalide')
    .nullable(),

  phone: Yup.string()
    .trim()
    .required('champ requis'),

  register_number: Yup.string()
    .trim()
    .required('champ requis'),

  region: Yup.string().nullable(),
  address: Yup.string().nullable(),
  birth_year: Yup.number()
    .typeError('Année de naissance en chiffre')
    .nullable(),
  height: Yup.string().nullable(),
  weight: Yup.string().nullable(),

  blood_group: Yup.string().nullable(),

  has_allergy: Yup.boolean()
    .typeError('champ requis')
    .required('champ requis'),
  is_old_smoking: Yup.boolean()
    .typeError('champ requis')
    .required('champ requis'),
  is_smoking: Yup.boolean()
    .typeError('champ requis')
    .required('champ requis'),

  has_vital_card: Yup.boolean()
    .typeError('champ requis')
    .required('champ requis'),
  vital_card_number: Yup.string().nullable(),

  has_old_desease: Yup.boolean()
    .typeError('champ requis')
    .required('champ requis'),

  has_current_desease: Yup.boolean()
    .typeError('champ requis')
    .required('champ requis'),

  has_health_insurance: Yup.boolean()
    .typeError('champ requis')
    .required('champ requis'),

  name_insurance: Yup.string().nullable(),
  validity_end_insurance: Yup.string().nullable(),
  percentage_insurance: Yup.number().nullable()
})

const TeamView = ({ history }) => {
  const classes = useStyles()

  /**
   *    current_deseases Input Array
   *  name: String,
   *  description: String,
   *  year: Int
   */
  const [name_current_desease, setNameCurrentDesease] = useState('')
  const [description_current_desease, setDescriptionCurrentDesease] = useState('')
  const [year_current_dease, setYearCurrentDesease] = useState('')
  const [current_deseases, setCurrentDeseases] = useState([])
  const [currentDeseaseModal, setCurrentDeseaseModal] = useState(false)

  /**
   *    old_deseases Input Array
   *  name: String,
   *  description: String,
   *  year: Int
   */
  const [name_old_desease, setNameOldDesease] = useState('')
  const [description_old_desease, setDescriptionOldDesease] = useState('')
  const [year_old_dease, setYearOldDesease] = useState('')
  const [old_deseases, setOldDeseases] = useState([])
  const [oldDeseaseModal, setOldDeseaseModal] = useState(false)

  /**
   *    allergies Input Array
   *  name: String,
   *  description: String,
   *  year: Int
   */
  const [name_allergy, setNameAllergy] = useState('')
  const [description_allergy, setDescriptionAllergy] = useState('')
  const [year_allergy, setYearAllergy] = useState('')
  const [allergies, setAllergies] = useState([])
  const [allergyModal, setAllergyModal] = useState(false)

  const [globalCreateError, setGlobalCreateError] = useState('')

  const { data: settingAdminData, loading: loading3 } = useQuery(
    oneSettingAdminQuery
  )

  const regions =
    settingAdminData &&
    settingAdminData.oneSettingAdmin &&
    settingAdminData.oneSettingAdmin.regions
      ? settingAdminData.oneSettingAdmin.regions
      : null

  const phone_code =
    settingAdminData &&
    settingAdminData.oneSettingAdmin &&
    settingAdminData.oneSettingAdmin.phone_code
      ? settingAdminData.oneSettingAdmin.phone_code
      : null
  const phone_min_size =
    settingAdminData &&
    settingAdminData.oneSettingAdmin &&
    settingAdminData.oneSettingAdmin.phone_min_size
      ? settingAdminData.oneSettingAdmin.phone_min_size
      : null

  const phone_max_size =
    settingAdminData &&
    settingAdminData.oneSettingAdmin &&
    settingAdminData.oneSettingAdmin.phone_max_size
      ? settingAdminData.oneSettingAdmin.phone_max_size
      : null

  const [createItem] = useMutation(createPatientMutation)

  const create = async (
    values,

    { setSubmitting, setFieldError }
  ) => {
    if (values.has_vital_card && !values.vital_card_number) {
      window.alert(
        'Vous devez renseigner le numéro de la carte vitae si vous cochez que le patient en possède'
      )
      return
    }
    if (values.has_old_desease && !old_deseases.length) {
      window.alert(
        'Vous devez renseigner les maladies passées si vous cochez que le patient en a '
      )
      return
    }

    if (values.has_current_desease && !current_deseases.length) {
      window.alert(
        'Vous devez renseigner les maladies actuelles si vous cochez que le patient en a'
      )
      return
    }

    if (values.has_allergy && !allergies.length) {
      window.alert(
        'Vous devez renseigner les allergies actuelles si vous cochez que le patient en a'
      )
      return
    }

    if (
      values.has_health_insurance &&
      (!values.name_insurance ||
        !values.name_insurance.trim().length ||
        !values.validity_end_insurance ||
        !values.validity_end_insurance ||
        !values.validity_end_insurance.trim().length ||
        !values.percentage_insurance.trim().length)
    ) {
      window.alert(
        "Vous devez renseigner le nom de l'assureur,la date de validité et le taux de couverture si vous cochez que le patient a une assurance maladie"
      )
      return
    }

    const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)

    const phoneLength = parseInt(values.phone.trim().length)

    if (
      !values.phone.toString().startsWith(phone_code.toString()) ||
      phoneLength < parseInt(phone_min_size) ||
      phoneLength > parseInt(phone_max_size)
    ) {
      /* await setFieldError(
        'phone',
        `Le téléphone doit commencer par ${phone_code} ,taille minimale de ${phone_min_size} et maximale de ${phone_max_size}`,
      ); */
      window.alert(
        `Le téléphone doit commencer par ${phone_code} ,taille minimale de ${phone_min_size} et maximale de ${phone_max_size}`
      )
      return
    }
    const {
      name_insurance,
      validity_end_insurance,
      percentage_insurance,
      has_health_insurance,
      has_old_desease,
      has_current_desease,
      has_allergy,
      has_vital_card,
      is_old_smoking,
      is_smoking,
      ...rest
    } = values
    let patient = {
      has_health_insurance,
      has_old_desease,
      has_current_desease,
      has_allergy,
      has_vital_card,
      is_old_smoking,
      is_smoking
    }
    if (has_health_insurance) {
      patient.health_insurance = {
        name: name_insurance,
        validity_end: validity_end_insurance,
        percentage: percentage_insurance
      }
    }

    if (has_old_desease) {
      patient.old_deseases = old_deseases
    }

    if (has_current_desease) {
      patient.current_deseases = current_deseases
    }

    if (has_allergy) {
      patient.allergies = allergies
    }

    for (const [key, value] of Object.entries(rest)) {
      if (value && value.toString().trim().length > 0) {
        patient[key] = value.toString()
      }
    }
    let variables = {
      company_id,
      patient
    }

    const res = await createItem({
      variables
    })

    const { ok, errors } = res.data.createPatient
    if (ok) {
      await setSubmitting(false)

      await toast.success('patient ajouté avec succès')
      await history.push(CLIENT_HISTORY_PATH)
    } else {
      errors.forEach(async (error) => {
        if (error.path && error.path.toString() === 'global') {
          await setGlobalCreateError(error.message)
        } else {
          await setFieldError(error.path, error.message)
          await setSubmitting(false)
        }
      })
    }
  }
  // current Deseases
  const clearCurrentDesease = async () => {
    try {
      await setNameCurrentDesease('')
      await setDescriptionCurrentDesease('')
      await setYearCurrentDesease('')
    } catch (error) {
      window.alert(error.message)
    }
  }
  const addCurrentDesease = async (desease) => {
    try {
      if (!desease.name || desease.name.trim().length == 0) {
        window.alert('la maladie est obligatoire')
        return
      }

      if (!desease.description || desease.description.trim().length == 0) {
        window.alert('la description de la maladie est obligatoire')
        return
      }
      let deseases = current_deseases
      deseases.unshift(desease)
      await setCurrentDeseases(deseases)
      await clearCurrentDesease()

      await setCurrentDeseaseModal(false)
    } catch (error) {
      window.alert(error.message)
    }
  }

  const removeCurrentDesease = async (desease) => {
    try {
      let deseases = current_deseases
      const index = deseases.findIndex((e) => e.name == desease.name)

      if (index > -1) {
        deseases.splice(index, 1)

        const newArray = []
        deseases.forEach((d) => newArray.push(d))
        await setCurrentDeseases(newArray)
        return true
      }
    } catch (error) {
      window.alert(error.message)
    }
  }

  // end current Deseases

  // old Deseases
  const clearOldDesease = async () => {
    try {
      await setNameOldDesease('')
      await setDescriptionOldDesease('')
      await setYearOldDesease('')
    } catch (error) {
      window.alert(error.message)
    }
  }
  const addOldDesease = async (desease) => {
    try {
      if (!desease.name || desease.name.trim().length == 0) {
        window.alert('la maladie est obligatoire')
        return
      }

      if (!desease.description || desease.description.trim().length == 0) {
        window.alert('la description de la maladie est obligatoire')
        return
      }
      let deseases = old_deseases
      deseases.unshift(desease)
      await setOldDeseases(deseases)
      await clearOldDesease()

      await setOldDeseaseModal(false)
    } catch (error) {
      window.alert(error.message)
    }
  }

  const removeOldDesease = async (desease) => {
    try {
      let deseases = old_deseases
      const index = deseases.findIndex((e) => e.name == desease.name)

      if (index > -1) {
        deseases.splice(index, 1)

        const newArray = []
        deseases.forEach((d) => newArray.push(d))
        await setOldDeseases(newArray)
        return true
      }
    } catch (error) {
      window.alert(error.message)
    }
  }

  // end old Deseases

  // allergies Deseases
  const clearAllergy = async () => {
    try {
      await setNameAllergy('')
      await setDescriptionAllergy('')
      await setYearAllergy('')
    } catch (error) {
      window.alert(error.message)
    }
  }
  const addAllergy = async (desease) => {
    try {
      if (!desease.name || desease.name.trim().length == 0) {
        window.alert("le nom de l'allergie est obligatoire")
        return
      }

      if (!desease.description || desease.description.trim().length == 0) {
        window.alert("la description de l'allergie est obligatoire")
        return
      }
      let deseases = allergies
      deseases.unshift(desease)
      await setAllergies(deseases)
      await clearAllergy()

      await setAllergyModal(false)
    } catch (error) {
      window.alert(error.message)
    }
  }

  const removeAllergy = async (desease) => {
    try {
      let deseases = allergies
      const index = deseases.findIndex((e) => e.name == desease.name)

      if (index > -1) {
        deseases.splice(index, 1)

        const newArray = []
        deseases.forEach((d) => newArray.push(d))
        await setAllergies(newArray)
        return true
      }
    } catch (error) {
      window.alert(error.message)
    }
  }

  // end allergies
  const createInitialValues = {
    gender: '',
    name: '',
    lastname: '',
    email: '',
    phone: '',
    register_number: '',
    region: '',
    address: '',
    birth_year: '',
    height: '',
    weight: '',
    blood_group: '',
    has_allergy: false,
    is_old_smoking: false,
    is_smoking: false,
    has_vital_card: false,
    vital_card_number: '',
    has_old_desease: false,
    has_current_desease: false,
    has_health_insurance: false,
    name_insurance: '',
    validity_end_insurance: '',
    percentage_insurance: ''
  }

  return (
    <div className={classes.root}>
      
      <ModalDialog
        key='current-desease'
        open={currentDeseaseModal}
        cancel={async () => {
          // await props.handleReset()
          await setCurrentDeseaseModal(false)
        }}
        title={'Maladies actuelles'}
      >
        <InputModalCustom
          key={'current'}
          label1={'Nom de la maladie'}
          label2={'Décrivez la maladie'}
          label3={'Depuis quelle année ?'}
          addCurrentDesease={addCurrentDesease}
          name_current_desease={name_current_desease}
          setNameCurrentDesease={setNameCurrentDesease}
          setDescriptionCurrentDesease={setDescriptionCurrentDesease}
          description_current_desease={description_current_desease}
          year_current_dease={year_current_dease}
          setYearCurrentDesease={setYearCurrentDesease}
        />
      </ModalDialog>

      <ModalDialog
        key='old-desease'
        open={oldDeseaseModal}
        cancel={async () => {
          // await props.handleReset()
          await setOldDeseaseModal(false)
        }}
        title={'Maladies passées'}
      >
        <InputModalCustom
          key={'old'}
          label1={'Nom de la maladie passée'}
          label2={'Décrivez la maladie passée'}
          label3={'quelle année ?'}
          addCurrentDesease={addOldDesease}
          name_current_desease={name_old_desease}
          setNameCurrentDesease={setNameOldDesease}
          setDescriptionCurrentDesease={setDescriptionOldDesease}
          description_current_desease={description_old_desease}
          year_current_dease={year_old_dease}
          setYearCurrentDesease={setYearOldDesease}
        />
      </ModalDialog>

      <ModalDialog
        key='allergy'
        open={allergyModal}
        cancel={async () => {
          // await props.handleReset()
          await setAllergyModal(false)
        }}
        title={'Allergies'}
      >
        <InputModalCustom
          key={'allergy'}
          label1={"Nom de l'allergie"}
          label2={"Décrivez l'allergie"}
          label3={'quelle année ?'}
          addCurrentDesease={addAllergy}
          name_current_desease={name_allergy}
          setNameCurrentDesease={setNameAllergy}
          setDescriptionCurrentDesease={setDescriptionAllergy}
          description_current_desease={description_allergy}
          year_current_dease={year_allergy}
          setYearCurrentDesease={setYearAllergy}
        />
      </ModalDialog>
      <Formik
        initialValues={createInitialValues}
        validationSchema={createSchema}
        onSubmit={create}
      >
        {(props) => (
          <PatientCreate
            removeCurrentDesease={removeCurrentDesease}
            current_deseases={current_deseases}
            currentDeseaseModal={currentDeseaseModal}
            setCurrentDeseaseModal={setCurrentDeseaseModal}
            removeOldDesease={removeOldDesease}
            old_deseases={old_deseases}
            oldDeseaseModal={oldDeseaseModal}
            setOldDeseaseModal={setOldDeseaseModal}
            removeAllergy={removeAllergy}
            allergies={allergies}
            allergyModal={allergyModal}
            setAllergyModal={setAllergyModal}
            globalError={globalCreateError}
            {...props}
            regions={regions}
          />
        )}
      </Formik>
    </div>
  )
}

export default TeamView
