import React, { Fragment } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'

import { TableItem } from './TableItem'

export const CustomTable = ({ cols, rows, remove, keys, title }) => {
  console.log('rows',rows)
  return (
    <div className="list-custom-table">
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {cols.map((row, index) => (
                <TableCell key={index}>{row}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableItem key={index} keys={keys} item={row} remove={remove} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default CustomTable
