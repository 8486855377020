import React from 'react'
import {
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Loading from 'components/Loading'
import { MessageField } from 'components/MessageField'
import { SelectField } from 'components/SelectField'
import { PhoneField } from 'components/PhoneField'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: '100%',
    padding: 20,
    backgroundColor: 'white'
  },
  chip: {
    margin: theme.spacing(0.5)
  }
}))
export const EventCreateInternal = ({
  event,
  globalError,
  //  timezone,
  loading,
  handleSubmit,
  handleChange,
  touched,
  errors,
  values,

  // date,
  setFieldValue
}) => {
  console.log('errors', errors)
  const theme = useTheme()
  const classes = useStyles()

  if (!event) {
    return null
  }
  if (loading) {
    return <Loading />
  }

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete='off'
      //   onSubmit={handleSubmit}
    >
      {globalError && <MessageField message={globalError} severity={'error'} />}
      <Grid container spacing={3}>
        {/*   <Grid item xs={12} sm={12}>
          <TextField
            required
            label={'Service (interne)'}
            value={
              event && event.service && event.service.name ? event.service.name : ''
            }
            readOnly={true}
            fullWidth
          />
        </Grid> */}

        {/*    <Grid item xs={12} sm={6}>
          {event.service && event.service.picture && (
            <Avatar src={event.service.picture} alt={event.service.name} />
          )}
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id='name'
            name='name'
            label='Nom'
            onChange={handleChange}
            error={touched.name && errors.name ? true : false}
            fullWidth
            helperText={touched.name && errors.name ? errors.name : null}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id='lastname'
            name='lastname'
            label='Prénom'
            onChange={handleChange}
            error={touched.lastname && errors.lastname ? true : false}
            fullWidth
            helperText={touched.lastname && errors.lastname ? errors.lastname : null}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            required={true}
            multiple={false}
            handleChange={async ({ target: { value } }) => {
              try {
                await setFieldValue('gender', value)
              } catch (error) {}
            }}
            name='gender'
            label={'civilité'}
            value={values.gender}
            theme={theme}
            dataSource={['Monsieur', 'Madame']}
            helperText={touched.gender && errors.gender ? errors.gender : null}
            error={touched.gender && errors.gender ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <PhoneField
            value={values.phone}
            setFieldValue={setFieldValue}
            label='Téléphone'
            name='phone'
            helperText={touched.phone && errors.phone ? errors.phone : null}
            error={touched.phone && errors.phone ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id='start'
            label='Date début rendez-vous'
            name='start'
            type='datetime-local'
            fullWidth
            value={values.start}
            onChange={async ({ target: { value } }) => {
              await setFieldValue('start', value)
            }}
            InputLabelProps={{
              shrink: true
            }}
            helperText={touched.start && errors.start ? errors.start : null}
            error={touched.start && errors.start ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id='end'
            label='Date fin rendez-vous'
            name='end'
            type='datetime-local'
            value={values.end}
            onChange={async ({ target: { value } }) => {
              await setFieldValue('end', value)
            }}
            InputLabelProps={{
              shrink: true
            }}
            helperText={touched.end && errors.end ? errors.end : null}
            error={touched.end && errors.end ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                id='sms_notify'
                name='sms_notify'
                checked={values.sms_notify}
                onChange={async (_e, value) =>
                  await setFieldValue('sms_notify', value)
                }
              />
            }
            label={'Envoyer un sms de notification'}
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            color='primary'
            //disabled={!formState.isValid}
            type='submit'
            onClick={handleSubmit}
            variant='contained'
          >
            Planifier ce rendez-vous
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
