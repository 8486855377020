import React from "react"
import { useQuery, useMutation } from "react-apollo"
import { makeStyles } from "@material-ui/core/styles"
import Alert from "@material-ui/lab/Alert"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import CalendarIcon from "@material-ui/icons/CalendarToday"
import NotificationIcon from "@material-ui/icons/NotificationImportant"
import Loading from "../../components/Loading"
import { COMPANY_ID_STORAGE, EVENT_ONE_PATH, EVENT_PATH } from "../../utils/static_constants"
import { userCompNofifsQuery } from "graphql/query/notif"
import { oneSettingAdminQuery } from "graphql/query/setting_admin"
import { renderDate } from "utils/func"
import {
  removeNotifMutation,
  updateCompNofifsMutation,
} from "graphql/mutation/notif"
// import { useComponentWillMount } from "components/useComponentWillMount"
import { useComponentDidMount } from "components/useComponentDidMount"
import client from "graphql/apollo"
//kubeadm join 194.163.155.85:6443 --token od1i8n.hj32e2lb3jrwkacp \
//	--discovery-token-ca-cert-hash sha256:756aee6d446197fd247107d6a46ba9295100783878af55a266ee44c413d7e227
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "10px 15px",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  // notif: {
  //   display: "flex",
  //   alignItems: "center",
  //   padding: "0px 30px!important",
  // },
  // descriptionNotif: {
  //   textAlign: "center",
  // },
  notNotif: {
    height: "80vh",
    color: "#546e7a",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  notNotifText: {
    marginTop: 5,
    fontSize: 25,
    fontFamily: "Roboto",
  },
}))

export default function NotifView(props) {
  const classes = useStyles()
  const { loading: loading1, data: generalSetting } = useQuery(oneSettingAdminQuery)
  const { loading: loading2, data } = useQuery(userCompNofifsQuery, {
    variables: {
      company_id: localStorage.getItem(COMPANY_ID_STORAGE),
    },
    pollInterval: 10000,
  })
  const [removeItem] = useMutation(removeNotifMutation, {
    async update(
      cache,
      {
        data: {
          removeNotif: { ok, id },
        },
      }
    ) {
      if (ok) {
        const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
        const { userCompNofifs } = await cache.readQuery({
          query: userCompNofifsQuery,
          variables: {
            company_id,
          },
        })

        let docs = userCompNofifs

        const eventIndex = userCompNofifs.findIndex(
          (d) => d.id.toString() === id.toString()
        )

        if (eventIndex > -1) {
          docs.splice(eventIndex, 1)
        }

        await cache.writeQuery({
          query: userCompNofifsQuery,
          data: {
            userCompNofifs: {
              docs,
            },
          },
        })
      }
    },
  })

  const [updateNotif] = useMutation(updateCompNofifsMutation)

  const updateNotification = async () => {
    try {
      const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)

      const { userCompNofifs } = await client.readQuery({
        query: userCompNofifsQuery,
        variables: {
          company_id,
        },
      })

      let docs = userCompNofifs.map((notif) => ({ ...notif, seen: true }))

      await client.writeQuery({
        query: userCompNofifsQuery,
        data: {
          userCompNofifs: docs,
        },
      })
      const res = await updateNotif({ variables: { company_id } });
     

      return true
    } catch (error) {
      window.alert(error.message)
    }
  }

  useComponentDidMount(() => {
    updateNotification()
      .then(() => console.log("notif updated"))
      .catch((e) => console.log("error update notif", e))
  })
  if (loading1 || loading2) {
    return <Loading />
  }
  const timezone =
    generalSetting &&
    generalSetting.oneSettingAdmin &&
    generalSetting.oneSettingAdmin.timezone
      ? generalSetting.oneSettingAdmin.timezone
      : "Africa/dakar";

     // console.log('data',data)
  const notifs =
    data && data.userCompNofifs && data.userCompNofifs.length > 0
      ? data.userCompNofifs
      : null

  if (!notifs) {
    return (
      <div className={classes.root}>
        <div className={classes.notNotif}>
          <NotificationIcon style={{ fontSize: 50 }} />
          <p className={classes.notNotifText}>Aucune notification</p>
        </div>
      </div>
    )
  }
  return (
    <div className={classes.root}>
      {notifs.map((notif) => (
        <Alert
          className={'notif'}
          key={notif.id}
          severity={notif.notif_type === "cancel_event" ? "warning" : "success"}
          icon={<NotificationIcon />}
          action={
            <React.Fragment>
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={async () => {
                  await removeItem({ variables: { id: notif.id } })
                }}
                
              >
                <CloseIcon fontSize="inherit" color="error" />
              </IconButton>
            {/*   <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={async () => {
                  await props.history.push(`${EVENT_ONE_PATH}/${notif.notif_type_id}`)
                }}
                style={{ margin: 30 }}
              >
                <CalendarIcon fontSize="inherit" color="action" />
              </IconButton> */}
            </React.Fragment>
          }
        >
          <p className={'descriptionNotif'}>
            {notif.description}
          </p>
          <p>
            <span style={{ textAlign: "center" }}>
              {renderDate(notif.createdAt, timezone)}
            </span>
          </p>
        </Alert>
      ))}
    </div>
  )
}
