import React from "react"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import momentTimezonePlugin from "@fullcalendar/moment-timezone"
import timeGridPlugin from "@fullcalendar/timegrid"
import frLocale from "@fullcalendar/core/locales/fr"
import moment from "moment-timezone"
import "moment/locale/fr"
import "@fullcalendar/core/main.css"
import "@fullcalendar/daygrid/main.css"
import "@fullcalendar/timegrid/main.css"
import { Grid } from "@material-ui/core"
//import { Grid } from "@material-ui/core"

export const Calendar = ({ timezone, service, selectEvent, backevents }) => {
  if (!service || !backevents) {
    return null
  }
  const { businessHours, dateClick, hiddenDays, slotDuration, rest } = service
  const hideEvents = backevents.map((ev) => ({
    ...ev,
    start: new Date(parseInt(ev.start)),
    end: new Date(parseInt(ev.end)),
  }))

  const events = [rest, ...hideEvents]

  const range = {
    start: new Date(),
  }

  return (
    <Grid style={{ backgroundColor: "white" }}>
      <FullCalendar
        firstDay={1}
        selectable={true}
        selectOverlap={false}
        editable={false}
        nowIndicator={true}
        timeZone={timezone}
        select={async ({ startStr, endStr, ...rest }) => {
          try {
            if (moment().diff(startStr) <= 0) {
              await selectEvent({ start: startStr, end: endStr })
            }
          } catch (error) {
            console.log("error selecting date", error)
          }
        }}
        now={new Date(moment().tz(timezone))}
        events={events}
        hiddenDays={hiddenDays}
        slotDuration={slotDuration}
        businessHours={businessHours}
        slotLabelInterval={slotDuration}
        locale={frLocale}
        defaultView="timeGridWeek"
        plugins={[
          dayGridPlugin,
          interactionPlugin,
          momentTimezonePlugin,
          timeGridPlugin,
        ]}
        selectConstraint={"businessHours"}
        validRange={range}
        eventBackgroundColor={"green"}
        dateClick={dateClick}
        selectAllow={async (selectInfo) => {
          return moment().diff(selectInfo.start) <= 0
        }}
      />
    </Grid>
  )
}
