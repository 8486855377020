import gql from "graphql-tag"
import { TOKEN_NAME, COMPANY_ID_STORAGE } from "../../utils/static_constants"
import { GET_CURRENT_MENU_QUERY } from "../query/menu"

export const typeDefs = gql`
  type CurrentUser {
    authenticated: Boolean
    token: String
  }

  extend type Query {
    currentUser: CurrentUser
  }
`

export const resolvers = {
  Mutation: {
    loginUser: async (_obj, { currentUser }, { cache }) => {
      try {
        await localStorage.setItem(TOKEN_NAME, TOKEN_NAME)

        const data = {
          currentUser: {
            __typename: "currentUser",
            authenticated: true,

            token: currentUser.token,
          },
        }

        await cache.writeData({ data })

        return true
      } catch (error) {
        throw error
      }
    },
    logoutUser: async (_obj, _args, { cache }) => {
      try {
        const data = {
          currentUser: {
            __typename: "currentUser",
            authenticated: false,
          },
        }

        await localStorage.removeItem(TOKEN_NAME, TOKEN_NAME)
       // await localStorage.removeItem(COMPANY_ID_STORAGE)
        await cache.writeData({ data })

        return true
      } catch (error) {
        throw error
      }
    },
    toggleMenu: async (_obj, _args, { cache }) => {
      try {
        const {
          smallMenu: { smallMenu },
        } = await cache.readQuery({
          query: GET_CURRENT_MENU_QUERY,
        })

        const result = !smallMenu

        await cache.writeQuery({
          query: GET_CURRENT_MENU_QUERY,
          data: { smallMenu: { __typename: "smallMenu", smallMenu: result } },
        })

        return true
      } catch (error) {
        throw error
      }
    },
  },
}
