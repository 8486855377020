import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const ConfirmDialog = (props) => {
  const { open, cancel, title, confirmAction, children, key, ...rest } = props
  return (
    <div key={key}>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        {...rest}
        onClose={cancel}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button
            onClick={async (e) => {
              e.preventDefault()
              await cancel()
            }}
            color="secondary"
          >
            Annuler
          </Button>
          <Button
            onClick={async (e) => {
              
              await confirmAction()
            }}
            color="primary"
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
