import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Typography, Chip, Button } from '@material-ui/core'
import {ORANGE} from '../../../utils/constants'

import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

import { DisplayDate } from 'components/DisplayDate'
import moment from 'moment-timezone'
import { colors } from 'utils/constants'

//icons
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import LockIcon from '@material-ui/icons/Lock';

const useStyles = makeStyles((theme) => ({
  cardSerice:{
    boxShadow: '0px 4px 15px 0 rgb(0 0 0 / 5%)!important'
  }
}))

export const Service = (props) => {
  const { canModify, item, selectForUpdate, selectForRemove,currency } = props

  const end =
    item && item.validRange && item.validRange.end
      ? moment(item.validRange.end)
      : moment()

  const diff = end.diff(moment(), 'days')

  const classes = useStyles()

  return (
    <div
      className={`${item.internal ? 'card-service-internal ' : ' '} card-service ${classes.cardSerice}`}
    >
      {/*  <CardMedia
          className={classes.media}
          image={item.picture}
          title="Contemplative Reptile"
        /> */}
      {/*  {!item.internal && (
        <Typography align='center' variant='body1' component='span'>
          <span
            className={clsx(classes.styleIsactive)}
            style={{
              backgroundColor:
                item.is_active && diff > 0 ? colors.SKY_BLUE : colors.ORANGE
            }}
          >
            {item.is_active && diff > 0 ? 'actif' : 'inactif'}
          </span>
        </Typography>
      )} */}

      {/*   {item.internal && (
        <Typography align='center' variant='body1' component='span'>
          <span
            className={clsx(classes.styleIsactive)}
            style={{
              backgroundColor: item.is_active ? colors.SKY_BLUE : colors.ORANGE
            }}
          >
            {item.is_active ? 'actif' : 'inactif'}
          </span>
        </Typography>
      )} */}

      <div
        className={`${
          item.internal ? 'card-service-header-internal ' : ' '
        } card-service-header`}
      >
        <Typography gutterBottom variant='h5' component='h5'>
          {item.name}
        </Typography>
      </div>

      {item.internal && (
        <div className='card-service-item'>
          <span className='key-service'>Etat</span>
          <p className='data-service'>
            <span style={{color:colors.ORANGE}}><LockIcon color={colors.ORANGE}/></span>
            {/* <Typography variant='body1' color='textSecondary' component='span'>
              {item.is_active ? 'Actif' : 'Inactif'}
            </Typography> */}
          </p>
        </div>
      )}

      {!item.internal && (
        <div className='card-service-item'>
          <span className='key-service'>Etat</span>
          <p className='data-service'>
            <span style={{color:colors.SKY_BLUE}}><VerifiedUserIcon /></span>
           {/*  <Typography variant='body1' color='textSecondary' component='span'>
              {item.is_active && diff > 0 ? 'Actif' : 'Inactif'}
            </Typography> */}
          </p>
        </div>
      )}

      {item.internal && (
        <div className='card-service-item'>
          <span className='key-service'>Status</span>
          <p className='data-service'>
            <Typography variant='body1' color='textSecondary' component='span'>
              Prive
            </Typography>
          </p>
        </div>
      )}

      {!item.internal && (
        <div className='card-service-item'>
          <span className='key-service'>Statut</span>
          <p className='data-service'>
            <Typography variant='body1' color='textSecondary' component='span'>
              Public
            </Typography>
          </p>
        </div>
      )}

      <div className='card-service-item'>
        <span className='key-service'>Categorie</span>
        <p className='data-service'>
          <Typography variant='body1' color='textSecondary' component='span'>
            {item.category && item.category.name && (
              <span>{item.category.name}</span>
            )}
          </Typography>
        </p>
      </div>

      <div className='card-service-item'>
        <span className='key-service'>Auteur</span>
        <p className='data-service'>
          {canModify && (
            <Typography variant='body1' color='textSecondary' component='span'>
              {item.author && item.author.fullname && (
                <span>{item.author.fullname}</span>
              )}
            </Typography>
          )}
        </p>
      </div>

      <div className='card-service-item'>
        <span className='key-service'>Date</span>
        <p className='data-service'>
          <Typography variant='body1' color='textSecondary' component='span'>
            <DisplayDate date={item.createdAt} />
          </Typography>
        </p>
      </div>

      {!item.internal && (
        <>
          <div className='card-service-item'>
            <span className='key-service'>Prix Visible</span>
            <p className='data-service'>
              <Typography variant='body1' color='textSecondary' component='span'>
                {item.show_price ? 'Oui' : 'Non'}
              </Typography>
            </p>
          </div>
        </>
      )}

      <div className='card-service-item'>
        <span className='key-service'>Sms de rappel</span>
        <p className='data-service'>
          <Typography variant='body1' color='textSecondary' component='span'>
            {item.sms_reminder ? 'Oui' : 'pas de sms de rappel'}
          </Typography>
        </p>
      </div>
      <div className='card-service-item'>
        <span className='key-service'>Prix</span>
        <p className='data-service'>
          <Typography variant='body1' color='textSecondary' component='span'>
            {item.price ? `${item.price} ${currency}` : 'Aucun prix indiqué'}
          </Typography>
        </p>
      </div>

      {!item.internal && (
        <div className='end-calendar'>
          <Typography variant='body2' component='span'>
            {diff > 0
              ? `La disponibilité calendaire du service expire dans ${diff} jour(s)`
              : `Disponibilté calendaire expirée ya ${-1 * diff} jour(s)`}
          </Typography>
        </div>
      )}

      {!item.internal && (
        <div className='end-calendar'>
          <a target='_blank' href={`https://senrv.biz/service-rdv/${item.id}`}>
            voir sur internet
          </a>
        </div>
      )}

      <div className={'service-footer'}>
        {canModify && (
          <IconButton
          color='default'
            aria-label='delete'
            className={classes.margin}
            onClick={async (e) => {
              e.preventDefault()
              await selectForRemove(item)
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
        <IconButton
       
          aria-label='delete'
          className={classes.margin}
          onClick={async (e) => {
            e.preventDefault()
            await selectForUpdate(item)
          }}
        >
          <EditIcon />
        </IconButton>
      </div>
    </div>
  )
}
