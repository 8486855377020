import gql from "graphql-tag"
export const oneEventQuery = gql`
  query oneEvent($id: String!) {
    oneEvent(id: $id) {
      id
      title
      company_id
      start
      end
      event_number
      finished
      canceled
      canceled_by_author
      cancel_date
      canceled_by_company
      canceled_by_company_author_name
      created_by_company
      created_by_company_author_name
      signaled_by_company_author_name
      event_signaled
      author_presence
      motif_cancel
      createdAt
      updatedAt
      author {
        id
        name
        lastname
       phone
        gender
      }

      workers {
        id
        name
        lastname
        fullname
        phone
        gender
      }
      service {
        id
        name

        picture
        category {
          id
          name
        }
      }
    }
  }
`
export const findEventsCompQuery = gql`
  query findEventsComp(
    $page: Int!
    $event_number: String
    $company_id: String!
    $start: String
    $category_ids: [String]
    $service_ids: [String]
    $worker_ids: [String]
  ) {
    findEventsComp(
      page: $page
      event_number: $event_number
      company_id: $company_id
      start: $start
      category_ids: $category_ids
      service_ids: $service_ids
      worker_ids: $worker_ids
    ) {
      totalDocs
      hasPrevPage
      totalPages
      limit
      offset
      page
      prevPage
      nextPage
      docs {
        company_id
        patient_id
        id
        title
        start
        end
        event_number
        finished
        canceled
        canceled_by_author
        cancel_date
        canceled_by_company
        canceled_by_company_author_name
        created_by_company
        created_by_company_author_name
        signaled_by_company_author_name
        event_signaled
        author_presence
        motif_cancel
        createdAt
        updatedAt
        author {
          id
          name
          lastname
          phone
          gender
        }

        workers {
          id
          name
          lastname
          fullname
          phone
          gender
        }
        service {
          id
          name

          picture
          category {
            id
            name
          }
        }
      }
    }
  }
`

export const findEventsWorkerQuery = gql`
  query findEventsWorker(
    $page: Int!
    $event_number: String
    $company_id: String!
    $start: String
    $category_ids: [String]
    $service_ids: [String]
  ) {
    findEventsWorker(
      page: $page
      event_number: $event_number
      company_id: $company_id
      start: $start
      category_ids: $category_ids
      service_ids: $service_ids
    ) {
      totalDocs
      hasPrevPage
      totalPages
      limit
      offset
      page
      prevPage
      nextPage
      docs {
        id
        title
        start
        end
        price
        event_number
        finished
        canceled
        canceled_by_author
        cancel_date
        canceled_by_company
        canceled_by_company_author_name
        created_by_company
        created_by_company_author_name
        signaled_by_company_author_name
        event_signaled
        author_presence
        createdAt
        updatedAt
        author {
          id
          name
          lastname
          phone
          gender
        }

        workers {
          id
          name
          lastname
          fullname
          phone
          gender
        }
        service {
          id
          name
          show_price
          picture
          category {
            id
            name
          }
        }
      }
    }
  }
`


export const findEventsBillQuery = gql`
  query findEventsBill($page: Int!, $limit: Int, $bill_id: String!) {
    findEventsBill(page: $page, limit: $limit, bill_id: $bill_id) {
      totalDocs
      hasPrevPage
      totalPages
      limit
      offset
      page
      prevPage
      nextPage
      hasNextPage
      docs {
        id
        title
        start
        end
        event_number

        createdAt
        updatedAt
        author {
          id
          name
          lastname
          phone
          gender
          
        }

        service {
          id
          name
        }
      }
    }
  }
`