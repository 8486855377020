import React from 'react'
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Paper,
  TableBody,
  IconButton
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: '100%',
    padding: '0px 15px'
  },
  chip: {
    margin: theme.spacing(0.5)
  }
}))

export const OrdonnanceItems = ({ items, removeItem }) => {
  const classes = useStyles()

  return (
    <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: 20 }} key={items.lenth}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Médicament</TableCell>
              <TableCell>Quantité</TableCell>
              <TableCell>Prescription</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, i) => (
              <TableRow key={i}>
                <TableCell>{item.name}</TableCell>

                <TableCell>{item.quantity}</TableCell>

                <TableCell>{item.description}</TableCell>

                <TableCell>
                  <IconButton color='default' component='span'>
                    <DeleteIcon
                      className='buttonLeft'
                      //type="submit"
                      onClick={async (e) => {
                        await e.preventDefault()

                        await removeItem(i)
                      }}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}
