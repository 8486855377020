import gql from "graphql-tag"

export const oneCompanyQuery = gql`
  query oneCompany($company_id: String!) {
    oneCompany(company_id: $company_id) {
      id
      name
      picture
      picture_id
      logo
      logo_id
      stamp
      stamp_id
      facebook
      twitter
      instagram
      linkedin
      website
      reference
      is_active
      description_address
      slug
      description
      region
      phone
      city
      lat
      lng
      subscription {
        price
        services_number
        workers_number
      }
      rating {
        average
        ratecount
      }
      createdAt
      updatedAt
      categories {
        id
        name
      }
    }
  }
`

export const companyClientsQuery = gql`
  query companyClients($company_id: String!) {
    companyClients(company_id: $company_id) {
      id
      name
      lastname
      fullname
      phone
    }
  }
`

export const companyServicesQuery = gql`
  query companyServices($company_id: String!) {
    companyServices(company_id: $company_id) {
      id
      name
      internal
      picture
      description
      region
      show_price
      sms_reminder
      is_active
      price

      createdAt
      updatedAt
      slotEventCount
      hiddenDays
      slotDuration
      createdAt
      updatedAt
      businessHours {
        daysOfWeek
        startTime
        endTime
      }
      validRange {
        start
        end
      }
      rest {
        title
        daysOfWeek
        startTime
        endTime
      }
      category {
        id
        name
      }
      author {
        id
        name
        lastname
        fullname
      }
    }
  }
`
