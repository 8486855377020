import React from "react"
 
 
import { Formik } from "formik"
import * as Yup from "yup"
 
import { useMutation, useQuery } from "react-apollo"
import { confirmPasswordLostMutation ,LOGIN_USER_MUTATION} from "../../graphql/mutation/user"
import ConfirmPasswordLost from "./components/ConfirmPasswordLost"
import { COMPANY_ID_STORAGE, LOGIN_PATH, PROFILE_PATH, TOKEN_NAME } from "../../utils/static_constants"
import { oneSettingAdminQuery } from "../../graphql/query/setting_admin"
const loginSchema = Yup.object().shape({
  password_lost_code: Yup.number().required("champ requis"),
  reference: Yup.number().required("champ requis"),
  phone: Yup.string()
    .trim()
    /*  .min(13, "Téléphone incorrect")
    .max(13, "Téléphone incorrect") */
    .required("champ requis"),
})

const ConfirmPasswordLostView = ({history}) => {
  const [confirm] = useMutation(confirmPasswordLostMutation)
  const [setUser] = useMutation(LOGIN_USER_MUTATION)
  const { data, loading } = useQuery(oneSettingAdminQuery)
  const phone_code =
    data && data.oneSettingAdmin && data.oneSettingAdmin.phone_code
      ? data.oneSettingAdmin.phone_code
      : null

  const phone_max_size =
    data && data.oneSettingAdmin && data.oneSettingAdmin.phone_max_size
      ? data.oneSettingAdmin.phone_max_size
      : null

  const phone_min_size =
    data && data.oneSettingAdmin && data.oneSettingAdmin.phone_min_size
      ? data.oneSettingAdmin.phone_min_size
      : null

  return (
    <Formik
      initialValues={{
        phone: "",
        password_lost_code: "",
        reference:''
      }}
      validationSchema={loginSchema}
      onSubmit={async (
        values,

        { setSubmitting, setFieldError }
      ) => {
        try {
          const phoneLength = parseInt(values.phone.trim().length)

          if (
            !values.phone.startsWith(phone_code) ||
            phoneLength < phone_min_size ||
            phoneLength > phone_max_size
          ) {
            /*  await setFieldError(
            'phone',
            `Le téléphone doit commencer par ${phone_code} ,taille minimale de ${phone_min_size} et maximale de ${phone_max_size}`,
          ); */
            window.alert(
              `Le téléphone doit commencer par ${phone_code} ,taille minimale de ${phone_min_size} et maximale de ${phone_max_size}`
            )
            return
          }
          const res = await confirm({
            variables: {
              password_lost_code: parseInt(values.password_lost_code),
              phone: values.phone.trim(),
              reference:values.reference
            },
          })

          const { ok, errors, token, company_id} = res.data.confirmPasswordLost

          if (ok) {
            await localStorage.setItem(COMPANY_ID_STORAGE, company_id)
          //  await localStorage.setItem(TOKEN_NAME, TOKEN_NAME)
            const currentUser = {
              authenticated: true,
              token,
            }
            await setUser({
              variables: {
                currentUser,
              },
            })
          } else {
            errors.forEach(async (error) => {
              await setFieldError(error.path, error.message)
              await setSubmitting(false)
              window.alert(error.message)
            })
          }
        } catch (error) {
          window.alert(error.message)
        }
      }}
    >
      {(props) => <ConfirmPasswordLost {...props} phone_code={phone_code} />}
    </Formik>
  )
}

export default ConfirmPasswordLostView
