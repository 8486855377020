import React, { useState, Fragment } from 'react'
import { useQuery } from 'react-apollo'
import Loading from 'components/Loading'
import moment from 'moment-timezone'
import {
  Grid,
  makeStyles,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  Box
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import { Accordion } from 'components/Accordion'
import { readNumber } from 'utils/readnumber'
import Paper from '@material-ui/core/Paper'
import { oneSettingAdminQuery } from 'graphql/query/setting_admin'
import { renderDate } from 'utils/func'
import { compBillByIdQuery, findSmsCompQuery } from 'graphql/query/comp_bill'

import { COMPANY_ID_STORAGE } from 'utils/static_constants'
import { findEventsBillQuery } from 'graphql/query/event'
import { EventList } from './components/EventList'

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650
  },

  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1)
  }
}))
const OneFactureView = ({
  match: {
    params: { bill_id }
  }
}) => {
  const classes = useStyles()
  const [page, setPage] = useState(1)
  const [limit] = useState(10)
  const [page2, setPage2] = useState(1)
  const [limit2] = useState(10)
  const [expanded1, setExpanded1] = useState(true)
  const [expanded2, setExpanded2] = useState(false)
  const [expanded3, setExpanded3] = useState(false)

  const { loading: loading1, data: generalSetting } = useQuery(oneSettingAdminQuery)

  //

  const { loading: loading4, data: rvData } = useQuery(findEventsBillQuery, {
    skip: !bill_id ? true : false,
    variables: { bill_id, page: page2, limit: limit2 }
  })

  const { loading: loading3, data: billData } = useQuery(compBillByIdQuery, {
    skip: !bill_id ? true : false,
    variables: { id: bill_id }
  })

  console.log('billData', billData)
  const { loading: loading2, data: commentData, fetchMore } = useQuery(
    findSmsCompQuery,
    {
      variables: {
        company_id: localStorage.getItem(COMPANY_ID_STORAGE),
        page,
        limit,
        bill_id
      }
    }
  )

  const bill =
    billData &&
    billData.compBillById &&
    billData.compBillById.ok &&
    billData.compBillById.bill
      ? billData.compBillById.bill
      : null
  const clients =
    commentData && commentData.findSmsComp && commentData.findSmsComp.docs
      ? commentData.findSmsComp
      : null

  const computeVariables = async (page) => {
    const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
    let variables = { page, limit, company_id }

    await setPage(page)

    return variables
  }

  const computeVariables2 = async (page) => {
    let variables = { page, limit }

    await setPage2(page)

    return variables
  }

  const onPageChange2 = async (page) => {
    try {
      let variables = await computeVariables2(page)

      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { findSmsComp } = fetchMoreResult

          return Object.assign({}, prev, {
            findSmsComp
          })
        }
      })
    } catch (error) {
      window.alert(error.message)
    }
  }
  const onPageChange = async (page) => {
    try {
      let variables = await computeVariables(page)

      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { findSmsComp } = fetchMoreResult

          return Object.assign({}, prev, {
            findSmsComp
          })
        }
      })
    } catch (error) {
      window.alert(error.message)
    }
  }

  const currency =
    generalSetting &&
    generalSetting.oneSettingAdmin &&
    generalSetting.oneSettingAdmin.currency
      ? generalSetting.oneSettingAdmin.currency
      : ''
  const timezone =
    generalSetting &&
    generalSetting.oneSettingAdmin &&
    generalSetting.oneSettingAdmin.timezone
      ? generalSetting.oneSettingAdmin.timezone
      : 'Africa/dakar'

  if (loading1 || loading2 || loading3 || loading4) {
    return <Loading />
  }

  return (
    <Fragment>
      {/*  <h3
        style={{ textAlign: "center", color: colors.VIOLET, padding: 20 }}
      >{`Facture encours  du   ${from.format("ll")}  au   ${to.format("ll")}`}</h3> */}

      {bill && (
        <Fragment>
          <Accordion
            id={'search-clientbill'}
            title={`Facture totale du  ${moment(
              new Date(parseInt(bill.from_date))
            ).format('ll')} Au
          ${moment(new Date(parseInt(bill.to_date))).format('ll')}`}
            expanded={expanded1}
            setExpanded={setExpanded1}
          >
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Box className={'miniContent miniContentSetting'}>
                  <Box className={'miniContentMain miniContentMainSetting'}>
                    <Typography style={{ flex: 2 }} variant='body2'>
                      Facture totale
                    </Typography>
                    <p className={'infoText'} style={{ fontSize: 20 }}>
                      {bill.final_bill + ' ' + currency}
                    </p>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box className={'miniContent miniContentSetting'}>
                  <Box className={'miniContentMain miniContentMainSetting'}>
                    <Typography style={{ flex: 2 }} variant='body2'>
                      Etat facture
                    </Typography>
                    <p
                      className={'infoText'}
                      style={{
                        fontSize: 20,
                        color: bill.bill_paid ? 'green' : 'red'
                      }}
                    >
                      {bill.bill_paid ? `Facture payée ` : 'Facture non payée'}
                    </p>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box className={'miniContent miniContentSetting'}>
                  <Box className={'miniContentMain miniContentMainSetting'}>
                    <Typography style={{ flex: 2 }} variant='body2'>
                      Abonnement
                    </Typography>
                    <p className={'infoText'}>
                      {bill.subscription_price + ' ' + currency}
                    </p>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box className={'miniContent miniContentSetting'}>
                  <Box className={'miniContentMain miniContentMainSetting'}>
                    <Typography style={{ flex: 2 }} variant='body2'>
                      Tva ( {bill.vat} %)
                    </Typography>
                    <p className={'infoText'}>{bill.total_vat + ' ' + currency}</p>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box className={'miniContent miniContentSetting'}>
                  <Box className={'miniContentMain miniContentMainSetting'}>
                    <Typography style={{ flex: 2 }} variant='body2'>
                      Sms consommés ( {readNumber(bill.total_sms)})
                    </Typography>
                    <p className={'infoText'}>
                      {bill.total_sms_price + ' ' + currency}
                    </p>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box className={'miniContent miniContentSetting'}>
                  <Box className={'miniContentMain miniContentMainSetting'}>
                    <Typography style={{ flex: 2 }} variant='body2'>
                      Commission sur rendez-vous ( {readNumber(bill.total_rv)})
                    </Typography>
                    <p className={'infoText'}>
                      {bill.total_rv_price + ' ' + currency}
                    </p>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Accordion>
        </Fragment>
      )}

      <Accordion
        id={'search-client-sms'}
        title={'Total sms consommés'}
        expanded={expanded2}
        setExpanded={setExpanded2}
      >
        <Grid container spacing={3}>
          {clients && (
            <Grid item xs={12} sm={12}>
              <Pagination
                count={clients.totalPages}
                page={page}
                onChange={async (_event, value) => {
                  /*   if (value.toString() !== page.toString()) {
                    await onPageChange(value)
                  } */
                  await onPageChange(value)
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} style={{ textAlign: 'center' }}>
            <p>{readNumber(clients.totalDocs) + ' sms'}</p>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Envoyé le</TableCell>
                    <TableCell>Téléphone</TableCell>
                    <TableCell>Envoyé à</TableCell>
                    <TableCell>Objet</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clients &&
                    clients.docs.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{renderDate(item.createdAt, timezone)}</TableCell>
                        <TableCell>{item.phone}</TableCell>

                        <TableCell>
                          {item.author.gender == 'male' ? 'Monsieur' : 'Madame'}{' '}
                          {item.author.lastname + ' ' + item.author.name}
                        </TableCell>
                        <TableCell>{item.title}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Accordion>

      <Accordion
        id={'search-client-rvs'}
        title={'Total rendez-vous'}
        expanded={expanded3}
        setExpanded={setExpanded3}
      >
        <EventList
          data={rvData && rvData.findEventsBill ? rvData.findEventsBill : null}
          onPageChange={onPageChange2}
          // selectEvent={selectEvent}
          timezone={timezone}
        />
      </Accordion>
    </Fragment>
  )
}
export default OneFactureView
