import React from "react"
import MuiPhoneInput from "material-ui-phone-number"
import { TextField, InputLabel } from "@material-ui/core"
import { PHONE_COUNTRIES, PHONE_DEFAULT_COUNTRY, PHONE_REGION } from "utils/static_constants"
/* export const PHONE_COUNTRIES=['sn']
export const PHONE_REGION='africa'
export const PHONE_DEFAULT_COUNTRY='sn' */
export const PhoneField = ({
  value,
  setFieldValue,
  error,
  label,
  name,
  helperText,
  fullWidth,
  required,
}) => {
  return (
    <React.Fragment>
      <InputLabel id={name} required={required}>
        {label}
      </InputLabel>
      <MuiPhoneInput
        defaultCountry={PHONE_DEFAULT_COUNTRY}
        onlyCountries={PHONE_COUNTRIES}
        region={PHONE_REGION}
        as={TextField}
        //  className={classes.textField}
        error={error}
        fullWidth={fullWidth !== undefined ? fullWidth : true}
        helperText={helperText}
        //label={label}
        name={name}
        onChange={async (phone) => setFieldValue("phone", phone)}
        type="text"
        value={value}
        variant="outlined"
      />
    </React.Fragment>
  )
}
