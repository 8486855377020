import React, { useState } from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"

import { useMutation } from "react-apollo"

import { loginMutation } from "../../graphql/mutation/user"
import { LOGIN_USER_MUTATION } from "../../graphql/mutation/user"
import Login from "./components/Login"
import { COMPANY_ID_STORAGE, TOKEN_NAME } from "utils/static_constants"

const loginSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .min(5, "minimun 5 caractères")
    .max(50, "maximum 50 caractères")
    .required("champ requis"),

  reference: Yup.string()
    .trim()
    .max(50, "maximum 50 caractères")
    .required("champ requis"),

  phone: Yup.string()
    .trim()
    .min(13, "Téléphone incorrect")
    .max(13, "Téléphone incorrect")
    .required("champ requis"),
})

const SignIn = () => {
  const [login] = useMutation(loginMutation)
  const [setUser] = useMutation(LOGIN_USER_MUTATION)
  const [globalError, setGlobalError] = useState("")

  return (
    <Formik
      initialValues={{
        reference: "",
        phone: "",
        password: "",
      }}
      validationSchema={loginSchema}
      onSubmit={async (
        values,

        { setSubmitting, setFieldError }
      ) => {
        try {
          await setGlobalError("")
          const res = await login({
            variables: {
              ...values,
              phone: values.phone.trim(),
            },
          })

          const { ok, errors, token, company_id } = res.data.loginCompany

          if (ok) {
            await localStorage.setItem(COMPANY_ID_STORAGE, company_id)
          //  await localStorage.setItem(TOKEN_NAME, TOKEN_NAME)
            const currentUser = {
              authenticated: true,
              token,
            }
            await setUser({
              variables: {
                currentUser,
              },
            })
            //await history.push(CONDITIONS_PATH)
            //await setSubmitting(false);
          } else {
            errors.forEach(async (error) => {
              if (error.path && error.path.toString() === "global") {
                await setGlobalError(error.message)
              } else {
                await setFieldError(error.path, error.message)
                await setSubmitting(false)
              }
            })
          }
        } catch (error) {
          throw error
        }
      }}
    >
      {(props) => <Login {...props} globalError={globalError} />}
    </Formik>
  )
}

SignIn.propTypes = {
  history: PropTypes.object,
}

export default withRouter(SignIn)
