import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { makeStyles } from "@material-ui/styles"
import { Paper,Grid, Button, TextField, Link, Typography } from "@material-ui/core"
import { MessageField } from "components/MessageField"
import Loading from "components/Loading"
import {LOGIN_PATH} from "utils/static_constants"

const useStyles = makeStyles((theme) => ({

  textField: {
    marginBottom: theme.spacing(2),
  },

  signInButton:{
    marginTop:18,
   
  },
  
}))

const ConfirmPasswordLost = ({
  touched,
  errors,

  handleSubmit,
  isSubmitting,
  handleChange,
  globalError,
  values,
  phone_code
}) => {
  const classes = useStyles()

  if (isSubmitting) {
    return <Loading />
  }
  return (
  
              <form
               className="formcontent"
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
              >
                {globalError && (
                  <MessageField message={globalError} severity={"error"} />
                )}

                <br />

                <h2 className="titlelogin">Code de confirmation reçu par mail suite à une déclaration de
                  password perdu
                </h2>
      
               

                <TextField
                  className={classes.textField}
                  id="phone"
                  name="phone"
                  autoComplete='off'
                  value={values.phone}
                  required
                  label={`Votre téléphone (${phone_code})`}
                  onChange={handleChange}
                  fullWidth
                  helperText={touched.phone && errors.phone ? errors.phone : null}
                  error={touched.phone && errors.phone ? true : false}
                  variant="filled"
                />
                <TextField
                  className={classes.textField}
                  fullWidth
                  required
                  autoComplete='off'
                  inputProps={{ min: "0", step: "1" }}
                  label="Code reçu par email"
                  value={values.password_lost_code}
                  name="password_lost_code"
                  id="password_lost_code"
                  onChange={handleChange}
                  type="text"
                  variant="filled"
                  helperText={
                    touched.password_lost_code && errors.password_lost_code
                      ? errors.password_lost_code
                      : null
                  }
                  error={
                    touched.password_lost_code && errors.password_lost_code
                      ? true
                      : false
                  }
                />
                <TextField
                  required
                  inputProps={{
                    autoComplete: 'off'
                 }}
                  error={touched.reference && errors.reference ? true : false}
                  fullWidth
                  helperText={
                    touched.reference && errors.reference ? errors.reference : null
                  }
                  label="Référence de votre entreprise"
                  name="reference"
                  onChange={handleChange}
                  type="password"
                  value={values.reference}
                  variant="filled"
                />

              <div className="footer-login">

                <Button
                  className={classes.signInButton}
                  color="primary"
                  //disabled={!formState.isValid}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  CONFIRMER
                </Button>
               
                
                  <Link component={RouterLink} to={'/'} className="linkreturnhomepage">
                      Se connecter
                  </Link>
              
              </div>        
          
      </form>
  )
}

export default ConfirmPasswordLost
