import React, { useState, useEffect, Fragment } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import * as Yup from 'yup'
import { Formik } from 'formik'
import moment from 'moment-timezone'
import { makeStyles } from '@material-ui/styles'
import {
  createServiceInternalMutation,
  createServiceMutation,
  deleteServiceMutation,
  updateServiceInternalMutation,
  updateServiceMutation
} from '../../graphql/mutation/service'
import { findServicesQuery } from '../../graphql/query/service'
import Loading from 'components/Loading'
import { ServiceList } from './components/ServiceList'
import { ServiceToolbar } from './components/ServiceToolbar'
import { ServiceCreate } from './components/ServiceCreate'
import { ModalDialog } from 'components/ModalDialog'
import { ServiceEdit } from './components/ServiceEdit'
import { ConfirmDialog } from 'components/ConfirmDialog'
import { ServiceDelete } from './components/ServiceDelete'
import { oneCompanyQuery } from 'graphql/query/company'
import { COMPANY_ID_STORAGE } from 'utils/static_constants'
import { settingQuery } from 'graphql/query/setting'
import { dayOfWeekFunc, dayNumberFunc, optionDays } from 'utils/func'
import { oneSettingAdminQuery } from 'graphql/query/setting_admin'
import { toast } from 'react-toastify'
import { currentUserQuery } from 'graphql/query/user'
import { ServiceCreateIntern } from './components/ServiceCreateIntern'
import { ServiceEditIntern } from './components/ServiceEditIntern'

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: '0px 15px'
  },
  rooter: {
    maxWidth: 345
  },
  media: {
    height: 140
  },

  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}))

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png']

const createInternSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .typeError('champ requis')
    .min(2, 'minimum 2 caractères')
    .max(100, 'maximum 100 caractères')
    .required('champ requis'),

  category_id: Yup.string().required('champ requis'),

  price: Yup.number().required('champ requis'),
  description: Yup.string()
    .trim()
    .min(10, 'minimum 10 caractères')
    .required('champ requis')
    .max(500, 'maximum 500 caractères'),

  file: Yup.mixed()
    .nullable()
    .test('fileFormat', 'extension non acceptée', (value) => {
      if (value) {
        return value && SUPPORTED_FORMATS.includes(value.type)
      }
      return true
    })
})
const createSchema = Yup.object().shape({
  daysOfWeek: Yup.array()
    .of(Yup.string())
    .required('champ requis'),
  /*   startTime: Yup.string().required("champ requis"),
  endTime: Yup.string().required("champ requis"),
  startTimeRest: Yup.string().required("champ requis"),
  endTimeRest: Yup.string().required("champ requis"),
  slotDuration: Yup.string().required("champ requis"), */

  hiddenDays: Yup.array()
    .of(Yup.string())
    .nullable(),
  name: Yup.string()
    .trim()
    .typeError('champ requis')
    .min(2, 'minimum 2 caractères')
    .max(100, 'maximum 100 caractères')
    .required('champ requis'),
  validRangeStart: Yup.date()
    .typeError('champ requis')
    .required('champ requis'),
  validRangeEnd: Yup.date()
    .typeError('champ requis')
    .required('champ requis'),

  category_id: Yup.string().required('champ requis'),

  show_price: Yup.boolean()
    .typeError('champ requis')
    .required('champ requis'),
  sms_reminder: Yup.boolean()
    .typeError('champ requis')
    .required('champ requis'),
  //slotEventCount: Yup.number().required("champ requis"),
  price: Yup.number().nullable(),
  description: Yup.string()
  .trim()
  .min(10, 'minimum 10 caractères')
  .nullable()
  .max(500, 'maximum 500 caractères'),

  file: Yup.mixed()
    .nullable()
    .test('fileFormat', 'extension non acceptée', (value) => {
      if (value) {
        return value && SUPPORTED_FORMATS.includes(value.type)
      }
      return true
    })
  /* .test("fileSize", "image trop large", (value) => {
      if (value) {
        return value && value.size <= FILE_SIZE
      }
      return true
    }), */
})

const updateInternSchema = Yup.object().shape({
  id: Yup.string()
    .trim()
    .required('champ requis'),

  is_active: Yup.boolean()
    .default(false)
    .typeError('champ requis'),
  name: Yup.string()
    .trim()
    .typeError('champ requis')
    .min(2, 'minimum 2 caractères')
    .max(100, 'maximum 100 caractères')
    .nullable(),

  category_id: Yup.string(),

  price: Yup.number().nullable(),
  description: Yup.string()
    .trim()
    .min(10, 'minimum 10 caractères')
    .nullable()
    .max(500, 'maximum 500 caractères'),

  file: Yup.mixed()
    .nullable()
    .test('fileFormat', 'extension non acceptée', (value) => {
      if (value) {
        return value && SUPPORTED_FORMATS.includes(value.type)
      }
      return true
    })
  /*   .test("fileSize", "image trop large", (value) => {
      if (value) {
        return value && value.size <= FILE_SIZE
      }
      return true
    }), */
})
const updateSchema = Yup.object().shape({
  id: Yup.string()
    .trim()
    .required('champ requis'),
  daysOfWeek: Yup.array()
    .of(Yup.string())
    .nullable(),
  /* startTime: Yup.string().nullable(),
  endTime: Yup.string().nullable(),
  startTimeRest: Yup.string().nullable(),
  endTimeRest: Yup.string().nullable(), */
  validRangeStart: Yup.date()
    .typeError('champ requis')
    .nullable(),
  validRangeEnd: Yup.date()
    .typeError('champ requis')
    .nullable(),
  /*  slotDuration: Yup.string().nullable(),
  slotEventCount: Yup.number().nullable(), */
  hiddenDays: Yup.array()
    .of(Yup.string())
    .nullable(),
  name: Yup.string()
    .trim()
    .typeError('champ requis')
    .min(2, 'minimum 2 caractères')
    .max(100, 'maximum 100 caractères')
    .nullable(),

  estimated_time: Yup.string()
    .nullable()
    .trim()
    .typeError('champ requis'),

  category_id: Yup.string(),
  show_price: Yup.boolean()
    .default(false)
    .typeError('champ requis'),
  sms_reminder: Yup.boolean()
    .default(false)
    .typeError('champ requis'),
  price: Yup.number()

    .nullable()
   /*  .when('show_price', (show_price, schema) => {
      return show_price
        ? schema
            .typeError('champ requis')
            .required('champ requis')
            .positive('champ requis')
        : schema.nullable()
    }), */
    ,
  description: Yup.string()
    .trim()
    .min(10, 'minimum 10 caractères')
    .nullable()
    .max(500, 'maximum 500 caractères'),

  file: Yup.mixed()
    .nullable()
    .test('fileFormat', 'extension non acceptée', (value) => {
      if (value) {
        return value && SUPPORTED_FORMATS.includes(value.type)
      }
      return true
    })
  /*   .test("fileSize", "image trop large", (value) => {
      if (value) {
        return value && value.size <= FILE_SIZE
      }
      return true
    }), */
})

const removeSchema = Yup.object().shape({
  company_id: Yup.string().required('champ requis')
  //service_id: Yup.string().required("champ requis"),
})
const ServiceView = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [page, setPage] = useState(1)
  const [createModal, setCreateModal] = useState(false)

  const [createInternModal, setCreateInternModal] = useState(false)
  const [canModify, setCanModify] = useState(false)
  const [globalCreateError, setGlobalCreateError] = useState('')
  const [globalCreateInternError, setGlobalCreateInternError] = useState('')
  const [globalEditError, setGlobalEditError] = useState('')

  const [globalEditInternError, setGlobalEditInternError] = useState('')
  const [editModal, setEditModal] = useState(false)
  const [editModalIntern, setEditModalIntern] = useState(false)
  const [editedItem, setEditedItem] = useState(null)
  const [globalDeleteError, setGlobalDeleteError] = useState('')
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteItem, setDeleteItem] = useState(null)
  const { data: userData } = useQuery(currentUserQuery, {
    fetchPolicy: 'network-only'
  })
  const roles =
    userData && userData.onlineUser && userData.onlineUser.roles
      ? userData.onlineUser.roles
      : []
  const { loading: loading1, data, fetchMore, refetch } = useQuery(
    findServicesQuery,
    {
      variables: { page: 1, company_id: localStorage.getItem(COMPANY_ID_STORAGE) }
    }
  )

  const { loading: loading2, data: comp } = useQuery(oneCompanyQuery, {
    variables: { company_id: localStorage.getItem(COMPANY_ID_STORAGE) }
  })

  const { data: settingData, loading3 } = useQuery(settingQuery, {
    variables: {
      company_id: localStorage.getItem(COMPANY_ID_STORAGE)
    }
  })
  const { loading: loading4, data: generalSetting } = useQuery(oneSettingAdminQuery)

  const [createItem] = useMutation(createServiceMutation, {
    async update (
      cache,
      {
        data: {
          createService: { ok, service }
        }
      }
    ) {
      if (ok) {
        await clearFilter()
        /*  let variables = await computeVariables(page)

        const { findServices } = await cache.readQuery({
          query: findServicesQuery,
          variables,
        })

        const docs = findServices.docs.unshift(service)
        await cache.writeQuery({
          query: findServicesQuery,
          variables,
          data: {
            findServices: {
              ...findServices,
              totalDocs: findServices.totalDocs + 1,
              docs,
            },
          },
        }) */
      }
    }
  })

  const [createInternItem] = useMutation(createServiceInternalMutation, {
    async update (
      cache,
      {
        data: {
          createServiceInternal: { ok }
        }
      }
    ) {
      if (ok) {
        await clearFilter()
        /*  let variables = await computeVariables(page)

        const { findServices } = await cache.readQuery({
          query: findServicesQuery,
          variables,
        })

        const docs = findServices.docs.unshift(service)
        await cache.writeQuery({
          query: findServicesQuery,
          variables,
          data: {
            findServices: {
              ...findServices,
              totalDocs: findServices.totalDocs + 1,
              docs,
            },
          },
        }) */
      }
    }
  })

  const [updateInternItem] = useMutation(updateServiceInternalMutation, {
    async update (
      cache,
      {
        data: {
          updateServiceInternal: { ok }
        }
      }
    ) {
      if (ok) {
        await clearFilter()
        /*  let variables = await computeVariables(page)

        const { findServices } = await cache.readQuery({
          query: findServicesQuery,
          variables,
        })

        const docs = findServices.docs.unshift(service)
        await cache.writeQuery({
          query: findServicesQuery,
          variables,
          data: {
            findServices: {
              ...findServices,
              totalDocs: findServices.totalDocs + 1,
              docs,
            },
          },
        }) */
      }
    }
  })
  const [removeItem] = useMutation(deleteServiceMutation, {
    async update (
      cache,
      {
        data: {
          deleteService: { ok, service_id }
        }
      }
    ) {
      if (ok) {
        let variables = await computeVariables(page)
        const { findServices } = await cache.readQuery({
          query: findServicesQuery,
          variables
        })

        let docs = findServices.docs

        const serviceIndex = findServices.docs.findIndex(
          (d) => d.id.toString() === service_id.toString()
        )

        if (serviceIndex > -1) {
          docs.splice(serviceIndex, 1)
        }

        await cache.writeQuery({
          query: findServicesQuery,
          variables,
          data: {
            findServices: {
              ...findServices,
              totalDocs: findServices.totalDocs - 1,
              docs
            }
          }
        })
      }
    }
  })
  const [updateItem] = useMutation(updateServiceMutation, {
    async update (
      cache,
      {
        data: {
          updateService: { ok, service }
        }
      }
    ) {
      if (ok) {
        let variables = await computeVariables(page)
        const { findServices } = await cache.readQuery({
          query: findServicesQuery,
          variables
        })
        let docs = findServices.docs
        const index = docs.findIndex(
          (d) => d.id.toString() === service.id.toString()
        )
        if (index > -1) {
          docs[index] = service
        }
        await cache.writeQuery({
          query: findServicesQuery,
          variables,
          data: {
            findServices: {
              ...findServices,

              docs
            }
          }
        })
      }
    }
  })

  const create = async (
    values,

    { setSubmitting, setFieldError }
  ) => {
    try {
      const {
        category_id,
        show_price,
        price,
        startTimeRest,
        endTimeRest,
        startTime,
        endTime,
        validRangeEnd,
        validRangeStart,
        ...rest
      } = values

      /*    const strTimeRest = moment(startTimeRest, "HH:mm")
      const ndTimeRest = moment(endTimeRest, "HH:mm")
      const strTime = moment(startTime, "HH:mm")
      const ndTime = moment(endTime, "HH:mm") */
      const endValRange = moment(validRangeEnd)
      const startValRange = moment(validRangeStart)

      /*  if (strTimeRest.isAfter(ndTimeRest)) {
        await setFieldError(
          "startTimeRest",
          "heures début et fin pause incohérentes"
        )
        await setFieldError("endTimeRest", "heures début et fin pause incohérentes")
        return
      }

      if (strTime.isAfter(ndTime)) {
        await setFieldError(
          "startTime",
          "heures début et fin d'activités incohérentes"
        )
        await setFieldError(
          "endTime",
          "heures début et fin d'activités incohérentes"
        )
        return
      }

      if (strTimeRest.isBefore(strTime)) {
        await setFieldError(
          "startTimeRest",
          "heures début activité et pause incohérentes"
        )
        await setFieldError(
          "startTime",
          "heures début activité et pause incohérentes"
        )
        return
      }

      if (ndTimeRest.isAfter(ndTime)) {
        await setFieldError(
          "endTimeRest",
          "heures fin activité et pause incohérentes"
        )
        await setFieldError("endTime", "heures fin activité et pause incohérentes")
        return
      } */

      if (endValRange.isBefore(startValRange)) {
        await setFieldError('validRangeEnd', 'plage de disponibilité incohérente')
        await setFieldError('validRangeStart', 'plage de disponibilité incohérente')
        return
      }
      const category = comp.oneCompany.categories.find(
        (cat) => cat.name.toString() === category_id.toString()
      )

      const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
      let service = {
        ...rest,
        /*  startTimeRest,
        endTimeRest,
        startTime,
        endTime, */
        validRangeEnd,
        validRangeStart,
        show_price,
        price,
        hiddenDays: rest.hiddenDays.map(dayNumberFunc),
        daysOfWeek: rest.daysOfWeek.map(dayNumberFunc),
        category_id: category.id,
        company_id
      }
     /*  if (show_price && !price) {
        window.alert(
          'Si le prix du service est public, le prix est par conséquent obligatoire'
        )
        return
      }
      if (show_price) {
        service.price = price
      } */

      const res = await createItem({
        variables: {
          service,
          company_id
        }
      })
      const { ok, errors } = res.data.createService

      if (ok) {
        await setSubmitting(false)
        await setCreateModal(false)
        await toast.success('Service crée avec succès')
      } else {
        errors.forEach(async (error) => {
          if (error.path && error.path.toString() === 'global') {
            await setGlobalCreateError(error.message)
          } else {
            await setFieldError(error.path, error.message)
            await setSubmitting(false)
          }
        })
      }
    } catch (error) {
      throw error
    }
  }

  const createIntern = async (
    values,

    { setSubmitting, setFieldError }
  ) => {
    try {
      const {
        category_id,

        price,
        file,
        ...rest
      } = values

      const category = comp.oneCompany.categories.find(
        (cat) => cat.name.toString() === category_id.toString()
      )

      const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
      let service = {
        ...rest,

        category_id: category.id,
        company_id
      }
      if (file) {
        service.file = file
      }
      if (price) {
        service.price = price
      }

      const res = await createInternItem({
        variables: {
          ...service,
          company_id
        }
      })
      const { ok, errors } = res.data.createServiceInternal

      if (ok) {
        await setSubmitting(false)
        await setCreateInternModal(false)
        await toast.success('Service crée avec succès')
      } else {
        errors.forEach(async (error) => {
          if (error.path && error.path.toString() === 'global') {
            await setGlobalCreateInternError(error.message)
          } else {
            await setFieldError(error.path, error.message)
            await setSubmitting(false)
          }
        })
      }
    } catch (error) {
      window.alert(error.message)
      //throw error
    }
  }

  const update = async (
    values,

    { setSubmitting, setFieldError }
  ) => {
    try {
      const {
        category_id,
        price,
        show_price,
        /*  startTimeRest,
        endTimeRest,
        startTime,
        endTime, */
        validRangeEnd,
        validRangeStart,
        ...rest
      } = values

      /*   const strTimeRest = moment(startTimeRest, "HH:mm")
      const ndTimeRest = moment(endTimeRest, "HH:mm")
      const strTime = moment(startTime, "HH:mm")
      const ndTime = moment(endTime, "HH:mm") */
      const endValRange = moment(validRangeEnd)
      const startValRange = moment(validRangeStart)
      /*  if (strTimeRest.isAfter(ndTimeRest)) {
        await setFieldError(
          "startTimeRest",
          "heures début et fin pause incohérentes"
        )
        await setFieldError("endTimeRest", "heures début et fin pause incohérentes")
        return
      }

      if (strTime.isAfter(ndTime)) {
        await setFieldError(
          "startTime",
          "heures début et fin d'activités incohérentes"
        )
        await setFieldError(
          "endTime",
          "heures début et fin d'activités incohérentes"
        )
        return
      }

      if (strTimeRest.isBefore(strTime)) {
        await setFieldError(
          "startTimeRest",
          "heures début activité et pause incohérentes"
        )
        await setFieldError(
          "startTime",
          "heures début activité et pause incohérentes"
        )
        return
      }

      if (ndTimeRest.isAfter(ndTime)) {
        await setFieldError(
          "endTimeRest",
          "heures fin activité et pause incohérentes"
        )
        await setFieldError("endTime", "heures fin activité et pause incohérentes")
        return
      } */
      if (endValRange.isBefore(startValRange)) {
        await setFieldError('validRangeEnd', 'plage de disponibilité incohérente')
        await setFieldError('validRangeStart', 'plage de disponibilité incohérente')
        return
      }
      const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
      let service = {
        ...rest,
        /*  startTimeRest,
        endTimeRest,
        startTime,
        endTime, */
        validRangeEnd,
        validRangeStart,

        id: editedItem.id,
        show_price,
        price,
        hiddenDays: rest.hiddenDays.map(dayNumberFunc),
        daysOfWeek: rest.daysOfWeek.map(dayNumberFunc),
        // category_id: category.id,
        company_id
      }
     /*  if (show_price) {
        service.price = price
      } */
      if (category_id) {
        const category = comp.oneCompany.categories.find(
          (cat) => cat.id.toString() === category_id.toString()
        )

        if (category && category.id) {
          service.category_id = category.id
        }
      }

      const res = await updateItem({
        variables: {
          service,
          company_id
        }
      })

      const { ok, errors } = res.data.updateService

      if (ok) {
        await setSubmitting(false)
        await setEditModal(false)
        await setEditedItem(null)
        await toast.success('Service mis-à-jour avec succès')
      } else {
        errors.forEach(async (error) => {
          if (error.path && error.path.toString() === 'global') {
            await setGlobalEditError(error.message)
            window.alert(error.message)
          } else {
            window.alert(error.message)
            await setFieldError(error.path, error.message)
            await setSubmitting(false)
          }
        })
      }
    } catch (error) {
      throw error
    }
  }

  const updateIntern = async (
    values,

    { setSubmitting, setFieldError }
  ) => {
    try {
      const {
        category_id,
        price,

        ...rest
      } = values

      const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
      let service = {
        ...rest,

        id: editedItem.id,

        company_id
      }
      if (price) {
        service.price = price
      }
      if (category_id) {
        const category = comp.oneCompany.categories.find(
          (cat) => cat.id.toString() === category_id.toString()
        )

        if (category && category.id) {
          service.category_id = category.id
        }
      }

      const res = await updateInternItem({
        variables: {
          ...service,
          company_id
        }
      })

      const { ok, errors } = res.data.updateServiceInternal

      if (ok) {
        await setSubmitting(false)
        await setEditModalIntern(false)
        await setEditedItem(null)
        await toast.success('Service mis-à-jour avec succès')
      } else {
        errors.forEach(async (error) => {
          if (error.path && error.path.toString() === 'global') {
            await setGlobalEditInternError(error.message)
            window.alert(error.message)
          } else {
            window.alert(error.message)
            await setFieldError(error.path, error.message)
            await setSubmitting(false)
          }
        })
      }
    } catch (error) {
      window.alert(error.message)
    }
  }

  const remove = async (
    values,

    { setSubmitting, setFieldError, resetForm }
  ) => {
    const { company_id } = values
    const variables = {
      company_id,
      service_id: deleteItem && deleteItem.id ? deleteItem.id : null
    }

    const res = await removeItem({
      variables
    })

    const { ok, errors } = res.data.deleteService

    if (ok) {
      await resetForm()
      await setSubmitting(false)
      await setDeleteModal(false)
      await setDeleteItem(null)
      await toast.success('Service supprimé avec succès')
    } else {
      errors.forEach(async (error) => {
        if (error.path && error.path.toString() === 'global') {
          await setGlobalDeleteError(error.message)
        } else {
          await setFieldError(error.path, error.message)
          await setSubmitting(false)
        }
      })
    }
  }
  const computeVariables = async (page) => {
    try {
      await setPage(page)

      const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
      let variables = { company_id, page }
      if (name) {
        variables.name = name
      }
      return variables
    } catch (error) {
      window.alert(error.message)
    }
  }
  const onPageChange = async (page) => {
    try {
      const variables = await computeVariables(page)
      await setLoading(true)

      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { findServices } = fetchMoreResult
          return Object.assign({}, prev, {
            findServices
          })
        }
      })

      await setLoading(false)
    } catch (error) {
      window.alert(error.message)
    }
  }
  const search = async () => {
    try {
      await setLoading(true)

      let variables = await computeVariables(1)

      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { findServices } = fetchMoreResult
          return Object.assign({}, prev, {
            findServices
          })
        }
      })
      await setLoading(false)
    } catch (error) {
      window.alert(error.message)
    }
  }
  const selectForUpdate = async (item) => {
    try {
      await setEditedItem(item)
      if (item.internal) {
        await setEditModalIntern(true)
      } else {
        await setEditModal(true)
      }
    } catch (error) {
      window.alert(error.message)
    }
  }

  const selectForRemove = async (item) => {
    try {
      await setDeleteItem(item)
      await setDeleteModal(true)
    } catch (error) {
      window.alert(error.message)
    }
  }
  const clearFilter = async () => {
    try {
      await setName('')
      await setPage(1)
      const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
      await refetch({ variables: { page, company_id } })
    } catch (error) {
      window.alert(error.message)
    }
  }

  const setUpRoles = async (roles) => {
    try {
      const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)

      const modify = roles
        .filter((r) => r.company_id && r.company_id == company_id)
        .some((r) => r.is_comp_super_admin || r.is_comp_admin)

      await setCanModify(modify)

      return true
    } catch (error) {
      throw error
    }
  }
  useEffect(() => {
    if (roles) {
      setUpRoles(roles)
        .then(() => {})
        .catch((err) => console.log('error', err))
    }
  }, [roles])
  const createInitialValues = {
    hiddenDays:
      settingData &&
      settingData.setting &&
      settingData.setting.setting &&
      settingData.setting.setting.hiddenDays
        ? settingData.setting.setting.hiddenDays.map(dayOfWeekFunc)
        : [],
    daysOfWeek:
      settingData &&
      settingData.setting &&
      settingData.setting.setting &&
      settingData.setting.setting.businessHours &&
      settingData.setting.setting.businessHours.daysOfWeek
        ? settingData.setting.setting.businessHours.daysOfWeek.map(dayOfWeekFunc)
        : [],

    name: '',
    description: '',
    file: null,
    category_id: '',

    validRangeStart: new Date(),
    validRangeEnd: new Date().setMonth(new Date().getMonth() + 3),

    show_price: false,
    sms_reminder: false,
    price: 0
  }

  const createInitialInternValues = {
    name: '',
    description: '',
    file: null,
    category_id: '',

    price: 0
  }
  
  const editInitialValues = {
    id: editedItem && editedItem.id ? editedItem.id : '',
    hiddenDays: editedItem ? editedItem.hiddenDays.map(dayOfWeekFunc) : [],
    daysOfWeek:
      editedItem && editedItem.businessHours && editedItem.businessHours.daysOfWeek
        ? editedItem.businessHours.daysOfWeek.map(dayOfWeekFunc)
        : [],

    name: editedItem && editedItem.name ? editedItem.name : '',
    description: editedItem && editedItem.description ? editedItem.description : '',
    file: null,
    category_id:
      editedItem && editedItem.category && editedItem.category.name
        ? editedItem.category.name
        : '',

    show_price: editedItem ? editedItem.show_price : false,
    sms_reminder: editedItem ? editedItem.sms_reminder : false,
    price: editedItem && editedItem.price ? editedItem.price : '',
    is_active: editedItem ? editedItem.is_active : false,

    validRangeStart:
      editedItem && editedItem.validRange && editedItem.validRange.start
        ? editedItem.validRange.start
        : new Date(),
    validRangeEnd:
      editedItem && editedItem.validRange && editedItem.validRange.end
        ? editedItem.validRange.end
        : new Date().setMonth(new Date().getMonth() + 3)
  }

  const editInitialValuesIntern = {
    id: editedItem && editedItem.id ? editedItem.id : '',

    name: editedItem && editedItem.name ? editedItem.name : '',
    description: editedItem && editedItem.description ? editedItem.description : '',
    file: null,
    category_id:
    editedItem && editedItem.category && editedItem.category.name
      ? editedItem.category.name
      : '',

    price: editedItem && editedItem.price ? editedItem.price : '',
    is_active: editedItem ? editedItem.is_active : false
  }

  if (loading1 || loading || loading3 || loading4 || loading2) {
    return <Loading />
  }
  return (
    <div className={classes.root}>
      <ServiceToolbar
        openModal={async () => await setCreateModal(true)}
        openModalIntern={async () => await setCreateInternModal(true)}
        setName={setName}
        name={name}
        canModify={canModify}
        search={search}
        clearFilter={clearFilter}
      />
      {canModify && (
        <Fragment>
          <Formik
            initialValues={createInitialValues}
            validationSchema={createSchema}
            onSubmit={create}
          >
            {(props) => (
              <ModalDialog
                maxWidth={'md'}
                open={createModal}
                cancel={async () => {
                  await props.handleReset()
                  await setCreateModal(false)
                }}
                title={'Création Service'}
              >
                <ServiceCreate
                  //   calendarProps={calendarProps}
                  canModify={canModify}
                  optionDays={optionDays}
                  categories={
                    comp && comp.oneCompany && comp.oneCompany.categories
                      ? comp.oneCompany.categories.map((cat) => cat.name)
                      : []
                  }
                  currency={
                    generalSetting &&
                    generalSetting.oneSettingAdmin &&
                    generalSetting.oneSettingAdmin.currency
                      ? generalSetting.oneSettingAdmin.currency
                      : ''
                  }
                  sms_price={
                    generalSetting &&
                    generalSetting.oneSettingAdmin &&
                    generalSetting.oneSettingAdmin.sms_price
                      ? generalSetting.oneSettingAdmin.sms_price
                      : ''
                  }
                  timezone={
                    generalSetting &&
                    generalSetting.oneSettingAdmin &&
                    generalSetting.oneSettingAdmin.timezone
                      ? generalSetting.oneSettingAdmin.timezone
                      : 'Africa/dakar'
                  }
                  globalError={globalCreateError}
                  {...props}
                />
              </ModalDialog>
            )}
          </Formik>

          <Formik
            initialValues={createInitialInternValues}
            validationSchema={createInternSchema}
            onSubmit={createIntern}
          >
            {(props) => (
              <ModalDialog
                maxWidth={'md'}
                open={createInternModal}
                cancel={async () => {
                  await props.handleReset()
                  await setCreateInternModal(false)
                }}
                title={'Création Service interne'}
              >
                <ServiceCreateIntern
                  //   calendarProps={calendarProps}
                  canModify={canModify}
                  optionDays={optionDays}
                  categories={
                    comp && comp.oneCompany && comp.oneCompany.categories
                      ? comp.oneCompany.categories.map((cat) => cat.name)
                      : []
                  }
                  currency={
                    generalSetting &&
                    generalSetting.oneSettingAdmin &&
                    generalSetting.oneSettingAdmin.currency
                      ? generalSetting.oneSettingAdmin.currency
                      : ''
                  }
                  sms_price={
                    generalSetting &&
                    generalSetting.oneSettingAdmin &&
                    generalSetting.oneSettingAdmin.sms_price
                      ? generalSetting.oneSettingAdmin.sms_price
                      : ''
                  }
                  timezone={
                    generalSetting &&
                    generalSetting.oneSettingAdmin &&
                    generalSetting.oneSettingAdmin.timezone
                      ? generalSetting.oneSettingAdmin.timezone
                      : 'Africa/dakar'
                  }
                  globalError={globalCreateInternError}
                  {...props}
                />
              </ModalDialog>
            )}
          </Formik>
        </Fragment>
      )}

      {editModal && (
        <Formik
          initialValues={editInitialValues}
          validationSchema={updateSchema}
          onSubmit={update}
        >
          {(props) => (
            <ModalDialog
              open={editModal}
              maxWidth={'md'}
              cancel={async () => {
                await setEditModal(false)
                await setEditedItem(null)

                await props.handleReset()
              }}
              title={'Edition Service'}
            >
              <ServiceEdit
                {...props}
                //  calendarProps={calendarProps}
                canModify={canModify}
                item={editedItem}
                globalError={globalEditError}
                optionDays={optionDays}
                categories={
                  comp && comp.oneCompany && comp.oneCompany.categories
                    ? comp.oneCompany.categories.map((cat) => cat.name)
                    : []
                }
                currency={
                  generalSetting &&
                  generalSetting.oneSettingAdmin &&
                  generalSetting.oneSettingAdmin.currency
                    ? generalSetting.oneSettingAdmin.currency
                    : ''
                }
                sms_price={
                  generalSetting &&
                  generalSetting.oneSettingAdmin &&
                  generalSetting.oneSettingAdmin.sms_price
                    ? generalSetting.oneSettingAdmin.sms_price
                    : ''
                }
                timezone={
                  generalSetting &&
                  generalSetting.oneSettingAdmin &&
                  generalSetting.oneSettingAdmin.timezone
                    ? generalSetting.oneSettingAdmin.timezone
                    : 'Africa/dakar'
                }
              />
            </ModalDialog>
          )}
        </Formik>
      )}

      {editModalIntern && (
        <Formik
          initialValues={editInitialValuesIntern}
          validationSchema={updateInternSchema}
          onSubmit={updateIntern}
        >
          {(props) => (
            <ModalDialog
              open={editModalIntern}
              maxWidth={'md'}
              cancel={async () => {
                await setEditModalIntern(false)
                await setEditedItem(null)

                await props.handleReset()
              }}
              title={'Edition Service interne'}
            >
              <ServiceEditIntern
                {...props}
                //  calendarProps={calendarProps}
                canModify={canModify}
                item={editedItem}
                globalError={globalEditInternError}
                optionDays={optionDays}
                categories={
                  comp && comp.oneCompany && comp.oneCompany.categories
                    ? comp.oneCompany.categories.map((cat) => cat.name)
                    : []
                }
                currency={
                  generalSetting &&
                  generalSetting.oneSettingAdmin &&
                  generalSetting.oneSettingAdmin.currency
                    ? generalSetting.oneSettingAdmin.currency
                    : ''
                }
                sms_price={
                  generalSetting &&
                  generalSetting.oneSettingAdmin &&
                  generalSetting.oneSettingAdmin.sms_price
                    ? generalSetting.oneSettingAdmin.sms_price
                    : ''
                }
                timezone={
                  generalSetting &&
                  generalSetting.oneSettingAdmin &&
                  generalSetting.oneSettingAdmin.timezone
                    ? generalSetting.oneSettingAdmin.timezone
                    : 'Africa/dakar'
                }
              />
            </ModalDialog>
          )}
        </Formik>
      )}
      {canModify && (
        <Formik
          initialValues={{
            company_id: localStorage.getItem(COMPANY_ID_STORAGE)
          }}
          validationSchema={removeSchema}
          onSubmit={remove}
        >
          {(props) => (
            <ConfirmDialog
              open={deleteModal}
              cancel={async () => {
                await setDeleteModal(false)
                await setDeleteItem(null)
                await props.handleReset()
              }}
              confirmAction={async () => await props.handleSubmit()}
              title={'Suppression Service'}
            >
              <ServiceDelete
                canModify={canModify}
                {...props}
                item={deleteItem}
                globalError={globalDeleteError}
              />
            </ConfirmDialog>
          )}
        </Formik>
      )}

      <div className={classes.content}>
        <ServiceList
          canModify={canModify}
          data={data && data.findServices ? data.findServices : null}
          onPageChange={onPageChange}
          selectForUpdate={selectForUpdate}
          selectForRemove={selectForRemove}
          currency={
            generalSetting &&
            generalSetting.oneSettingAdmin &&
            generalSetting.oneSettingAdmin.currency
              ? generalSetting.oneSettingAdmin.currency
              : ''
          }
        />
      </div>
    </div>
  )
}

export default ServiceView
