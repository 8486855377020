import gql from "graphql-tag"

export const removeBackEventAbsenceMutation = gql`
  mutation removeBackEventAbsence($id: String!, $company_id: String!) {
    removeBackEventAbsence(id: $id, company_id: $company_id) {
      ok
      id
      errors {
        path
        message
      }
    }
  }
`


export const createBackEventAbsenceMutation = gql`
  mutation createBackEventAbsence($backevent: BackEventInput!, $company_id: String!) {
    createBackEventAbsence(backevent: $backevent, company_id: $company_id) {
      ok
      backevent{
          id
          start
          end
          createdAt
      }
      errors {
        path
        message
      }
    }
  }
`
