import React from "react"
import { Link } from "react-router-dom"
import { Grid,Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Pagination from "@material-ui/lab/Pagination"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { FACTURE_PATH } from 'utils/static_constants'

import { Bill } from "./Bill"

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

export const BillList = ({
  docs,
  page,
  totalPages,
  currency,
  onPageChange,
  time_zone,
  selectForUpdate,
  goToFacture
}) => {
  const classes = useStyles()

  if (!docs || docs.length == 0) {
    return null
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <div className="content-bill-btn-consum">
            <Pagination
              count={totalPages}
              page={page}
              onChange={async (_event, value) => {
                if (value.toString() !== page.toString()) {
                  await onPageChange(value)
                }
              }}
            />
      
            <Link to={FACTURE_PATH}>
                <Button variant="contained" color="primary">Consommation Du Mois</Button>
            </Link>
        </div>
      </Grid>
      <Grid item xs={12} sm={12}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>  
                <TableCell>Etat facture</TableCell>
                <TableCell>{`Total en ${currency}`}</TableCell>
                <TableCell>Contact</TableCell>
                <TableCell>Abonnement</TableCell>
                <TableCell>sms</TableCell>
                <TableCell>Commission(rv)</TableCell>
                <TableCell>Mois</TableCell>
                <TableCell>afficher</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {docs.map((row) => (
                <Bill
                  key={row.id}
                  item={row}
                  time_zone={time_zone}
                  selectForUpdate={selectForUpdate}
                  goToFacture={goToFacture}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}
