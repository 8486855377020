import React from "react"
import { Link } from "react-router-dom"
import { makeStyles } from "@material-ui/styles"
import { TableCell, TableRow, Avatar,IconButton } from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import HistoryIcon from '@material-ui/icons/History'
import WorkerIcon from "@material-ui/icons/Accessibility"
import inconnu from "../../../assets/inconnu.jpg"
import { DisplayDate } from "components/DisplayDate"
import { colors } from "utils/constants"
import { CLIENT_HISTORY_PATH } from "utils/static_constants"

const useStyles = makeStyles((theme) => ({
  statsIcon: {
    cursor: "pointer",
    color: theme.palette.icon,
    marginRight: theme.spacing(1),
  },
}))

export const Event = (props) => {
  const { item, selectEvent, timezone } = props

  const classes = useStyles()
  const cellStyle = { fontSize: 12 }

  const computeStyle = () => {
    if (item) {
      if (item.canceled || (item.finished && !item.author_presence)) {
        return { margin: 10, backgroundColor: "lightgray" }
      }

      if (item.author_presence) {
        return { margin: 10, backgroundColor: "#ADFF2F" }
      }
    }

    return { margin: 10 }
  }
  return (
    <TableRow key={item.id} style={computeStyle()}>
      {/* {item.service.picture && (
        <TableCell>
          <Avatar size="medium" src={item.service.picture} alt={item.service.name} />
        </TableCell>
      )}

      {!item.service.picture && (
        <TableCell>
          <Avatar size="tiny" src={inconnu} alt={"iconnu"} />
        </TableCell>
      )} */}
      <TableCell style={cellStyle}>{item.service.name}</TableCell>
      <TableCell style={cellStyle}>{item.service.category.name}</TableCell>
      <TableCell style={cellStyle}>{item.author.fullname}</TableCell>
      <TableCell>{item.event_number}</TableCell>
      <TableCell style={{ fontSize: 10, color: colors.VIOLET }}>
        <DisplayDate date={item.start} timezone={timezone} />
      </TableCell>

      <TableCell>
        <WorkerIcon   className={classes.statsIcon}/>
        {item.workers ? item.workers.length : 0}
      </TableCell>

      <TableCell>
        <IconButton color="primary" component="span"
        onClick={async (e) => {
          try {
            e.preventDefault()
            await selectEvent(item.id)
          } catch (error) {
            throw error
          }
        }}
        >
            <EditIcon
              color="primary"
              disable={item && item.canceled}
              
            />
          </IconButton>
      </TableCell>
      <TableCell>
        <Link to={`${CLIENT_HISTORY_PATH}/${item.company_id}/${item.author.id}`}>
          <IconButton color="primary" component="span">
              <HistoryIcon />
          </IconButton>
        </Link>
      </TableCell>
    </TableRow>
  )
}
