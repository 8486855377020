import gql from 'graphql-tag'

//company_id:String!,page:Int!,limit:Int,register_number:String,lastname:String
export const compPatientsQuery = gql`
  query compPatients(
    $company_id: String!
    $register_number: String
    $lastname: String
    $phone: String
    $page: Int!
    $limit: Int
  ) {
    compPatients(
      company_id: $company_id
      register_number: $register_number
      page: $page
      limit: $limit
      lastname: $lastname
      phone: $phone
    ) {
      totalDocs
      hasPrevPage
      totalPages
      hasNextPage
      limit
      offset
      page
      prevPage
      nextPage
      docs {
        id
        gender
        name
        lastname
        phone
        register_number
        client_id

        createdAt
      }
    }
  }
`

export const findOnePatientQuery = gql`
  query findOnePatient($company_id: String!, $phone: String!) {
    findOnePatient(company_id: $company_id, phone: $phone) {
      ok

      errors {
        path
        message
      }
      patient {
        id

        client_id

        register_number
        blood_group
        name
        lastname
        phone

        gender

        updatedAt
        createdAt
      }
    }
  }
`
export const onePatientQuery = gql`
  query onePatient($company_id: String!, $id: String!) {
    onePatient(company_id: $company_id, id: $id) {
      id
      company_id
      client_id
      region
      address
      birth_year
      height
      weight
      register_number
      blood_group
      name
      lastname
      phone
      email
      gender

      is_old_smoking
      is_smoking

      has_vital_card
      vital_card_number

      has_health_insurance
      health_insurance {
        name
        validity_end
        percentage
      }

      has_current_desease
      current_deseases {
        name
        description
        year
      }

      has_old_desease
      old_deseases {
        name
        description
        year
      }

      has_allergy
      allergies {
        name
        description
        year
      }

      updatedAt
      createdAt
    }
  }
`
