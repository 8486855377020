import React from "react"
import { InputLabel, MenuItem, Select } from "@material-ui/core"

import { minutes } from "utils/func"

function DurationHourInput({ value, id, name, handleChange }) {
  return (
    <React.Fragment>
      <InputLabel id={id}>{name}</InputLabel>
      <Select labelId={id} id={id} value={[value]} onChange={handleChange} fullWidth>
        {minutes.map((h, i) => (
          <MenuItem key={i} value={h}>
            {h}
          </MenuItem>
        ))}
      </Select>
    </React.Fragment>
  )
}

export default DurationHourInput
