import React from 'react'

import { IconButton, TableCell, TableRow } from '@material-ui/core'
import RemoveIcon from '@material-ui/icons/Delete'

export const TableItem = ({ item, keys, remove }) => {
  return (
    <TableRow key={item.id}>
      {keys.map((key, index) => (
        <TableCell key={index}>{item[key]}</TableCell>
      ))}

      <TableCell>
        <IconButton color='' component='span'
        onClick={async (e) => {
          e.preventDefault()
          await remove(item)
        }}
        >
          <RemoveIcon
            color=''
          />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}
