import React, { useState } from "react"
import PropTypes from "prop-types"
import { makeStyles, useTheme } from "@material-ui/styles"
import { Typography, Grid, Button, Box } from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import InfoIcon from "@material-ui/icons/Info"
import LocationCityIcon from "@material-ui/icons/LocationCity"
import "../../../assets/scss/index.scss"
import { Logo } from "./Logo"
import { colors } from "../../../utils/constants"

const useStyles = makeStyles((theme) => ({
  root: {},
  imageContainer: {
    height: 64,
    width: 64,
    margin: "0 auto",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "5px",
    overflow: "hidden",
    display: "flex",
  },
  image: {
    width: "100%",
  },
  statsItem: {
    display: "flex",
  },
  imgCompany: {
    marginBottom: 12,
    width: "100%",
    maxWidth: "100%",
    height: 300,
  },

  showmore: {
    cursor: "pointer",
    color: "#333",
    fontSize: 16,
    "&:hover": {
      textDecoration: "underline",
    },
  },
}))

export const Company = (props) => {
  const {
    item,
    selectForUpdate,
    canModify,
    logo,
    stamp,
    company_id,
    picture,
    picture_id,
    setFile,
    saveFile,
    removeFile,
    logo_id,
  } = props
  const classes = useStyles()
  const theme = useTheme()
  const [showMinicontent1, setshowMinicontent1] = useState(false)
  const [showMinicontent2, setshowMinicontent2] = useState(false)

  if (!item || !item.id) {
    return null
  }
  return (
    <>
      {/* <Grid xs={12}>
        {item.picture && (
          <img
            className={classes.imgCompany}
            src={item.picture}
            alt={item.picture}
          />
        )}
      </Grid> */}
      <Box padding={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Box className={`miniContent miniContentHeightMin`}>
              <div className={"miniContentHeader"}>
                <InfoIcon className={"iconminiContentHeader"} />
                <h3>Informations Générales</h3>
                {/* <Typography variant="h3">Informations Générales</Typography> */}
              </div>

              <Box className={"miniContentMain"}>
                <Typography style={{ margin: "0px auto" }}>
                  <a
                    href={`https://doctosen.com/doctosen/${item.slug}`}
                    target="_blank"
                  >
                    Consulter votre profile DOCTOSEN sur le web
                  </a>
                </Typography>
              </Box>
              <Box className={"miniContentMain"}>
                <Typography style={{ flex: 2 }} variant="body2">
                  Nom Entreprise
                </Typography>
                <p className={"infoText"}>{item.name ? item.name : ""}</p>
              </Box>

              <Box className={"miniContentMain"}>
                <Typography style={{ flex: 2 }} variant="body2">
                  Téléphone
                </Typography>
                <p className={"infoText"}>{item.phone ? item.phone : ""}</p>
              </Box>

              {showMinicontent1 && (
                <>
                  <Box className={"miniContentMain"}>
                    <Typography style={{ flex: 2 }} variant="body2">
                      Reference
                    </Typography>
                    <p className={"infoText"}>
                      {item.reference ? item.reference : ""}
                    </p>
                  </Box>

                  <Box className={"miniContentMain"}>
                    <Typography style={{ flex: 2 }} variant="body2">
                      Secteurs d'activités
                    </Typography>
                    <p className={"infoText"}>
                      {item.categories && item.categories.length > 0
                        ? item.categories.map((el) => el.name + " ")
                        : ""}
                    </p>
                  </Box>

                  <Box style={{ paddingTop: 30 }}>
                    <Typography style={{ flex: 2 }} variant="body2">
                      Description
                    </Typography>
                    <Typography style={{ paddingTop: 10 }}>
                      {item.description && item.description}
                      {!item.description && "Aucune description"}
                    </Typography>
                  </Box>

                  <Box style={{ paddingTop: 30 }}>
                    <Typography style={{ flex: 2 }} variant="body2">
                      Page facebook
                    </Typography>
                    <Typography style={{ paddingTop: 10 }}>
                      {item.facebook && (
                        <a href={item.facebook} target="_blank">
                          {item.facebook}
                        </a>
                      )}
                      {!item.facebook && "Aucune page facebook"}
                    </Typography>
                  </Box>

                  <Box style={{ paddingTop: 30 }}>
                    <Typography style={{ flex: 2 }} variant="body2">
                      Page instagram
                    </Typography>
                    <Typography style={{ paddingTop: 10 }}>
                      {item.instagram && (
                        <a href={item.instagram} target="_blank">
                          {item.instagram}
                        </a>
                      )}
                      {!item.instagram && "Aucune page instagram"}
                    </Typography>
                  </Box>

                  <Box style={{ paddingTop: 30 }}>
                    <Typography style={{ flex: 2 }} variant="body2">
                      Page twitter
                    </Typography>
                    <Typography style={{ paddingTop: 10 }}>
                      {item.twitter && (
                        <a href={item.twitter} target="_blank">
                          {item.twitter}
                        </a>
                      )}
                      {!item.twitter && "Aucune page twitter"}
                    </Typography>
                  </Box>

                  <Box style={{ paddingTop: 30 }}>
                    <Typography style={{ flex: 2 }} variant="body2">
                      Page linkedin
                    </Typography>
                    <Typography style={{ paddingTop: 10 }}>
                      {item.linkedin && (
                        <a href={item.linkedin} target="_blank">
                          {item.linkedin}
                        </a>
                      )}
                      {!item.linkedin && "Aucune page linkedin"}
                    </Typography>
                  </Box>

                  <Box style={{ paddingTop: 30 }}>
                    <Typography style={{ flex: 2 }} variant="body2">
                      Votre site website
                    </Typography>
                    <Typography style={{ paddingTop: 10 }}>
                      {item.website && (
                        <a href={item.website} target="_blank">
                          {item.website}
                        </a>
                      )}
                      {!item.website && "Aucune page website"}
                    </Typography>
                  </Box>
                </>
              )}

              {!showMinicontent1 && (
                <Box
                  display="flex"
                  justifyContent="center"
                  marginTop={2}
                  onClick={() => setshowMinicontent1(!showMinicontent1)}
                >
                  <Typography className={classes.showmore}>Afficher plus</Typography>
                </Box>
              )}
              {showMinicontent1 && (
                <Box
                  display="flex"
                  justifyContent="center"
                  marginTop={2}
                  onClick={() => setshowMinicontent1(!showMinicontent1)}
                >
                  <Typography className={classes.showmore}>
                    Afficher moins
                  </Typography>
                </Box>
              )}

              {/*      facebook,
          twitter,
          instagram,
          linkedin,
          website */}
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box className={`miniContent miniContentHeightMin`}>
              <div className={"miniContentHeader"}>
                <LocationCityIcon className={"iconminiContentHeader"} />
                {/*  <Typography variant="h3">Adresse</Typography> */}
                <h3>Adresse</h3>
              </div>
              <Box className={"miniContentMain"}>
                <Typography style={{ flex: 2 }} variant="body2">
                  Région
                </Typography>
                <p className={"infoText"}>
                  {item.region && item.region}
                  {!item.region && "Aucune Region"}
                </p>
              </Box>
              <Box className={"miniContentMain"}>
                <Typography style={{ flex: 2 }} variant="body2">
                  Ville/Quartier
                </Typography>
                <p className={"infoText"}>
                  {item.city && item.city}
                  {!item.city && "Aucune"}
                </p>
              </Box>
              <Box className={"miniContentMain"}>
                <Typography style={{ flex: 2 }} variant="body2">
                  Complément adresse
                </Typography>
                <p className={"infoText"}>
                  {item.description_address && item.description_address}
                  {!item.description_address && "Aucun complément"}
                </p>
              </Box>
              {showMinicontent2 && (
                <>
                  <Box className={"miniContentMain"}>
                    <Typography style={{ flex: 2 }} variant="body2">
                      Latitude
                    </Typography>
                    <p className={"infoText"}>
                      {item.lat && item.lat}
                      {!item.lat && "aucune géolocalisation"}
                    </p>
                  </Box>

                  <Box className="miniContentMain miniContentMainLast">
                    <Typography style={{ flex: 2 }} variant="body2">
                      Longitude
                    </Typography>
                    <p className={"infoText"}>
                      {item.lng && item.lng}
                      {!item.lng && "aucune géolocalisation"}
                    </p>
                  </Box>

                  {item.lng && item.lat && (
                    <Box className="miniContentMain miniContentMainLast">
                      <Typography style={{ flex: 2 }} variant="body2">
                        <a
                          className="btn-generale"
                          target="_blank"
                          href={`https://www.google.com/maps/?q=${item.lat},${item.lng}`}
                        >
                          position sur google map
                        </a>
                      </Typography>
                    </Box>
                  )}

                  {canModify && (
                    <Grid className={"contentBtn"} item xs={12} sm={12}>
                      <Button
                        onClick={async (e) => {
                          e.preventDefault()
                          await selectForUpdate(item)
                        }}
                        size=""
                        variant="contained"
                        color="primary"
                        className={"buttonUpdate"}
                        startIcon={<EditIcon className={"statsIcon"} />}
                      >
                        Modifier
                      </Button>
                    </Grid>
                  )}
                </>
              )}

              {!showMinicontent2 && (
                <Box
                  display="flex"
                  justifyContent="center"
                  marginTop={2}
                  onClick={() => setshowMinicontent2(!showMinicontent2)}
                >
                  <Typography className={classes.showmore}>Afficher plus</Typography>
                </Box>
              )}
              {showMinicontent2 && (
                <Box
                  display="flex"
                  justifyContent="center"
                  marginTop={2}
                  onClick={() => setshowMinicontent2(!showMinicontent2)}
                >
                  <Typography className={classes.showmore}>
                    Afficher moins
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Logo
              saveFile={saveFile}
              removeFile={removeFile}
              logo={logo}
              stamp={stamp}
              company_id={company_id}
              logo_id={logo_id}
              picture={picture}
              picture_id={picture_id}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

Company.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
}
