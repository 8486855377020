import React, { Fragment, useState } from 'react'
import { Grid, Button, TextField, Avatar, Box } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import moment from 'moment-timezone'
import Loading from 'components/Loading'
import inconnu from '../../../assets/inconnu.jpg'
import { MessageField } from 'components/MessageField'
import { CLIENT_HISTORY_PATH, NEW_PATIENT_CLIENT_PATH } from 'utils/static_constants'
import { Link } from 'react-router-dom'
import { renderDate } from 'utils/func'
import { Accordion } from 'components/Accordion'
import { Autocomplete } from '@material-ui/lab'
import { colors } from 'utils/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: '100%'
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  contentActionrdv: {
    width: '100%'
  },

  buttonActionrdv: {
    marginTop: 10
  },
  labelregroupe: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0px'
    }
  }
}))
export const EventEdit = ({
  event,
  globalError,
  remove,
  timezone,
  loading,
  users,
  selectWorkers,
  valueWorkers,
  updateWorkers,
  signal,
  globalSignalError,
  workers,
  canModify
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const [expanded1, setExpanded1] = useState(true)
  const [expanded2, setExpanded2] = useState(false)
  const [motif_cancel, setMotifCancel] = useState('')

  if (!event) {
    return null
  }
  if (loading) {
    return <Loading />
  }
  const currentDate = moment()
  const startDate = moment(new Date(parseInt(event.start))).tz(timezone)
  const endDate = moment(new Date(parseInt(event.end))).tz(timezone)

  const shouldSignal =
    event && !event.finished && !event.canceled && currentDate.isAfter(startDate)

  const canCancel =
    event && !event.finished && !event.canceled && currentDate.isBefore(startDate)
  const id = event && event.id ? event.id : ''
  const gender = event.author.gender == 'male' ? 'Monsieur' : 'Madame'
  return (
    <form
      className={classes.root}
      noValidate
      autoComplete='off'
      // onSubmit={handleSubmit}
    >
      {event && event.patient_id && (
        <Link to={`${CLIENT_HISTORY_PATH}/${event.company_id}/${event.patient_id}`}>
          <Button color='primary' variant='outlined'>
            VOIR HISTORIQUE
          </Button>
        </Link>
      )}

      {event && !event.patient_id && (
        <Link to={NEW_PATIENT_CLIENT_PATH}>
          <Button color='primary' variant='outlined'>
            Créer la fiche patient
          </Button>
        </Link>
      )}

      {globalSignalError && (
        <MessageField message={globalSignalError} severity={'error'} />
      )}
      {globalError && <MessageField message={globalError} severity={'error'} />}

      {event.finished && event.author_presence && (
        <MessageField
          message={`Rendez-vous accompli avec succès`}
          severity={'success'}
        />
      )}

      {event.finished && !event.author_presence && (
        <MessageField message={`Client absent`} severity={'error'} />
      )}

      {event.created_by_company && (
        <>
          <br />
          <MessageField
            message={`Rendez-vous crée par ${
              event.created_by_company_author_name
            } le ${renderDate(event.createdAt)}`}
            severity={'info'}
          />
        </>
      )}

      {event.canceled_by_company && (
        <MessageField
          message={`Rendez-vous annulé par ${
            event.canceled_by_company_author_name
          } le ${renderDate(event.updatedAt)} pour motif: ${event.motif_cancel}`}
          severity={'error'}
        />
      )}

      {event.canceled_by_author && (
        <MessageField
          message={`Rendez-vous annulé par le client le ${renderDate(
            event.updatedAt
          )} pour motif: ${event.motif_cancel}`}
          severity={'error'}
        />
      )}
      <br />
      <br />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label={'Service'}
            value={event.service.name}
            readOnly={true}
            fullWidth
          />
        </Grid>

        {/*   <Grid item xs={12} sm={6}>
            {event.service && event.service.picture && (
              <Avatar src={event.service.picture} alt={event.service.name} />
            )}

            {!event.service ||
              (!event.service.picture && (
                <Avatar size="tiny" src={inconnu} alt={"iconnu"} />
              ))}
          </Grid> */}
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label={'Client'}
            value={gender + ' ' + event.author.name + ' ' + event.author.lastname}
            readOnly={true}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            label='Date rendez-vous'
            fullWidth
            readOnly={true}
            value={renderDate(event.start, timezone)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            readOnly={true}
            label='N° rdv'
            value={event.event_number}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label='Fin rendez-vous'
            fullWidth
            readOnly={true}
            value={renderDate(event.end, timezone)}
          />
        </Grid>

        {workers.length > 0 && (
          <Grid item xs={12}>
            <div className='label-regroup'>
              <h4 style={{ color: colors.VIOLET, textAlign: 'center' }}>
                Responsable(s)
              </h4>
              <div className={`label-regroup-eventedit ${classes.labelregroupe}`}>
                <Grid item container xs={12}>
                  {workers.map((v, i) => (
                    <Grid lg={5} md={5} sm={6} xs={12}>
                      <div className='label-responsible' key={i}>
                        {v}
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </div>
          </Grid>
        )}
      </Grid>
      {canCancel && canModify && (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Accordion
              id={'search-event-form-affectation'}
              title={'Responsable(s) Rendez-vous'}
              defaultAccordion={true}
              expanded={expanded1}
              setExpanded={setExpanded1}
            >
              <Box className={classes.contentActionrdv}>
                {/*  <SelectField
                    required={false}
                    multiple={true}
                    handleChange={selectWorkers}
                    name="workers"
                    label={"Affectation(s)"}
                    fullWidth={true}
                    value={valueWorkers}
                    theme={theme}
                    dataSource={users}
                  /> */}
                <Autocomplete
                  multiple={true}
                  noOptionsText={'Aucun résultat'}
                  id='workers'
                  name='workers'
                  label={'Personnel'}
                  defaultValue={valueWorkers}
                  options={users}
                  onChange={async (e, newVal) => {
                    e.preventDefault()

                    await selectWorkers(newVal)
                  }}
                  getOptionLabel={(option) => option}
                  //   style={{ margin: 100 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Personnel entreprise'
                      variant='outlined'
                      required
                      fullWidth
                    />
                  )}
                />

                <Button
                  color='primary'
                  disabled={event && event.canceled}
                  onClick={async (e) => {
                    e.preventDefault()
                    await updateWorkers()
                  }}
                  className={classes.buttonActionrdv}
                  //    type="submit"
                  // onClick={handleSubmit}
                  variant='contained'
                >
                  Affectez
                </Button>
              </Box>
            </Accordion>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Accordion
              id={'search-event-form-cancel'}
              title={'Annulation rendez-vous'}
              defaultAccordion={true}
              expanded={expanded2}
              setExpanded={setExpanded2}
            >
              <Box className={classes.contentActionrdv}>
                <TextField
                  id='motif_cancel'
                  name='motif_cancel'
                  rows={2}
                  multiline={true}
                  value={motif_cancel}
                  required
                  label={'Motif annulation rendez-vous'}
                  onChange={async (e) => {
                    await setMotifCancel(e.target.value)
                  }}
                  fullWidth
                />
                <Button
                  color='secondary'
                  //disabled={!formState.isValid}
                  onClick={async (e) => {
                    e.preventDefault()
                    await remove(event.id, motif_cancel)
                  }}
                  disabled={event && event.canceled}
                  //    type="submit"
                  // onClick={handleSubmit}
                  variant='contained'
                  className={classes.buttonActionrdv}
                >
                  Annuler le rendez-vous
                </Button>
              </Box>
            </Accordion>
          </Grid>
        </Grid>
      )}
      {shouldSignal && (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Button
              style={{ backgroundColor: 'red', color: 'white' }}
              disabled={!shouldSignal}
              onClick={async (e) => {
                e.preventDefault()
                await signal(false, id)
              }}
              fullWidth
              size='large'
              //    type="submit"
              // onClick={handleSubmit}
              variant='contained'
            >
              Signaler absence client
            </Button>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Button
              color='secondary'
              disabled={!shouldSignal}
              onClick={async (e) => {
                e.preventDefault()
                await signal(true, id)
              }}
              //    type="submit"
              // onClick={handleSubmit}
              variant='contained'
            >
              Confirmer la présence du client
            </Button>
          </Grid>
        </Grid>
      )}
    </form>
  )
}
