import React from "react"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import momentTimezonePlugin from "@fullcalendar/moment-timezone"
import timeGridPlugin from "@fullcalendar/timegrid"
import frLocale from "@fullcalendar/core/locales/fr"
import moment from "moment-timezone"
import "moment/locale/fr"
import "@fullcalendar/core/main.css"
import "@fullcalendar/daygrid/main.css"
import "@fullcalendar/timegrid/main.css"

export const Calendar = ({
  businessHours,

  hiddenDays,
  slotDuration,
  timezone,
  rest,
  no_rest,
  validRange,
}) => {
  let otherprops = {}

  if (!no_rest) {
    otherprops.events = [rest]
  }
  return (
    <FullCalendar
      eventBackgroundColor={"gray"}
      select={async ({ startStr }) => {
        if (moment().diff(startStr) <= 0) {
          return
        }
      }}
      //  defaultDate={new Date('2017-03-22')}

      //  events={no_rest ? [] : [rest]}
      //  firstDay={1}

      {...otherprops}
      defaultView={"agenda"}
      validRange={validRange}
      hiddenDays={hiddenDays}
      slotDuration={slotDuration}
      nowIndicator={true}
      businessHours={businessHours}
      timeZone={timezone}
      slotLabelInterval={slotDuration}
      locale={frLocale}
      defaultView="timeGridWeek"
      plugins={[
        dayGridPlugin,
        interactionPlugin,
        momentTimezonePlugin,
        timeGridPlugin,
      ]}
      selectConstraint={"businessHours"}
      selectAllow={async (selectInfo) => {
        return moment().diff(selectInfo.start) <= 0
      }}
    />
  )
}
