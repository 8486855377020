export const TOKEN_NAME = "appointer0000111SenRvdiarama"
export const USER_STORAGE = "appointerhoochyketch"
export const LOGIN_PATH = "/login"
export const CURRENCY = "cfa"
export const TIME_ZONE = "Africa/Dakar"
export const COMPANY_ID_STORAGE = "appointeritwillid"
export const CONDITIONS_PATH = "/calendar"
export const APP_MOBILE_PATH = "/app-mobile"
export const TEAM_PATH = "/calendar/teams"
export const SETTING_PATH = "/calendar/settings"
export const EVENT_PATH = "/calendar/events"
export const EVENT_ONE_PATH = "/calendar/events_one"
export const EVENT_WORKER_PATH = "/calendar/events_worker"
export const CLIENT_SIGNAL_PATH = "/calendar/client_signals"

export const COMP_HISTORY_PATH = "/calendar/comp_histories"
export const MESSAGE_PATH = "/calendar/messages"
export const PROFILE_PATH = "/calendar/profile"
export const COMPANY_PATH = "/calendar/company"
export const CALENDAR_PATH = "/calendar/calendar-setting"
export const SERVICE_PATH = "/calendar/services"

export const DASHBOARD_PATH = "/calendar/dashboard"
export const NOTIF_PATH = "/calendar/notifications"
export const NEW_EVENT_COMPANY_PATH = "/calendar/newevent"
export const ABSENCE_PATH = "/calendar/absences"
export const COMPANY_CLIENT_PATH = "/calendar/company_clients"
export const PATIENT_CLIENT_PATH = "/calendar/patients"
export const NEW_PATIENT_CLIENT_PATH = "/calendar/patients/new"
export const CLIENT_NOTICE_PATH = "/calendar/avis_clients"
export const FACTURE_PATH = "/calendar/facture-ongoing"
export const PHONE_COUNTRIES = ["sn"]
export const PHONE_REGION = "africa"
export const PHONE_DEFAULT_COUNTRY = "sn"
export const ONE_COMPANY_FACTURE_PATH = "/calendar/facture"
export const FACTURE_LIST_PATH = "/calendar/facture-list"
export const CLIENT_HISTORY_PATH="/calendar/company_clients"

export const ADMIN_ROLE = "ADMINISTRATEUR"
export const SUPER_ADMIN_ROLE = "SUPER_ADMINISTRATEUR"
export const MANAGER_ROLE = "MANAGER"
export const WORKER_ROLE = "EMPLOYE"


export const CONFIRM_PHONE_PATH = "/confirm-phone";
export const CONFIRM_PASSWORD_LOST_PATH = "/confirm-password-lost";
export const PASSWORD_FORGOT_PATH = "/password-forgot";
