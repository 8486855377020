import React from "react"

import { Grid, Typography, Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import { useQuery } from "react-apollo"
import { oneSiteSettingAdminQuery } from "graphql/query/site_setting"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "9px 15px",
  },
}))

const appleStore =
  "https://www.westernunion.com/staticassets/static/5d6ed7c87427ce45173b3d7e283d8f45/AppStore-FR.svg"

export default function MobileView() {
  const classes = useStyles()

  const { data: settingSite } = useQuery(oneSiteSettingAdminQuery)

  const client_appletore_link =
    settingSite &&
    settingSite.oneSiteSettingAdmin &&
    settingSite.oneSiteSettingAdmin.client_appletore_link
      ? settingSite.oneSiteSettingAdmin.client_appletore_link
      : ""
  const client_playstore_link =
    settingSite &&
    settingSite.oneSiteSettingAdmin &&
    settingSite.oneSiteSettingAdmin.client_playstore_link
      ? settingSite.oneSiteSettingAdmin.client_playstore_link
      : ""
  const saler_appletore_link =
    settingSite &&
    settingSite.oneSiteSettingAdmin &&
    settingSite.oneSiteSettingAdmin.saler_appletore_link
      ? settingSite.oneSiteSettingAdmin.saler_appletore_link
      : ""
  const saler_playstore_link =
    settingSite &&
    settingSite.oneSiteSettingAdmin &&
    settingSite.oneSiteSettingAdmin.saler_playstore_link
      ? settingSite.oneSiteSettingAdmin.saler_playstore_link
      : ""

  const worker_appletore_link =
    settingSite &&
    settingSite.oneSiteSettingAdmin &&
    settingSite.oneSiteSettingAdmin.worker_appletore_link
      ? settingSite.oneSiteSettingAdmin.worker_appletore_link
      : ""
  const worker_playstore_link =
    settingSite &&
    settingSite.oneSiteSettingAdmin &&
    settingSite.oneSiteSettingAdmin.worker_playstore_link
      ? settingSite.oneSiteSettingAdmin.worker_playstore_link
      : ""

  const youtube_link_comp =
    settingSite &&
    settingSite.oneSiteSettingAdmin &&
    settingSite.oneSiteSettingAdmin.youtube_link_comp
      ? settingSite.oneSiteSettingAdmin.youtube_link_comp
      : ""

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <div className={"miniContent miniContent-two"}>
            <div className="dow-miniContentHeader">
              <h3 className="title-dow">Doctosen.pro</h3>
            </div>
            <p className="txt-dow-app">
              Tout ce que vous faites sur ce même site web est accessible depuis
              l'application mobile Doctosenpro, que ce soit sur Android ou sur iOS.
            </p>
            <div className="dow-body">
              <div className="dow-body-link">
                <a href={saler_playstore_link} target="_blank">
                  <img src="/images/google-play.png" alt="google-play" />
                </a>
                {/* <a href={saler_appletore_link} target="_blank">
                  <img src={appleStore} alt="apple-store" />
                </a> */}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <div className={"miniContent miniContent-two"}>
            <div className="dow-miniContentHeader">
              <h3 className="title-dow">DoctosenWork</h3>
            </div>
            <p className="txt-dow-app">
              Il se peut que vous ayez une grande équipe, et que vos personnels
              soignants ne s'occupent que des rendez-vous qui leur sont affectés. Ces
              derniers peuvent télécharger l'application mobile DoctosenWork sur
              Android et iOS, où ils ne verront que les rendez-vous qui leur sont
              affectés.
            </p>
            <div className="dow-body">
              <div className="dow-body-link">
                <a href={worker_playstore_link} target="_blank">
                  <img src="/images/google-play.png" alt="google-play" />
                </a>
                {/* <a href={worker_appletore_link} target="_blank">
                  <img src={appleStore} alt="apple-store" />
                </a> */}
              </div>
            </div>
          </div>
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <div className={"miniContent miniContent-two"}>
            <div className="dow-miniContentHeader">
              <h3 className="title-dow">Doctosen</h3>
            </div>
            <p className="txt-dow-app">
              En créeant vos services et les associant à un paramétrage calendaire,
              vous donnez la possibilité à des milliers d'internautes sénégalaises et
              sénégalais de prendre rendez-vous médicaux depuis l'application mobile
              sénégalaise Doctosen
            </p>
            <div className="dow-body">
              <div className="dow-body-link">
                <a href={client_playstore_link} target="_blank">
                  <img src="/images/google-play.png" alt="google-play" />
                </a>
                {/* <a href={client_appletore_link} target="_blank">
                  <img src={appleStore} alt="apple-store" />
                </a> */}
              </div>
            </div>
          </div>
        </Grid>
        {/* <Grid item lg={6} md={6} sm={12} xs={12}>
          <div className={"miniContent"}>
            <div className="dow-miniContentHeader">
              <Typography className="title-dow" variant="h3">
                DoctosenPro en quelque mots ...
              </Typography>
            </div>
            <div className='dow-content-video'>
                <iframe
                  width='560'
                  height='315'
                  src={`https://www.youtube.com/embed/${youtube_link_comp}`}
                  title='YouTube video player'
                  frameborder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowfullscreen
                ></iframe>
              </div>  
          </div>
        </Grid> */}
      </Grid>
    </div>
  )
}
