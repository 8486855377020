import React, { Fragment, useCallback, useState } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import Pagination from '@material-ui/lab/Pagination'
import { Accordion } from 'components/Accordion'
import Alert from '@material-ui/lab/Alert'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Grid, Button, IconButton } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { Typography, Box } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import SearchIcon from '@material-ui/icons/Search'
import { findCompPatientHistoryQuery } from 'graphql/query/patient_history'
import { currentUserQuery, findUserByIdQuery } from 'graphql/query/user'
import AddIcon from '@material-ui/icons/Add'
import Loading from 'components/Loading'
import { toast } from 'react-toastify'
import { addPatientHistoryMutation } from 'graphql/mutation/patient_history'
import { ModalDialog } from 'components/ModalDialog'
import { oneCompanyQuery } from 'graphql/query/company'
import { HistoryCreate } from './components/HistoryCreate'
import NotificationIcon from '@material-ui/icons/NotificationImportant'
import { readNumber } from 'utils/readnumber'
import { UserInfo } from './components/UserInfo'

import { BillCreate } from './components/BillCreate'

import moment from 'moment-timezone'
import { allServicesQuery } from 'graphql/query/service'
import { oneSettingAdminQuery } from 'graphql/query/setting_admin'
import { OrdonnanceCreate } from './components/OrdonnanceCreate'
import { onePatientQuery } from 'graphql/query/patient'
import PatientEdit from './components/PatientEdit'
moment.locale('fr')
moment.tz('Africa/Dakar')

const createSchema = Yup.object().shape({
  category_id: Yup.string().required('champ requis'),
  patient_id: Yup.string().required('champ requis'),
  company_id: Yup.string().required('champ requis'),

  description: Yup.string()
    .trim()
    .min(10, 'minimum 10 caractères')
    .required('champ requis')
    .max(500, 'maximum 500 caractères')
})

const ClientHistoryView = ({ match: { params } }) => {
  const { patient_id, company_id } = params

  const [createModal, setCreateModal] = useState(false)
  const [expanded1, setExpanded1] = useState(false)
  const [expanded2, setExpanded2] = useState(false)
  const [expanded3, setExpanded3] = useState(false)
  const [expanded4, setExpanded4] = useState(false)

  const [loading, setLoading] = useState(false)

  const [from_bill, setFromBill] = useState(undefined)
  const [to_bill, setToBill] = useState(undefined)

  const [quantity, setQuantity] = useState(1)
  const [description, setDescription] = useState(undefined)
  const [tax, setTax] = useState(undefined)
  const [price, setPrice] = useState(undefined)
  const [logo, setLogo] = useState(undefined)
  const [items, setItems] = useState([])
  const [item, setItem] = useState(undefined)
  // end invoice

  // start medical prescription
  const [quantityM, setQuantityM] = useState(1)
  const [medicament, setMedicament] = useState(undefined)
  const [descriptionM, setDescriptionM] = useState(undefined)
  const [itemsM, setItemsM] = useState([])
  const [itemM, setItemM] = useState(undefined)
  const [logo2, setLogo2] = useState(undefined)
  // end medical prescription
  const [globalCreateError, setGlobalCreateError] = useState('')

  const [, updateState] = useState()
  const forceUpdate = useCallback(() => updateState({}), [])
  const [page, setPage] = useState(1)

  const { loading: loading4, data: userData } = useQuery(onePatientQuery, {
    skip: patient_id ? false : true,
    variables: { company_id, id: patient_id }
  })
  const { data: currentUserData } = useQuery(currentUserQuery)
  const [createItem] = useMutation(addPatientHistoryMutation)

  const { loading: loading5, data: comp } = useQuery(oneCompanyQuery, {
    variables: { company_id }
  })
  const { data: generalSetting } = useQuery(oneSettingAdminQuery)
  const { loading: loading6, data: serviceData } = useQuery(allServicesQuery, {
    variables: { company_id }
  })

  const { loading: loading3, data: historyData, fetchMore, refetch } = useQuery(
    findCompPatientHistoryQuery,
    {
      // fetchPolicy: "network-only",
      variables: { page, patient_id, company_id }
    }
  )
  const currency =
    generalSetting &&
    generalSetting.oneSettingAdmin &&
    generalSetting.oneSettingAdmin.currency
      ? generalSetting.oneSettingAdmin.currency
      : 'CFA'

  const timezone =
    generalSetting &&
    generalSetting.oneSettingAdmin &&
    generalSetting.oneSettingAdmin.time_zone
      ? generalSetting.oneSettingAdmin.time_zone
      : 'Africa/Dakar'
  const computeVariables = async (page) => {
    await setPage(page)

    let variables = { page, company_id, patient_id }
    try {
      if (from_bill) {
        variables.start = from_bill
      }

      if (to_bill) {
        variables.end = to_bill
      }

      return variables
    } catch (error) {
      window.alert(error.message)
    }
  }

  const removeItem = async (i) => {
    try {
      if (window.confirm('êtes vous sûr(e) de vouloir supprimer ?')) {
        let newItems = items

        await newItems.splice(i, 1)

        await setItems(newItems)

        await forceUpdate()
      }
    } catch (error) {
      throw error
    }
  }

  const removeItemM = async (i) => {
    try {
      if (window.confirm('êtes vous sûr(e) de vouloir supprimer ?')) {
        let newItems = itemsM

        await newItems.splice(i, 1)

        await setItemsM(newItems)

        await forceUpdate()
      }
    } catch (error) {
      throw error
    }
  }
  const onPageChange = async (page) => {
    try {
      let variables = await computeVariables(page)
      await fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { findCompPatientHistory } = fetchMoreResult
          return Object.assign({}, prev, {
            findCompPatientHistory
          })
        }
      })
    } catch (error) {
      throw error
    }
  }
  const clearFilter = async () => {
    try {
      await setPage(1)
      await setFromBill(undefined)
      await setToBill(undefined)

      await refetch({ variables: { page: 1, company_id, patient_id } })
    } catch (error) {
      window.alert(error.message)
    }
  }

  const search = async () => {
    try {
      const variables = await computeVariables(1)

      await setLoading(true)

      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { findCompPatientHistory } = fetchMoreResult

          return Object.assign({}, prev, {
            findCompPatientHistory
          })
        }
      })
    } catch (error) {
      window.alert(error.message)
    }
  }
  const create = async (
    values,

    { setSubmitting, setFieldError }
  ) => {
    try {
      const category = comp.oneCompany.categories.find(
        (cat) => values.category_id === cat.name
      )
      const variables = {
        ...values,
        category_id: category.id
      }

      const res = await createItem({
        variables
      })
      const { ok, errors } = res.data.addPatientHistory

      if (ok) {
        await setSubmitting(false)
        await toast.success(`Note ajoutée `)
        await setCreateModal(false)

        await clearFilter()
      } else {
        await setSubmitting(false)
        errors.forEach(async (error) => {
          if (error.path && error.path.toString() === 'global') {
            await setGlobalCreateError(error.message)
          } else {
            await setFieldError(error.path, error.message)
            await setSubmitting(false)
          }
        })
      }
    } catch (error) {
      await setSubmitting(false)
      window.alert(error.message)
    }
  }

 
  const covered =
    userData && userData.onePatient
      ? userData.onePatient.has_health_insurance
      : false

  const percentage_insurance =
    userData &&
    userData.onePatient &&
    userData.onePatient.health_insurance &&
    userData.onePatient.health_insurance.percentage
      ? userData.onePatient.health_insurance.percentage
      : false
  const insurance_validity_end =
    userData &&
    userData.onePatient &&
    userData.onePatient.health_insurance &&
    userData.onePatient.health_insurance.validity_end
      ? moment(new Date(parseInt(userData.onePatient.health_insurance.validity_end))).isAfter(moment())
      : false

   
  const insurance_name =
    userData &&
    userData.onePatient &&
    userData.onePatient.health_insurance &&
    userData.onePatient.health_insurance.name
      ? userData.onePatient.health_insurance.name
      : ''
 
  //validity_end

  if (loading5 || loading6) {
    return <Loading />
  }

  return (
    <Fragment>
      <Accordion
        id={'search-userData.medical-prescription-total-search'}
        title={`Fiche patient de ${
          userData && userData.onePatient ? userData.onePatient.name : ''
        }`}
        expanded={expanded1}
        setExpanded={setExpanded1}
        accordionStyle={true}
      >
        {/* <UserInfo
        user={userData && userData.onePatient ? userData.onePatient : null}
        expanded={expanded3}
        setExpanded={setExpanded3}
      /> */}
        <PatientEdit
          user={userData && userData.onePatient ? userData.onePatient : null}
        />
      </Accordion>

      <Accordion
        id={'search-userData.medical-bill-total-search'}
        title={'Génération facture'}
        expanded={expanded3}
        setExpanded={setExpanded3}
        accordionStyle={true}
      >
        <BillCreate
          removeItem={removeItem}
          invoice_number={2345678}
          client={userData && userData.onePatient ? userData.onePatient : null}
          insurance_name={insurance_name}
          covered={covered && insurance_validity_end}
          insurance_validity_end={insurance_validity_end}
          percentage_insurance={percentage_insurance}
          //  health_insurance={}
          currency={currency}
          timezone={timezone}
          services={serviceData && serviceData.allServices ? serviceData.allServices:[]}
          servicesDatasource={
            serviceData && serviceData.allServices
              ? serviceData.allServices.map((e) => `${e.name}`)
              : []
          } 
          logo={
            comp && comp.oneCompany && comp.oneCompany.logo
              ? comp.oneCompany.logo
              : ''
          }


          logo_id={
            comp && comp.oneCompany && comp.oneCompany.logo_id
              ? comp.oneCompany.logo_id
              : ''
          }
          stamp={
            comp && comp.oneCompany && comp.oneCompany.stamp
              ? comp.oneCompany.stamp
              : ''
          }

          stamp_id={
            comp && comp.oneCompany && comp.oneCompany.stamp_id
              ? comp.oneCompany.stamp_id
              : ''
          }
          /*  setLogo={setLogo}
          item={item}
          setItem={setItem}
          items={items}
          setItems={setItems}
          quantity={quantity}
          setQuantity={setQuantity}
          description={description}
          setDescription={setDescription} */

          company={comp && comp.oneCompany ? comp.oneCompany : null}
        />
      </Accordion>

      <Accordion
        id={'search-userData.medical-prescription-total-search'}
        title={'Génération Ordonnance'}
        expanded={expanded2}
        setExpanded={setExpanded2}
        accordionStyle={true}
      >
        <OrdonnanceCreate
          removeItem={removeItem}
          invoice_number={2345678}
          client={userData && userData.onePatient ? userData.onePatient : null}
          insurance_name={insurance_name}
          covered={covered && insurance_validity_end}
          insurance_validity_end={insurance_validity_end}
          percentage_insurance={percentage_insurance}
          //  health_insurance={}
          currency={currency}
          timezone={timezone}
          /*  servicesDatasource={
            serviceData && serviceData.allServices
              ? serviceData.allServices.map((e) => `${e.name}`)
              : []
          } */
          logo={
            comp && comp.oneCompany && comp.oneCompany.logo
              ? comp.oneCompany.logo
              : ''
          }


          logo_id={
            comp && comp.oneCompany && comp.oneCompany.logo_id
              ? comp.oneCompany.logo_id
              : ''
          }
          stamp={
            comp && comp.oneCompany && comp.oneCompany.stamp
              ? comp.oneCompany.stamp
              : ''
          }

          stamp_id={
            comp && comp.oneCompany && comp.oneCompany.stamp_id
              ? comp.oneCompany.stamp_id
              : ''
          }
          /*  setLogo={setLogo}
          item={item}
          setItem={setItem}
          items={items}
          setItems={setItems}
          quantity={quantity}
          setQuantity={setQuantity}
          description={description}
          setDescription={setDescription} */

          company={comp && comp.oneCompany ? comp.oneCompany : null}
        />
      </Accordion>
      <Accordion
        id={'search-userData.findUserById-bill-total-search'}
        title={"Filtrer l'historique"}
        expanded={expanded4}
        setExpanded={setExpanded4}
        accordionStyle={true}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className='new-service-content'>
              <Button
                color='primary'
                variant='contained'
                onClick={async () => await setCreateModal(true)}
                startIcon={<AddIcon />}
              >
                Ajouter une note
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id='start'
              label='Du '
              name='start'
              type='date'
              fullWidth
              value={from_bill}
              onChange={async ({ target: { value } }) => {
                await setFromBill(value)
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id='end'
              label='Au'
              name='end'
              type='date'
              value={to_bill}
              onChange={async ({ target: { value } }) => {
                await setToBill(value)
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

          <div className='content-btn-profil-footer controle-btn'>
            <Button
              className='buttonLeft'
              color='secondary'
              //type="submit"
              onClick={clearFilter}
              variant='contained'
              startIcon={<DeleteIcon />}
            >
              effacer la recherche
            </Button>

            <Button
              //  style={{ backgroundColor: "red", color: "white" }}
              color='primary'
              //type="submit"
              onClick={search}
              variant='contained'
              startIcon={<SearchIcon />}
            >
              chercher
            </Button>
          </div>

          {historyData && historyData.findCompPatientHistory.totalPages && (
            <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
              <Pagination
                disabled={loading}
                count={historyData.findCompPatientHistory.totalPages}
                page={page}
                onChange={async (_event, value) => {
                  /*  if (value.toString() !== page.toString()) {
                  await onPageChange(value)
                } */

                  await onPageChange(value)
                }}
              />
            </Grid>
          )}
          {loading3 && <Loading />}

          {historyData && historyData.findCompPatientHistory && (
            <Grid item xs={12} sm={12}>
              <Box marginTop={2}>
                {!loading &&
                  historyData &&
                  historyData.findCompPatientHistory.totalDocs && (
                    <Typography
                      variant='body2'
                      style={{ textAlign: 'center', marginBottom: 20 }}
                    >
                      {readNumber(historyData.findCompPatientHistory.totalDocs) +
                        ' notes'}
                    </Typography>
                  )}

                {historyData.findCompPatientHistory.docs &&
                  historyData.findCompPatientHistory.docs.length === 0 && (
                    <p>
                      <strong>Aucune historique</strong>
                    </p>
                  )}
                {historyData.findCompPatientHistory.docs &&
                  historyData.findCompPatientHistory.docs.map((item, index) => (
                    <div className='content-client-history' key={index}>
                      <h4 className='notif-title-client-history'>
                        {item.title + ' '} {item.createdAt}
                      </h4>
                      <p>{item.description}</p>
                    </div>
                  ))}
              </Box>
            </Grid>
          )}
        </Grid>
        <br />
      </Accordion>

      <Formik
        initialValues={{
          category_id: '',
          description: '',
          patient_id,
          company_id
        }}
        validationSchema={createSchema}
        onSubmit={create}
      >
        {(props) => (
          <ModalDialog
            maxWidth={'md'}
            open={createModal}
            cancel={async () => {
              await props.handleReset()
              await setCreateModal(false)
            }}
            title={'Ajout Historique'}
          >
            <HistoryCreate
              categories={
                comp && comp.oneCompany && comp.oneCompany.categories
                  ? comp.oneCompany.categories.map((cat) => cat.name)
                  : []
              }
              globalError={globalCreateError}
              {...props}
            />
          </ModalDialog>
        )}
      </Formik>
    </Fragment>
  )
}

export default ClientHistoryView
