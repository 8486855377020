import React, { useState } from "react"
import { Grid, TextField, Button } from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"
//import moment from "moment-timezone"

import { SelectField } from "components/SelectField"
import { Accordion } from "components/Accordion"
import SearchIcon from '@material-ui/icons/Search';

export const EventSearch = ({
  usersDatasource,
  users,
  services,
  categoriesDatasource,
  categories,
  servicesDatasource,
  selectWorkers,
  selectServices,
  selectCategories,
  eventNumber,
  setEventNumber,
  start,
  setStart,
  submit,
  refetch,
}) => {
  const theme = useTheme()
  const [expanded, setExpanded] = useState(false)
  return (
    <Accordion
      id={"search-event-form"}
      title={"Filtrer les rendez vous"}
      expanded={expanded}
      setExpanded={setExpanded}
    >
      <Grid container spacing={3} justify="center" style={{ padding: '0px 15' }}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="start"
            name="start"
            label="A partir de"
            type="date"
            defaultValue={start}
            onChange={async (e) => {
              e.preventDefault()
              const {
                target: { value },
              } = e
              console.log("value date", value)
              await setStart(value)
            }}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={"Numéro RV"}
            type="number"
            name="eventNumber"
            value={eventNumber}
            onChange={async ({ target: { value } }) => {
              await setEventNumber(value)
            }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SelectField
            required={false}
            multiple={true}
            handleChange={selectServices}
            name="services"
            label={"Services"}
            value={services}
            theme={theme}
            dataSource={servicesDatasource}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            required={false}
            multiple={true}
            handleChange={selectCategories}
            name="categories"
            label={"Catégories"}
            value={categories}
            theme={theme}
            dataSource={categoriesDatasource}
          />
        </Grid>

        {/* <Grid item xs={12} sm={6}>
          <SelectField
            required={false}
            multiple={true}
            handleChange={selectWorkers}
            name="workers"
            label={"Affectation(s)"}
            value={users}
            theme={theme}
            dataSource={usersDatasource}
          />
        </Grid> */}

      <div className="btn-refresh-content">
          <Button
            color="primary"
            //disabled={!formState.isValid}
            onClick={async (e) => {
              e.preventDefault()
              await submit()
            }}
            variant="contained"
            startIcon={<SearchIcon />}
          >
            Recherche
          </Button>
      
          <Button
            onClick={async (e) => {
              e.preventDefault()
              await refetch()
            }}
          >
            Rafraichir la page
          </Button>
        </div>
      </Grid>
    </Accordion>
  )
}
