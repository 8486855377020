import React, { useState } from "react"
/* import { Link as RouterLink } from "react-router-dom" */
import clsx from "clsx"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"
import { Button } from "@material-ui/core"
import { useMutation } from "react-apollo"

import { logoutSMutation, LOGOUT_USER_MUTATION } from "graphql/mutation/user"
import { ConfirmDialog } from "components/ConfirmDialog"
/* import { colors } from "utils/constants"
import client from "graphql/apollo" */

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
}))

const Profile = (props) => {
  const { className, ...rest } = props

  const [logoutModal, setLogoutModal] = useState(false)
  const [logout] = useMutation(LOGOUT_USER_MUTATION)
  const [logoutS] = useMutation(logoutSMutation)
  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Button
        color="primary"
        variant="outlined"
       /*  style={{ backgroundColor: colors.LIGHT_GRAY }} */
        onClick={async (e) => {
          e.preventDefault()
          await setLogoutModal(true)
        }}
      >
        Se déconnecter
      </Button>

      <ConfirmDialog
        open={logoutModal}
        cancel={async () => {
          await setLogoutModal(false)
        }}
        confirmAction={async () => {
          await logoutS()
        
          await logout()
          await setLogoutModal(false)
          //await client.clearStore();
        }}
        title={"Déconnexion"}
      >
        <p>Voulez vous vous déconnectez ?</p>
      </ConfirmDialog>
    </div>
  )
}

Profile.propTypes = {
  className: PropTypes.string,
}

export default Profile
