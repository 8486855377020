import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { makeStyles, useTheme } from "@material-ui/styles"
import { useMediaQuery } from "@material-ui/core"

import { Sidebar, Topbar, Footer } from "./components"

import { useQuery } from "@apollo/react-hooks"
import DashboardIcon from "@material-ui/icons/DashboardOutlined"
import PeopleIcon from "@material-ui/icons/PeopleOutlined"

import SettingsIcon from "@material-ui/icons/SettingsOutlined"
import CalendarIcon from "@material-ui/icons/CalendarTodayOutlined"
import HistoryIcon from "@material-ui/icons/HistoryOutlined"
import BuildIcon from "@material-ui/icons/BuildOutlined"
import HighlightOff from "@material-ui/icons/HighlightOff"
import PhoneAndroidOutlined from "@material-ui/icons/PhoneAndroidOutlined"

import {
  SETTING_PATH,
  SERVICE_PATH,
  TEAM_PATH,
  COMPANY_PATH,
  PROFILE_PATH,
  EVENT_PATH,
  EVENT_WORKER_PATH,
  NEW_EVENT_COMPANY_PATH,
  ABSENCE_PATH,
  COMPANY_CLIENT_PATH,
  CLIENT_NOTICE_PATH,
  CLIENT_SIGNAL_PATH,
  FACTURE_PATH,
  COMPANY_ID_STORAGE,
  COMP_HISTORY_PATH,
  FACTURE_LIST_PATH,
  APP_MOBILE_PATH,
  PATIENT_CLIENT_PATH,
  NEW_PATIENT_CLIENT_PATH,
} from "utils/static_constants"
import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined"

import { currentUserQuery } from "graphql/query/user"
import moment from "moment-timezone"
import "moment/locale/fr"
import Loading from "components/Loading"
moment.locale("fr")

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 56,
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 64,
    },
  },
  isDesktopCotent: {
    [theme.breakpoints.up("md")]: {
      /* transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms', */
      /* transitionDuration: 0.3, */
      paddingLeft: 260,
    },
  },

  content: {
    height: "100%",
    transitionDuration: "200ms",
  },
}))

const Main = (props) => {
  const { children } = props
  const { data: userData, loading } = useQuery(currentUserQuery, {
    fetchPolicy: "network-only",
  })
  let roles = undefined
  roles =
    userData && userData.onlineUser && userData.onlineUser.roles
      ? userData.onlineUser.roles
      : []
  const [, updateState] = useState()
  const forceUpdate = useCallback(() => updateState({}), [])
  const classes = useStyles()
  const theme = useTheme()
  const [pages, setPages] = useState([
    {
      title: "Application Mobile",
      href: APP_MOBILE_PATH,
      icon: <PhoneAndroidOutlined />,
    },

    {
      title: "Profil professionnel",
      href: COMPANY_PATH,
      icon: <DashboardIcon />,
    },

    {
      title: "Prestation de services",
      href: SERVICE_PATH,
      icon: <BuildIcon />,
    },
  ])
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  })

  const [openSidebar, setOpenSidebar] = useState(true)
  const [computedRole, setComputedRole] = useState(false)

  const handleSidebarOpen = () => {
    setOpenSidebar(!openSidebar)
  }

  const handleSidebarClose = () => {
    setOpenSidebar(false)
  }

  let worker = []

  let super_admin = [
    {
      title: "Historiques des Activités",
      href: COMP_HISTORY_PATH,
      icon: <HistoryIcon />,
    },
  ]
  let admin = [
    {
      title: "Patients",
      href: COMPANY_CLIENT_PATH,
      icon: <PeopleIcon />,
    },

    {
      title: "Paramétrage calendrier",
      href: SETTING_PATH,
      icon: <SettingsIcon />,
    },
    {
      title: "Personnel",
      href: TEAM_PATH,
      icon: <GroupAddOutlinedIcon />,
    },
  ]
  let adminManager = [
    {
      title: "Les Rendez-vous",
      href: EVENT_PATH,
      icon: <CalendarIcon />,
    },
    {
      title: "Absences",
      href: ABSENCE_PATH,
      icon: <HighlightOff />,
    },
  ]

  const setUpTabs = async (roles) => {
    try {
      let tabs = pages
      const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)

      const roless = roles.filter((r) => r.company_id && r.company_id == company_id)

      roless.forEach(async (role, _i) => {
        if (role.is_comp_super_admin) {
          // tabs = tabs.concat(admin).concat(adminManager).concat(super_admin);
          admin.forEach((t) => {
            if (tabs.findIndex((r) => r.title == t.title) < 0) {
              tabs.push(t)
            }
          })

          adminManager.forEach((t) => {
            if (tabs.findIndex((r) => r.title == t.title) < 0) {
              tabs.push(t)
            }
          })

          super_admin.forEach((t) => {
            if (tabs.findIndex((r) => r.title == t.title) < 0) {
              tabs.push(t)
            }
          })
        }
        if (role.is_comp_admin) {
          // tabs = tabs.concat(adminManager).concat(admin)

          admin.forEach((t) => {
            if (tabs.findIndex((r) => r.title == t.title) < 0) {
              tabs.push(t)
            }
          })

          adminManager.forEach((t) => {
            if (tabs.findIndex((r) => r.title == t.title) < 0) {
              tabs.push(t)
            }
          })
        }

        if (role.is_comp_worker) {
          // tabs = tabs.concat(worker)

          worker.forEach((t) => {
            if (tabs.findIndex((r) => r.title == t.title) < 0) {
              tabs.push(t)
            }
          })
        }

        if (role.is_comp_manager) {
          //tabs = adminManager.concat(tabs)

          adminManager.forEach((t) => {
            if (tabs.findIndex((r) => r.title == t.title) < 0) {
              tabs.push(t)
            }
          })
        }
      })

      await setPages(tabs)
      await setComputedRole(true)
      return true
    } catch (error) {
      throw error
    }
  }
  useEffect(() => {
    if (roles && roles.length > 0) {
      setUpTabs(roles)
        .then(() => {
          // forceUpdate()
        })
        .catch((err) => console.log("error", err))
    }
  }, [loading, roles])

  // const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div
      key={pages.length}
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} openSidebar={openSidebar} />

      {computedRole && (
        <Sidebar
          computedRole={computedRole}
          key={pages.length}
          onClose={handleSidebarClose}
          open={openSidebar}
          variant={isDesktop ? "persistent" : "temporary"}
          pages={pages}
        />
      )}
      <main
        className={clsx({
          [classes.content]: true,
          [classes.isDesktopCotent]: openSidebar,
        })}
      >
        {children}
        <Footer />
      </main>
    </div>
  )
}

Main.propTypes = {
  children: PropTypes.node,
}

export default Main
