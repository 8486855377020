import React from "react"
import { TableCell, TableRow,IconButton } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { Link } from "react-router-dom"
import { ONE_COMPANY_FACTURE_PATH } from "utils/static_constants"
import moment from "moment-timezone"
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles((theme) => ({
  statsIcon: {
    cursor:'pointer',
    color: theme.palette.icon,
    marginRight: theme.spacing(1),
  },
}))
export const Bill = (props) => {
  const { item, selectForUpdate } = props
  const classes = useStyles()

  return (
    <TableRow key={item.id}>
      
      <TableCell style={{ color: item.bill_paid ? "green" : "red" }}>
        {item.bill_paid ? "facture payée" : "non payée"}
      </TableCell>
      <TableCell>{item.final_bill}</TableCell>

      <TableCell style={{ fontSize: 12 }}>{item.phone}</TableCell>
      <TableCell>{item.subscription_price}</TableCell>

      <TableCell>{item.total_sms_price}</TableCell>

     
      <TableCell>{item.total_rv_price}</TableCell>
      <TableCell style={{ fontSize: 11 }}>
        {moment(new Date(parseInt(item.from_date))).format("MMMM-YYYY")}
        <br />
      </TableCell>
      
      <TableCell>
        <Link to={`${ONE_COMPANY_FACTURE_PATH}/${item.id}`}>
          <IconButton component="span">
            <VisibilityIcon />
          </IconButton>
        </Link>
      </TableCell>
    </TableRow>
  )
}
