import gql from "graphql-tag"

export const findCompPatientHistoryQuery = gql`
  query findCompPatientHistory(
    $company_id: String!
    $patient_id: String!
    $page: Int!
    $limit: Int
    $start: String
    $end: String
  ) {
    findCompPatientHistory(
      company_id: $company_id
      patient_id: $patient_id
      page: $page
      limit: $limit
      start: $start
      end: $end
    ) {
      totalDocs
      hasPrevPage
      totalPages
      hasNextPage
      limit
      offset
      page
      prevPage
      nextPage
      docs {
        id
        category {
          id
          name
        }
        title
        description
        createdAt
      }
    }
  }
`



