import { Button, Grid, TextField } from '@material-ui/core'
import { SelectInput } from 'components/SelectInput'
import React from 'react'
import { generateYearsBetween } from 'utils/func'

export const InputModalCustom = ({
  name_current_desease,
  setNameCurrentDesease,
  setDescriptionCurrentDesease,
  description_current_desease,
  year_current_dease,
  setYearCurrentDesease,
  addCurrentDesease,
  label1,
  label2,
  label3
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <TextField
          fullWidth
          required
          label={label1}
          value={name_current_desease}
          onChange={async ({ target: { value } }) => {
            await setNameCurrentDesease(value)
          }}
          // error={touched.name && errors.name ? true : false}

          //helperText={touched.name && errors.name ? errors.name : null}
        />
      </Grid>

      <Grid item lg={6} md={6} sm={6} xs={12}>
      {/*   <TextField
          required
          label={label3}
          value={year_current_dease}
          onChange={async ({ target: { value } }) => {
            await setYearCurrentDesease(value)
          }}
          // error={touched.name && errors.name ? true : false}
          fullWidth
          //helperText={touched.name && errors.name ? errors.name : null}
        /> */}

        <SelectInput
        
          key={'select-year-patient'}
         
          label={label3}
          fullWidth={true}
          value={year_current_dease}
          options={generateYearsBetween(1900, 2100)}
          required={false}
          onChange={async ({ target: { value } }) => {
            await setYearCurrentDesease(value)
          }}
        />
      </Grid>

      <Grid item lg={12} xs={12}>
        <TextField
          multiline
          rows={2}
          fullWidth
          required
          label={label2}
          value={description_current_desease}
          onChange={async ({ target: { value } }) => {
            await setDescriptionCurrentDesease(value)
          }}
          // error={touched.name && errors.name ? true : false}
          fullWidth
          //helperText={touched.name && errors.name ? errors.name : null}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <Button
          color='primary'
          //disabled={!formState.isValid}

          onClick={async (e) => {
            try {
              e.preventDefault()
              await addCurrentDesease({
                name: name_current_desease,
                description: description_current_desease,

                year: year_current_dease
              })
            } catch (error) {
              window.alert(error.message)
            }
          }}
          variant='contained'
        >
          Ajouter
        </Button>
      </Grid>
    </Grid>
  )
}
