import React, { useState } from 'react'
import { useQuery, useMutation, useLazyQuery } from 'react-apollo'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { oneSettingAdminQuery } from 'graphql/query/setting_admin'
import { COMPANY_ID_STORAGE, EVENT_PATH } from 'utils/static_constants'
import Loading from 'components/Loading'
import { companyClientsQuery, companyServicesQuery } from 'graphql/query/company'
import { Button, Grid, TextField, useTheme } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  createEventCompanyMutation,
  createEventInternalCompanyMutation
} from 'graphql/mutation/event'
import { backEventsQuery } from 'graphql/query/back_event'
import { MessageField } from 'components/MessageField'
import { Calendar } from './components/Calendar'
import { ModalDialog } from 'components/ModalDialog'
import { EventCreate } from './components/EventCreate'
import { toast } from 'react-toastify'
import { EventCreateInternal } from './components/EventCreateInternal'
import moment from 'moment-timezone'
import { findOnePatientQuery } from 'graphql/query/patient'

const createSchema = Yup.object().shape({
  /*  name: Yup.string()
    .trim()
    .min(2, 'minimun 2 caractères')
    .max(50, 'maximum 50 caractères')
    .required('champ requis'),
  lastname: Yup.string()
    .trim()
    .min(2, 'minimun 2 caractères')
    .max(50, 'maximum 50 caractères')
    .required('champ requis'),

  gender: Yup.string().required('champ requis'),

  phone: Yup.string()
    .trim()
    .min(13, 'Téléphone incorrect')
    .max(13, 'Téléphone incorrect')
    .required('champ requis'), */

  sms_notify: Yup.boolean().default(false),
  //service_id: Yup.string().required("champ requis"),
  company_id: Yup.string().required('champ requis')
  /* start: Yup.string().required("champ requis"),
  end: Yup.string().required("champ requis"), */
})

const createInternalSchema = Yup.object().shape({
  /*   name: Yup.string()
    .trim()
    .min(2, 'minimun 2 caractères')
    .max(50, 'maximum 50 caractères')
    .required('champ requis'),
  lastname: Yup.string()
    .trim()
    .min(2, 'minimun 2 caractères')
    .max(50, 'maximum 50 caractères')
    .required('champ requis'),

  gender: Yup.string().required('champ requis'),

  phone: Yup.string()
    .trim()
    .min(13, 'Téléphone incorrect')
    .max(13, 'Téléphone incorrect')
    .required('champ requis'), */

  start: Yup.string().required('champ requis'),
  end: Yup.string().required('champ requis'),
  //service_id: Yup.string().required("champ requis"),
  company_id: Yup.string().required('champ requis')
  /* start: Yup.string().required("champ requis"),
  end: Yup.string().required("champ requis"), */
})
export default function NewEventView ({ history }) {
  const theme = useTheme()
  const [createModal, setCreateModal] = useState(false)
  const [event, setEvent] = useState({})
  const [globalCreateError, setGlobalCreateError] = useState('')
  const [globalCreateInternalError, setGlobalCreateInternalError] = useState('')
  const [startEnd, setStartEnd] = useState({})
  const [errors, setErrors] = useState('')
  const [loading, setLoading] = useState(false)
  const [phone, setPhone] = useState(undefined)
  const [patient, setPatient] = useState(undefined)

  //

  const [findOnePat, { loading: loading2, data: onePatientData }] =
    useLazyQuery(findOnePatientQuery)
  const { loading: loading1, data: generalSetting } = useQuery(oneSettingAdminQuery)
  /* const { loading: loading2, data: clientsData } = useQuery(companyClientsQuery, {
    variables: { company_id: localStorage.getItem(COMPANY_ID_STORAGE) }
  }) */
  const { loading: loading3, data: servicesData } = useQuery(companyServicesQuery, {
    variables: { company_id: localStorage.getItem(COMPANY_ID_STORAGE) },
    fetchPolicy: 'network-only'
  })

  const [createAppoint] = useMutation(createEventCompanyMutation)
  const [createAppointInternal] = useMutation(createEventInternalCompanyMutation)

  const [getBackEvents, { loading: loading4, data: backEventData }] = useLazyQuery(
    backEventsQuery,
    {
      fetchPolicy: 'network-only'
    }
  )
  const pat =
    onePatientData &&
    onePatientData.findOnePatient &&
    onePatientData.findOnePatient.patient
      ? onePatientData.findOnePatient.patient
      : undefined

  const timezone =
    generalSetting &&
    generalSetting.oneSettingAdmin &&
    generalSetting.oneSettingAdmin.time_zone
      ? generalSetting.oneSettingAdmin.time_zone
      : 'Africa/Dakar'
  const phone_code =
    generalSetting &&
    generalSetting.oneSettingAdmin &&
    generalSetting.oneSettingAdmin.phone_code
      ? generalSetting.oneSettingAdmin.phone_code
      : null
  const phone_min_size =
    generalSetting &&
    generalSetting.oneSettingAdmin &&
    generalSetting.oneSettingAdmin.phone_min_size
      ? generalSetting.oneSettingAdmin.phone_min_size
      : null

  const phone_max_size =
    generalSetting &&
    generalSetting.oneSettingAdmin &&
    generalSetting.oneSettingAdmin.phone_max_size
      ? generalSetting.oneSettingAdmin.phone_max_size
      : null

  const optionsService =
    servicesData && servicesData.companyServices
      ? [...new Set(servicesData.companyServices.map((e) => e.name))]
      : []

  const selectService = async (e) => {
    try {
      const service = e.target.value

      const serv = servicesData.companyServices.find(
        (e) => e.name.toString() === service.toString()
      )

      await setEvent({ ...event, service: serv })

      if (serv && serv.id && !serv.internal) {
        const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
        await getBackEvents({ variables: { company_id } })
      }
    } catch (error) {
      window.alert(error.message)
    }
  }

  const selectEvent = async ({ start, end }) => {
    try {
      await setStartEnd({ start, end })

      await setCreateModal(true)
    } catch (error) {
      window.alert(error.message)
    }
  }

  const createEventInternal = async (
    values,

    { setSubmitting, setFieldError }
  ) => {
    try {
      if (!pat) {
        window.alert(`Faut sélectionner un patient`)
        return
      }
      /*   const phoneLength = parseInt(values.phone.trim().length)

      if (
        !values.phone.toString().startsWith(phone_code.toString()) ||
        phoneLength < parseInt(phone_min_size) ||
        phoneLength > parseInt(phone_max_size)
      ) {
        console.log('error5==')
        
        window.alert(
          `Le téléphone doit commencer par ${phone_code} ,taille minimale de ${phone_min_size} et maximale de ${phone_max_size}`
        )
        return
      } */
      const { company_id, start, end } = values

      const now = moment()
      const strt = moment(start)
      const endt = moment(end)

      if (strt.isBefore(now)) {
        await setFieldError('start', "L'absence ne doit pas se situer dans le passé")
        console.log('error1')
        return
      }
      if (strt.isSame(end)) {
        await setFieldError(
          'end',
          'la date de fin doit supérieure à la date de début'
        )
        console.log('error2')
        return
      }

      if (strt.isAfter(endt)) {
        await setFieldError(
          'start',
          'la date de début ne peut être supérieure à la date de fin'
        )
        console.log('error3')
        return
      }

      let newEvent = {
        start,
        end,
        service_id: event.service.id,
        category_id: event.service.category.id,
        company_id,
        /*  lastname,
        name,
        gender: gender == 'Monsieur' ? 'male' : 'female',
        phone */
        patient_id: pat.id
      }
      const response = await createAppointInternal({
        variables: {
          event: newEvent,
          company_id
        }
      })
      console.log('response', response)
      const { ok, errors } = response.data.createEventInternalCompany

      if (ok) {
        await setCreateModal(false)

        await setEvent({})
        await toast.success('Rendez-vous crée avec succès')
        await history.push(EVENT_PATH)
      } else {
        errors.forEach(async (error) => {
          if (error.path && error.path.toString() === 'global') {
            await setGlobalCreateInternalError(error.message)
          } else {
            await setFieldError(error.path, error.message)
            await setSubmitting(false)
          }
        })
      }
    } catch (error) {
      window.alert(error.message)
    }
  }
  const createEvent = async (
    values,

    { setSubmitting, setFieldError, resetForm }
  ) => {
    try {
      if (!pat) {
        window.alert(`Faut sélectionner un patient`)
        return
      }
      //  const phoneLength = parseInt(values.phone.trim().length)

      /*   if (
        !values.phone.toString().startsWith(phone_code.toString()) ||
        phoneLength < parseInt(phone_min_size) ||
        phoneLength > parseInt(phone_max_size)
      ) {
       
        window.alert(
          `Le téléphone doit commencer par ${phone_code} ,taille minimale de ${phone_min_size} et maximale de ${phone_max_size}`
        )
        return
      } */
      const { company_id, sms_notify } = values

      let newEvent = {
        start: startEnd.start,
        end: startEnd.end,
        service_id: event.service.id,
        category_id: event.service.category.id,
        company_id,
        /*   lastname,
        name,
        gender: gender == 'Monsieur' ? 'male' : 'female',
        phone, */
        sms_notify,
        patient_id: pat.id
      }
      const response = await createAppoint({
        variables: {
          event: newEvent,
          company_id
        }
      })

      const { ok, errors } = response.data.createEventCompany

      if (ok) {
        await setCreateModal(false)

        await resetForm()
        await setStartEnd({})
        await setEvent({})
        await toast.success('Rendez-vous crée avec succès')
        await history.push(EVENT_PATH)
      } else {
        errors.forEach(async (error) => {
          if (error.path && error.path.toString() === 'global') {
            await setGlobalCreateError(error.message)
          } else {
            await setFieldError(error.path, error.message)
            await setSubmitting(false)
          }
        })
      }
    } catch (error) {
      window.alert(error.message)
    }
  }

  if (loading1 || loading3) {
    return <Loading />
  }

  return (
    <Grid>
      <Grid item xs={12} sm={12}>
        <MessageField severity='info' message={'Création rendez vous'} />
      </Grid>

      <Formik
        initialValues={{
          /*  name: '',
          lastname: '',
          phone: '',
          gender: '', */
          company_id: localStorage.getItem(COMPANY_ID_STORAGE),

          sms_notify: false
        }}
        validationSchema={createSchema}
        onSubmit={createEvent}
      >
        {(props) => (
          <ModalDialog
            maxWidth={'md'}
            open={createModal}
            cancel={async () => {
              //await props.handleReset()
              await setCreateModal(false)
              await setErrors('')
            }}
            title={'Création Rendez-vous'}
          >
            <EventCreate
              pat={pat}
              event={event}
              loading={loading}
              date={startEnd}
              errors={errors}
              timezone={timezone}
              phone_code={phone_code}
              phone_max_size={phone_max_size}
              phone_min_size={phone_min_size}
              globalError={globalCreateError}
              {...props}
            />
          </ModalDialog>
        )}
      </Formik>

      <Grid container spacing={3} style={{ padding: 20 }}>
        <Grid item xs={6} sm={6}>
          <TextField
            fullWidth
            id='phone'
            label={`Téléphone ${phone_code}`}
            name='phone'
            type='phone'
            value={phone}
            onChange={async ({ target: { value } }) => {
              await setPhone(value)
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
        </Grid>

        <Grid item xs={6} sm={6}>
          <Button
            color='primary'
            //disabled={!formState.isValid}
            // type="submit"

            onClick={async (e) => {
              try {
                console.log('phone', phone)
                e.preventDefault()
                // await handleSubmit()

                const phoneLength = parseInt(phone.trim().length)

                if (
                  !phone.toString().startsWith(phone_code.toString()) ||
                  phoneLength < parseInt(phone_min_size) ||
                  phoneLength > parseInt(phone_max_size)
                ) {
                  /* await setFieldError(
                  'phone',
                  `Le téléphone doit commencer par ${phone_code} ,taille minimale de ${phone_min_size} et maximale de ${phone_max_size}`,
                ); */
                  window.alert(
                    `Le téléphone doit commencer par ${phone_code} ,taille minimale de ${phone_min_size} et maximale de ${phone_max_size}`
                  )
                  return
                }

                const company_id = localStorage.getItem(COMPANY_ID_STORAGE)
                const result = await findOnePat({ variables: { company_id, phone } })
                console.log('result find patient', onePatientData)
              } catch (error) {
                window.alert(error.message)
              }
            }}
            variant='contained'
          >
            Rechercher patient(e)
          </Button>
        </Grid>
        {pat && (
          <Grid item xs={6} sm={6}>
            <p>
              <h5>{pat.gender == 'male' ? 'Monsieur' : 'Madame'}</h5>
              {<h2>{pat.lastname + ' ' + pat.name}</h2>}
            </p>
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <Autocomplete
            noOptionsText={'Aucun résultat'}
            id='service'
            required
            label={'Prestation'}
            options={optionsService}
            onSelect={selectService}
            getOptionLabel={(option) => option}
            //   style={{ margin: 100 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Prestation'
                variant='outlined'
                required
                fullWidth
              />
            )}
          />
        </Grid>

        {loading4 && <Loading />}

        {event && event.service && !event.service.internal && (
          <Grid item xs={12} sm={12}>
            <Calendar
              service={event && event.service && !loading4 ? event.service : null}
              backevents={
                backEventData && backEventData.backEvents
                  ? backEventData.backEvents
                  : []
              }
              selectEvent={selectEvent}
              timezone={timezone}
            />
          </Grid>
        )}

        {event && event.service && event.service.internal && (
          <Formik
            initialValues={{
              /*  name: '',
              lastname: '',
              phone: '',
              gender: '', */
              company_id: localStorage.getItem(COMPANY_ID_STORAGE),
              sms_notify: false
            }}
            validationSchema={createInternalSchema}
            onSubmit={createEventInternal}
          >
            {(props) => (
              <EventCreateInternal
                pat={pat}
                event={event}
                loading={loading}
                timezone={timezone}
                globalError={globalCreateInternalError}
                {...props}
              />
            )}
          </Formik>
        )}
      </Grid>
    </Grid>
  )
}
