import React from 'react'

import { makeStyles } from '@material-ui/styles'
import { TableCell, TableRow, Avatar, IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import RestoreIcon from '@material-ui/icons/Restore'
import { Link } from 'react-router-dom'
import WorkerIcon from '@material-ui/icons/Accessibility'
import inconnu from '../../../assets/inconnu.jpg'
import { DisplayDate } from 'components/DisplayDate'
import { colors } from 'utils/constants'
import { CLIENT_HISTORY_PATH } from 'utils/static_constants'

const useStyles = makeStyles((theme) => ({
  statsIcon: {
    // color: theme.palette.icon,
/*     cursor: 'pointer', */
    marginRight: theme.spacing(1)
  }
}))

export const Event = (props) => {
  const { item, selectEvent, timezone, company_id } = props
  console.log('item', item)
  const classes = useStyles()
  const cellStyle = { fontSize: 12 }

  const computeStyle = () => {
    if (item) {
      if (item.canceled || (item.finished && !item.author_presence)) {
        return { margin: 10, backgroundColor: 'lightgray' }
      }

      if (item.author_presence) {
        return { margin: 10, backgroundColor: '#ADFF2F' }
      }
    }

    return { margin: 10 }
  }
  const gender = item.author.gender == 'male' ? 'Monsieur' : 'Madame'
  return (
    <TableRow key={item.id} style={computeStyle()}>
      {/* {item.service.picture && (
        <TableCell>
          <Avatar size="medium" src={item.service.picture} alt={item.service.name} />
        </TableCell>
      )}

      {!item.service.picture && (
        <TableCell>
          <Avatar size="tiny" src={inconnu} alt={"iconnu"} />
        </TableCell>
      )} */}
      <TableCell style={cellStyle}>{item.service.name}</TableCell>
      <TableCell style={cellStyle}>{item.service.category.name}</TableCell>
      <TableCell style={cellStyle}>
        {gender + ' ' + item.author.name + ' ' + item.author.lastname}
      </TableCell>
      <TableCell>{item.event_number}</TableCell>
      <TableCell style={{ fontSize: 10, color: colors.VIOLET }}>
        <DisplayDate date={item.start} timezone={timezone} />
      </TableCell>

      <TableCell>
        <WorkerIcon className={classes.statsIcon} />
        {item.workers ? item.workers.length : 0}
      </TableCell>

      <TableCell>
        <IconButton component='span'>
          <EditIcon
            // disable={item && item.canceled}

           
            onClick={async (e) => {
              try {
                e.preventDefault()
                await selectEvent(item.id)
              } catch (error) {
                window.alert(error.message)
              }
            }}
          />
        </IconButton>
      </TableCell>

      <TableCell>
        <IconButton  component='span'>
          <Link to={`${CLIENT_HISTORY_PATH}/${item.company_id}/${item.patient_id}`}>
            <RestoreIcon />
          </Link>
        </IconButton>
      </TableCell>
    </TableRow>
  )
}
