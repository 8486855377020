import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField
} from '@material-ui/core'
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField'
import { SelectInput } from 'components/SelectInput'
import React from 'react'

export const BillInput = ({
  name,
  setName,
  quantity,
  setQuantity,
  description,
  setDescription,
  hasInsurance,
  setHasInsurance,
  covered,
  label_insurance,
  currency,
  price,
  setPrice,
  addItem,
  servicesDatasource,
  services
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        {/*   <TextField
          fullWidth
          required
          label={'Prestation'}
          value={name}
          onChange={async ({ target: { value } }) => {
            await setName(value)
          }}
          multiline
          // error={touched.name && errors.name ? true : false}

          //helperText={touched.name && errors.name ? errors.name : null}
        /> */}

        <SelectInput
          key={'select-gender-prestation'}
          name='gender'
          label={'Prestation'}
          fullWidth={true}
          value={name}
          options={servicesDatasource}
          required={true}
          /*   helperText={touched.gender && errors.gender ? errors.gender : null}
              error={touched.gender && errors.gender ? true : false} */
          onChange={async ({ target: { value } }) => {
            try {
              await setName(value)
              const service = services.find((s) => s.name === value)
              if (service) {
                await setPrice(service.price)
              }
            } catch (error) {
              window.alert(error.message)
            }
          }}
        />
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <TextField
          fullWidth
          required={false}
          label={'Description de la prestation'}
          value={description}
          onChange={async ({ target: { value } }) => {
            await setDescription(value)
          }}
          multiline
          // error={touched.name && errors.name ? true : false}

          //helperText={touched.name && errors.name ? errors.name : null}
        />
      </Grid>

      <Grid item lg={6} md={6} sm={6} xs={12}>
        <SelectInput
          key={'select-quantity-patient'}
          label={'Nombre de prestations'}
          fullWidth={true}
          value={quantity}
          options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          required={true}
          onChange={async ({ target: { value } }) => {
            await setQuantity(value)
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <CurrencyTextField
          as={TextField}
          fullWidth
          //  disabled={!canModify}
          value={price}
          name='price'
          id='price'
          variant='standard'
          // disabled={!values.show_price}
          label={`Prix unitaire en ${currency}`}
          onChange={async (_event, value) => await setPrice(value)}
          /*  helperText={touched.price && errors.price ? errors.price : null}
            error={touched.price && errors.price ? true : false} */
          currencySymbol={currency}
        />
      </Grid>
      {covered && (
        <Grid item xs={12} sm={12}>
          <FormControlLabel
            control={
              <Checkbox
                id='has_insurance'
                name='has_insurance'
                checked={hasInsurance}
                onChange={async (_e, _value) => {
                  await setHasInsurance(!hasInsurance)
                }}
              />
            }
            label={label_insurance}
          />
        </Grid>
      )}

      <Grid item xs={12} sm={12}>
        <Button
          color='primary'
          //disabled={!formState.isValid}

          onClick={async (e) => {
            try {
              e.preventDefault()
              await addItem()
            } catch (error) {
              window.alert(error.message)
            }
          }}
          variant='contained'
        >
          Ajouter
        </Button>
      </Grid>
    </Grid>
  )
}
