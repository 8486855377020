import gql from "graphql-tag";

export const backEventsQuery = gql`
  query backEvents($company_id: String!) {
    backEvents(company_id: $company_id) {
      id
      title
      start
      end
      color
      rendering
      company_id
      createdAt
      updatedAt
    }
  }
`;


export const backEventsAbsenceQuery = gql`
  query absenceEvents($company_id: String!) {
    absenceEvents(company_id: $company_id) {
      id
    
      start
      end
       
      createdAt
     
    }
  }
`;


/* export const backEventsQuery = gql`
  query backEventsM($company_id: String!,$service_id:String!,$start:String!,$end:String!) {
    backEventsM(company_id: $company_id, service_id:$service_id,start:$start,end:$end) {
      
      start
      
    }
  }
`; */
