import React, { Fragment, useCallback, useState } from 'react'
import { Grid, Button, InputLabel } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'

import Loading from 'components/Loading'

import axios from 'axios'
import { BillItems } from './BillItems'
import { ModalDialog } from 'components/ModalDialog'
import { BillInput } from './BillInput'

//import { PDFReader } from 'reactjs-pdf-reader'
import moment from 'moment-timezone'
import 'moment/locale/fr'

moment.locale('fr')
const HOST_FILE = 'https://pdf.doctosen.com/api/client-bill'
const HOST_FILE_IMG = 'https://pdf.doctosen.com/images'

/* const HOST_FILE = 'http://localhost:5000/api/client-bill'
const HOST_FILE_IMG = 'http://localhost:5000/images'  */

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: '100%',
    padding: '0px 15px'
  },
  chip: {
    margin: theme.spacing(0.5)
  }
}))

export const BillCreate = ({
  logo,
  stamp,
  currency,
  time_zone,
  client,

  company,
  stamp_id,
  logo_id,
  insurance_name,
  invoice_number,
  covered,
  percentage_insurance,
  servicesDatasource,
  services
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const [loadingInvoice, setLoadingInvoice] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [, updateState] = useState()
  const forceUpdate = useCallback(() => updateState({}), [])
  const [createModal, setCreateModal] = useState(false)

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [quantity, setQuantity] = useState(1)
  const [price, setPrice] = useState(0)
  const [hasInsurance, setHasInsurance] = useState(false)
  const [items, setItems] = useState([])

  const clearForm = async () => {
    try {
      await setName('')
      await setQuantity(1)
      await setDescription('')
      await setPrice(0)
      await setHasInsurance(false)
      await setCreateModal(false)
    } catch (error) {
      window.alert(error.message)
    }
  }

  const addItem = async () => {
    try {
      let articles = items

      if (!name || !name.trim().length) {
        window.alert('La prestation est obligatoire')
        return
      }

      /*  if (!description || !description.trim().length) {
        window.alert('Décrire la prestation est obligatoire')
        return
      } */

      if (!quantity) {
        window.alert('La quantité est obligatoire')
        return
      }

      if (!price) {
        window.alert('Le prix de la prestation est obligatoire')
        return
      }

      let data = { name, quantity, price, description }

      data.total = parseInt(quantity) * parseFloat(price)

      if (covered && hasInsurance) {
        data.total_covered = (parseFloat(percentage_insurance) * data.total) / 100
      } else {
        data.total_covered = 0
      }
      if (covered && !hasInsurance) {
        data.total_covered = 0
      }

      articles.push(data)

      await setItems(articles)

      await clearForm()
    } catch (error) {
      window.alert(error.message)
    }
  }
  const removeItem = async (i) => {
    try {
      if (window.confirm('êtes vous sûr(e) de vouloir supprimer ?')) {
        let newItems = items

        await newItems.splice(i, 1)

        await setItems(newItems)

        await forceUpdate()
      }
    } catch (error) {
      throw error
    }
  }
  const generatePdf = async (e) => {
    e.preventDefault()

    //

    try {
      const date_time = Date.now()
      const filename = `facture-${company.id}-${date_time}.pdf`
      const bill_date = moment.tz(new Date(), time_zone).format('LLLL')

      await setShowModal(true)
      await setLoadingInvoice(true)

      let company_address = ''

      if (company.description_address) {
        company_address += ` ${company.description_address},`
      }

      if (company.city) {
        company_address += ` ${company.city},`
      }

      if (company.region) {
        company_address += ` ${company.region},`
      }
      const gender = client.gender == 'male' ? 'Monsieur' : 'Madame'
      let data = {
        company_address,
        filename,
        bill_date,
        covered,
        invoice_number: date_time,
        client: `${gender} ${client.lastname} ${client.name}`,
        items,

        currency,
        company_name: company.name,
        insurance_name: `${percentage_insurance}% ${insurance_name}`,
        company_phone: company.phone ? company.phone : '',
        company_email: company.email ? company.email : '',
        company_signature: company.signature ? company.signature : ''
      }

      if (stamp) {
        data.stamp = stamp
        data.stamp_id = stamp_id
      }

      if (logo) {
        data.logo = logo
        data.logo_id = logo_id
      }

      const amounts = items.map((a) => a.total)
      const covs = items.map((a) => a.total_covered)

      let total_amount = amounts.reduce(
        (a, b) => (parseFloat(a) || 0) + (parseFloat(b) || 0),
        0
      )

      let covered_amount = covs.reduce(
        (a, b) => (parseFloat(a) || 0) + (parseFloat(b) || 0),
        0
      )

      if (covered) {
        data.covered_amount = covered_amount

        data.total_amount = total_amount

        data.final_amount = data.total_amount - data.covered_amount
      } else {
        data.final_amount = total_amount
      }
      const response = await axios.post(HOST_FILE, data)
      await setLoadingInvoice(false)
      if (response.status == 200) {
        await window.open(`${HOST_FILE_IMG}/${filename}`)
      }
      console.log('response', response)
      await setLoadingInvoice(false)
    } catch (error) {
      await setLoadingInvoice(false)
      window.alert(error.message)
    }
  }

  return (
    <Fragment>
      <ModalDialog
        key='current-desease'
        open={createModal}
        cancel={async () => {
          // await props.handleReset()
          await setCreateModal(false)
        }}
        title={'Article facture'}
      >
        <BillInput
          key={'current'}
          name={name}
          servicesDatasource={servicesDatasource}
          services={services}
          setName={setName}
          setPrice={setPrice}
          price={price}
          covered={covered}
          quantity={quantity}
          setQuantity={setQuantity}
          hasInsurance={hasInsurance}
          currency={currency}
          setHasInsurance={setHasInsurance}
          description={description}
          setDescription={setDescription}
          percentage_insurance={percentage_insurance}
          addItem={addItem}
          label_insurance={`Cochez si la prestation est  couverte de ${percentage_insurance}% par la mutuelle ${insurance_name} du patient`}
        />
      </ModalDialog>
      <ModalDialog
        maxWidth={'md'}
        open={loadingInvoice}
        title={'PDF Facture'}
        cancel={async () => {
          // await props.handleReset()
          await setLoadingInvoice(false)
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            {loadingInvoice && (
              <InputLabel required style={{ textAlign: 'center' }}>
                Facture en cours de génération
              </InputLabel>
            )}
            <br />
            <br />

            <Loading />
          </Grid>
        </Grid>
      </ModalDialog>
      <form className={classes.root} noValidate autoComplete='off'>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <InputLabel required>Logo Facture</InputLabel>
            <br />
            {logo && (
              <div>
                <img src={logo} alt='logo' width='100' height='100' />
              </div>
            )}
          </Grid>

          <Grid item xs={6}>
            <div className='btn-content-generate'>
              <Button
                color='primary'
                // disabled={items.length === 0}
                type='button'
                startIcon={<AddIcon />}
                onClick={async () => {
                  await setCreateModal(true)
                }}
                variant='contained'
              >
                Ajouter un article
              </Button>
            </div>
          </Grid>

          {items.length && (
            <BillItems
              items={items}
              removeItem={removeItem}
              key={items.length}
              covered={covered}
              currency={currency}
              label_insurance={`${percentage_insurance}% assurance  ${insurance_name}`}
            />
          )}

          <Grid item xs={12}>
            <div id='pdf'></div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <Button
                color='primary'
                disabled={items.length === 0}
                type='button'
                onClick={generatePdf}
                variant='contained'
              >
                Générer facture en pdf
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  )
}
