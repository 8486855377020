export const colors = {
  PRIMARY: '#59b8cc',
  PRIMARY_LIGHT:'#4093a4',
  SECONDARY: '#e02787',
  SECONDARY_LIGHT:'#c32075',
  WARNING:'#f68092',
  WHITE: '#FFFFFF',
  LIGHT_GRAY: '#E0E0E0',
  LIGHT_GREEN: '#00FA9A',
  BLUE: '#17a2b8',
  SOFT_ORANGE: '#F8EFBA',
  ORANGE: '#FF7F50',
  SOFT_GREEN: '#dcf8c6',
  BEIGE: '#F5F5DC',
  RED: '#3b5998',
  PINK: 'white',
  // VIOLET: "#d35400"
  //VIOLET: '#3b5998',
  VIOLET: '28b5b5',
  RED: '#FF0000',
  SKY_BLUE: '#28b5b5'
}

export const GOOGLE_API_KEY = 'AIzaSyAQ4Tue3DMbcDd0-lzJ9DKPB4mfJk5P5I4'
