import React, { Fragment } from "react"
import {
  Grid,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core"
import TimeField from "react-simple-timefield"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Loading from "components/Loading"
import { MessageField } from "components/MessageField"
import { SelectField } from "components/SelectField"
import DurationHourInput from "components/DurationHourInput"
import DurationMinuteInput from "components/DurationMinuteInput"
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "100%",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}))
export const SettingEdit = ({
  values,
  touched,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  item,
  globalError,
  setFieldValue,
  optionDays,
}) => {
  const theme = useTheme()
  const classes = useStyles()

  if (!item || !item.id) {
    return null
  }
  if (isSubmitting) {
    return <Loading />
  }
  const dataSourceDays = optionDays.map((d) => d.name)

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      {globalError && <MessageField message={globalError} severity={"error"} />}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <MessageField
            message={` Durée moyenne de vos rendez-vous en heures/minutes ${
              values.slotDuration.split(":")[0]
            }h${values.slotDuration.split(":")[1]}min`}
            severity="success"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <DurationHourInput
            name={"heures"}
            handleChange={async ({ target: { value } }) => {
              if (values.slotDuration) {
                const [oldH, oldM] = values.slotDuration.split(":")
                if (oldM) {
                  await setFieldValue("slotDuration", `${value}:${oldM}`)
                } else {
                  await setFieldValue("slotDuration", `${value}:00`)
                }
              }
            }}
            id="slotDuration-hours"
            value={values.slotDuration ? values.slotDuration.split(":")[0] : ""}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <DurationMinuteInput
            name={"minutes"}
            handleChange={async ({ target: { value } }) => {
              if (values.slotDuration) {
                const [oldH, oldM] = values.slotDuration.split(":")
                if (oldH) {
                  await setFieldValue("slotDuration", `${oldH}:${value}`)
                } else {
                  await setFieldValue("slotDuration", `00:${value}`)
                }
              }
            }}
            id="slotDuration-hours"
            value={values.slotDuration ? values.slotDuration.split(":")[1] : ""}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <MessageField
            message={"Votre capacité à gérer plus d'un rendez-vous en même temps"}
            severity="success"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            label=" Capacité Rv"
            value={values.slotEventCount}
            inputProps={{ min: "1", step: "1" }}
            name="slotEventCount"
            id="slotEventCount"
            onChange={handleChange}
            type="number"
            helperText={
              touched.slotEventCount && errors.slotEventCount
                ? errors.slotEventCount
                : null
            }
            error={touched.slotEventCount && errors.slotEventCount ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <MessageField
            message={"Horaires journaliers des activités"}
            severity="success"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="startTime"
            id="startTime"
            label="Heure début d'activité"
            value={values.startTime}
            onChange={handleChange}
            type="time"
            fullWidth
            helperText={
              touched.startTime && errors.startTime ? errors.startTime : null
            }
            error={touched.startTime && errors.startTime ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="endTime"
            id="endTime"
            label="Heure fin d'activité"
            value={values.endTime}
            onChange={handleChange}
            type="time"
            fullWidth
            helperText={touched.endTime && errors.endTime ? errors.endTime : null}
            error={touched.endTime && errors.endTime ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <MessageField
            message={"Caractéristique d'interruption temporaire des activités"}
            severity="success"
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormControlLabel
            fullWidth
            control={
              <Checkbox
                color="primary"
                id="no_rest"
                name="no_rest"
                checked={values.no_rest}
                onChange={async (_e, value) => await setFieldValue("no_rest", value)}
              />
            }
            label={"Pas de pause"}
          />
        </Grid>

        {!values.no_rest && (
          <Fragment>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                name="startTimeRest"
                id="startTimeRest"
                label="Heure début pause"
                value={values.startTimeRest}
                onChange={handleChange}
                type="time"
                fullWidth
                helperText={
                  touched.startTimeRest && errors.startTimeRest
                    ? errors.startTimeRest
                    : null
                }
                error={touched.startTimeRest && errors.startTimeRest ? true : false}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                name="endTimeRest"
                id="endTimeRest"
                label="Heure fin pause"
                value={values.endTimeRest}
                onChange={handleChange}
                type="time"
                fullWidth
                helperText={
                  touched.endTimeRest && errors.endTimeRest
                    ? errors.endTimeRest
                    : null
                }
                error={touched.endTimeRest && errors.endTimeRest ? true : false}
              />
            </Grid>
          </Fragment>
        )}

        <Grid item xs={12} sm={12}>
          <MessageField message={"Jours d'activités"} severity="success" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            multiple={true}
            label={"jours  activités"}
            value={values.daysOfWeek}
            name="daysOfWeek"
            id="daysOfWeek"
            theme={theme}
            dataSource={dataSourceDays}
            disabled={false}
            helperText={
              touched.daysOfWeek && errors.daysOfWeek ? errors.daysOfWeek : null
            }
            error={touched.daysOfWeek && errors.daysOfWeek ? true : false}
            handleChange={async ({ target: { value } }) => {
              await setFieldValue("daysOfWeek", value)

              const newHiddenDays = []
              optionDays.forEach((el) => {
                if (value.indexOf(el.name) < 0) {
                  newHiddenDays.push(el.name)
                }
              })

              await setFieldValue("hiddenDays", newHiddenDays)
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SelectField
            name="hiddenDays"
            id="hiddenDays"
            helperText={
              touched.hiddenDays && errors.hiddenDays ? errors.hiddenDays : null
            }
            error={touched.hiddenDays && errors.hiddenDays ? true : false}
            handleChange={async ({ target: { value } }) => {
              await setFieldValue("hiddenDays", value)
              const newDays = []
              optionDays.forEach((el) => {
                if (value.indexOf(el.name) < 0) {
                  newDays.push(el.name)
                }
              })

              await setFieldValue("daysOfWeek", newDays)
            }}
            multiple={true}
            label={"jours hors activités"}
            value={values.hiddenDays}
            theme={theme}
            dataSource={dataSourceDays}
            disabled={false}
          />
        </Grid>
        <div>
          <Button
            style={{ marginTop: 20, marginLeft: 8 }}
            color="primary"
            //disabled={!formState.isValid}
            fullWidth
            size="large"
            type="submit"
            onClick={handleSubmit}
            variant="contained"
          >
            enregistrer
          </Button>
        </div>
      </Grid>
    </form>
  )
}
