import gql from "graphql-tag"

export const loginMutation = gql`
  mutation loginCompany($reference: String, $phone: String, $password: String) {
    loginCompany(reference: $reference, phone: $phone, password: $password) {
      ok
      errors {
        path
        message
      }
      token
      refreshtoken
      company_id
    }
  }
`
export const logoutSMutation = gql`
  mutation logoutS {
    logoutS {
      ok
    }
  }
`

export const changePasswordMutation = gql`
  mutation changePassword($password: String!, $phone: String!) {
    changePassword(password: $password, phone: $phone) {
      ok
      must_change_password
      errors {
        path
        message
      }
    }
  }
`

export const updateAccountMutation = gql`
  mutation updateAccount(
    $email: String
    $phone: String
    $name: String
    $lastname: String
    $gender: String
  ) {
    updateAccount(
      email: $email
      phone: $phone
      name: $name
      lastname: $lastname
      gender: $gender
    ) {
      ok
      gender
      name
      email
      phone
      lastname
      errors {
        path
        message
      }
    }
  }
`

export const updateProfileMutation = gql`
  mutation updateProfile(
    $id: String!
    $name: String
    $lastname: String
    $file: Upload
    $email: String
    $phone: String
    $password: String
  ) {
    updateProfile(
      id: $id
      name: $name
      lastname: $lastname
      file: $file
      email: $email
      phone: $phone
      password: $password
    ) {
      ok
      errors {
        path
        message
      }
      user {
        id
        name
        lastname
        fullname
        email
        phone
        picture
        is_active
        phone_confirmed
        roles {
          _id
          name

          is_comp_super_admin
          is_comp_admin
          is_comp_manager
          is_comp_worker
          company_id
        }
      }
    }
  }
`

export const LOGIN_USER_MUTATION = gql`
  mutation loginUser($currentUser: CurrentUserInput) {
    loginUser(currentUser: $currentUser) @client
  }
`

export const LOGOUT_USER_MUTATION = gql`
  mutation logoutUser {
    logoutUser @client
  }
`

export const createUserMutation = gql`
  mutation createUserComp(
    $company_id: String!
    $gender: String!
    $name: String!
    $lastname: String!
    $phone: String!
    $email: String
    $password: String!
    $roles: [String]!
    $sms_notify: Boolean
  ) {
    createUserComp(
      company_id: $company_id
      gender: $gender
      name: $name
      lastname: $lastname
      phone: $phone
      email: $email
      password: $password
      roles: $roles
      sms_notify: $sms_notify
    ) {
      ok
      errors {
        path
        message
      }
      user {
        id
        name
        lastname
        fullname
        email
        picture
        phone
        is_active
        phone_confirmed
        createdAt
        roles {
          _id
          name

          is_comp_super_admin
          is_comp_admin
          is_comp_manager
          is_comp_worker
          company_id
        }
      }
    }
  }
`

export const updateUserMutation = gql`
  mutation updateUserComp(
    $company_id: String!
    $user_id: String!
    $roles: [String]!
  ) {
    updateUserComp(company_id: $company_id, user_id: $user_id, roles: $roles) {
      ok
      errors {
        path
        message
      }
      user {
        id
        name
        lastname
        fullname
        email
        picture
        phone
        is_active
        phone_confirmed
        createdAt
        roles {
          _id
          name

          is_comp_super_admin
          is_comp_admin
          is_comp_manager
          is_comp_worker
          company_id
        }
      }
    }
  }
`

export const removeUserMutation = gql`
  mutation removeUserComp($company_id: String!, $id: String!) {
    removeUserComp(company_id: $company_id, id: $id) {
      ok
      errors {
        path
        message
      }
      user {
        id
      }
    }
  }
`

export const passwordLostMutation = gql`
  mutation passwordLost($email: String!) {
    passwordLost(email: $email) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const confirmPasswordLostMutation = gql`
  mutation confirmPasswordLost(
    $phone: String!
    $password_lost_code: Int!
    $reference: String
  ) {
    confirmPasswordLost(
      phone: $phone
      password_lost_code: $password_lost_code
      reference: $reference
    ) {
      ok
      errors {
        path
        message
      }
      token
      refreshtoken
      company_id
    }
  }
`;