import gql from "graphql-tag"

export const findSmsCompQuery = gql`
  query findSmsComp(
    $page: Int!
    $limit: Int
    $company_id: String!
    $from_date: String
    $to_date: String
  ) {
    findSmsComp(
      page: $page
      limit: $limit
      company_id: $company_id
      from_date: $from_date
      to_date: $to_date
    ) {
      totalDocs
      hasPrevPage
      hasNextPage
      totalPages
      limit
      offset
      page
      prevPage
      nextPage
      docs {
        id
        phone
        author {
          name
          lastname
          gender
        }
        title
        createdAt
      }
    }
  }
`
