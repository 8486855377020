import React, { useState, useEffect } from "react"
import * as Yup from "yup"
import { Formik } from "formik"
import moment from "moment-timezone"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { useQuery, useMutation } from "react-apollo"
import { backEventsAbsenceQuery } from "graphql/query/back_event"
import { COMPANY_ID_STORAGE } from "utils/static_constants"
import Loading from "components/Loading"
import { Grid, IconButton } from "@material-ui/core"
import { AbsenceToolBar } from "./components/ToolBar"
import { AbsenceCreate } from "./components/AbsenceCreate"
import { renderDate } from "utils/func"
import { oneSettingAdminQuery } from "graphql/query/setting_admin"
import { ModalDialog } from "components/ModalDialog"
import RemoveIcon from "@material-ui/icons/Delete"
import {
  createBackEventAbsenceMutation,
  removeBackEventAbsenceMutation,
} from "graphql/mutation/backevent"
import { AbsenceRemove } from "./components/AbsenceRemove"
import { colors } from "../../utils/constants"
import { currentUserQuery } from "graphql/query/user"
 

const createSchema = Yup.object().shape({
  start: Yup.string().required("champ requis"),
  end: Yup.string().required("champ requis"),
  company_id: Yup.string().required("champ requis"),
})

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },

  statsIcon: {
    cursor: "pointer",
  },
}))

export default function AbsenceView() {
  const [createModal, setCreateModal] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [globalCreateError, setGlobalCreateError] = useState("")
  const [globalRemoveError, setGlobalRemoveError] = useState("")
  const [removeModal, setRemoveModal] = useState(false)

  const [absenceRemove, setAbsenceRemove] = useState({})

  const [canModify, setCanModify] = useState(false)
  const { data: userData } = useQuery(currentUserQuery, {
    fetchPolicy: "network-only",
  })
  const roles =
    userData && userData.onlineUser && userData.onlineUser.roles
      ? userData.onlineUser.roles
      : []
  const { loading, data: backeventData } = useQuery(backEventsAbsenceQuery, {
    variables: { company_id: localStorage.getItem(COMPANY_ID_STORAGE) },
  })
  const { loading: loading1, data: generalSetting } = useQuery(oneSettingAdminQuery)
  const [createItem] = useMutation(createBackEventAbsenceMutation, {
    async update(
      cache,
      {
        data: {
          createBackEventAbsence: { ok, backevent },
        },
      }
    ) {
      try {
        if (ok) {
          const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
          const query = await cache.readQuery({
            query: backEventsAbsenceQuery,
            variables: { company_id },
          })

          let absenceEvents = query.absenceEvents
          absenceEvents.push(backevent)
          await cache.writeQuery({
            query: backEventsAbsenceQuery,

            data: {
              absenceEvents,
            },
          })
        }
      } catch (error) {
        throw error
      }
    },
  })

  const [removeItem] = useMutation(removeBackEventAbsenceMutation, {
    async update(
      cache,
      {
        data: {
          removeBackEventAbsence: { ok, id },
        },
      }
    ) {
      try {
        if (ok) {
          const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
          const query = await cache.readQuery({
            query: backEventsAbsenceQuery,
            variables: { company_id },
          })

          let absenceEvents = query.absenceEvents
          const index = absenceEvents.findIndex((e) => e.id == id)
          if (index > -1) {
            absenceEvents.splice(index, 1)
            await cache.writeQuery({
              query: backEventsAbsenceQuery,

              data: {
                absenceEvents,
              },
            })
          }
        }
      } catch (error) {
        throw error
      }
    },
  })
  const events =
    backeventData && backeventData.absenceEvents ? backeventData.absenceEvents : null
  const timezone =
    generalSetting &&
    generalSetting.oneSettingAdmin &&
    generalSetting.oneSettingAdmin.time_zone
      ? generalSetting.oneSettingAdmin.time_zone
      : "Africa/Dakar"
  const classes = useStyles()

  const create = async (
    values,

    { setSubmitting, setFieldError, resetForm }
  ) => {
    try {
      const { start, end, company_id } = values
      const now = moment()
      const strt = moment(start)
      const endt = moment(end)

      if (strt.isBefore(now)) {
        await setFieldError("start", "L'absence ne doit pas se situer dans le passé")

        return
      }
      if (strt.isSame(end)) {
        await setFieldError(
          "end",
          "la date de fin doit supérieure à la date de début"
        )

        return
      }

      if (strt.isAfter(endt)) {
        await setFieldError(
          "start",
          "la date de début ne peut être supérieure à la date de fin"
        )

        return
      }

      let backevent = {
        start,
        end,
        company_id,
      }

      const response = await createItem({
        variables: {
          company_id,
          backevent,
        },
      })

      const { ok, errors } = response.data.createBackEventAbsence

      if (ok) {
        await setCreateModal(false)

        await resetForm()
      } else {
        errors.forEach(async (error) => {
          if (error.path && error.path.toString() === "global") {
            await setGlobalCreateError(error.message)
          } else {
            await setFieldError(error.path, error.message)
            await setSubmitting(false)
          }
        })
      }
    } catch (error) {
      window.alert(error.message)
    }
  }

  const selectRemove = async (evt) => {
    try {
      await setAbsenceRemove(evt)
      await setRemoveModal(true)
    } catch (error) {
      window.alert(error.message)
    }
  }

  const deleteBack = async () => {
    try {
      if (window.confirm("êtes vous sûre de vouloir supprimer cette absence")) {
        await setLoadingDelete(true)
        const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
        const response = await removeItem({
          variables: {
            company_id,
            id: absenceRemove.id,
          },
        })
        const { ok, errors } = response.data.removeBackEventAbsence
        if (ok) {
          await setLoadingDelete(false)
          await setRemoveModal(false)

          await setAbsenceRemove(null)
        } else {
          errors.forEach(async (error) => {
            if (error.path && error.path.toString() === "global") {
              await setGlobalRemoveError(error.message)
            } else {
              await setLoadingDelete(false)
            }
          })
        }
      }
    } catch (error) {
      window.alert(error)
    }
  }

  const setUpRoles = async (roles) => {
    try {
      const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)

      const modify = roles
        .filter((r) => r.company_id && r.company_id == company_id)
        .some((r) => r.is_comp_super_admin || r.is_comp_admin)

      await setCanModify(modify)

      return true
    } catch (error) {
      throw error
    }
  }
  useEffect(() => {
    if (roles) {
      setUpRoles(roles)
        .then(() => {})
        .catch((err) => console.log("error", err))
    }
  }, [roles])
  if (loading) {
    return <Loading />
  }

  return (
    <Grid container spacing={3} style={{padding:12}}>
      {canModify && (
          <Grid item xs={12}>
            <AbsenceToolBar openModal={async () => await setCreateModal(true)} />
    
            <Formik
              initialValues={{
                start: "",
                end: "",
                company_id: localStorage.getItem(COMPANY_ID_STORAGE),
              }}
              validationSchema={createSchema}
              onSubmit={create}
            >
              {(props) => (
                <ModalDialog
                  open={createModal}
                  cancel={async () => {
                    await setCreateModal(false)

                    await props.handleReset()
                  }}
                  title={"Ajout d'une absence"}
                >
                  <AbsenceCreate {...props} globalError={globalCreateError} />
                </ModalDialog>
              )}
            </Formik>

            <ModalDialog
              open={removeModal}
              cancel={async () => {
                await setRemoveModal(false)

                await setAbsenceRemove(null)
              }}
              title={"Supprimer absence"}
            >
              <AbsenceRemove
                globalError={globalRemoveError}
                values={absenceRemove}
                handleSubmit={deleteBack}
                loading={loadingDelete}
                timezone={timezone}
              />
            </ModalDialog>
          </Grid>
 
      )}
      {events && (
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Absence</TableCell>
                  <TableCell align="right">Début</TableCell>
                  <TableCell align="right">Fin</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events.map((evt, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="right">{renderDate(evt.start)}</TableCell>
                    <TableCell align="right">{renderDate(evt.end)}</TableCell>

                    <TableCell align="right">
                     <IconButton>
                      <RemoveIcon
                        
                        onClick={async (e) => {
                          await selectRemove(evt)
                        }}
                      />
                     </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </Grid>
  )
}
