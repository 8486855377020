import React, { useState } from "react"
import { useQuery, useMutation } from "react-apollo"
import { oneEventQuery } from "../../graphql/query/event"
import Loading from "../../components/Loading"
import { COMPANY_ID_STORAGE, EVENT_PATH } from "../../utils/static_constants"
import { oneSettingAdminQuery } from "graphql/query/setting_admin"
import { EventEdit } from "../Event/components/EventEdit"
import { salerTeamUsersQuery } from "graphql/query/user"
import {
  cancelEventCompMutation,
  signalEventMutation,
  updateEventMutation,
} from "graphql/mutation/event"
import { toast } from "react-toastify"
export default function RdvView({
  match: {
    params: { id },
  },
  history,
}) {
  const [selectedWorkers, setSelectedWorkers] = useState([])
  const [updateLoading, setUpdateLoading] = useState(false)
  const [changedWorkers, setChangedWorkers] = useState(false)
  const [globalError, setGlobalError] = useState("")
  const [globalSignalError, setGlobalSignalError] = useState("")
  const { loading: loading1, data: generalSetting } = useQuery(oneSettingAdminQuery)

  const { data: salerTeamUsersData, loading: loading4 } = useQuery(
    salerTeamUsersQuery,
    {
      variables: {
        company_id: localStorage.getItem(COMPANY_ID_STORAGE),
      },
    }
  )

  const { data: oneEventData, loading: loading5 } = useQuery(oneEventQuery, {
    variables: { id },
    fetchPolicy:'cache-and-network'
  })

  const [updateItem] = useMutation(updateEventMutation)
  const [removeItem] = useMutation(cancelEventCompMutation)
  const [signalEvent] = useMutation(signalEventMutation, {
    async update(
      cache,
      {
        data: {
          signalEvent: { ok, id, finished, author_presence, event_signaled },
        },
      }
    ) {
      if (ok) {
        const { oneEvent } = await cache.readQuery({
          query: oneEventQuery,
          variables: { id },
        })

        await cache.writeQuery({
          query: oneEventQuery,
          data: {
            oneEvent: {
              ...oneEvent,
              finished,
              author_presence,
              event_signaled,
            },
          },
        })
      }
    },
  })

  if (loading1 || loading4 || loading5) {
    return <Loading />
  }
  if (!oneEventData || !oneEventData.oneEvent) {
    return null
  }
  const selectWorkers = async ({ target: { value } }) => {
    try {
      await setSelectedWorkers(value)
    } catch (error) {}
  }

  const signal = async (author_presence, id) => {
    try {
      if (window.confirm("êtes vous sûr(e) de vouloir signaler cette action ?")) {
        const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
        const res = await signalEvent({
          variables: { id, company_id, author_presence },
        })

        const { ok, errors } = res.data.signalEvent

        if (ok) {
          await setChangedWorkers(false)

          await setSelectedWorkers([])
          await setGlobalSignalError("")

          toast.success("Rendez-vous signalé avec succès")
        } else {
          errors.forEach(async (error) => {
            if (error.path && error.path.toString() === "global") {
              await setGlobalSignalError(error.message)
            }
          })
        }
      }
    } catch (error) {
      window.alert("Erreur", error.message)
    }
  }
  const removeEvent = async () => {
    try {
      if (
        window.confirm(
          "êtes vous sûr(e) de vouloir supprimer ou annuler ce rendez-vous ?"
        )
      ) {
        const company_id = await localStorage.getItem(COMPANY_ID_STORAGE)
        const res = await removeItem({
          variables: { id, company_id },
        })

        const { ok, errors } = res.data.cancelEventComp

        if (ok) {
          await history.push(`${EVENT_PATH}`)
          // await setSelectedWorkers([])
        } else {
          errors.forEach(async (error) => {
            if (error.path && error.path.toString() === "global") {
              await setGlobalError(error.message)
            }
          })
        }
      }
    } catch (error) {
      console.log("error removeEvent", error)
    }
  }

  const updateEvent = async () => {
    try {
      await setUpdateLoading(true)
      const workers = []

      salerTeamUsersData.salerTeamUsers.forEach((u) => {
        selectedWorkers.forEach((w) => {
          if (w.split("-->")[1].toString() === u.phone.toString()) {
            workers.push(u.id)
          }
        })
      })
      const variables = { id, workers }

      const res = await updateItem({
        variables,
      })

      const { ok, errors } = res.data.updateEvent

      if (ok) {
        await setUpdateLoading(false)
        await setSelectedWorkers([])
      } else {
        errors.forEach(async (error) => {
          if (error.path && error.path.toString() === "global") {
            await setGlobalError(error.message)
          }
        })
        await setUpdateLoading(false)
      }
    } catch (error) {
      console.log("error removeEvent", error)
      await setUpdateLoading(false)
    }
  }

  const timezone =
    generalSetting &&
    generalSetting.oneSettingAdmin &&
    generalSetting.oneSettingAdmin.time_zone
      ? generalSetting.oneSettingAdmin.time_zone
      : "Africa/Dakar"
  return (
    <div style={{ margin: 50 }}>
      <EventEdit
        event={oneEventData && oneEventData.oneEvent ? oneEventData.oneEvent : null}
        timezone={timezone}
        globalError={globalError}
        users={
          salerTeamUsersData && salerTeamUsersData.salerTeamUsers
            ? salerTeamUsersData.salerTeamUsers.map(
                (e) => `${e.fullname}-->${e.phone}`
              )
            : []
        }
        workers={
          oneEventData &&
          oneEventData.oneEvent &&
          oneEventData.oneEvent.workers &&
          oneEventData.oneEvent.workers.length
            ? oneEventData.oneEvent.workers:[]
        }
        valueWorkers={
          selectedWorkers &&
          !selectedWorkers.length &&
          oneEventData &&
          oneEventData.oneEvent &&
          oneEventData.oneEvent.workers &&
          oneEventData.oneEvent.workers.length
            ? oneEventData.oneEvent.workers
                .map((e) => `${e.fullname}-->${e.phone}`)
                .concat(selectedWorkers)
            : selectedWorkers
        }
        update={updateEvent}
        signal={signal}
        globalSignalError={globalSignalError}
        loading={updateLoading}
        remove={removeEvent}
        selectWorkers={selectWorkers}
      />
    </div>
  )
}
