import gql from "graphql-tag"

export const oneSiteSettingAdminQuery = gql`
  query oneSiteSettingAdmin {
    oneSiteSettingAdmin {
      id
      cgu
      cgu_comp
      phone
      phone_fix
      email
      facebook_page
      twitter_page
      instagram_page
      linkedin_page
      youtube_page
      youtube_link
      youtube_link_comp
      saler_playstore_link
      saler_appletore_link
      saler_web_link
      worker_playstore_link
      worker_appletore_link
      client_playstore_link
      client_appletore_link
      client_web_link
      picture
      createdAt
      updatedAt
    }
  }
`
