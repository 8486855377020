import gql from 'graphql-tag'

//addPicture
export const addPictureMutation = gql`
  mutation addPicture(
    $company_id: String!
    $logo: Upload
    $logo_id: String
    $picture: Upload
    $picture_id: String
    $stamp: Upload
    $stamp_id: String
  ) {
    addPicture(
      company_id: $company_id
      logo: $logo
      logo_id: $logo_id
      picture: $picture
      picture_id: $picture_id

      stamp: $stamp
      stamp_id: $stamp_id
    ) {
      ok
      errors {
        path
        message
      }
    }
  }
`

export const removePictureMutation = gql`
  mutation removePicture(
    $company_id: String!
    $logo: String
    $picture: String
    $stamp: String
  ) {
    removePicture(
      company_id: $company_id
      logo: $logo

      picture: $picture

      stamp: $stamp
    ) {
      ok
      errors {
        path
        message
      }
    }
  }
`
export const updateCompanyMutation = gql`
  mutation updateCompany(
    $company_id: String
    $name: String
    $reference: String
    $file: Upload
    $description: String
    $description_address: String
    $city: String
    $phone: String
    $facebook: String
    $twitter: String
    $instagram: String
    $linkedin: String
    $website: String
    $lat: String
    $lng: String
  ) {
    updateCompany(
      company_id: $company_id
      name: $name
      reference: $reference
      file: $file
      description: $description
      description_address: $description_address

      city: $city
      phone: $phone

      facebook: $facebook
      twitter: $twitter
      instagram: $instagram
      linkedin: $linkedin
      website: $website

      lat: $lat
      lng: $lng
    ) {
      ok
      errors {
        path
        message
      }
      company {
        id
        name
        picture
        reference
        description_address
        description
        region
        phone
        city
        lat
        lng
        facebook
        twitter
        instagram
        linkedin
        website
        createdAt
        updatedAt
        categories {
          id
          name
        }
      }
    }
  }
`
