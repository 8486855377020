import React from 'react'
import { Grid, Button, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Loading from 'components/Loading'
import { MessageField } from 'components/MessageField'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { colors, GOOGLE_API_KEY } from 'utils/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: '100%'
  },
  chip: {
    margin: theme.spacing(0.5)
  }
}))
export const CompanyEdit = ({
  values,
  touched,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  item,
  globalError,
  setFieldValue,
  setPlaceId
}) => {
  console.log('errors',errors)
  const classes = useStyles()

  if (!item || !item.id) {
    return null
  }
  if (isSubmitting) {
    return <Loading />
  }

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit}
    >
      {globalError && <MessageField message={globalError} severity={'error'} />}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            id='name'
            name='name'
            label='Nom'
            value={values.name}
            onChange={handleChange}
            error={touched.name && errors.name ? true : false}
            fullWidth
            helperText={touched.name && errors.name ? errors.name : null}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id='reference'
            name='reference'
            label='Référence'
            value={values.reference}
            onChange={handleChange}
            error={touched.reference && errors.reference ? true : false}
            fullWidth
            helperText={
              touched.reference && errors.reference ? errors.reference : null
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id='phone'
            name='phone'
            label='Téléphone'
            value={values.phone}
            onChange={handleChange}
            error={touched.phone && errors.phone ? true : false}
            fullWidth
            helperText={touched.phone && errors.phone ? errors.phone : null}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id='city'
            name='city'
            label='Ville/Quartier'
            value={values.city}
            onChange={handleChange}
            error={touched.city && errors.city ? true : false}
            fullWidth
            helperText={touched.city && errors.city ? errors.city : null}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Typography>Adresse de votre structure</Typography>
          <GooglePlacesAutocomplete
            minLengthAutocomplete={2}
            selectProps={{
              placeholder: 'Adresse',

              isSearchable: true,

              styles: {
                input: (provided) => ({
                  ...provided,
                  color: colors.PRIMARY
                }),
                option: (provided) => ({
                  ...provided,
                  color: colors.PRIMARY
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: colors.PRIMARY
                })
              },
              // value: values.description_address,

              onChange: async (value) => {
                
                if (value && value.label) {
                  //setPlaceId

                  if (value.value.place_id) {
                    await setPlaceId(value.value.place_id)
                  }
                  await setFieldValue('description_address', value.label)
                }
              },
              isClearable: true,
              defaultInputValue: values.description_address
            }}
            apiKey={GOOGLE_API_KEY}
            autocompletionRequest={{ componentRestrictions: { country: 'sn' } }}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            multiline
            rows={2}
            id='description'
            name='description'
            label='Description'
            value={values.description}
            onChange={handleChange}
            style={{ width: '100%' }}
          />
          <span></span>

          {touched.description && errors.description && (
            <span style={{ color: 'red' }}>{errors.description}</span>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id='facebook'
            name='facebook'
            label='Page facebook'
            value={values.facebook}
            onChange={handleChange}
            error={touched.facebook && errors.facebook ? true : false}
            fullWidth
            helperText={touched.facebook && errors.facebook ? errors.facebook : null}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id='twitter'
            name='twitter'
            label='Page twitter'
            value={values.twitter}
            onChange={handleChange}
            error={touched.twitter && errors.twitter ? true : false}
            fullWidth
            helperText={touched.twitter && errors.twitter ? errors.twitter : null}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id='instagram'
            name='instagram'
            label='Page instagram'
            value={values.instagram}
            onChange={handleChange}
            error={touched.instagram && errors.instagram ? true : false}
            fullWidth
            helperText={
              touched.instagram && errors.instagram ? errors.instagram : null
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id='linkedin'
            name='linkedin'
            label='Page linkedin'
            value={values.linkedin}
            onChange={handleChange}
            error={touched.linkedin && errors.linkedin ? true : false}
            fullWidth
            helperText={touched.linkedin && errors.linkedin ? errors.linkedin : null}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id='website'
            name='website'
            label='Votre site web'
            value={values.website}
            onChange={handleChange}
            error={touched.website && errors.website ? true : false}
            fullWidth
            helperText={touched.website && errors.website ? errors.website : null}
          />
        </Grid>
        {/*
        
        facebook,
          twitter,
          instagram,
          linkedin,
          website
        <Grid item xs={12} sm={6}>
          
          {item.picture && (
            <img
              src={item.picture}
              alt="company"
              style={{ width: "100%", height: 200 }}
            />
          )}
         <Typography variant="subtitle1" style={{ color: "green" }}>Photo actuelle</Typography>
          <br />
          {!item.picture && <Typography>Aucune photo</Typography>}

          <Box>
        
              <Input
                fullWidth
                type="file"
                name="file"
                id="file"
                onChange={async ({
                  target: {
                    validity,
                    files: [file],
                  },
                }) => {
                  await setFieldValue("file", file)
                }}
                style={{ display: "none" }}
              />

              <label htmlFor="file">
                <Button style={{borderRadius:'100px'}} variant="contained" component="span" color="secondary">
                  {item.picture ? "Changer de photo" : "Ajouter une photo"}
                </Button>
              </label>
              {values.file && (
                <img
                  src={URL.createObjectURL(values.file)}
                  alt="company"
                  style={{ width: "100%", height: 300 }}
                />
              )}
              <br />
              {touched.file && errors.file && (
                <span style={{ color: "red" }}>{errors.file}</span>
              )}
            </Box>
        </Grid> */}

        <Grid item xs={12}>
          <div>
            <Button
              color='primary'
              //disabled={!formState.isValid}
              type='submit'
              onClick={handleSubmit}
              variant='contained'
            >
              enregistrer
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}
