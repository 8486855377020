import gql from 'graphql-tag'

//signalEvent(id:String!,author_presence:Boolean!,company_id:String!)
export const signalEventMutation = gql`
  mutation signalEvent(
    $company_id: String!
    $author_presence: Boolean!
    $id: String!
  ) {
    signalEvent(
      company_id: $company_id
      author_presence: $author_presence
      id: $id
    ) {
      ok
      errors {
        path
        message
      }
      id
      finished
      author_presence
      event_signaled
    }
  }
`

//

export const createEventInternalCompanyMutation = gql`
  mutation createEventInternalCompany(
    $event: EventCompanyInput
    $company_id: String!
  ) {
    createEventInternalCompany(event: $event, company_id: $company_id) {
      ok
      errors {
        path
        message
      }
      event {
        id
        title
        start
        end
        company {
          id
          name
        }
        service {
          id
          name

          picture

          price
          category {
            id
            name
          }
        }
      }
    }
  }
`
export const createEventCompanyMutation = gql`
  mutation createEventCompany($event: EventCompanyInput, $company_id: String!) {
    createEventCompany(event: $event, company_id: $company_id) {
      ok
      errors {
        path
        message
      }
      event {
        id
        title
        start
        end
        company {
          id
          name
        }
        service {
          id
          name

          picture
          show_price
          price
          category {
            id
            name
          }
        }
      }
    }
  }
`

export const cancelEventCompMutation = gql`
  mutation cancelEventComp(
    $id: String!
    $company_id: String!
    $motif_cancel: String!
  ) {
    cancelEventComp(id: $id, company_id: $company_id, motif_cancel: $motif_cancel) {
      ok
      id
      errors {
        path
        message
      }
    }
  }
`

export const updateEventMutation = gql`
  mutation updateEvent($id: String!, $workers: [String]) {
    updateEvent(id: $id, workers: $workers) {
      ok

      errors {
        path
        message
      }
      event {
        id
        title
        start
        end
        event_number
        author {
          id
          name
          lastname
          fullname
        }

        workers {
          id
          name
          lastname
          fullname
          phone
        }
        service {
          id
          name

          picture
          category {
            id
            name
          }
        }
      }
    }
  }
`
export const updateEventWorkersMutation = gql`
  mutation updateEventWorkers(
    $event_id: String!
    $worker_ids: [String]!
    $company_id: String!
  ) {
    updateEventWorkers(
      event_id: $event_id
      worker_ids: $worker_ids
      company_id: $company_id
    ) {
      ok
      workers {
        id
        name
        lastname
        fullname
        phone
      }

      errors {
        path
        message
      }
    }
  }
`
