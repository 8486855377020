import gql from "graphql-tag"

export const removeNotifMutation = gql`
  mutation removeNotif($id: String!) {
    removeNotif(id: $id) {
      id
      ok
      errors {
        path
        message
      }
    }
  }
`

//updateCompNofifs

export const updateCompNofifsMutation = gql`
  mutation updateCompNofifs($company_id: String!) {
    updateCompNofifs(company_id: $company_id) {
      ok
      errors {
        path
        message
      }
    }
  }
`
