import React, { Fragment } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'

import { TableItem } from './TableItem'

export const CustomTable = ({ cols, rows, remove, keys, title }) => {
  return (
    <Fragment>
      <h5 style={{textAlign:'center'}}>{title}</h5>
      <TableContainer>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              {cols.map((row, index) => (
                <TableCell key={index}>{row}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableItem key={index} keys={keys} item={row} remove={remove} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  )
}

export default CustomTable
